import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Card, Col } from "react-bootstrap";

import "../../../App.css";
import ReportingList from "./ReportingList";

function ReportingComponent({
  gpt4TurboPrompts,
  llama2Prompts,
  palm2Prompts,
  gpt4Prompts,
  geminiPrompts,
  claudePrompts,
  promptData
}) {
  return (
    <Container className="mb-3">
      <Card as={Col} md="12" className="border-0 whi">
        <Card.Body>
          <Card.Title className="mb-4">Dashboard and Reporting</Card.Title>
          <ReportingList
            gpt4TurboPrompts={gpt4TurboPrompts}
            llama2Prompts={llama2Prompts}
            palm2Prompts={palm2Prompts}
            gpt4Prompts={gpt4Prompts}
            geminiPrompts={geminiPrompts}
            claudePrompts={claudePrompts}
            promptData={promptData}
          />
        </Card.Body>
      </Card>
      <ToastContainer />
    </Container>
  );
}

export default ReportingComponent;
