import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { BsPlus, BsX } from "react-icons/bs";

import classes from "./FormModal.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGeographies, getMarkets } from "../../services/apiService";
import GeoMarketListComponent from "../Settings/Component/GeoMarketsListComponent";

function GeoMarketFormModal({
  show,
  handleClose,
  handleAddNewBrand,
  handleAddGeoMarkets,
  handleUpdateBrand,
  isUpdate,
  selectedData,
  isInProgress,
}) {
  const geoMarkets = useSelector((state) => state.geoMarkets);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [geographyName, setgeographyName] = useState("");
  const [marketName, setMarketName] = useState("");
  const [compBrandDescription, setCompBrandDescription] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [geographies, setGeographies] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geoMarketNames, setgeoMarketNames] = useState([]);

  const [selectedOption, setSelectedOption] = useState("manual");
  const [marketSelectedOption, setMarketSelectedOption] = useState("newMarket");
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMarketItems, setSelectedMarketItems] = useState([]);
  const [selectedGeoItems, setSelectedGeoItems] = useState([]);
  const [prevBrandName, setPrevBrandName] = useState("");
  const [selectedCoreAttributes, setSelectedCoreAttributes] = useState([]);
  const [selectedPersonaNames, setSelectedPersonaNames] = useState([
    "General Persona",
  ]);

  const [geoToAdd, setGeoToAdd] = useState();
  const [geoMarketToAdd, setGeoMarketToAdd] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let geo = await getGeographies();
      let market = await getMarkets();
      setGeographies(geo?.data);
      setMarkets(market?.data);
    }

    fetchData();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleMarketOptionChange = (e) => {
    setMarketSelectedOption(e.target.value);
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (!geoToAdd) {
      errorMessage = "Please Enter/Select a Geography !";
    } else if (geoMarketToAdd && geoMarketToAdd.length === 0) {
      errorMessage = "Please Enter/select at least one market !";
    }

    return errorMessage;
  };

  const handleCheckChange = (key) => {
    if (selectedGeoItems.includes(key)) {
      setSelectedGeoItems(selectedGeoItems.filter((item) => item !== key));
    } else {
      setSelectedGeoItems([key]);
      setGeoToAdd(key);
      setgeographyName();
    }
  };

  const handleMarketCheckChange = (key) => {
    if (selectedMarketItems.includes(key)) {
      setSelectedMarketItems(
        selectedMarketItems.filter((item) => item !== key)
      );
    } else {
      setSelectedMarketItems([...selectedMarketItems, key]);
      setgeoMarketNames([...geoMarketNames, key]);
      const marketId = markets.filter((obj) => obj?.name == key)[0]?.id;
      setGeoMarketToAdd([...geoMarketToAdd, Number(marketId)]);
    }
  };

  const handleCheckCoreAttributeChange = (key) => {
    if (selectedCoreAttributes.includes(key)) {
      setSelectedCoreAttributes(
        selectedCoreAttributes.filter((item) => item !== key)
      );
    } else {
      setSelectedCoreAttributes([...selectedCoreAttributes, key]);
    }
  };

  const handleCheckPersonaChange = (key) => {
    if (selectedPersonaNames.includes(key)) {
      setSelectedPersonaNames(
        selectedPersonaNames.filter((item) => item !== key)
      );
    } else {
      setSelectedPersonaNames([...selectedPersonaNames, key]);
    }
  };

  const handleAddUpdateGeoMarket = () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }

    const geoItem =
      geographies.filter((obj) => obj?.name == geoToAdd)[0]?.id || geoToAdd;

    const payload = {
      geographyItem: geoItem,
      marketList: geoMarketToAdd,
    };

    if (isUpdate) {
      // handleUpdateBrand(selectedData?.id, payload);
    } else {
      handleAddGeoMarkets(payload);
    }
  };

  const handleGeoMarketList = () => {
    if (marketName) {
      setgeoMarketNames([...geoMarketNames, marketName]);
      setGeoMarketToAdd([...geoMarketToAdd, marketName]);
      setMarketName("");
    }
  };

  const handleDeleteCompBrand = (brandIndex) => {
    let currentgeoMarketNames = [...geoMarketNames];
    currentgeoMarketNames = currentgeoMarketNames.filter((geoName, index) => {
      return index !== brandIndex;
    });

    setgeoMarketNames(currentgeoMarketNames);
  };

  // useEffect(() => {
  //   if (isUpdate) {
  //     const coreAttributesArray = selectedData?.coreAttribute.map(
  //       (obj) => obj.core_attribute.name
  //     );

  //     const personaArray =
  //       selectedData?.persona &&
  //       selectedData?.persona.length !== 0 &&
  //       selectedData?.persona.map((obj) => {
  //         return obj?.persona?.name;
  //       });
  //     const compArray =
  //       selectedData?.competitors &&
  //       selectedData?.competitors.length !== 0 &&
  //       selectedData?.competitors.map((obj) => {
  //         return {
  //           comp_brand_name: obj?.brand?.name,
  //           comp_brand_description: obj?.brand?.description,
  //         };
  //       });
  //     setSelectedCoreAttributes(coreAttributesArray);
  //     setBrandName(selectedData?.brand?.name);
  //     setBrandDescription(selectedData?.brand?.description);
  //     setcategoryName(selectedData?.category?.name);
  //     setcategoryDescription(selectedData?.category?.description);
  //     setSelectedPersona(selectedData?.persona[0]?.persona?.name);
  //     // setCompBrandDescription(selectedData?.competitors[0]?.brand?.description);
  //     // setgeographyName(selectedData?.competitors[0]?.brand?.name);
  //     setgeoMarketNames(compArray);
  //     setSelectedPersonaNames(personaArray || []);
  //   } else {
  //     const coreAttributesArray = coreAttributes?.data?.data.map(
  //       (obj) => obj.name
  //     );
  //     setSelectedCoreAttributes(coreAttributesArray);
  //   }

  //   const prompt = getPromptForBrandCompetitorsData();
  // }, [isUpdate, selectedData]);

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          {isUpdate ? "Update Geo-markets Info" : "Add New Geo-Markets"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <p style={{ textAlign: "center" }}> Geography</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <label for="manual">
              <input
                checked={selectedOption === "manual"}
                onChange={handleOptionChange}
                type="radio"
                id="option1"
                name="options"
                value="manual"
              />{" "}
              &nbsp; Create New Geography
            </label>{" "}
            &nbsp; &nbsp;
            <label for="fromGeoList">
              <input
                checked={selectedOption === "fromGeoList"}
                onChange={handleOptionChange}
                type="radio"
                id="option2"
                name="options"
                value="fromGeoList"
              />{" "}
              &nbsp; Select Available
            </label>
          </div>
          <p> </p>
          {selectedOption === "manual" && (
            <>
              <div style={{ display: "flex" }}>
                <Form.Control
                  style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
                  as="textarea"
                  rows={1}
                  cols={2}
                  name="firstName"
                  placeholder="Geography name"
                  // className="big"
                  value={geographyName}
                  onChange={(e) => {
                    setgeographyName(e.target.value);
                    setGeoToAdd(e.target.value);
                    setSelectedGeoItems([]);
                  }}
                />
              </div>{" "}
            </>
          )}
        </Form.Group>

        {selectedOption === "fromGeoList" ? (
          <div style={{ width: "70%", margin: "auto" }}>
            <GeoMarketListComponent
              options={geographies}
              handleCheckChange={handleCheckChange}
              selectedGeoMarketItems={selectedGeoItems}
            />
          </div>
        ) : null}

        <hr />

        <Form.Group>
          <p style={{ textAlign: "center" }}> Geo-Markets</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <label for="newMarket">
              <input
                checked={marketSelectedOption === "newMarket"}
                onChange={handleMarketOptionChange}
                type="radio"
                value="newMarket"
              />{" "}
              &nbsp; Create New Market
            </label>{" "}
            &nbsp; &nbsp;
            <label for="fromMarketList">
              <input
                checked={marketSelectedOption === "fromMarketList"}
                onChange={handleMarketOptionChange}
                type="radio"
                value="fromMarketList"
              />{" "}
              &nbsp; Select Available
            </label>
          </div>
          <p> </p>
          {marketSelectedOption === "newMarket" && (
            <>
              <div style={{ display: "flex" }}>
                <Form.Control
                  style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
                  as="textarea"
                  rows={1}
                  cols={2}
                  name="firstName"
                  placeholder="Market name"
                  // className="big"
                  value={marketName}
                  onChange={(e) => setMarketName(e.target.value)}
                />

                <div
                  onClick={() => {
                    handleGeoMarketList();
                  }}
                  style={{
                    height: "29px",
                    border: "1px solid lighblack",
                    backgroundColor: "lightgrey",
                    width: "8%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <BsPlus />
                </div>
              </div>{" "}
            </>
          )}
        </Form.Group>

        <p> </p>

        {marketSelectedOption === "fromMarketList" ? (
          <div style={{ width: "70%", margin: "auto" }}>
            <GeoMarketListComponent
              options={markets}
              handleCheckChange={handleMarketCheckChange}
              selectedGeoMarketItems={selectedMarketItems}
            />
          </div>
        ) : null}

        {geoMarketNames && geoMarketNames.length !== 0 && (
          <ul style={{ padding: "12px 12px", width: "65%", margin: "auto" }}>
            {geoMarketNames.map((geoName, index) => {
              return (
                <li>
                  <div
                    onClick={() => {
                      handleDeleteCompBrand(index);
                    }}
                    style={{
                      height: "29px",
                      border: "1px solid lighblack",
                      backgroundColor: "lightgrey",
                      // width: "8%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px",
                      borderRadius: "8px",
                      padding: "0px 8px 0px 8px",
                    }}
                  >
                    {geoName} &nbsp; <BsX size={24} />
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          disabled={geoMarkets?.loading}
          onClick={() => handleAddUpdateGeoMarket()}
        >
          {geoMarkets?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div> {isUpdate ? "Update" : "Add"} </div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}

export default GeoMarketFormModal;
