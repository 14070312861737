import React, { useState } from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import classes from "../settings.module.css";
import {
  createGeoMarket,
  fetchGeoMarkets,
} from "../../../redux/actions/geoMarketsActions";

const geographyRadioOption = [
  {
    value: "new",
    label: "Create new Geography",
  },
  {
    value: "Available",
    label: "Select Available",
  },
];
const marketRadioOption = [
  {
    value: "new",
    label: "Create new Market",
  },
  {
    value: "Available",
    label: "Select Available",
  },
];

const GeographyForm = ({
  geographyName,
  setGeographyName,
  market,
  setMarket,
  handleCloseModal,
  markets,
  geographies,
  selectGeograph,
  setSelectGeograph,
  selectMarket,
  setSelectMarket,
  createNewGeoMarket,
}) => {
  const [geographyFlag, setGeographyFlag] = useState("new");
  const [marketFlag, setMarketFlag] = useState("new");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(
      geographyName,
      "userName",
      market,
      selectMarket,
      selectGeograph
    );

    let geographyValue =
      geographyFlag === "new" ? geographyName : selectGeograph;

    createNewGeoMarket(selectMarket, geographyValue);
  };
  const handleChange = (event) => {
    setGeographyFlag(event.target.value);
    console.log(event.target.value, "event");
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.divContainer}>
        <div>
          <Typography variant="subtitle1">Geography</Typography>
          <RadioButtonsGroup
            options={geographyRadioOption}
            handleChange={handleChange}
          ></RadioButtonsGroup>
        </div>
        {geographyFlag === "Available" ? (
          <Select
            value={selectGeograph}
            onChange={(e) => setSelectGeograph(e.target.value)}
            displayEmpty
            fullWidth
            margin="normal"
            className={classes.select}
            required
          >
            {geographies?.map((res) => {
              return <MenuItem value={res.id}>{res.name}</MenuItem>;
            })}
          </Select>
        ) : (
          <TextInput
            label="Geography"
            variant="outlined"
            fullWidth
            required
            value={geographyName}
            onChange={(e) => setGeographyName(e.target.value)}
            // className={classes.textField}
          />
        )}
        <div>
          <Typography variant="subtitle1">Market</Typography>
          <RadioButtonsGroup
            options={marketRadioOption}
            handleChange={(e) => setMarketFlag(e.target.value)}
            // onChange={(e) => setCompetitorOption(e.target.value)}
          ></RadioButtonsGroup>
        </div>

        {marketFlag === "Available" ? (
          <Select
            value={selectMarket} // This should be an array to handle multiple selections
            onChange={(e) => setSelectMarket(e.target.value)}
            displayEmpty
            fullWidth
            margin="normal"
            className={classes.select}
            multiple // This enables multiple selection
            required
          >
            {markets?.map((res) => {
              return (
                <MenuItem key={res.id} value={res.id}>
                  {res.name}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <TextInput
            label="Markets"
            variant="outlined"
            fullWidth
            required
            value={market}
            onChange={(e) => setMarket(e.target.value)}
            // className={classes.textField}
          />
        )}
        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            variant="contained"
            type="submit"
          >
            add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default GeographyForm;
