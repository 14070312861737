import {
    FETCH_PRODUCT_CATEGORIES_REQUEST,
    FETCH_PRODUCT_CATEGORIES_SUCCESS,
    FETCH_PRODUCT_CATEGORIES_FAILURE,
    FETCH_PRODUCT_CATEGORY_REQUEST,
    FETCH_PRODUCT_CATEGORY_SUCCESS,
    FETCH_PRODUCT_CATEGORY_FAILURE,
    CREATE_PRODUCT_CATEGORY_REQUEST,
    CREATE_PRODUCT_CATEGORY_SUCCESS,
    CREATE_PRODUCT_CATEGORY_FAILURE,
    UPDATE_PRODUCT_CATEGORY_REQUEST,
    UPDATE_PRODUCT_CATEGORY_SUCCESS,
    UPDATE_PRODUCT_CATEGORY_FAILURE,
    DELETE_PRODUCT_CATEGORY_REQUEST,
    DELETE_PRODUCT_CATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_FAILURE,
  } from '../constants/actionTypes';
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const productCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PRODUCT_CATEGORIES_REQUEST:
      case FETCH_PRODUCT_CATEGORY_REQUEST:
      case CREATE_PRODUCT_CATEGORY_REQUEST:
      case UPDATE_PRODUCT_CATEGORY_REQUEST:
      case DELETE_PRODUCT_CATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_PRODUCT_CATEGORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FETCH_PRODUCT_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map(category =>
            category.id === action.payload.id ? action.payload : category
          ),
        };
      case CREATE_PRODUCT_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: [...state.data, action.payload],
        };
      case UPDATE_PRODUCT_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map(category =>
            category.id === action.payload.id ? action.payload : category
          ),
        };
      case DELETE_PRODUCT_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.filter(category => category.id !== action.payload),
        };
      case FETCH_PRODUCT_CATEGORIES_FAILURE:
      case FETCH_PRODUCT_CATEGORY_FAILURE:
      case CREATE_PRODUCT_CATEGORY_FAILURE:
      case UPDATE_PRODUCT_CATEGORY_FAILURE:
      case DELETE_PRODUCT_CATEGORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default productCategoriesReducer;
  