import BrandSpecItem from "./../BrandSpecItem/BrandSpecItem";
import classes from "./BrandSnippets.module.css";

const THEMES = [
  {
    id: 1,
    name: "gpt-4o",
    llm: "gpt-4o",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp",
    text: "",
  },
  {
    id: 2,
    name: "Gemini",
    llm: "Gemini",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://www.gstatic.com/lamda/images/gemini_sparkle_v002_d4735304ff6292a690345.svg",
    text: "",
  },
  {
    id: 3,
    name: "Claude3",
    llm: "Claude3",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://claude.ai/images/claude_app_icon.png",
    text: "",
  },
  {
    id: 4,
    name: "llama3",
    llm: "llama3",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://static.xx.fbcdn.net/rsrc.php/y5/r/m4nf26cLQxS.ico",
    text: "",
  },
  {
    id: 5,
    name: "Perplexity",
    llm: "Perplexity",
    url: "https://brandluminaire.eastus.cloudapp.azure.com/api/llm-execute",
    img: "https://mintlify.s3-us-west-1.amazonaws.com/perplexity/_generated/favicon/favicon-32x32.png?v=3",
    text: "",
  },
];

export const mergeLLMData = (dataStructure, llmResponses, isDefinition) => {
  // Iterate over the data structure array and update the text key
  return dataStructure.map((item) => {
    // Find the corresponding LLM response based on the `llm` key
    const llmData = llmResponses[item.llm];

    // If found, update the `text` with the definition array joined as a string
    if (llmData && (llmData.definition || llmData?.phrase)) {
      const content = isDefinition ? llmData.phrase : llmData?.definition;
      item.text = content
        .map((def) => def.charAt(0).toUpperCase() + def.slice(1)) // Capitalize the first letter
        .join(", "); // Join definitions with a comma and space
    }

    return item;
  });
};

export default function BrandSnippets({ type, brandProductInfo }) {
  let brandSnippets = THEMES;
  brandSnippets = brandSnippets?.map((obj) => {
    return { ...obj, text: "Affordable, Quality, Personalization, Community" };
  });
  if (brandProductInfo != null) {
    brandSnippets = mergeLLMData(brandSnippets, brandProductInfo, true);
  }

  return (
    <div className={classes.container}>
      <BrandSpecItem
        data={brandSnippets}
        title={type === "Company/Brand" ? "Brand Snippets" : "Product Snippets"}
      />
    </div>
  );
}
