import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

const ReVerticalMultibarChart = ({ data, label }) => {
  const top10Objects = data
    .sort((a, b) => {
      const aValue = Object.values(a).find((v) => typeof v === "number"); // Get dynamic value
      const bValue = Object.values(b).find((v) => typeof v === "number");
      return bValue - aValue; // Sort by descending order
    })
    .slice(0, 10);
  console.log("data==>", data, top10Objects);
  // Preprocess data to remove null values and avoid empty spaces in bars
  const filteredData = top10Objects?.map((item) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (newItem[key] === null || newItem[key] === undefined) {
        delete newItem[key];
      }
    });
    return newItem;
  });

  // Get all unique keys from the data array
  const allKeys = Array.from(
    new Set(
      filteredData?.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "name")
      )
    )
  );

  const llmColorsMapping = {
    "gpt-4o": "#C7F0EC",
    Claude3: "#FF6F6F",
    Gemini: "#CEB4FF",
    llama3: "#FFCE6E",
    Perplexity: "#92B7FE",
    "llm-web": "#FEE7E6",
  };

  const barSize = 30; // Fixed width for each bar
  const margin = { top: 20, right: 20, left: 40, bottom: 5 };

  // Calculate the chart width based on the number of keys (brands) and attributes
  const chartWidth = allKeys.length * barSize + margin.left + margin.right;

  const BAR_HEIGHT = 75; // Height per bar
  const MIN_HEIGHT = 300; // Minimum height for the chart

  const chartHeight = Math.max(MIN_HEIGHT, filteredData?.length * BAR_HEIGHT);

  return (
    <>
      <h5 className="me-3">{label}</h5>

      {!data || data?.length == 0 ? (
        <EmptyView message="Data Not Available" />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={chartHeight}
          style={{ backgroundColor: "white" }}
        >
          <BarChart
            layout="vertical"
            data={filteredData}
            width={chartWidth} // Dynamically calculated width
            height={300}
            //   data={data}
            margin={margin}
            barCategoryGap="20%" // Adjust the gap between bars in a category
          >
            <XAxis
              type="number" // X-axis now represents the values
              domain={[0, 100]} // Set limit from 0 to 100
              tickCount={11} // Number of ticks including 0
              tickLine={false}
              tick={{
                fill: "#b6b7b8",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
              }}
              axisLine={{ stroke: "#b6b7b8", strokeWidth: 1 }}
            />
            <YAxis
              dataKey="name" // Y-axis now represents the names
              type="category"
              tickLine={false}
              tick={{
                // fill: "#808080",
                fontFamily: "Roboto Flex, sans-serif",
                fontSize: 14,
              }}
              width={150} // Increase the width to allow for wrapping
              axisLine={{ stroke: "white" }}
              padding={{ top: 10, bottom: 10 }}
            />

            <Tooltip />

            {allKeys.map((brand, index) => (
              <Bar
                key={brand}
                dataKey={brand}
                fill={
                  llmColorsMapping?.[brand] ||
                  `#${Math.floor(Math.random() * 16777215).toString(16)}`
                } // Random color
                barSize={barSize} // Fixed bar width for better visibility
                isAnimationActive={true}
                radius={[10, 10, 10, 10]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ReVerticalMultibarChart;
