import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";

import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../../Component/UI/Table/Table";
import MUICard from "./../../../../Component/UI/MUICard/MUICard";
import classes from "./CoreAttributes.module.css";
import { extractDomain } from "../../../../utils/helpers";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

const TABLE_HEADERS = [
  {
    id: "attr",
    name: "Brand Pillars",
  },
];

export function compareScores(currentData, previousData) {
  // Helper function to find score by brand name in a list
  const findBrandScore = (scores, brandName) => {
    return scores.find((scoreObj) => scoreObj.name === brandName);
  };

  return currentData?.map((category) => {
    // Find corresponding category in previous data
    const prevCategory = previousData?.find(
      (prev) => prev.name === category.name
    );

    // Map through each score in the current category
    const updatedScores = category.scores.map((currentScore) => {
      // Find previous score for the same brand (if exists)
      const prevScoreObj = prevCategory
        ? findBrandScore(prevCategory.scores, currentScore.name)
        : null;

      // Calculate delta
      const prevScore = prevScoreObj ? prevScoreObj.score : null;
      const delta =
        prevScore !== null && prevScore !== 0
          ? currentScore.score - prevScore
          : null;

      // Add delta to current score object
      return {
        ...currentScore,
        delta: delta,
      };
    });

    // Return updated category with scores containing delta
    return {
      ...category,
      scores: updatedScores,
    };
  });
}

export default function CoreAttributes({
  coreAttributesData,
  pastcoreAttributesData,
  brandDomains,
}) {
  function formatName(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function extractUniqueBrands(data) {
    if (data && data.length) {
      // Find the attribute with the maximum number of competitors (brands)
      let maxCompetitorAttribute = data.reduce((maxAttr, currentAttr) => {
        return currentAttr.scores.length > maxAttr.scores.length
          ? currentAttr
          : maxAttr;
      });

      // Get the unique brand names from that attribute
      const uniqueBrands = new Set();
      maxCompetitorAttribute.scores.forEach((brandObj) => {
        uniqueBrands.add(brandObj.name);
      });

      // Create an array of objects with unique id and brand name
      const brandArray = Array.from(uniqueBrands).map((brandName, index) => ({
        id: index + 1, // Unique ID starting from 1
        name: brandName,
      }));

      return brandArray;
    }
  }

  function renderRows(item, index) {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell className={classes.attribute_headings}>
          {formatName(item.name)}
        </StyledTableCell>
        {item.scores.map((item, index) => {
          let color = "#dc3545";
          if (item.score >= 7) color = "#2196F3";
          if (item.score >= 9) color = "#198754";

          return (
            <Tooltip
              key={index}
              title={item.explanation}
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -18],
                      },
                    },
                  ],
                },
              }}
            >
              <StyledTableCell
                style={{ color }}
                className={classes.attribute_score}
              >
                {Number(item.score).toFixed(0)} 
                {/* &nbsp; &nbsp; {item.delta !== null && (
                  <span style={{ color: "grey" }}>
                    <FontAwesomeIcon
                      className={
                        item.delta < 0
                          ? `${classes["core-down-icon"]} text-danger me-1`
                          : `${classes["core-up-icon"]} text-success me-1`
                      }
                      icon={item.delta < 0 ? faSortDown : faSortUp}
                    />
                    <span className={classes.coreDelta}>
                      {Number(Math.abs(item.delta)).toFixed(0)}
                    </span>
                  </span>
                )} */}
              </StyledTableCell>
            </Tooltip>
          );
        })}
      </StyledTableRow>
    );
  }

  function renderHeaders() {
    const headers = [
      ...TABLE_HEADERS,
      ...extractUniqueBrands(coreAttributesData),
    ];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers.map((header, index) => {
            let domain = extractDomain(brandDomains?.[header?.name]);

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const coreAttributesDataWithDelta = compareScores(
    coreAttributesData,
    pastcoreAttributesData
  );

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      {coreAttributesDataWithDelta && coreAttributesDataWithDelta.length > 0 ? (
        <Table
          elevation={0}
          data={coreAttributesDataWithDelta}
          renderHeaders={() => renderHeaders(coreAttributesData)}
          render={renderRows}
        />
      ) : (
        <>
          <h5> Brand Pillars </h5>
          <EmptyView message="Data Not Available" />
        </>
      )}
    </MUICard>
  );
}
