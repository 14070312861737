// option 1 ==> line chart with attributes at y-axis

// import React from "react";
// import { Line } from "react-chartjs-2";
// import Chart from "chart.js/auto";

// const MonitoringLineChart = ({ monitoringData }) => {
// let dataToDisplay = [...monitoringData]
//   // Extracting all unique attribute names
//   const allAttributes = dataToDisplay.reduce((acc, data) => {
//     Object.keys(data).forEach((attr) => {
//       if (!acc.includes(attr)) {
//         acc.push(attr);
//       }
//     });
//     return acc;
//   }, []);

// //Function to generate random color
// const getRandomColor = () => {
//   return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
//     Math.random() * 256
//   )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
// };

//   // Organizing data for Chart.js
//   const data = {
//     labels: allAttributes, // Labels are now the attributes
//     datasets: dataToDisplay.map((data, index) => ({
//       label: `Week ${index + 1}`,
//       backgroundColor: getRandomColor(),
//       borderColor: getRandomColor(),
//       borderWidth: 1,
//       data: allAttributes.map((attr) => data[attr] || 0), // If an attribute is missing, set its strength to 0
//     })),
//   };

//   const options = {
//     indexAxis: "y", // Renders the bars horizontally
//     skipNull: true,
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             weight: "bold", // Make legend text bold
//           },
//         },
//         borderWidth: 2,
//       },
//     },
//     scales: {
//       x: {
//         type: "linear",
//         position: "bottom",
//         min: 0,
//         max: 100, // Set max value based on type
//         ticks: {
//           stepSize: 10, // Adjust step size based on type
//           font: {
//             weight: "bold",
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//       y: {
//         ticks: {
//           font: {
//             weight: "bold", // Making all labels bold
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       {/* <h2>Weekly Line Chart</h2> */}
//       <Line data={data} options={options} height={allAttributes.length*15} />
//     </div>
//   );
// };

// export default MonitoringLineChart;

// option 2 ==> line chart with frequency at x-axis

// import React from "react";
// import { Line } from "react-chartjs-2";

// const MonitoringLineChart = ({ monitoringData }) => {
//   // Extracting all unique attribute names
//   let dataToDisplay = [...monitoringData]
//   const allAttributes = dataToDisplay.reduce((acc, data) => {
//     Object.keys(data).forEach((attr) => {
//       if (!acc.includes(attr)) {
//         acc.push(attr);
//       }
//     });
//     return acc;
//   }, []);

//   // Function to generate random color
//   const getRandomColor = () => {
//     return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
//       Math.random() * 256
//     )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
//   };

//   // Organizing data for Chart.js
//   const data = {
//     labels: dataToDisplay.map((data, index) => `Week ${index + 1}`), // Labels are now the weeks
//     datasets: allAttributes.map((attr, index) => ({
//       label: attr,
//       backgroundColor: getRandomColor(), // Random color for each attribute
//       borderColor: getRandomColor(),
//       borderWidth: 1,
//       data: dataToDisplay.map((data) => data[attr] || 0), // If an attribute is missing, set its strength to 0
//     })),
//   };

//   const options = {
//     indexAxis: "x", // Renders the bars vertically
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             weight: "bold", // Make legend text bold
//           },
//         },
//         borderWidth: 2,
//       },
//     },
//     scales: {
//       x: {
//         ticks: {
//           font: {
//             weight: "bold",
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//       y: {
//         type: "linear",
//         position: "left",
//         min: 0,
//         max: 100, // Set max value based on type
//         ticks: {
//           stepSize: 10, // Adjust step size based on type
//           font: {
//             weight: "bold", // Making all labels bold
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       {/* <h2>Weekly Line Chart</h2> */}
//       <Line data={data} options={options} height={(dataToDisplay.length*10)+100} />
//     </div>
//   );
// };

// export default MonitoringLineChart;

// option 3 ==> stacked bar with frequency on y-axis

import React from "react";
import { Bar } from "react-chartjs-2";

// export const MonitoringStackedBarChart1 = ({ monitoringData }) => {
//   // Extracting all unique attribute names
//   let dataToDisplay = [...monitoringData];
//   const allAttributes = dataToDisplay.reduce((acc, data) => {
//     Object.keys(data).forEach((attr) => {
//       if (!acc.includes(attr)) {
//         acc.push(attr);
//       }
//     });
//     return acc;
//   }, []);

//   // Function to generate random color
//   const getRandomColor = () => {
//     return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
//       Math.random() * 256
//     )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
//   };

//   // Organizing data for Chart.js
//   const data = {
//     datasets: allAttributes.map((attr, index) => ({
//       label: attr,
//       backgroundColor: getRandomColor(), // Random color for each attribute
//       data: dataToDisplay.map((data) => data[attr] || null), // If an attribute is missing, set its strength to 0
//     })),
//     labels: dataToDisplay.map((data, index) => `Week ${index + 1}`), // Labels are now the weeks
//   };

//   const options = {
//     indexAxis: "y", // Renders the bars horizontally
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             weight: "bold", // Make legend text bold
//           },
//         },
//       },
//     },
//     scales: {
//       y: {
//         stacked: true,
//         ticks: {
//           font: {
//             weight: "bold",
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//       x: {
//         stacked: true,
//         type: "linear",
//         beginAtZero: true,
//         ticks: {
//           stepSize: 30, // Adjust step size based on type
//           font: {
//             weight: "bold", // Making all labels bold
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//     },

//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     // Adjust bar width
//     barPercentage: 0.2, // Adjust width of bars
//     categoryPercentage: 0.8,
//   };

//   return (
//     <div>
//       {/* <h2>Weekly Stacked Bar Chart</h2> */}
//       <Bar
//         data={data}
//         options={options}
//         height={dataToDisplay.length * 10 + 100}
//       />
//     </div>
//   );
// };

// // option 4 ==> stacked bar with attributes on y-axis

// export const MonitoringStackedBarChart2 = ({ monitoringData }) => {
//   // Extracting all unique attribute names
//   let dataToDisplay = [...monitoringData];
//   const allAttributes = dataToDisplay.reduce((acc, data) => {
//     Object.keys(data).forEach((attr) => {
//       if (!acc.includes(attr)) {
//         acc.push(attr);
//       }
//     });
//     return acc;
//   }, []);

//   // Function to generate random color for each week
//   const getRandomColor = (index) => {
//     return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
//       Math.random() * 256
//     )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
//   };

//   // Organizing data for Chart.js
//   const datasets = dataToDisplay.map((data, weekIndex) => ({
//     label: `Week ${weekIndex + 1}`,
//     backgroundColor: getRandomColor(weekIndex),
//     data: allAttributes.map((attr) => data[attr] || null), // If an attribute is missing, set its strength to 0
//   }));

//   const data = {
//     labels: allAttributes, // Labels are now the attributes
//     datasets: datasets,
//   };

//   const options = {
//     indexAxis: "y", // Renders the bars horizontally
//     responsive: true,
//     skipNull: true,
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             weight: "bold", // Make legend text bold
//           },
//         },
//         // borderWidth: 2,
//       },
//     },
//     scales: {
//       x: {
//         stacked: true,
//         type: "linear",
//         position: "bottom",
//         beginAtZero: true,
//         ticks: {
//           stepSize: 30, // Adjust step size based on type
//           font: {
//             weight: "bold", // Making all labels bold
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//       y: {
//         stacked: true,
//         ticks: {
//           font: {
//             weight: "bold",
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//     },
//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     // Adjust bar width
//     barPercentage: 0.5, // Adjust width of bars
//     // categoryPercentage: 0.8,
//   };

//   return (
//     <div>
//       <Bar data={data} options={options} />
//     </div>
//   );
// };

// // option 5 ==> multibars with frequency on y-axis..

// export const MonitoringMultiBarChart1 = ({ monitoringData }) => {
//   // Extracting all unique attribute names
//   let dataToDisplay = [...monitoringData];
//   const allAttributes = dataToDisplay.reduce((acc, data) => {
//     Object.keys(data).forEach((attr) => {
//       if (!acc.includes(attr)) {
//         acc.push(attr);
//       }
//     });
//     return acc;
//   }, []);

//   // Function to generate random color for each week
//   const getRandomColor = (index) => {
//     return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
//       Math.random() * 256
//     )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
//   };

//   // Organizing data for Chart.js
//   const datasets = allAttributes.map((attr, attrIndex) => ({
//     label: attr,
//     backgroundColor: getRandomColor(attrIndex),
//     data: dataToDisplay.map((data) => data[attr] || null), // If an attribute is missing, set its strength to 0
//   }));

//   const data = {
//     labels: dataToDisplay.map((_, weekIndex) => `Week ${weekIndex + 1}`), // Labels are now the weeks
//     datasets: datasets,
//   };

//   const options = {
//     indexAxis: "y", // Renders the bars horizontally
//     responsive: true,
//     skipNull: true,
//     plugins: {
//       legend: {
//         position: "top",
//         labels: {
//           font: {
//             weight: "bold", // Make legend text bold
//           },
//         },
//         // borderWidth: 2,
//       },
//     },
//     scales: {
//       x: {
//         stacked: false, // No longer stacking bars
//         type: "linear",
//         position: "bottom",
//         beginAtZero: true,
//         ticks: {
//           stepSize: 10, // Adjust step size based on type
//           font: {
//             weight: "bold", // Making all labels bold
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//       y: {
//         stacked: false, // No longer stacking bars
//         ticks: {
//           font: {
//             weight: "bold",
//             size: 12,
//             color: "rgba(0, 0, 0, 1)",
//           },
//         },
//       },
//     },
//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     // Adjust bar width
//     barPercentage: 0.5, // Adjust width of bars
//     // categoryPercentage: 0.8,
//   };

//   return (
//     <div>
//       <Bar data={data} options={options} />
//     </div>
//   );
// };

// option 6 ==> multibar with attibutes on y-axis

export const MonitoringMultiBarChart2 = ({
  monitoringData,
  frequency,
  type,
  focusAttribute,
}) => {
  // Extracting all unique attribute names
  let dataToDisplay = monitoringData.filter((data) => {
    return data !== null;
  });

  const allAttributes = dataToDisplay.reduce((acc, data) => {
    if (data || data !== null) {
      Object.keys(data).forEach((attr) => {
        if (!acc.includes(attr)) {
          acc.push(attr);
        }
      });
      return acc;
    }
  }, []);

  const FREQUENCY_MAPPING = {
    Weekly: "Week",
    Monthly: "Month",
    Daily: "Day",
  };

  // Function to generate random color for each attribute
  // const getRandomColor = (index) => {
  //   return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
  //     Math.random() * 256
  //   )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
  // };

  function generateColor(index) {
    const hue = ((index + 2) * 137.508) % 360; // Use the golden ratio to ensure distinct hues
    const saturation = 70 + ((index * 15) % 30); // Vary saturation between 70% and 100%
    const lightness = 50 + ((index * 10) % 20); // Vary lightness between 50% and 70%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  // Organizing data for Chart.js
  const datasets = dataToDisplay.map((data, weekIndex) => ({
    label:
      weekIndex === 0
        ? `Baseline`
        : `${FREQUENCY_MAPPING[frequency]}${weekIndex}`,
    backgroundColor: generateColor(weekIndex),
    barThickness: 28,
    data: allAttributes.map((attr) => data?.[attr] || null), // If an attribute is missing, set its strength to 0
  }));

  const data = {
    labels: allAttributes, // Labels are now the attributes
    datasets: datasets,
  };

  const options = {
    indexAxis: "y", // Renders the bars horizontally
    skipNull: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            weight: "bold", // Make legend text bold
          },
        },
        // borderWidth: 2,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        min: 0,
        max: 100, // Set max value based on type
        ticks: {
          stepSize: 10, // Adjust step size based on type
          font: {
            weight: "bold",
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
      y: {
        ticks: {
          font: {
            weight: "bold", // Making all labels bold
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
    },
  };

  // const options = {
  //   indexAxis: "y", // Renders the bars vertically
  //   responsive: true,
  //   skipNull: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //       labels: {
  //         font: {
  //           weight: "bold", // Make legend text bold
  //         },
  //       },
  //       // borderWidth: 0,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       stacked: false,
  //       ticks: {
  //         font: {
  //           weight: "bold", // Making all labels bold
  //           size: 12,
  //           color: "rgba(0, 0, 0, 1)",
  //         },
  //       },
  //     },
  //     y: {
  //       stacked: false,
  //       beginAtZero: true,
  //       ticks: {
  //         font: {
  //           weight: "bold",
  //           size: 12,
  //           color: "rgba(0, 0, 0, 1)",
  //         },
  //       },
  //     },
  //   },
  //   layout: {
  //     padding: {
  //       left: 20,
  //       right: 20,
  //       top: 20,
  //       bottom: 20,
  //     },
  //   },
  //   // Adjust bar width
  //   barPercentage: 0.5, // Adjust width of bars
  //   // categoryPercentage: 0.8,
  // };

  let scaleFactor;
  switch (dataToDisplay.length) {
    case 1:
      scaleFactor = 1.4;
      break;
    case 2:
      scaleFactor = 1.7;
      break;
    case 3:
      scaleFactor = 2.4;
      break;
    default:
      scaleFactor = 2.4 + (dataToDisplay.length/5);
  }

  return (
    <>
      {dataToDisplay && dataToDisplay.length !== 0 && (
        <>
          <p style={{ padding: "5px", fontWeight: "600" }}>
            {focusAttribute === "Top 5 Brands in Category" ||
            focusAttribute === "Top 5 Products in Category"
              ? `Average Rank: ${focusAttribute}`
              : `Signal Strength: ${type}`}
            {/* {type === "Sources" ? `Signal Strength: ${type}` : ""} */}
          </p>
          <div>
            <Bar
              data={data || []}
              options={options}
              height={data.labels.length * scaleFactor}
              width={15}
              // height={dataToDisplay.length*95}
            />
          </div>
          <p style={{ textAlign: "center", padding: "5px", fontWeight: "600" }}>
            {focusAttribute === "Top 5 Brands in Category" ||
            focusAttribute === "Top 5 Products in Category"
              ? `Average Rank (%)`
              : `Signal Strength (%)`}
          </p>
        </>
      )}
    </>
  );
};
