import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { BsPlus, BsX } from "react-icons/bs";
import { fetchGpt4API } from "../../services/apiService";
import CoreAttributesSelectionComponent from "../Settings/Component/CoreAttributesSelectionComponent";
import CompetitorListComponent from "../Settings/Component/CompetitorListComponent";
import { getPromptForBrandCompetitorsData } from "../../utils/helpers";
import classes from "./FormModal.module.css";
import JSON5 from "json5";
import PersonaSelectionComponent from "../Settings/Component/PersonaSelectionComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BrandProductFormModal({
  show,
  handleClose,
  handleAddBrandProduct,
  handleUpdateBrandProduct,
  isUpdate,
  selectedData,
  isInProgress,
}) {
  const brands = useSelector((state) => state.brands);
  const brandProducts = useSelector((state) => state.brandProducts);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const personas = useSelector((state) => state.personas);
  const [productName, setproductName] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [compProductName, setcompProductName] = useState("");
  const [compProductDescription, setcompProductDescription] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCoreAttributes, setSelectedCoreAttributes] = useState([]);
  const [selectedOption, setSelectedOption] = useState("manual");
  const [selectedCompetitorItems, setselectedCompetitorItems] = useState([]);
  const [compBrands, setCompBrands] = useState([]);
  const [compBrandName, setCompBrandName] = useState("");
  const [compBrandDescription, setCompBrandDescription] = useState("");
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevBrandName, setPrevBrandName] = useState("");
  const [selectedPersonaNames, setSelectedPersonaNames] = useState(['General Persona']);

  const handleBrandList = () => {
    if (compBrandName && compBrandDescription) {
      const obj = {
        comp_product_name: compBrandName,
        comp_product_description: compBrandDescription,
      };
      setCompBrands([...compBrands, obj]);
    }
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (!selectedBrand) {
      errorMessage = "Please select a brand !";
    } else if (!productName) {
      errorMessage = "Please Enter a Product Name !";
    } else if (!categoryName) {
      errorMessage = "Please Enter a Product Category Name !";
    } else if (selectedPersonaNames && selectedPersonaNames.length == 0) {
      errorMessage = "Please select a persona !";
    }

    return errorMessage;
  };

  const handleCheckChange = (key) => {
    if (selectedCompetitorItems.includes(key)) {
      setselectedCompetitorItems(
        selectedCompetitorItems.filter((item) => item !== key)
      );
    } else {
      setselectedCompetitorItems([...selectedCompetitorItems, key]);
    }
  };

  const handleDeleteCompBrand = (brandIndex) => {
    let currentCompBrands = [...compBrands];
    currentCompBrands = currentCompBrands.filter((brandInfo, index) => {
      return index !== brandIndex;
    });

    setCompBrands(currentCompBrands);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setselectedCompetitorItems([]);
    setCompBrands([]);
  };

  const handleCheckPersonaChange = (key) => {
    if (selectedPersonaNames.includes(key)) {
      setSelectedPersonaNames(
        selectedPersonaNames.filter((item) => item !== key)
      );
    } else {
      setSelectedPersonaNames([...selectedPersonaNames, key]);
    }
  };

  useEffect(() => {
    if (selectedOption === "throughAi" && productName) {
      async function getCompetitorsData() {
        if (prevBrandName !== productName) {
          let prompt = getPromptForBrandCompetitorsData(productName, "product");
          setIsLoading(true);
          let result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Brand-onboarding"
          );
          result = result["GPT-4"][0];

          // Match the array of objects using the regular expression
          let matchedArray = result.match(
            /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
          );

          if (matchedArray) {
            // Parse the matched array into a JavaScript object
            let resultArray = JSON5.parse(matchedArray[0]);
            // console.log(resultArray);
            setIsLoading(false);
            setCompetitorsFromAi(resultArray);
            setPrevBrandName(productName);
          }
        }
      }

      getCompetitorsData();
    }
  }, [selectedOption]);

  console.log('selecteddata==>', selectedData)

  useEffect(() => {
    if (isUpdate) {
      const coreAttributesArray =
        selectedData?.customer_brand_product_core_attributes.map(
          (obj) => obj.core_attribute.name
        );

      const personaArray =
        selectedData?.custProduct &&
        selectedData?.custProduct.length !== 0 &&
        selectedData?.custProduct.map((obj) => {
          return obj?.persona?.name;
        });
      const compArray =
        selectedData?.customer_competitor_brand_products &&
        selectedData?.customer_competitor_brand_products.length !== 0 &&
        selectedData?.customer_competitor_brand_products.map((obj) => {
          return {
            comp_product_name: obj?.brand_product?.name,
            comp_product_description: obj?.brand_product?.description,
          };
        });

      setSelectedCoreAttributes(coreAttributesArray);
      setproductName(selectedData?.product?.name);
      setSelectedBrand(selectedData?.brand_product?.brand?.name);
      setproductDescription(selectedData?.brand_product?.description);
      setcategoryName(
        selectedData?.customer_brand_product_categories[0]?.category?.name
      );
      setcategoryDescription(
        selectedData?.customer_brand_product_categories[0]?.category
          ?.description
      );
      setSelectedPersona(
        selectedData?.custProduct[0]?.persona?.name
      );
      // setcompProductDescription(
      //   selectedData?.customer_competitor_brand_products[0]?.brand_product
      //     ?.description
      // );
      // setcompProductName(
      //   selectedData?.customer_competitor_brand_products[0]?.brand_product?.name
      // );

      setCompBrands(compArray);
      setSelectedPersonaNames(personaArray);
    } else {
      const coreAttributesArray = coreAttributes?.data?.data.map(
        (obj) => obj.name
      );
      setSelectedCoreAttributes(coreAttributesArray);
    }
  }, [isUpdate, selectedData]);

  const handleAddBrandProductInfo = () => {
    // const persona_id = personas?.data?.data.filter(
    //   (persona) => persona?.persona?.name === selectedPersona
    // )[0]?.id;

    const brand_id = brands?.data?.data.filter(
      (brand) => brand?.brand?.name === selectedBrand
    )[0]?.brand?.id;

    const coreAttribtesArray = coreAttributes?.data?.data.filter(
      (coreAttribute) => selectedCoreAttributes.includes(coreAttribute?.name)
    );

    const personasArray = personas?.data?.data
      .filter((persona) =>
        selectedPersonaNames.includes(persona?.persona?.name)
      )
      .map((obj) => obj?.persona_id);

    let compArray;
    if (selectedCompetitorItems && selectedCompetitorItems.length !== 0) {
      const competitor = competitorsFromAi.filter((obj) => {
        return selectedCompetitorItems.includes(obj.comp_product_name);
      });

      compArray = competitor;
    } else {
      compArray = compBrands;
    }
    const payload = {
      product_name: productName,
      product_description: productDescription,
      category_name: categoryName,
      category_description: categoryDescription,
      comp_products: compArray,
      personaIds: personasArray,
      brand_id: brand_id,
      coreAttributesList: coreAttribtesArray,
    };

    // console.log('test==>', payload)
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }

    if (isUpdate) {
      handleUpdateBrandProduct(selectedData?.id, payload);
    } else {
      handleAddBrandProduct(payload);
    }
  };

  const handleCheckCoreAttributeChange = (key) => {
    if (selectedCoreAttributes.includes(key)) {
      setSelectedCoreAttributes(
        selectedCoreAttributes.filter((item) => item !== key)
      );
    } else {
      setSelectedCoreAttributes([...selectedCoreAttributes, key]);
    }
  };

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          {isUpdate ? "Update Product Info" : "Add New Product"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <p style={{ textAlign: "center" }}>Brand</p>
          <Form.Control
            as="select"
            value={selectedBrand}
            onChange={(e) => {
              setSelectedBrand(e.target.value);
            }}
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          >
            <option value="">select Brand</option>
            {brands?.data?.data &&
              brands?.data?.data.length !== 0 &&
              brands?.data?.data
                .filter((obj) => obj?.is_active)
                .map((brand) => (
                  <option value={brand?.brand?.name}>
                    {brand?.brand?.name}
                  </option>
                ))}
          </Form.Control>
        </Form.Group>
        <p></p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Product Name"
          // className="big"
          value={productName}
          onChange={(e) => {
            setproductName(e.target.value);
            setSelectedOption("manual");
          }}
        />
        <p> </p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Product description"
          // className="big"
          value={productDescription}
          onChange={(e) => setproductDescription(e.target.value)}
        />
        <hr />
        <Form.Group>
          <p style={{ textAlign: "center" }}> Product Category</p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Product category name"
            // className="big"
            value={categoryName}
            onChange={(e) => setcategoryName(e.target.value)}
          />
          <p> </p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Product category description"
            // className="big"
            value={categoryDescription}
            onChange={(e) => setcategoryDescription(e.target.value)}
          />
        </Form.Group>
        <hr />
        {/* <Form.Group>
          <p style={{ textAlign: "center" }}> Product Competitor</p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Competitor product name"
            // className="big"
            value={compProductName}
            onChange={(e) => setcompProductName(e.target.value)}
          />
          <p> </p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Competitor product description"
            // className="big"
            value={compProductDescription}
            onChange={(e) => setcompProductDescription(e.target.value)}
          />
        </Form.Group> */}

        <Form.Group>
          <p style={{ textAlign: "center" }}> Product Competitor</p>
          <div style={{ display: "flex" }}>
            <label for="manual">
              <input
                checked={selectedOption === "manual"}
                onChange={handleOptionChange}
                type="radio"
                id="option1"
                name="options"
                value="manual"
              />{" "}
              &nbsp; Enter Competitors Manually
            </label>{" "}
            &nbsp; &nbsp;
            <label for="throughAi">
              <input
                checked={selectedOption === "throughAi"}
                onChange={handleOptionChange}
                type="radio"
                id="option2"
                name="options"
                value="throughAi"
              />{" "}
              &nbsp; Get Competitors from AI
            </label>
          </div>
          <p> </p>
          {selectedOption === "manual" && (
            <>
              {" "}
              <Form.Control
                style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
                as="textarea"
                rows={1}
                cols={2}
                name="firstName"
                placeholder="Competitor brand name"
                // className="big"
                value={compBrandName}
                onChange={(e) => setCompBrandName(e.target.value)}
              />
              <p> </p>
              <div style={{ display: "flex" }}>
                <Form.Control
                  style={{
                    padding: "12px 12px",
                    width: "70%",
                    marginLeft: "69px",
                  }}
                  as="textarea"
                  rows={1}
                  cols={2}
                  name="firstName"
                  placeholder="Competitor brand description"
                  // className="big"
                  value={compBrandDescription}
                  onChange={(e) => setCompBrandDescription(e.target.value)}
                />
                &nbsp; &nbsp;
                <div
                  onClick={() => {
                    handleBrandList();
                  }}
                  style={{
                    height: "29px",
                    border: "1px solid lighblack",
                    backgroundColor: "lightgrey",
                    width: "8%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <BsPlus />
                </div>
              </div>{" "}
            </>
          )}
        </Form.Group>

        <p> </p>
        {compBrands && compBrands.length !== 0 && (
          <ul style={{ padding: "12px 12px", width: "65%", margin: "auto" }}>
            {compBrands.map((brandInfo, index) => {
              return (
                <li>
                  <div
                    onClick={() => {
                      handleDeleteCompBrand(index);
                    }}
                    style={{
                      height: "29px",
                      border: "1px solid lighblack",
                      backgroundColor: "lightgrey",
                      // width: "8%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px",
                      borderRadius: "8px",
                      padding: "0px 8px 0px 8px",
                    }}
                  >
                    {brandInfo?.comp_product_name} &nbsp; <BsX size={24} />
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {selectedOption === "throughAi" ? (
          isLoading ? (
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : productName ? (
            <div style={{ width: "70%", margin: "auto" }}>
              <CompetitorListComponent
                options={competitorsFromAi}
                handleCheckChange={handleCheckChange}
                selectedCompetitorItems={selectedCompetitorItems}
                isProduct={true}
              />
            </div>
          ) : (
            <p style={{ color: "red", textAlign: "center" }}>
              Enter Product Name 1st to get its competitors !!{" "}
            </p>
          )
        ) : null}

        <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Product Persona</p>
          <PersonaSelectionComponent
            options={personas?.data?.data}
            handleCheckChange={handleCheckPersonaChange}
            selectedPersonaNames={selectedPersonaNames}
          />
        </div>

        {/* 
        <hr />

        <Form.Group>
          <p style={{ textAlign: "center" }}>Product Persona</p>
          <Form.Control
            as="select"
            value={selectedPersona}
            onChange={(e) => {
              setSelectedPersona(e.target.value);
            }}
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          >
            <option value="">select Product persona</option>
            {personas?.data?.data &&
              personas?.data?.data.length !== 0 &&
              personas?.data?.data.map((persona) => (
                <option value={persona?.persona?.name}>
                  {persona?.persona?.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group> */}

        {/* <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Brand Product Core Attribute</p>
          <CoreAttributesSelectionComponent
            options={coreAttributes?.data?.data}
            handleCheckChange={handleCheckCoreAttributeChange}
            selectedCoreAttributes={selectedCoreAttributes}
          />
        </div> */}

        {isInProgress && (
          <>
            <hr />
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Product is on-boarded successfully, Dashboard data is being
              generated !!
            </div>
          </>
        )}

        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          disabled={brandProducts?.loading || isInProgress}
          onClick={() => handleAddBrandProductInfo()}
        >
          {brandProducts?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div> {isUpdate ? "Update" : "Add"} </div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}

export default BrandProductFormModal;
