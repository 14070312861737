import { useRef, useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import TextInput from "./../../Component/UI/TextInput/TextInput";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import RadioGroup from "../../Component/UI/RadioGroup/RadioGroup";
import MUISelect from "../../Component/UI/MUISelect/MUISelect";
import classes from "./Form.module.css";
import { DiscoveryContext } from "./discovery-context";
import { LLM_OPTIONS } from "../../utils/constant";

const RADIO_OPTIONS = [
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "product",
    label: "Product",
  },
  /*{
    value: "market",
    label: "Market Research",
  },*/
];

export default function Form({ onSubmit, disabled, onTypeChanged = () => {} }) {
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [market, setMarket] = useState("");
  const [llm, setLLM] = useState("gpt-4o");
  const [type, setType] = useState("brand");

  const discoveryCtx = useContext(DiscoveryContext);

  return (
    <div className={classes.form}>
      <RadioGroup
        options={RADIO_OPTIONS}
        onChange={(value) => {
          setType(value);
          onTypeChanged(value);
        }}
      />
      <div className={classes.input_group}>
        <TextInput
          label={type === "brand" ? "Brand*" : "Product*"}
          placeholder={type === "brand" ? "Nike" : "Nike Air Jordan 1"}
          onChange={(e) => setBrand(e.target.value)}
        />
        <TextInput
          label="Category*"
          placeholder="Athletic Apparel"
          onChange={(e) => setCategory(e.target.value)}
        />
        <TextInput
          label="Market"
          placeholder="North America"
          onChange={(e) => setMarket(e.target.value)}
        />
        <MUISelect
          label={"LLM*"}
          defaultValue="gpt-4o"
          options={LLM_OPTIONS}
          onChange={(e) => setLLM(e.target.value)}
        />
        <MUIButton
          variant="contained"
          color={discoveryCtx.loading ? "error" : "primary"}
          onClick={() => {
            if (!brand || !category || !llm) {
              toast.warn("Please fill all required fields");
              return;
            }

            if(discoveryCtx.loading) {
              discoveryCtx.updateAbortLaunch(true);
              return;
            }

            onSubmit({
              brand,
              category,
              market,
              llm,
              type,
            });
          }}
        >
          {discoveryCtx.loading ? "Cancel" : "Launch"}
        </MUIButton>
      </div>
      <ToastContainer />
    </div>
  );
}
