import { TableCell, TableHead, TableRow, Tooltip } from "@mui/material";

import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../Component/UI/Table/Table";
import MUICard from "../../../../Component/UI/MUICard/MUICard";
import classes from "./CategoryDims.module.css";
import { extractDomain } from "../../../../utils/helpers";
import { compareScores } from "../CoreAttributes/CoreAttributes";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

const TABLE_HEADERS = [
  {
    id: "attr",
    name: "Purchase Criteria",
  },
];

export default function CategoryDimensions({
  purchaseDimData,
  pastPurchaseDimData,
  brandDomains,
}) {
  function formatName(str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function extractUniqueBrands(data) {
    if (data && data.length) {
      // Find the attribute with the maximum number of competitors (brands)
      let maxCompetitorAttribute = data.reduce((maxAttr, currentAttr) => {
        return currentAttr.scores.length > maxAttr.scores.length
          ? currentAttr
          : maxAttr;
      });

      // Get the unique brand names from that attribute
      const uniqueBrands = new Set();
      maxCompetitorAttribute.scores.forEach((brandObj) => {
        uniqueBrands.add(brandObj.name);
      });

      // Create an array of objects with unique id and brand name
      const brandArray = Array.from(uniqueBrands).map((brandName, index) => ({
        id: index + 1, // Unique ID starting from 1
        name: brandName,
      }));

      return brandArray;
    }
  }

  function renderRows(item, index) {
    return (
      <StyledTableRow key={index}>
        <StyledTableCell className={classes.attribute_headings}>
          {formatName(item.name)}
        </StyledTableCell>
        {item.scores.map((item, index) => {
          let color = "#dc3545";
          if (item.score >= 7) color = "#2196F3";
          if (item.score >= 9) color = "#198754";

          return (
            <Tooltip
              key={index}
              title={item.explanation}
              arrow
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -18],
                      },
                    },
                  ],
                },
              }}
            >
              <StyledTableCell
                style={{ color }}
                className={classes.attribute_score}
              >
                {Number(item.score).toFixed(0)}
                {/* &nbsp; &nbsp; {item.delta !== null && (
                  <span style={{ color: "grey" }}>
                    <FontAwesomeIcon
                      className={
                        item.delta < 0
                          ? `${classes["core-down-icon"]} text-danger me-1`
                          : `${classes["core-up-icon"]} text-success me-1`
                      }
                      icon={item.delta < 0 ? faSortDown : faSortUp}
                    />
                    <span className={classes.coreDelta}>
                      {Number(Math.abs(item.delta)).toFixed(0)}
                    </span>
                  </span>
                )} */}
              </StyledTableCell>
            </Tooltip>
          );
        })}
      </StyledTableRow>
    );
  }

  function renderHeaders() {
    const headers = [...TABLE_HEADERS, ...extractUniqueBrands(purchaseDimData)];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers.map((header, index) => {
            let domain = extractDomain(brandDomains?.[header?.name]);

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  const purchaseDimDataWithDelta = compareScores(
    purchaseDimData,
    pastPurchaseDimData
  )?.slice(0, 8);

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      {purchaseDimDataWithDelta && purchaseDimDataWithDelta.length > 0 ? (
        <Table
          elevation={0}
          data={purchaseDimDataWithDelta}
          renderHeaders={() => renderHeaders(purchaseDimData)}
          render={renderRows}
        />
      ) : (
        <>
          <h5> Purchase Criteria </h5>
          <EmptyView message="Data Not Available" />
        </>
      )}
    </MUICard>
  );
}
