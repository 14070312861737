import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "../../../context/auth-context";
import {
  fetchBrandProducts,
  deleteBrandProduct,
} from "../../../redux/actions/brandProductsActions";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { useNavigate } from "react-router";
import { FaArrowRight } from "react-icons/fa";
import JobInputFormModal from "../../forms/JobInputFormModal";
import { generateProductMetrics } from "../../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";

const ProductComponent = ({
  handleSelectedData,
  llms,
  markets,
  geographies,
  customerLlms,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedBrandCustomerId, setSelectedBrandCustomerId] = useState();
  const [productPersonas, setProductPersonas] = useState([]);
  const [jobIsInprogress, setJobIsInProgress] = useState(false);

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }

    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }

    if (coreAttributes?.data && coreAttributes?.data.length === 0) {
      dispatch(fetchcoreAttributes(customerId));
    }
  }, [dispatch]);

  const handleDeleteBrandProduct = (brandProductId) => {
    dispatch(deleteBrandProduct(customerId, brandProductId)).then(() => {
      dispatch(fetchBrandProducts(customerId));
    });
  };

  const handleProductMetricsJob = async (
    llmId,
    brandPersonaId,
    marketId,
    globalLlmId,
    selectedId,
    brandcustomerId
  ) => {
    setJobIsInProgress(selectedId);
    setShowModal(false);
    generateProductMetrics(llmId, brandPersonaId)
      .then(() => {
        setJobIsInProgress(false);
        navigate(
          `/brandonboarding/dashboard?brandProductId=${selectedProductId}
          &personaId=${brandPersonaId}&marketId=${marketId}
          &llmId=${globalLlmId}&brandCustomerId=${brandcustomerId}&isDetailPage=1`
        );
      })
      .catch((error) => {
        toast.error(error?.message || "something went wrong !", {
          autoClose: 700,
        });
        setJobIsInProgress(false);
      });
  };

  if (brandProducts.loading) {
    return <p>Loading...</p>;
  }

  if (brandProducts.deleteLoading) {
    return <p>Product is being deleted Please wait...</p>;
  }

  if (brandProducts.error) {
    return <p>Error: {brandProducts.error}</p>;
  }

  const brandProductsData = brandProducts?.data?.data.filter(
    (obj) => obj.is_active
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleProductPersonas = (persona, id, customerId) => {
    setProductPersonas(persona);
    setSelectedProductId(id);
    setSelectedBrandCustomerId(customerId);
    setShowModal(true);
  };

  return (
    <>
      {" "}
      <JobInputFormModal
        show={showModal}
        handleClose={handleCloseModal}
        llms={llms}
        markets={markets}
        geographies={geographies}
        personas={productPersonas}
        customerLlms={customerLlms}
        handleBrandMetricsJob={handleProductMetricsJob}
        jobIsInprogress={jobIsInprogress}
        brandId={selectedProductId}
        brandCustomerId={selectedBrandCustomerId}
      />
      <Table>
        <thead style={{ backgroundColor: "#CCCCCC" }}>
          <tr>
            <th>Brand</th>
            <th>Product Name</th>
            <th>Details</th>
            <th>Product category</th>
            <th>Product competitor</th>
            <th>Persona</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {brandProductsData &&
            brandProductsData.length !== 0 &&
            brandProductsData.map((data) => {
              return (
                <tr>
                  <td>{data?.brand_product?.brand?.name}</td>
                  <td>{data?.product?.name}</td>
                  <td>{data?.brand_product?.description}</td>
                  <td>
                    {" "}
                    {data?.customer_brand_product_categories &&
                      data?.customer_brand_product_categories.length !== 0 &&
                      data?.customer_brand_product_categories.map((data) => {
                        return <p> {data?.category?.name} </p>;
                      })}
                  </td>
                  <td>
                    {data?.customer_competitor_brand_products &&
                      data?.customer_competitor_brand_products.length !== 0 &&
                      data?.customer_competitor_brand_products.map((data) => {
                        return <p> {data?.brand_product?.name} </p>;
                      })}
                  </td>
                  <td>
                    {data?.custProduct &&
                      data?.custProduct.length !== 0 &&
                      data?.custProduct.map((data) => {
                        return <p> {data?.persona?.name} </p>;
                      })}
                  </td>
                  <td>
                    {jobIsInprogress != data?.id ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleIcon
                          style={{ color: "#4CAF50", marginRight: 4 }}
                        />
                        <Typography style={{ color: "#4CAF50" }}>
                          ACTIVE
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <HourglassTopIcon
                          style={{ color: "#FFA500", marginRight: 4 }}
                        />
                        <Typography style={{ color: "#FFA500" }}>
                          In-Process
                        </Typography>
                      </Box>
                    )}
                  </td>
                  <td className={classes.actions}>
                    <div style={{ display: "flex" }}>
                      <Button
                        style={{
                          backgroundColor: "green",
                        }}
                        disabled={data?.customer?.id != customerId}
                        variant="success"
                        onClick={() =>
                          handleProductPersonas(
                            data?.custProduct,
                            data?.id,
                            data?.customer?.id
                          )
                        }
                      >
                        Run
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "green",
                          width: "unset",
                        }}
                        variant="success"
                        onClick={() => handleSelectedData(data)}
                      >
                        Update
                      </Button>{" "}
                      &nbsp;
                      <Button
                        style={{
                          backgroundColor: "#d32f2f",
                          width: "unset",
                        }}
                        variant="danger"
                        onClick={() => handleDeleteBrandProduct(data?.id)}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "blue",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        // variant="danger"
                        onClick={() =>
                          navigate(
                            `/brandonboarding/dashboard?brandProductId=${data?.id}&brandCustomerId=${data?.customer?.id}&isDetailPage=1`
                          )
                        }
                      >
                        Dashboard
                        <FaArrowRight
                          style={{ cursor: "pointer" }}
                          // size={20}
                          onClick={() => {
                            // navigate("/brandonboarding/dashboard");
                            // setIsDetailPage(false);
                          }}
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
        {/* <tbody>
        <tr>
          <td>1</td>
          <td>Data 1</td>
          <td>Data 2</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Data 3</td>
          <td>Data 4</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Data 5</td>
          <td>Data 6</td>
        </tr>
      </tbody> */}
      </Table>
      <ToastContainer />
    </>
  );
};

export default ProductComponent;
