import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";

let ROUTE_MOCKUP = {
  Discovery: "",
  Dashboard: "dashboard",
  Reporting: "reporting",
  Monitoring: "brandonboarding/dashboard",
  // Dashboard2: "dashboard2",
  Admin: "admin",
  // Dashboard2: "dashboard2",
};

function LinkTab(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedInUser } = React.useContext(AuthContext);
  const [routes, setRoutes] = React.useState(ROUTE_MOCKUP);

  React.useEffect(() => {
    if (loggedInUser?.role === "demo") {
      setRoutes({
        Discovery: "",
        Dashboard: "dashboard",
        Monitoring: "monitors",
        Reporting: "reporting",
        Admin: "admin",
      });
    }
  }, [loggedInUser?.role]);

  const handleClick = (event) => {
    event.preventDefault();
    let tabValue = Object.keys(routes).indexOf(props.label);
    navigate(`/${routes[props.label]}?tab=${tabValue}`);
  };

  return (
    <Tab
      sx={{ color: "black", fontWeight: "450" }}
      onClick={handleClick}
      selected={
        location.search ===
        `?tab=${Object.keys(ROUTE_MOCKUP).indexOf(props.label)}`
      }
      {...props}
    />
  );
}

export default function NavTabs() {
  const location = useLocation();
  const { isCurrentUserAdmin, token, loggedInUser } =
    React.useContext(AuthContext);
  const searchParams = new URLSearchParams(location.search);
  const tab = 0;
  const initialTabValue = searchParams.has("tab")
    ? parseInt(searchParams.get("tab"))
    : tab;
  const tabStyle = isCurrentUserAdmin ? { width: "68%" } : { width: "58%" };

  const [value, setValue] = React.useState(initialTabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);

  return (
    <Box sx={tabStyle}>
      {token && (
        <Tabs value={value} onChange={handleChange} role="navigation">
          <LinkTab label="Discovery" />
          <LinkTab label="Dashboard" />
          <LinkTab label="Reporting" />
          <LinkTab label="Monitoring" />

          {/* {loggedInUser?.role != "demo" && <LinkTab label="Dashboard2" />} */}
          {isCurrentUserAdmin && <LinkTab label="Admin" />}
        </Tabs>
      )}
    </Box>
  );
}
