import React from "react";

import { Switch } from "@mui/material";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomer,
  updateCustomer,
} from "../../../../redux/actions/userActions";

const MainJobController = () => {
  const dispatch = useDispatch();
  const { customer } = useSelector((state) => state.user);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h6>
          <LegendToggleIcon /> &nbsp; &nbsp; Brand/Product Monitoring
        </h6>
        <Switch
          defaultChecked={customer?.monitoring}
          onChange={(e) =>
            dispatch(
              updateCustomer(customer?.id, {
                monitoring: e.target.checked ? 1 : 0,
              })
            )
              .then(() => {
                dispatch(fetchCustomer(customer?.id));
              })
              .catch(() => {
                dispatch(fetchCustomer(customer?.id));
              })
          }
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h6>
          <LegendToggleIcon /> &nbsp; &nbsp; Competitor Monitoring
        </h6>
        <Switch
          defaultChecked={customer?.competitor_monitoring}
          onChange={(e) =>
            dispatch(
              updateCustomer(customer?.id, {
                comp_monitoring: e.target.checked ? 1 : 0,
              })
            )
              .then(() => {
                dispatch(fetchCustomer(customer?.id));
              })
              .catch(() => {
                dispatch(fetchCustomer(customer?.id));
              })
          }
        />
      </div>
    </>
  );
};

export default MainJobController;
