import { ButtonGroup, Button } from "@mui/material";

export default function MonitoringFrequencyTabs({ data, activeFrequencyId, onChange }) {
  return (
    <ButtonGroup variant="outlined" size="small" aria-label="Basic button group">
      {data.map((item) => (
        <Button
          variant={item.id === activeFrequencyId ? "contained" : "outlined"}
          key={item.id}
          onClick={()=> {
            if(item.id === activeFrequencyId) return;
            onChange(item)
          }}
        >
          {item.type}
        </Button>
      ))}
    </ButtonGroup>
  );
}
