import api from "../../BrandOnBoarding/api";
import {
  FETCH_BRAND_PRODUCTS_REQUEST,
  FETCH_BRAND_PRODUCTS_SUCCESS,
  FETCH_BRAND_PRODUCTS_FAILURE,
  CREATE_BRAND_PRODUCT_REQUEST,
  CREATE_BRAND_PRODUCT_SUCCESS,
  CREATE_BRAND_PRODUCT_FAILURE,
  UPDATE_BRAND_PRODUCT_REQUEST,
  UPDATE_BRAND_PRODUCT_SUCCESS,
  UPDATE_BRAND_PRODUCT_FAILURE,
  DELETE_BRAND_PRODUCT_REQUEST,
  DELETE_BRAND_PRODUCT_SUCCESS,
  DELETE_BRAND_PRODUCT_FAILURE,
} from "../constants/actionTypes";

export const fetchBrandProducts = (id) => async (dispatch) => {
  dispatch({ type: FETCH_BRAND_PRODUCTS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/brand-products`);
    dispatch({ type: FETCH_BRAND_PRODUCTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_BRAND_PRODUCTS_FAILURE, error: error.message });
  }
};

export const createBrandProduct = (id, product) => async (dispatch) => {
  dispatch({ type: CREATE_BRAND_PRODUCT_REQUEST });
  try {
    const response = await api.post(`/customers/${id}/brand-product`, product);
    dispatch({ type: CREATE_BRAND_PRODUCT_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_BRAND_PRODUCT_FAILURE, error: error.message });
    throw error;
  }
};

export const updateBrandProduct =
  (id, brandProductId, product) => async (dispatch) => {
    dispatch({ type: UPDATE_BRAND_PRODUCT_REQUEST });
    try {
      const response = await api.put(
        `/customers/${id}/brand-product/${brandProductId}`,
        product
      );
      dispatch({ type: UPDATE_BRAND_PRODUCT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPDATE_BRAND_PRODUCT_FAILURE, error: error.message });
    }
  };

export const deleteBrandProduct = (id, brandProductId) => async (dispatch) => {
  dispatch({ type: DELETE_BRAND_PRODUCT_REQUEST });
  try {
    await api.delete(`/customers/${id}/brand-product/${brandProductId}`);
    dispatch({ type: DELETE_BRAND_PRODUCT_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_BRAND_PRODUCT_FAILURE, error: error.message });
  }
};
