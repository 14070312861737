import React, { useState, useEffect } from "react";
import BrandScore from "./BrandScore/BrandScore";
import {
  coreAttributesDataTransform,
  getSummaryPrompt,
} from "../../../utils/helpers";
import CoreAttributes from "./CoreAttributes/CoreAttributes";
import classes from "./DashboardComponent.module.css";
import AIGenerated from "../../../Component/AIGenerated/AIGenerated";
import CategoryDimensions from "./CategoryDimensions/CategoryDims";
import PositiveAttributes from "./PositiveAttribute/PositiveAttribute";
import NegativeAttributes from "./NegativeAttribute/NegativeAttribute";
import Competitors from "./Competitors/Competitors";
import Source from "./Source/Source";
import { fetchGeminiAPI } from "../../../services/apiService";

const BrandProductOverview = ({
  indexScoreMetaData,
  compIndexScoreMetaData,
  indexScoreDataForLineChart,
  domain,
  brandProductCategory,
  brandProduct,
  type,
  compCoreAttributes,
  brandDomains,
  compCategoryDimensions,
  positiveAttGraphData,
  pastPositiveAttGraphData,
  negativeAttGraphData,
  pastNegativeAttGraphData,
  compBrandIndexScore,
  pastCompBrandIndexScore,
  pastCompCoreAttributes,
  pastCompCategoryDimensions,
  categoryDimensionRatingSource,
  coreAttSourceData,
  reachSourceData,
  sentimentSourceData,
  brandProductInfo,
  frequency,
}) => {
  const [summaries, setSummaries] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [loading, setLoading] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [errors, setErrors] = useState({
    positiveAttributes: null,
    negativeAttributes: null,
    indexScore: null,
    favorability: null,
    rank: null,
  });

  const [abortControllers, setAbortControllers] = useState([]);

  // Helper to create a new AbortController
  const createAbortController = () => {
    const abortController = new AbortController();
    setAbortControllers((prevControllers) => [
      ...prevControllers,
      abortController,
    ]);
    return abortController;
  };

  const resetAbortControllers = () => {
    abortControllers.forEach((controller) => controller.abort());
    setAbortControllers([]);
  };

  let coreAttributesData =
    (compCoreAttributes &&
      coreAttributesDataTransform(
        Object.fromEntries(Object.entries(compCoreAttributes).slice(0, 5))
      )) ||
    [];
  let purchaseDimData =
    compCategoryDimensions &&
    coreAttributesDataTransform(
      Object.fromEntries(Object.entries(compCategoryDimensions).slice(0, 5))
    );
  let pastcoreAttributesData = coreAttributesDataTransform(
    pastCompCoreAttributes
  );
  let pastPurchaseDimData = coreAttributesDataTransform(
    pastCompCategoryDimensions
  );

  const filterDataByBrand = (data, brand) => {
    const filteredData = {};

    for (let property in data) {
      if (data[property].hasOwnProperty(brand)) {
        // Create a new object with brand and its score for each property
        filteredData[property] = { [brand]: data[property][brand] };
      }
    }

    return filteredData;
  };

  const getAttributesSummary = (type) => {
    // Fetch data for each prop simultaneously
    if (
      type == "positive-attributes" &&
      positiveAttGraphData &&
      positiveAttGraphData.length
    ) {
      fetchAttributeData(
        positiveAttGraphData,
        pastPositiveAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "positive",
        frequency,
        fetchSummaryData,
        "positiveAttributes"
      );
    }

    if (
      type == "negative-attributes" &&
      negativeAttGraphData &&
      negativeAttGraphData.length
    ) {
      fetchAttributeData(
        negativeAttGraphData,
        pastNegativeAttGraphData,
        currentFrequencyMapping,
        priorFrequencyMapping,
        brandProduct,
        "negative",
        frequency,
        fetchSummaryData,
        "negativeAttributes"
      );
    }
  };

  // Function to handle fetching summary data for both positive and negative attributes
  const fetchAttributeData = (
    currentGraphData,
    pastGraphData,
    currentFrequencyMapping,
    priorFrequencyMapping,
    brandProduct,
    type,
    frequency,
    fetchSummaryData,
    attributeType
  ) => {
    const currentData = transformGraphData(currentGraphData);

    const priorData = transformGraphData(pastGraphData);

    const finalData = {
      [`${
        currentFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: currentData,
      [`${
        priorFrequencyMapping[frequency || ""]
      } ${type} attributes of ${brandProduct}`]: priorData,
    };
    fetchSummaryData(finalData, attributeType);
  };

  const transformGraphData = (graphData) => {
    return (
      graphData?.map((obj) => ({
        "attribute-category": obj?.name,
        "attribute-category-signal-strength": obj?.value,
        attributes: obj?.altNames,
        totalSignlStrengths: obj?.signalStrengths,
      })) || []
    );
  };

  const brandIndexScore = filterDataByBrand(compBrandIndexScore, brandProduct);
  const pastBrandIndexScore = filterDataByBrand(
    pastCompBrandIndexScore,
    brandProduct
  );

  const currentFrequencyMapping = {
    Current: "today's",
    "3Days": "current 3 days average",
    "7Days": "current 7 days average",
    Month: "current 30 days average",
  };

  const priorFrequencyMapping = {
    Current: "yesterday's",
    "3Days": "prior 3 days average",
    "7Days": "prior 7 days average",
    Month: "prior 30 days average",
  };

  const getBrandIndexPromptData = (property, label) => {
    return {
      [`${currentFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...brandIndexScore?.[label],
        category: brandProductCategory,
      },
      [`${priorFrequencyMapping?.[frequency] || ""} ${property}`]: {
        ...pastBrandIndexScore?.[label],
        category: brandProductCategory,
      },
    };
  };

  const getBrandIndexSummary = (data, mainDataKey, promptKey, summaryKey) => {
    if (data?.[mainDataKey]) {
      const data = getBrandIndexPromptData(promptKey, mainDataKey);
      fetchSummaryData(data, summaryKey);
    }
  };

  const fetchSummaryData = async (dataProp, propKey) => {
    setLoading((prev) => ({ ...prev, [propKey]: true }));
    setErrors((prev) => ({ ...prev, [propKey]: null }));

    const compReachScoreData = compBrandIndexScore?.Rank;

    try {
      const controller = createAbortController();
      const prompt = getSummaryPrompt(dataProp, propKey, compReachScoreData);
      let result = await fetchGeminiAPI(
        prompt,
        controller,
        undefined,
        undefined,
        "gpt-4o"
      );
      result = result["Gemini"][0];
      setSummaries((prev) => ({ ...prev, [propKey]: result }));
    } catch (err) {
      setErrors((prev) => ({
        ...prev,
        [propKey]: "Failed to generate summary",
      }));
    } finally {
      setLoading((prev) => ({ ...prev, [propKey]: false }));
    }
  };

  useEffect(() => {
    // Reset existing abort controllers before making new API calls
    resetAbortControllers();

    getBrandIndexSummary(
      brandIndexScore,
      "Brand Index Score",
      "Brand Index Score",
      "indexScore"
    );
  }, [compBrandIndexScore]);

  return (
    <div>
      <BrandScore
        domain={domain}
        indexScoreDataForLineChart={indexScoreDataForLineChart}
        brandProductCategory={brandProductCategory}
        brandProduct={brandProduct}
        type={type}
        brandIndexScore={brandIndexScore}
        pastBrandIndexScore={pastBrandIndexScore}
        brandProductInfo={brandProductInfo}
        errors={errors}
        loading={loading}
        summaries={summaries}
        indexScoreMetaData={indexScoreMetaData}
        frequency={frequency}
      />

      <p> </p>
      <Competitors
        brandDomains={brandDomains}
        compBrandIndexScore={compBrandIndexScore}
        pastCompBrandIndexScore={pastCompBrandIndexScore}
        compIndexScoreMetaData={compIndexScoreMetaData}
      />
      <p> </p>
      <PositiveAttributes
        positiveAttGraphData={positiveAttGraphData}
        pastPositiveAttGraphData={pastPositiveAttGraphData}
        getAttributesSummary={getAttributesSummary}
        errors={errors}
        summaries={summaries}
        loading={loading}
      />
      <NegativeAttributes
        negativeAttGraphData={negativeAttGraphData}
        pastNegativeAttGraphData={pastNegativeAttGraphData}
        getAttributesSummary={getAttributesSummary}
        errors={errors}
        summaries={summaries}
        loading={loading}
      />
      <p> </p>
      <div className={classes.section_summary}>
        <div className={classes.flex_one}>
          <CoreAttributes
            brandDomains={brandDomains}
            coreAttributesData={coreAttributesData}
            pastcoreAttributesData={pastcoreAttributesData}
          />
        </div>
        <div className={classes.flex_one}>
          <AIGenerated
            edit
            // text={coreAttributesSummary}
            // loading={coreAttributesSummary === ""}
            firebaseNode="core_attributes"
            isMonitoring={true}
          />
        </div>
      </div>
      <p> </p>
      <div className={classes.section_summary}>
        <div className={classes.flex_one}>
          <CategoryDimensions
            brandDomains={brandDomains}
            purchaseDimData={purchaseDimData}
            pastPurchaseDimData={pastPurchaseDimData}
          />
        </div>
        <div className={classes.flex_one}>
          <AIGenerated
            edit
            // text={coreAttributesSummary}
            // loading={coreAttributesSummary === ""}
            firebaseNode="core_attributes"
            isMonitoring={true}
          />
        </div>
      </div>
      <p> </p>
      <Source
        categoryDimensionRatingSource={categoryDimensionRatingSource}
        coreAttSourceData={coreAttSourceData}
        reachSourceData={reachSourceData}
        sentimentSourceData={sentimentSourceData}
      />
    </div>
  );
};

export default BrandProductOverview;
