import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
  FaHome,
  FaBullhorn,
  FaChartPie,
  FaCog,
  FaUserCircle,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavbarComponent = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Top Horizontal Navbar */}
      <Navbar
        style={{
          width: "100%",
          height: "8%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 30px 0px 45px",
        }}
        bg="light"
        expand="lg"
        fixed="top"
      >
        <Navbar.Brand
          style={{ cursor: "pointer", fontSize:'28px', fontWeight:'450' }}
          onClick={() => navigate("/")}
          className="ml-3"
        >
          Revere
        </Navbar.Brand>
        <FaUserCircle size={30} />
      </Navbar>

      {/* Vertical Sidebar Navbar */}
      <Navbar
        expand="lg"
        className="d-flex flex-column vh-100"
        style={{
          width: "10%",
          position: "fixed",
          left: 0,
          top: "40px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <Nav className="flex-column w-100 mt-3">
          <Nav.Link
            onClick={() => navigate("/brandonboarding/home")}
            className="text-center mb-3"
          >
            <FaHome size={30} />
          </Nav.Link>
          {/* Uncomment the following links if needed */}
          <Nav.Link 
          onClick={() => navigate("/brandonboarding/dashboard")}
          className="text-center mb-3">
            <FaChartPie size={30} />
          </Nav.Link>
          <Nav.Link className="text-center mb-3">
            <FaBullhorn size={30} />
          </Nav.Link>
        </Nav>
        <Nav className="flex-column w-100 mt-auto mb-5">
          <Nav.Link
            onClick={() => navigate("/brandonboarding/settings")}
            className="text-center"
          >
            <FaCog size={30} />
          </Nav.Link>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
