import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const PieChart = ({
  width,
  height,
  radius = "100%",
  center = ["50%", "60%"],
  data,
}) => {
  const chartRef = useRef(null);

  function getKnobColor(value) {
    if(value <= 52) {
      return "#e76262"
    }
    if(value > 52 && value <= 68) {
      return "#f9cf4a"
    }
    if(value > 68 && value <= 85) {
      return "#eca336"
    }
    if(value > 85) {
      return "#3ece80"
    }
  }

  useEffect(() => {
    let chart = echarts.init(chartRef.current);

    const option = {
      series: [
        {
          data: data,
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          splitNumber: 3,
          radius: radius,
          center: center,
          pointer: {
            icon: "circle",
            length: "14%", //knob size
            width: 110,
            offsetCenter: [0, "-88.5%"], // knob adjustment
            itemStyle: {
              color: "#FFFFFF",
              borderColor: getKnobColor(data[0].value),
              borderWidth: 10,
              // shadowColor: "rgba(10, 31, 68, 0.5)",
              // shadowBlur: 2,
              // shadowOffsetY: 1,
            },
          },
          axisLine: {
            show: true,
            roundCap: true,
            lineStyle: {
              width: 18, //track width
              color: [
                [0.5, "#e76262"],
                [0.55],
                [0.66, "#f9cf4a"],
                [0.71],
                [0.83, "#eca336"],
                [0.88],
                [1, "#3ece80"],
              ],
            },
          },
          axisTick: {
            length: 2,
            lineStyle: {
              color: "#8a94a6",
              width: 2,
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            rich: {
              header: {
                fontSize: 58,
                fontWeight: 700,
                fontFamily: "Inter",
                color: "#0a1f44",
              },
              subHeader: {
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Open Sans",
                color: "#8a94a6",
              },
            },
            // formatter: ["{header|{value}}", "{subHeader|15-30-2022}"].join(
            //   "\n"
            // ),
            formatter: ["{header|{value}}"].join("\n\n"),
            offsetCenter: [0, "-20%"],
            valueAnimation: true,
          },
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data[0].value]);

  return (
    <div ref={chartRef} style={{ width, height }} />
  );
};

export default PieChart;
