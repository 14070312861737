import {
    FETCH_BRAND_CATEGORIES_REQUEST,
    FETCH_BRAND_CATEGORIES_SUCCESS,
    FETCH_BRAND_CATEGORIES_FAILURE,
    FETCH_BRAND_CATEGORY_REQUEST,
    FETCH_BRAND_CATEGORY_SUCCESS,
    FETCH_BRAND_CATEGORY_FAILURE,
    CREATE_BRAND_CATEGORY_REQUEST,
    CREATE_BRAND_CATEGORY_SUCCESS,
    CREATE_BRAND_CATEGORY_FAILURE,
    UPDATE_BRAND_CATEGORY_REQUEST,
    UPDATE_BRAND_CATEGORY_SUCCESS,
    UPDATE_BRAND_CATEGORY_FAILURE,
    DELETE_BRAND_CATEGORY_REQUEST,
    DELETE_BRAND_CATEGORY_SUCCESS,
    DELETE_BRAND_CATEGORY_FAILURE,
  } from '../constants/actionTypes';
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const brandCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_BRAND_CATEGORIES_REQUEST:
      case FETCH_BRAND_CATEGORY_REQUEST:
      case CREATE_BRAND_CATEGORY_REQUEST:
      case UPDATE_BRAND_CATEGORY_REQUEST:
      case DELETE_BRAND_CATEGORY_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_BRAND_CATEGORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case FETCH_BRAND_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map(category =>
            category.id === action.payload.id ? action.payload : category
          ),
        };
      case CREATE_BRAND_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          // data: [...state.data, action.payload],
        };
      case UPDATE_BRAND_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map(category =>
            category.id === action.payload.id ? action.payload : category
          ),
        };
      case DELETE_BRAND_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.filter(category => category.id !== action.payload),
        };
      case FETCH_BRAND_CATEGORIES_FAILURE:
      case FETCH_BRAND_CATEGORY_FAILURE:
      case CREATE_BRAND_CATEGORY_FAILURE:
      case UPDATE_BRAND_CATEGORY_FAILURE:
      case DELETE_BRAND_CATEGORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default brandCategoriesReducer;
  