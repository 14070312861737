import { CircularProgress } from "@mui/material";

import classes from "./UserQueue.module.css";

function UserQueue({ position }) {
  return (
    <div className={classes.container}>
      <CircularProgress color="inherit" size={15} />
      <span className={classes.status}>
        There are <strong>{position}</strong> users before you in line. Please wait...
      </span>
    </div>
  );
}

export default UserQueue;
