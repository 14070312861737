import { useEffect, useState, useContext } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import TextInput from "./../../../Component/UI/TextInput/TextInput";
import HistoryItem from "./HistoryItem";
import { AuthContext } from "../../../context/auth-context";
import LoadingSkeleton from "./LoadingSkeleton";
import ErrorView from "./../../../Component/ErrorView/ErrorView";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import EmptyView from "./../../../Component/EmptyView/EmptyView";
import classes from "./History.module.css";
import api from "../../../BrandOnBoarding/api";
import { Alert } from "@mui/material";

const ITEMS_PER_PAGE = 10;

export default function History({ onItemSelected, onDelete }) {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const { user } = useContext(AuthContext);

  async function getHistory() {
    try {
      setError(null);
      setLoading(true);

      let url = `/llm-conversations/${user.id}`;

      if (searchQuery) {
        url += `?q=${searchQuery}`;
      } else {
        url += `?offset=${offset}&limit=${ITEMS_PER_PAGE}`;
      }

      const response = await api.get(url);
      if (response?.data?.data) {
        if (searchQuery) {
          setHistory(response.data.data);
          setOffset(0);
        } else {
          setOffset((prevOffset) => prevOffset + ITEMS_PER_PAGE);
          setHistory((prevHistory) => {
            return [...prevHistory, ...response.data.data];
          });
          if (response?.data?.data.length === 0) {
            setHasMoreData(false);
          }
        }
      } else {
        setError({ type: "load", message: "Failed to load history" });
      }
    } catch (error) {
      setError({ type: "load", message: error.message });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getHistory();
  }, []);

  function deleteHistoryItemHandler(id) {
    onDelete();
    setHistory((prevHistory) => {
      return prevHistory.filter((item) => item.id != id);
    });
  }

  return (
    <>
      <div className={classes.search}>
        <TextInput
          type="text"
          placeholder="Search..."
          fullWidth
          onChange={(e) => {
            if (!e.target.value || e.target.value === "") setHistory([]);
            setSearchQuery(e.target.value);
          }}
        />
        <MUIIconButton variant="contained" onClick={getHistory}>
          <SearchOutlinedIcon />
        </MUIIconButton>
      </div>
      {error && error.type === "delete" && (
        <Alert severity="error" className="mb-3">
          {error.message}
        </Alert>
      )}
      {error?.type != "load" &&
        history.map((item) => {
          return (
            <HistoryItem
              key={item.id}
              item={item}
              onDelete={deleteHistoryItemHandler}
              onSelect={(item) => onItemSelected(item)}
              onError={(msg) =>
                setError(msg ? { type: "delete", message: msg } : null)
              }
            />
          );
        })}
      {error && error.type === "load" && (
        <ErrorView title="Error" message={error.message} />
      )}
      {loading && history.length === 0 && <LoadingSkeleton />}
      {!loading && history.length === 0 && !error && (
        <EmptyView message={"Nothing here."} />
      )}
      {hasMoreData && history.length >= ITEMS_PER_PAGE && (
        <div className={classes.load_more}>
          <MUIButton loading={loading} variant="outlined" onClick={getHistory}>
            Load More
          </MUIButton>
        </div>
      )}
    </>
  );
}
