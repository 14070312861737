import {
  getDatabase,
  ref,
  onValue,
  remove,
  push,
  update,
  get,
  child,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import firebaseApp from "./Firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { auth } from "./Firebase";
import api from "../BrandOnBoarding/api";

const database = getDatabase(firebaseApp);

export const fetchDataFromFirebase = async (
  callback,
  collectionName,
  authEmail,
  targetDate
) => {
  const collectionRef = ref(database);
  const dataRef = ref(database, collectionName);
  const collection = await get(child(collectionRef, collectionName));

  if (collection.val() === null) {
    callback(null);
    return;
  }

  const unsubscribe = onValue(dataRef, async (snapshot) => {
    const data = snapshot.val();
    if (
      collectionName === "FirebaseData" ||
      collectionName === "MonitoringData" ||
      collectionName === "dashboard-data"
    ) {
      const emailQuery = query(
        dataRef,
        orderByChild("email"),
        equalTo(authEmail)
      );
      const historySnapshot = await get(emailQuery);
      const historyData = historySnapshot.val();
      callback(historyData);
      return;
    }
    let filteredData = {};
    if (data && targetDate) {
      for (const [key, value] of Object.entries(data)) {
        const dbDate = value.date.split(",")[0]; // Extract 'MM/DD/YYYY'
        const [month, day, year] = dbDate.split("/");
        const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;

        if (formattedDate === targetDate) {
          filteredData[key] = value;
        }
      }
      if (Object.keys(filteredData).length !== 0) {
        callback(filteredData);
      } else {
        callback(null);
      }

      return;
    }

    callback(data);
  });

  return unsubscribe;
};

export const addDataToFirebase = (collectionName, data) => {
  const database = getDatabase(firebaseApp);
  const dataRef = ref(database, collectionName);
  return push(dataRef, data);
};

export const updateDataInFirebase = (
  collectionName,
  documentId,
  updatedData
) => {
  const database = getDatabase(firebaseApp);
  const dataRef = ref(database, `${collectionName}/${documentId}`);
  return update(dataRef, updatedData);
};

export const deleteDataFromFirebase = (recordId, collectionName) => {
  const dataRef = ref(database, `${collectionName}/${recordId}`);

  return remove(dataRef);
};

export const getSingleDocFromFirebase = async (recordId, collectionName) => {
  const dataRef = ref(database);
  const snapshot = await get(child(dataRef, `${collectionName}/${recordId}`))
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    throw new Error("No data available");
  }
};

export const doRegisterUser = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const doLogin = (email, password) => {
  return api.post('/auth/signin', { email, password }) //signInWithEmailAndPassword(auth, email, password);
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const doLogout = () => {
  auth.signOut().then((result) => {});
};

export const updatePasswordWithReauthentication = async (
  currentPassword,
  newPassword
) => {
  try {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    console.log("Password updated successfully!");
    return "Password updated successfully!";
  } catch (error) {
    console.log(error.code);
    if (error.code === "auth/invalid-credential") {
      return "The current password provided is incorrect.";
    } else {
      return "Failed to update password. Please try again later.";
    }
  }
};
