import React from "react";
import { Bar } from "react-chartjs-2";

export const DashboardMultiBarChart = ({
  dashboardData,
  focusAttribute,
  additionalDashboradInfo,
}) => {
  const getDayWeekMonthYear = (date) => {
    if (!date) return { day: "", month: "", year: "" };
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Calculate the week number
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const week = getWeekNumber(dateObj);
    return { day, month, year, week };
  };
  // Extracting all unique attribute names
  let dataToDisplay = dashboardData.filter((data) => {
    return data !== null;
  });

  const allAttributes = dataToDisplay.reduce((acc, data) => {
    if (data || data !== null) {
      Object.keys(data).forEach((attr) => {
        if (!acc.includes(attr)) {
          acc.push(attr);
        }
      });
      return acc;
    }
  }, []);

  const handleFrequencyLabel = (frequency, index) => {
    const startDates = getDayWeekMonthYear(additionalDashboradInfo.startDate);
    const endDates = getDayWeekMonthYear(additionalDashboradInfo.endDate);

    const labels = {
      Daily: ["Day", startDates.day, endDates.day],
      Weekly: ["Week", startDates.week, endDates.week],
      Monthly: ["Month", startDates.month, endDates.month],
      Yearly: ["Year", startDates.year, endDates.year],
    };

    const [labelType, startLabel, endLabel] = labels[frequency] || [];
    if (frequency == "Yearly") {
      return index === 0
        ? `${labelType} (${startLabel})`
        : `${labelType} (${endLabel})`;
    } else if (frequency == "Daily") {
      return index === 0
        ? additionalDashboradInfo?.isInitial
          ? `Date1 (${additionalDashboradInfo.endDate})`
          : `Date1 (${additionalDashboradInfo.startDate})`
        : `Date2 (${additionalDashboradInfo.endDate})`;
    } else {
      return index === 0
        ? `${labelType} (${startLabel}) - Year ${startDates.year}`
        : `${labelType} (${endLabel}) - Year (${endDates.year})`;
    }
  };

  function generateColor(index) {
    const hue = ((index + 2) * 137.508) % 360; // Use the golden ratio to ensure distinct hues
    const saturation = 70 + ((index * 15) % 30); // Vary saturation between 70% and 100%
    const lightness = 50 + ((index * 10) % 20); // Vary lightness between 50% and 70%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  // Organizing data for Chart.js
  const datasets = dataToDisplay.map((data, weekIndex) => ({
    label: handleFrequencyLabel(
      additionalDashboradInfo?.selectedFrequency,
      weekIndex
    ),
    barThickness: 28,
    backgroundColor: generateColor(weekIndex),
    data: allAttributes.map((attr) => data?.[attr] || null), // If an attribute is missing, set its strength to 0
  }));

  const data = {
    labels: allAttributes, // Labels are now the attributes
    datasets: datasets,
  };

  const options = {
    indexAxis: "y", // Renders the bars horizontally
    skipNull: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            weight: "bold", // Make legend text bold
          },
        },
        // borderWidth: 2,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        min: 0,
        max: focusAttribute !== "Core Attributes" ? 100 : 10, // Set max value based on type
        ticks: {
          stepSize: focusAttribute !== "Core Attributes" ? 10 : 1, // Adjust step size based on type
          font: {
            weight: "bold",
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
      y: {
        ticks: {
          font: {
            weight: "bold", // Making all labels bold
            size: 12,
            color: "rgba(0, 0, 0, 1)",
          },
        },
      },
    },
  };

  let scaleFactor;
  switch (dataToDisplay.length) {
    case 1:
      scaleFactor = 1.4;
      break;
    case 2:
      scaleFactor = 1.7;
      break;
    case 3:
      scaleFactor = 2.4;
      break;
    default:
      scaleFactor = 2.6;
  }

  return (
    <>
      {dashboardData && dashboardData.length !== 0 && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ padding: "5px", fontWeight: "600" }}>
              {focusAttribute !== "Core Attributes"
                ? `Signal Strength: ${focusAttribute}`
                : `Average score: ${focusAttribute}`}
            </p>
            {additionalDashboradInfo?.selectedOptionShow && (
              <p style={{ padding: "5px", fontWeight: "600" }}>
                {additionalDashboradInfo?.selectedOptionShow == "Company/Brand"
                  ? `Brand: ${additionalDashboradInfo?.brand}`
                  : `Product: ${additionalDashboradInfo?.product}`}
              </p>
            )}
          </div>

          <div>
            <Bar
              key={allAttributes}
              data={data || []}
              options={options}
              height={data.labels.length * scaleFactor}
              width={15}
            />
          </div>
          <p style={{ textAlign: "center", padding: "5px", fontWeight: "600" }}>
            {focusAttribute !== "Core Attributes"
              ? "Signal Strength (%)"
              : "Average Score"}
          </p>
        </>
      )}
    </>
  );
};
