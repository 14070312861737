import BrandSnippets from "../BrandSnippets/BrandSnippets";
import BrandThemes from "../BrandThemes/BrandThemes";
import Description from "../Description/Description";
import classes from './BrandInsights.module.css';

export default function BrandInsights({ entity, category }) {
  return (
    <>
      <Description entity={entity} />
      <div className={classes.brand}>
        <div className={classes.brand_specs}>
          <BrandSnippets entity={entity} category={category} />
          <div className={classes.partition}></div>
          <BrandThemes entity={entity} category={category} />
        </div>
      </div>
    </>
  );
}
