import React, { useEffect, useState, useContext } from "react";
import Table from "@mui/material/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JSON5 from "json5";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AuthContext } from "../../context/auth-context";
import { useNavigate } from "react-router";
import { layout } from "../LayoutComponent/LayoutComponent";
import {
  fetchDataFromFirebase,
  deleteDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";
import { Spinner } from "react-bootstrap";
import { fetchGeminiAPI, fetchGpt4API } from "../../services/apiService";
import ConfirmModal from "./ConfirmModal";
import {
  calculateStrength,
  generateRankingObject,
  displayDateTime,
} from "../../utils/helpers";
import {
  SOURCES_CONSOLIDATION_PROMPT,
  SOURCES_PROMPT,
  ATTRIBUTES_OPTIMIZATION_PROMPT,
  DATA_COMPARISON_PROMPT,
} from "../../utils/constant";

function MonitorSetup() {
  const [rows, setRows] = useState([]);
  const [monitoringPrompts, setMonitoringPrompts] = useState({});
  const { authUserEmail, isCurrentUserAdmin, loggedInUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState("");
  const [rowData, setRowData] = useState("");

  const ATTRIBUTES_ANALYSIS_PROMPT = {
    "Top 5 Positive Attributes": monitoringPrompts?.positiveAttributesAnalysis,
    "Top 5 Negative Attributes": monitoringPrompts?.negativeAttributesAnalysis,
    "Top 5 Positive Statements": monitoringPrompts?.positiveFactsAnalysis,
    "Top 5 Negative Statements": monitoringPrompts?.negativeFactsAnalysis,
    "Top 5 Brands in Category": monitoringPrompts?.topBrandsAnalysis,
    "Top 5 Products in Category": monitoringPrompts?.topProductsAnalysis,
    "Frequently recommended buying criteria/considerations":
      monitoringPrompts?.buyingAnalysis,
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (isDelete, key) => {
    setShowModal(true);
    if (isDelete) {
      setIsDeleteAction(true);
      setIdToBeDeleted(key);
    } else {
      setIsDeleteAction(false);
      setRowData(key);
    }
  };

  useEffect(() => {
    fetchDataFromFirebase(
      (data) => {
        if (data !== null) {
          const arrayOfObjects = Object.entries(data).map(([key, value]) => ({
            key,
            ...value,
          }));
          setRows(arrayOfObjects);
        } else {
          setRows(null);
        }
      },
      "MonitoringData",
      authUserEmail
    );
  }, []);

  useEffect(() => {
    fetchDataFromFirebase((data) => {
      if (data !== null) {
        const promptsData = Object.values(data);
        setMonitoringPrompts(promptsData[0]);
      }
    }, "MonitoringPrompts");
  }, []);

  const navigate = useNavigate();

  const handleDeleteData = (itemKey) => {
    deleteDataFromFirebase(itemKey, "MonitoringData", () => {
      setRows((prevData) => prevData.filter((item) => item.key !== itemKey));
    });
    setShowModal(false);
  };

  const handlePauseAndResumeMonitoring = (data, isPause) => {
    const dataToUpdate = data;
    if (isPause) {
      dataToUpdate.isPaused = true;
      updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
    } else {
      dataToUpdate.isPaused = false;
      updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
    }
  };

  const testMonitoring = (data) => {
    if (data && Object.keys(data).length !== 0) {
      const dataToUpdate = data;
      dataToUpdate.status = "In-Progress";
      updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
      try {
        processMonitoringAPIs(data);
        setShowModal(false);
      } catch (err) {
        toast.error("Something went wrong, Please try again !", {
          autoClose: 700,
        });
        dataToUpdate.status = "Running";
        updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
        setShowModal(false);
      }
    }
  };

  const generateUniqueDataForAnalysis = async (results, attribute) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${ATTRIBUTES_ANALYSIS_PROMPT[attribute]}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${ATTRIBUTES_OPTIMIZATION_PROMPT}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      throw new Error(err);
    }
  };

  const signalStrengthAnalysis = async (results, prevObjects, attribute) => {
    let result = await generateUniqueDataForAnalysis(results, attribute);
    let signalStrengthObj;
    if (
      attribute === "Top 5 Brands in Category" ||
      attribute === "Top 5 Products in Category"
    ) {
      signalStrengthObj = JSON5.stringify(generateRankingObject(result));
    } else {
      signalStrengthObj = JSON5.stringify(calculateStrength(result));
    }

    const inputPrompt = `${DATA_COMPARISON_PROMPT}......first set of objects is==> ${prevObjects}.....and second set object is ==> ${signalStrengthObj}`;
    let modifiedresult = await fetchGpt4API(
      inputPrompt,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "Frontend Monitoring"
    );
    modifiedresult = modifiedresult["GPT-4"][0];
    modifiedresult = modifiedresult.match(/\{[\s\S]*\}/);
    modifiedresult = modifiedresult ? modifiedresult[0] : null;
    let jsObject = JSON5.parse(modifiedresult);
    return jsObject;
  };

  const getMonitoringTablePromptAndFrequency = (data, promptFromDB) => {
    let monitoringTablePrompt = promptFromDB;
    let frequency = "Week";
    if (data?.monitoringFrequency == "Daily") {
      monitoringTablePrompt = monitoringTablePrompt.replace(/week/g, "Day");
      frequency = "Day";
    } else if (data?.monitoringFrequency == "Monthly") {
      monitoringTablePrompt = monitoringTablePrompt.replace(/week/g, "Month");
      frequency = "Month";
    }

    return { monitoringTablePrompt, frequency };
  };

  const monitoringAPIAndComparisonResults = (
    results,
    frequency,
    apiResponse
  ) => {
    let finalResults = {};
    if (results && Object.keys(results).length !== 0) {
      finalResults = {
        ...results,
        [`${frequency}${Object.keys(results).length + 1}`]: apiResponse,
      };
    } else {
      finalResults = {
        [`${frequency}1`]: apiResponse,
      };
    }

    return finalResults;
  };

  const multipleAPIResults = async (count, llm, prompt) => {
    let apiCountArray = [];
    for (var i = 1; i <= count; i++) {
      apiCountArray.push(i);
    }

    if (llm === "gpt-4") {
      apiCountArray = apiCountArray.map((number) => number + 10);
    }

    try {
      const promises = apiCountArray.map(async (count) => {
        let result;

        if (count <= 10) {
          result = await fetchGeminiAPI(
            prompt,
            undefined,
            undefined,
            "Frontend Monitoring"
          );
          result = result["Gemini"][0];
        } else {
          result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Frontend Monitoring"
          );
          result = result["GPT-4"][0];
        }
        return result;
      });

      // responses from multiple api calls
      const allResults = await Promise.all(promises);

      return allResults;
    } catch (err) {
      console.log(err);
    }
  };

  const synthesizedResponseResult = async (results) => {
    const resultsWithQuotes = results.map((result) => `'${result}'`);
    const inputPrompt = `${monitoringPrompts?.synthesizationPrompt}==> multiple API responses in markdown raw strings are: ${resultsWithQuotes}`;
    let result = await fetchGeminiAPI(inputPrompt);
    result = result["Gemini"][0];
    return result;
  };

  const signalStrengthAnalysisForSources = async (
    results,
    prevObjects,
    attribute
  ) => {
    try {
      let result = await generateUniqueSourceDataForAnalysis(
        results,
        attribute
      );
      let signalStrengthObj = JSON5.stringify(calculateStrength(result));

      const inputPrompt = `${DATA_COMPARISON_PROMPT}......first set of objects is==> ${prevObjects}.....and second set object is ==> ${signalStrengthObj}`;
      let modifiedresult = await fetchGpt4API(
        inputPrompt,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      modifiedresult = modifiedresult["GPT-4"][0];
      modifiedresult = modifiedresult.match(/\{[\s\S]*\}/);
      modifiedresult = modifiedresult ? modifiedresult[0] : null;
      let jsObject = JSON5.parse(modifiedresult);
      return jsObject;
    } catch (err) {
      toast.error("something went wrong in APIs!", {
        autoClose: 2000,
      });
    }
  };

  const generateUniqueSourceDataForAnalysis = async (results, attribute) => {
    try {
      const resultsWithQuotes = results.map(
        (result, index) => `(${index + 1}).'${result}'`
      );
      const prompt = `${SOURCES_PROMPT}.... total (${resultsWithQuotes.length}) markdown datasets are:==> ${resultsWithQuotes}`;
      let result1 = await fetchGeminiAPI(
        prompt,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result1 = result1["Gemini"][0];
      // result1 = result1.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result1 = result1.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      const prompt2 = `${SOURCES_CONSOLIDATION_PROMPT}..input array is ==> ${result1}`;
      let result2 = await fetchGpt4API(
        prompt2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "Frontend Monitoring"
      );
      result2 = result2["GPT-4"][0];
      // result2 = result2.match(/\[\s*\[[^\]]*\]\s*,\s*\[[^\]]*\]\s*\]/);
      result2 = result2.match(/\[\s*\[[^\]]*\](?:\s*,\s*\[[^\]]*\])*\s*\]/)[0];
      result2 = JSON5.parse(result2);
      return result2;
    } catch (err) {
      throw new Error(err);
    }
  };

  const processMonitoringAPIs = async (data) => {
    try {
      // generate latest result from set prompt
      let count = data?.apiCount ? Number(data?.apiCount) : 2;
      count = count + 1;

      let apiResults = await multipleAPIResults(count, data?.llm, data?.prompt);

      let monitoringRecentData = await signalStrengthAnalysis(
        apiResults,
        data?.visualizationData,
        data?.focusAttribute
      );

      let monitoringRecentDataForSources;

      if (data?.visualizationDataForSources) {
        monitoringRecentDataForSources = await signalStrengthAnalysisForSources(
          apiResults,
          data?.visualizationDataForSources,
          data?.focusAttribute
        );
      }

      // let recentSynthesizedData = await synthesizedResponseResult(apiResults);

      // let apiResult;
      // if (data?.llm === "Gemini") {
      //   apiResult = await fetchGeminiAPI(data?.prompt);
      //   apiResult = apiResult["Gemini"][0];
      // }
      // if (data?.llm === "GPT-4") {
      //   apiResult = await fetchGpt4API(data?.prompt);
      //   apiResult = apiResult["GPT-4"][0];
      // }

      // let { monitoringTablePrompt, frequency } =
      //   getMonitoringTablePromptAndFrequency(
      //     data,
      //     monitoringPrompts?.monitoringTablePrompt
      //   );
      // let monitoringAPIResults = monitoringAPIAndComparisonResults(
      //   data?.monitoringAPIResults,
      //   frequency,
      //   recentSynthesizedData
      // );

      // compare the baseline and latest response and extract major and minor changes
      // const comparisonPrompt = `${monitoringPrompts?.dataComparisonPrompt} baseline data is ${data?.baselineSynthesized} & latest version data is ${recentSynthesizedData}`;
      // let comparisonResult = await fetchGpt4API(comparisonPrompt);
      // comparisonResult = comparisonResult["GPT-4"][0];
      // console.log("comparison Result==>", comparisonResult);

      // let comparisonResults = monitoringAPIAndComparisonResults(
      //   data?.comparisonResults,
      //   frequency,
      //   comparisonResult
      // );
      // generate final table and save it into DB
      // const tablePrompt = `${monitoringTablePrompt}.Existing markdown table is ${
      //   data?.monitoringData || data?.baselineData
      // } and new dataset is ${comparisonResult}`;
      // let finalResult = await fetchGpt4API(tablePrompt);
      // finalResult = finalResult["GPT-4"][0];

      const dataToUpdate = data;
      dataToUpdate.status = "Running";
      dataToUpdate.visualizationData = JSON5.stringify([
        ...JSON5.parse(dataToUpdate.visualizationData),
        monitoringRecentData,
      ]);
      if (dataToUpdate?.visualizationDataForSources) {
        dataToUpdate.visualizationDataForSources = JSON5.stringify([
          ...JSON5.parse(dataToUpdate.visualizationDataForSources),
          monitoringRecentDataForSources,
        ]);
      }
      // dataToUpdate.previousMonitoringData =
      //   data?.monitoringData || data?.baselineData;
      // dataToUpdate.monitoringData = finalResult;
      dataToUpdate.lastMonitored = displayDateTime();
      // dataToUpdate.isMonitored = true;
      // dataToUpdate.monitoringAPIResults = monitoringAPIResults;
      // dataToUpdate.comparisonResults = comparisonResults;
      // dataToUpdate.recentMonitoringAPIResult = recentSynthesizedData;
      // dataToUpdate.multipromptAPIResults = [...dataToUpdate?.multipromptAPIResults,apiResults]
      updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
    } catch (err) {
      const dataToUpdate = data;
      dataToUpdate.status = "Running";
      updateDataInFirebase("MonitoringData", data?.key, dataToUpdate);
      throw new Error(err);
    }
  };

  return (
    <>
      <ConfirmModal
        confirmMessage={
          isDeleteAction
            ? "Would you like to delete?"
            : "Would you like to Run Test?"
        }
        show={showModal}
        isDeleteAction={isDeleteAction}
        handleClose={handleCloseModal}
        idToBeDeleted={idToBeDeleted}
        rowData={rowData}
        testMonitoring={testMonitoring}
        handleDeleteData={handleDeleteData}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "5%",
          paddingRight: "5%",
        }}
      >
        <h3> Brand/Product Monitoring</h3>
        { loggedInUser?.role != "demo" && <Button
          onClick={() => {
            navigate(`/Monitoring?tab=3`);
          }}
          className="p-2 "
          style={{
            width: "15%",
            backgroundColor: "#3dc863",
            color: "white",
          }}
        >
          Add Monitor
        </Button> }
      </div>
      <br />
      {rows && rows.length == 0 ? (
        <div className="container d-flex flex-column align-items-center mt-5">
          <Spinner />
          <span className="mt-3">Loading Monitoring Data...</span>
        </div>
      ) : rows !== null ? (
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "90%",
            margin: "auto",
            overflow: "auto",
            marginBottom: "20px",
          }}
          md={{ maxWidth: "80%", margin: "auto", overflow: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: "12%" }}>
                  <b>WHAT TO MONITOR?</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "12%" }}>
                  <b>LLM USED</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "12%" }}>
                  <b>MONITOR FREQUENCY</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "40%" }}>
                  <b>FOCUS AREA</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  <b>STATUS</b>
                </TableCell>
                <TableCell align="center" sx={{ width: "20%" }}>
                  <b>ACTIONS</b>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    key={row.brandOrProductToMonitor}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {row.brandOrProductToMonitor}
                    </TableCell>
                    <TableCell align="center">{row?.llm || "--"}</TableCell>
                    <TableCell align="center">
                      {row?.monitoringFrequency || "--"}
                    </TableCell>
                    <TableCell align="left" sx={{ overflow: "auto" }}>
                      <Box
                        sx={{
                          maxHeight: "100px",
                          overflow: "auto",
                          width: "100%",
                        }}
                      >
                        {row?.focussingArea || row?.prompt}
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ width: "25%" }}>
                      {row.status === "In-Progress" ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <HourglassTopIcon
                            style={{ color: "#FFA500", marginRight: 4 }}
                          />
                          <Typography style={{ color: "#FFA500" }}>
                            In-Progress
                          </Typography>
                        </Box>
                      ) : row.status === "Running" ? (
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CheckCircleIcon
                              style={{ color: "#4CAF50", marginRight: 4 }}
                            />
                            <Typography style={{ color: "#4CAF50" }}>
                              ACTIVE
                            </Typography>
                          </Box>

                          {/* Display current date below the CheckCircleIcon and Typography */}
                          <Typography
                            sx={{
                              fontStyle: "italic",
                              fontSize: "11px",
                              color: "#757575",
                            }}
                          >
                            {row?.lastMonitored
                              ? `last monitored on ${row?.lastMonitored}`
                              : ""}
                          </Typography>
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "#3dc863",
                            margin: "3px 6px",
                          }}
                          onClick={() => {
                            navigate(`/monitoringreport/${index}?tab=3`);
                          }}
                          disabled={row?.status !== "Running"}
                        >
                          View
                        </Button>

                        {isCurrentUserAdmin && (
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "#3dc863",
                              margin: "3px 6px",
                            }}
                            // onClick={() => testMonitoring(row)}
                            onClick={() => {
                              handleShowModal(false, row);
                            }}
                            disabled={
                              row?.status !== "Running" ||
                              row?.isPaused ||
                              !row?.visualizationData
                            }
                          >
                            TEST
                          </Button>
                        )}
                        { loggedInUser?.role != "demo" && (
                          <>
                            {row?.isPaused ? (
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  backgroundColor: "#3dc863",
                                  margin: "3px 6px",
                                }}
                                sx={{ margin: "3px 6px" }}
                                disabled={row?.status !== "Running"}
                                onClick={() =>
                                  handlePauseAndResumeMonitoring(row, false)
                                }
                              >
                                RESUME
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                size="small"
                                color="error"
                                sx={{ margin: "3px 6px" }}
                                disabled={row?.status !== "Running"}
                                onClick={() =>
                                  handlePauseAndResumeMonitoring(row, true)
                                }
                              >
                                PAUSE
                              </Button>
                            )}
                          </>
                        )}

                        { loggedInUser?.role != "demo" && <Button
                          variant="contained"
                          size="small"
                          color="error"
                          sx={{ margin: "3px 6px" }}
                          // onClick={() => handleDeleteData(row.key)}
                          onClick={() => {
                            handleShowModal(true, row?.key);
                          }}
                          // disabled={row?.status !== "Running"}
                        >
                          DELETE
                        </Button> }
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="container d-flex flex-column align-items-center mt-5">
          <span className="mt-3">Monitoring has not been set !</span>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default layout(MonitorSetup, false);
