import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

import SettingsToggle from "./SettingsToggle";
import api from "./../../api";
import SettingsToggleItem from "./SettingsToggleItem";
import MonitoringFrequencyTabs from "./MonitoringFrequencyTabs";
import { AuthContext } from "./../../../context/auth-context";

export default function Monitoring() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [monitors, setMonitors] = useState([]);
  const [jobFrequencies, setJobFrequencies] = useState([]);

  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const getCustomerMetricsMonitoring = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/customers/${customerId}/metrics-job-frequency`
      );
      if (response.status === 200) {
        setMonitors(response.data.data);
      } else {
        setError("Failed to load monitorning frequencies. Try again later.");
      }
    } catch (error) {
      setError("Failed to load monitorning frequencies. Try again later");
    } finally {
      setLoading(false);
    }
  };

  const updateCustomerMetricsMonitoring = async (monitorId, frequencyId) => {
    try {
      const response = await api.patch(
        `/customers/${customerId}/metrics-job-frequency/${monitorId}`,
        {
          frequency_id: frequencyId,
        }
      );
      if (response.status === 201) {
        const newCustomerMetricMonitor = response.data.data;
        setMonitors((prevMonitors) => {
          const updatedMonitors = [...prevMonitors];
          const monitorIndex = updatedMonitors.findIndex(
            (monitor) => monitor.id === monitorId
          );
          if (monitorIndex > -1) {
            const frequency = jobFrequencies.find(
              (frequency) =>
                frequency.id == newCustomerMetricMonitor.job_frequency_id
            );
            updatedMonitors[monitorIndex].job_frequency = frequency;
            updatedMonitors[monitorIndex].job_frequency_id = frequency.id;
          }
          return updatedMonitors;
        });
      } else {
        toast.error("Failed to update monitorning frequency.Try again later.");
      }
    } catch (error) {
      toast.error("Failed to update monitorning frequency.Try again later.");
    }
  };

  const getJobFrequencies = async () => {
    try {
      const response = await api.get("/job-frequency");
      if (response.status === 200) {
        setJobFrequencies(response.data.data);
      }
    } catch (error) {
      console.log(`Something went wrong - ${error.message}`);
    }
  };

  useEffect(() => {
    getJobFrequencies();
    getCustomerMetricsMonitoring();
  }, []);

  return (
    <>
      <SettingsToggle
        error={error}
        loading={loading}
        data={monitors}
        render={(monitor) => (
          <SettingsToggleItem
            key={monitor.id}
            textPrimary={monitor.customer_metric.metric.name}
            textSecondary={monitor.customer_metric.metric.description}
            secondaryAction={
              <MonitoringFrequencyTabs
                activeFrequencyId={monitor.job_frequency_id}
                data={jobFrequencies}
                onChange={({ id: frequencyId }) =>
                  updateCustomerMetricsMonitoring(monitor.id, frequencyId)
                }
              />
            }
          />
        )}
      />
    </>
  );
}
