import ReplayIcon from '@mui/icons-material/Replay';

import MUIIconButton from '../UI/MUIIconButton/MUIIconButton';
import classes from './ErrorMessage.module.css';

export default function ErrorMessage({ align, message, retry, onRetry }) {
  const btnStyle = {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center'
  }

  if (align === 'left') {
    btnStyle.justifyContent = 'flex-start';
  } else if (align === 'right') {
    btnStyle.justifyContent = 'flex-end';
  }

  return (
    <div style={btnStyle}>
      <span className={classes.message}>{message}</span>
      {retry && <MUIIconButton className={classes.retry} variant='text' onClick={onRetry}>
        <ReplayIcon /></MUIIconButton>}
    </div>
  );
}
