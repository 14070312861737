import React, { useContext, useEffect, useState } from "react";

import MainJobController from "../../../BrandOnBoarding/Settings/Component/MonitoringControllers/MainJobController";
import PersonaController from "../../../BrandOnBoarding/Settings/Component/MonitoringControllers/PersonaController";
import Monitoring from "../../../BrandOnBoarding/Settings/Component/Monitoring";
import LlmController from "../../../BrandOnBoarding/Settings/Component/MonitoringControllers/LlmController";
import GeoMarketController from "../../../BrandOnBoarding/Settings/Component/MonitoringControllers/GeoMarketController";
import Spinner from "../../../Component/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { fetchCustomer } from "../../../redux/actions/userActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { fetchGeoMarkets } from "../../../redux/actions/geoMarketsActions";
import { AuthContext } from "../../../context/auth-context";
import Tabs from "../../../Component/UI/Tabs/Tabs";



const TabContent = [
  {
    name: "Brand/Product & Competitors",
    value: "Brand/Product & Competitors",
  },
  {
    name: "Personas",
    value: "Personas",
  },
  {
    name: "Llms",
    value: "Llms",
  },
  {
    name: "Geography & Markets",
    value: "Geography & Markets",
  },
  {
    name: "Metrics",
    value: "Metrics",
  },
];

const MonitoringSetup = () => {
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Brand/Product & Competitors");
  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCustomer(customerId));
        await dispatch(fetchPersonas(customerId));
        await dispatch(fetchGeoMarkets(customerId));
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, customerId]);

  const renderComponent = (componentName) => {
    switch (componentName) {
      case "Brand/Product & Competitors":
        return <MainJobController />;
      case "Personas":
        return <PersonaController />;
      case "Metrics":
        return <Monitoring />;
      case "Llms":
        return <LlmController />;
      case "Geography & Markets":
        return <GeoMarketController />;

      default:
        return null;
    }
  };

  if (loading) {
    return <Spinner />; // Replace with your actual loader component
  }

  return (
    <>
      {/* <Tabs defaultActiveKey="0" id="controlled-tab-example" className="mb-3">
        {TabContent.map((section, index) => (
          <Tab eventKey={index.toString()} title={section.title} key={index}>
            <div className="mt-3">{renderComponent(section.title)}</div>
          </Tab>
        ))}
      </Tabs> */}
      <Tabs
          options={TabContent}
          selected={selectedTab}
          onChange={(tab) => setSelectedTab(tab)}
        
        />
         <div className="p-4" style={{ background: "white" }}>
            {renderComponent(selectedTab)}
         </div>
    </>
  );
};

export default MonitoringSetup;
