import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const COLORS = ["#D76B66", "#F6CF7D", "#EA973D", "#75C57F"];

const RadialGraph = (props) => {
  const [animatedAngle, setAnimatedAngle] = useState(0);

  // Determine if the range is 0-100 or 0-50
  const isRange100 = props?.Range === 100;

  // Adjust the data based on the range
  const data = isRange100
    ? [
        { name: "completed", value: 30 },
        { name: "remaining", value: 20 },
        { name: "completed", value: 20 },
        { name: "remaining", value: 30 },
      ]
    : [
        { name: "completed", value: 15 },
        { name: "remaining", value: 10 },
        { name: "completed", value: 10 },
        { name: "remaining", value: 15 },
      ];

  // Current and past values
  const value = props?.value
    ? Number(Object.values(props?.value)).toFixed(0)
    : "NA";
  const pastValue = props?.pastValue
    ? Number(Object.values(props?.pastValue)).toFixed(0)
    : "NA";

  const delta = value - pastValue;

  // Calculate the final pointer angle based on the range
  const finalPointerAngle = isRange100
    ? 180 - (value / 100) * 180 // For range 0-100
    : 180 - (value / 50) * 180; // For range 0-50

  // Animate the pointer from 180 to the final angle
  useEffect(() => {
    const step = (180 - finalPointerAngle) / 50; // Number of steps for smooth animation
    let currentAngle = 180; // Start from 180 degrees (top)

    const interval = setInterval(() => {
      currentAngle -= step; // Decrease the angle
      if (currentAngle <= finalPointerAngle) {
        currentAngle = finalPointerAngle; // Ensure it stops at the final value
        clearInterval(interval); // Stop animation once the final angle is reached
      }
      setAnimatedAngle(currentAngle);
    }, 20); // Adjust timing for smoother/slower animation

    return () => clearInterval(interval); // Cleanup
  }, [finalPointerAngle]);

  // The center position (cx, cy) for the pointer
  const radius = 130; // Assuming this is the radius of the outer circle of the Pie
  const centerX = 140; // Adjusted center X (half of the SVG width)
  const centerY = 140; // Adjusted center Y (half of the SVG height)

  // Calculate the position for the pointer relative to the center
  const pointerCx =
    centerX + radius * Math.cos((Math.PI * animatedAngle) / 180);
  const pointerCy =
    centerY - radius * Math.sin((Math.PI * animatedAngle) / 180);

  // Determine the index of the color based on the value's position in data
  let cumulativeValue = 0;
  let colorIndex = 0;

  for (let i = 0; i < data.length; i++) {
    cumulativeValue += data[i].value;
    if (value <= cumulativeValue) {
      colorIndex = i;
      break;
    }
  }

  const circleColor = COLORS[colorIndex % COLORS.length];

  return (
    <div style={{ position: "relative", width: 280, height: 280 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            startAngle={180}
            endAngle={0}
            innerRadius={120}
            outerRadius={140}
            dataKey="value"
            cornerRadius={10}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {/* Displaying the value in the center of the radial graph */}
      <div
        style={{
          position: "absolute",
          top: "32%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "48px",
          fontWeight: "bold",
          color: "#242424",
        }}
      >
        {value}
      </div>
      <p
        style={{ marginTop: "-110px", width: "55%", margin: "-135px auto" }}
        className="lastDaysTag-white"
      >
        <FontAwesomeIcon
          style={delta < 0 ? { marginBottom: "8px" } : { marginTop: "5px" }}
          className={`${delta < 0 ? "text-danger" : "text-success"} me-1`}
          icon={delta < 0 ? faSortDown : faSortUp}
        />

        <span className="me-2 font-16-bold">
          {isNaN(delta) ? "NA" : delta < 0 ? `${delta}` : `+${delta}`}
        </span>
        <span className="darkgrey">{props?.frequencyLabel}</span>
      </p>

      {/* Adding the animated SVG pointer */}
      <svg
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        viewBox="0 0 280 280"
      >
        <circle
          cx={pointerCx}
          cy={pointerCy}
          r="8"
          fill={circleColor}
          stroke={circleColor}
          strokeWidth="9"
        />
        <circle cx={pointerCx} cy={pointerCy} r="5" fill="#FFF" />
      </svg>
    </div>
  );
};

export default RadialGraph;
