import React from "react";
import NavbarComponent from "./NavbarComponent";

export const layout = (Component) => {
  return function Layout() {
    return (
      <>
        <NavbarComponent />
        <div style={{ marginLeft: "10%", marginTop:'5.5%' }}>
        <Component />
        </div>
      </>
    );
  };
};
