import { useContext } from "react";

import { BrandIndexContext } from "../../context/brand-index-context";
import { createMarkdownTable } from "../../utils/helpers";
import BrandHistoryDetailItem from "./BrandHistoryDetailItem";
import BrandIndexSectionHeader from "./BrandIndexSectionHeader";

function BrandIndexHierarchical() {
  const brandIndexCtx = useContext(BrandIndexContext);

  const history = brandIndexCtx.history;

  function renderBrandIndexTable(hierarchy) {
    return hierarchy.data.map((item, index) => {
      const normalizedArrayForMarkdown = item.data.map((prod, _, arr) => {
        const getSortedIndex = (arr, prop, name) => arr.slice().sort((a, b) => b[prop] - a[prop])
                .findIndex(item => item.name === name);
        
        const rankIndex = getSortedIndex(arr, 'rank', prod.name);
        const favIndex = getSortedIndex(arr, 'favorability', prod.name);
        return [
          prod.name,
          `${prod.rank} <sup>(${rankIndex+1})</sup>`,
          `${prod.favorability} <sup> (${favIndex+1})</sup>`,
          `${prod.score} <sup>(${_+1})</sup>`,
        ]
      });
      normalizedArrayForMarkdown.unshift([
        "Brand/Product",
        "Rank",
        "Favorability",
        "Brand Index Score",
      ]);
      const markTable = createMarkdownTable(normalizedArrayForMarkdown);

      return (
        <BrandHistoryDetailItem
          key={index}
          item={item}
          hierarchy={hierarchy}
          markTable={markTable}
        />
      );
    });
  }

  return (
    <div>
      {history.map((hierarchy) => {
        return (
          <div key={hierarchy.date}>
            <BrandIndexSectionHeader date={hierarchy.date} top={0} />
            {renderBrandIndexTable(hierarchy)}
          </div>
        );
      })}
    </div>
  );
}

export default BrandIndexHierarchical;
