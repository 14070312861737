import { Modal, Button,FormControl } from "react-bootstrap";

import classes from "./SupportEmailModal.module.css";

function SupportEmailModal({
  show,
  handleClose,
  query,
  handleQueryChange,
  handleEmailClick,
  email,
  setEmail
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Have questions or feedback?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <FormControl
          type="text"
          placeholder="Enter Email"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        />
        <p></p>
      <FormControl
          as="textarea"
          rows={8} 
          cols={80} 
          placeholder="Enter questions or feedback..."
          value={query}
          onChange={handleQueryChange}
        />
     </Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button
          style={{backgroundColor:'green'}}
          variant="success"
          onClick={() => handleEmailClick()}
        >
          Send message
        </Button>
        <Button style={{backgroundColor:'#d32f2f'}} variant="danger" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SupportEmailModal;
