import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../../../context/auth-context";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import {
  fetchGeoMarkets,
  updateCustomerGeoLlmMarket,
} from "../../../../redux/actions/geoMarketsActions";

const LlmController = () => {
  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const llms = useSelector((state) => state.geoMarkets);
  const dispatch = useDispatch();

  useEffect(() => {
    if (llms?.data && llms?.data.length === 0) {
      dispatch(fetchGeoMarkets(customerId));
    }
  }, [dispatch]);

  const uniqueLlm = llms?.data?.data
    ?.map((item) => ({
      id: item.llm.id,
      name: item.llm.name,
      monitoring: item?.llm_monitoring,
    }))
    .filter(
      (llm, index, self) => index === self.findIndex((t) => t.id === llm.id)
    );

  if (uniqueLlm && uniqueLlm.length === 0) {
    return <p> Loading.. </p>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {uniqueLlm?.map((obj) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6>
              <OnlinePredictionIcon /> &nbsp; &nbsp; {obj?.name}
            </h6>
            <Switch
              defaultChecked={obj?.monitoring}
              onChange={(e) =>
                dispatch(
                  updateCustomerGeoLlmMarket(customerId, obj?.id, {
                    llm_monitoring: e.target.checked ? 1 : 0,
                  })
                )
                  .then(() => {
                    // dispatch(fetchGeoMarkets(customerId));
                  })
                  .catch(() => {
                    // dispatch(fetchGeoMarkets(customerId));
                  })
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default LlmController;
