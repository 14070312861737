import { Skeleton } from "@mui/material";

import classes from "./LoadingSkeleton.module.css";

export default function LoadingSkeleton() {
  return (
    <>
      <div className={classes.container}>
        <Skeleton height={20} width={"90%"} />
        <Skeleton height={15} width={"20%"} />
      </div>
      <div className={classes.container}>
        <Skeleton height={20} width={"90%"} />
        <Skeleton height={15} width={"20%"} />
      </div>
    </>
  );
}
