import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrandProducts } from "../../redux/actions/brandProductsActions";
import { fetchBrands } from "../../redux/actions/brandsActions";
import "react-toastify/dist/ReactToastify.css";
import DashboardLLMsComponent from "./DashboardComponent/DashboardLLMs";
import "../../App.css";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Card,
} from "react-bootstrap";
import DashboardBrandProductComponent from "./DashboardComponent/DashboardBrandProductComponent";
import DashboardBrandProductPersonaComponent from "./DashboardComponent/DashboardBrandProductPersonaComponent";
import DashboardFrequencyComponent from "./DashboardComponent/DashboardFrequencyComponent";

import LineChart from "./common/Linechart";

import {
  fetchCustomerLlms,
  getLlms,
  getMarkets,
  getProductAttributes,
  getBrandAttributes,
  getCompMetricsForBrand,
  getCompMetricsForProduct,
  getGeographies,
} from "../../services/apiService";
import { AuthContext } from "../../context/auth-context";
import useQuery from "./common/useQuery";
import BrandProductCoreAttributesTable from "./common/BrandProductCoreAttributesTable";
import BrandProductAttributesTable from "./common/BrandProductAttributesTable";
import BrandProductIndexScoresTable from "./common/BrandProductIndexScoresTable";
import MarketComponent from "./DashboardComponent/MarketComponent";
import GeographyComponent from "./DashboardComponent/GeographyComponent";
import CustomBarChart from "./common/ReBarchart";
function Dashboard() {
  const [value, setValue] = useState(0);
  const [selectedOptionShow, setSelectedOptionShow] = useState();

  const [showData, setShowData] = useState(false);

  const [isDone, setIsDone] = useState(true);
  const [selectedLlmItems, setSelectedLlmItems] = useState([]);
  const [selectedMarketItems, setSelectedMarketItems] = useState([]);
  const [selectedGeographyItems, setSelectedGeographyItems] = useState([]);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedFrequencyItems, setSelectedFrequencyItems] = useState([
    "Daily",
  ]);
  const [selectedSubFrequencyItems, setSelectedSubFrequencyItems] = useState([
    "Current",
  ]);
  const [brandItems, setBrandItems] = useState([]);
  const [productItems, setProductItems] = useState([]);
  const [selectedBrandItems, setSelectedBrandItems] = useState([]);
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [customDate, setCustomDate] = useState();
  const [additionalDashboradInfo, setAdditionalDashboradInfo] = useState({});

  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [compCoreAttributes, setCompCoreAttributes] = useState();
  const [avgBrandIndex, setAvgBrandIndex] = useState();
  const [llmAttributes, setLlmAttributes] = useState();
  const [llmNegativeAttributes, setLlmNegativeAttributes] = useState();
  const [llmAttributesSources, setLlmAttributesSources] = useState();
  const [llmNegativeAttributesSources, setLlmNegativeAttributesSources] =
    useState();

  const [llmCompBrandIndex, setLlmCompBrandIndex] = useState([]);
  const [llmCompBrandReach, setLlmCompBrandReach] = useState([]);
  const [llmCompBrandFav, setLlmCompBrandFav] = useState([]);

  const [brandsAvgRankData, setBrandsAvgRankData] = useState([]);
  const [avgRankSourcesData, setAvgRankSourcesData] = useState([]);
  const [categoryDimensionData, setCategoryDimensionData] = useState();
  const [categoryDimensionSourceData, setCategoryDimensionSourceData] =
    useState();
  const [compPositiveAttributes, setCompPositiveAttributes] = useState();
  const [compNegativeAttributes, setCompNegativeAttributes] = useState();

  const [customerLlm, setCustomerLlm] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geography, setGeography] = useState([]);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [brandIndexScore, setBrandIndexScore] = useState();
  const [brandCoreAttributesScore, setBrandCoreAttributesScore] = useState();
  const [brandPositiveAttributesStrength, setBrandPositiveAttributesStrength] =
    useState();
  const [brandNegativeAttributesStrength, setBrandNegativeAttributesStrength] =
    useState();
  const [indexScoreDataForChart, setIndexScoreDataForChart] = useState([]);

  const [positiveAttGraphData, setPositiveAttGraphData] = useState([]);
  const [negativeAttGraphData, setNegativeAttGraphData] = useState([]);

  const query = useQuery();
  const selectedBrandId = query.get("brandId") || 1;
  const productId = query.get("brandProductId");
  const selectedPersonaId = query.get("personaId");
  const selectedLlmId = query.get("llmId");
  const selectedMarketId = query.get("marketId");
  const selectedGeoId = query.get("geotId");

  const dispatch = useDispatch();
  const brandProducts = useSelector((state) => state.brandProducts);
  const brands = useSelector((state) => state.brands);

  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  const handleTabChange = (event, newValue) => {
    setShowData(false);
    setValue(newValue);
    setSelectedLlmItems(["gpt-4o"]);
    setSelectedGeographyItems(["USA"]);
    if (newValue !== 3) {
      setSelectedMarketItems(["General Market"]);
    }

    if (newValue !== 4) {
      if (selectedOptionShow != "Product") {
        setSelectedPersona(["General Persona"]);
      }
    }

    if (newValue !== 0) {
      setSelectedBrandItems(
        selectedBrandItems[0] ? [selectedBrandItems[0]] : []
      );
      if (selectedBrandItems[0]) {
        const selectedBrand = brands?.data?.data?.filter(
          (data) =>
            data?.brand?.name === selectedBrandItems[0] && data?.is_active
        );
        const persona = selectedBrand[0]?.persona;
        setPersonas(persona || []);
      }
    }

    if (
      newValue == 5 &&
      selectedSubFrequencyItems.includes("last 30 days Average")
    ) {
      setSelectedSubFrequencyItems(["Current"]);
    }
  };

  useEffect(() => {
    if (selectedOptionShow === "Product") {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brandProducts?.data?.data.filter(
          (obj) => obj.is_active
        );

        // const activeData = brandProducts?.data?.data;

        const selectedData = brandProducts?.data?.data.filter(
          (obj) => obj.id == productId
        );

        setSelectedProductItems([selectedData[0]?.product?.name]);
        setSelectedPersona([
          selectedData[0]?.custProduct[0]?.persona?.name,
        ]);
        setSelectedLlmItems(["gpt-4o"]);
        setSelectedMarketItems(["General Market"]);
        setSelectedGeographyItems(["USA"]);

        setProductItems(activeData);

        setPersonas(selectedData[0]?.custProduct);
        const brandProductId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id == globalLlmId &&
            item?.market_id == marketId &&
            item?.geography_id == geoId
        )[0]?.id;
        const personaId = [
          selectedData[0]?.custProduct[0]?.persona?.id,
        ];
        const productName = selectedData[0]?.product?.name;
        handleDashboardInitialData(
          undefined,
          brandProductId,
          llmId,
          personaId,
          undefined,
          productName
        );
      }
    }
  }, [brandProducts, dbLlms]);

  useEffect(() => {
    if (selectedOptionShow === "Company/Brand") {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        dbLlms &&
        dbLlms.length !== 0 &&
        customerLlm &&
        customerLlm.length !== 0 &&
        markets &&
        markets.length !== 0
      ) {
        const activeData = brands?.data?.data.filter((obj) => obj?.is_active);
        // const activeData = brands?.data?.data;
        const selectedData = brands?.data?.data.filter(
          (obj) => obj.id == selectedBrandId
        );
        let personaIndex = selectedData[0]?.persona.findIndex(
          (obj) => obj?.id == selectedPersonaId
        );

        personaIndex = personaIndex > 0 ? personaIndex : 0;
        let llmFromQuery = dbLlms.filter((obj) => obj?.id == selectedLlmId);
        llmFromQuery =
          llmFromQuery && llmFromQuery.length !== 0
            ? llmFromQuery[0]?.name
            : "gpt-4o";
        let marketFromQuery = markets.filter(
          (obj) => obj?.id == selectedMarketId
        );
        marketFromQuery =
          marketFromQuery && marketFromQuery.length !== 0
            ? marketFromQuery[0]?.name
            : "General Market";

        let geoFromQuery = geography.filter((obj) => obj?.id == selectedGeoId);
        geoFromQuery =
          geoFromQuery && geoFromQuery.length !== 0
            ? geoFromQuery[0]?.name
            : "USA";

        setSelectedBrandItems([selectedData[0]?.brand?.name]);
        setSelectedPersona([
          selectedData[0]?.persona[personaIndex]?.persona?.name,
        ]);
        setSelectedLlmItems([llmFromQuery]);
        setSelectedMarketItems([marketFromQuery]);
        setSelectedGeographyItems([geoFromQuery]);
        // setProductItems(brandProducts?.data?.data);
        setBrandItems(activeData);

        setPersonas(selectedData[0]?.persona);
        const brandId = selectedData[0]?.id || null;

        const globalLlmId =
          selectedLlmId ||
          dbLlms.filter((item) => item?.name === "gpt-4o")[0]?.id;
        const marketId =
          selectedMarketId ||
          markets.filter((item) => item?.name === "General Market")[0]?.id;
        const geoId =
          selectedGeoId ||
          geography.filter((item) => item?.name === "USA")[0]?.id;
        const llmId = customerLlm.filter(
          (item) =>
            item?.llm_id == globalLlmId &&
            item?.market_id == marketId &&
            item?.geography_id == geoId
        )[0]?.id;
        const personaId = [selectedData[0]?.persona[personaIndex]?.persona?.id];
        const brandName = selectedData[0]?.brand?.name;
        handleDashboardInitialData(
          brandId,
          undefined,
          llmId,
          personaId,
          brandName,
          undefined,
          selectedData[0]?.brand?.name
        );
      }
    }
  }, [brands, dbLlms]);

  useEffect(() => {
    async function fetchData() {
      const selectedBrandCustomerId =
        query.get("brandCustomerId") || customerId;
      const customerSpecificLlms = await fetchCustomerLlms([
        Number(selectedBrandCustomerId),
      ]);

      setCustomerLlm(customerSpecificLlms?.data);

      // set the customer geographies
      let geo = await getGeographies();
      const geographyIds = [
        ...new Set(customerSpecificLlms?.data.map((obj) => obj?.geography_id)),
      ];
      geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
      setGeography(geo);

      // set customer geography markets

      let market = await getMarkets();
      const filtercustomeLlm = customerSpecificLlms?.data.filter(
        (obj) => obj?.geography_id == 1
      );
      const marketIds = [
        ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
      ];
      market = market?.data.filter((obj) => marketIds.includes(obj?.id));
      setMarkets(market);

      if (brandProducts?.data && brandProducts?.data.length === 0) {
        dispatch(fetchBrandProducts(customerId));
      }

      if (brands?.data && brands?.data.length === 0) {
        dispatch(fetchBrands(customerId));
      }

      const llms = await getLlms();
      setDbLlms(llms?.data);
    }

    if (query.get("brandId") || query.get("brandProductId")) {
      if (query.get("brandId")) {
        setSelectedOptionShow("Company/Brand");
      } else if (query.get("brandProductId")) {
        setSelectedOptionShow("Product");
      }
    } else {
      setSelectedOptionShow("Company/Brand");
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        brandProducts?.data?.data &&
        brandProducts?.data?.data.length !== 0 &&
        selectedProductItems &&
        selectedProductItems.length > 0
      ) {
        const selectedBrandProductCustomerId = brandProducts?.data?.data
          .filter(
            (data) =>
              selectedProductItems.includes(data?.product?.name) &&
              data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandProductCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandProductCustomerId
          );
          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id == 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedProductItems, brandProducts]);

  useEffect(() => {
    async function fetchCustomerLlmsData() {
      if (
        brands?.data?.data &&
        brands?.data?.data.length !== 0 &&
        selectedBrandItems &&
        selectedBrandItems.length > 0
      ) {
        const selectedBrandCustomerId = brands?.data?.data
          .filter(
            (data) =>
              selectedBrandItems.includes(data?.brand?.name) && data?.is_active
          )
          .map((obj) => obj?.customer?.id);

        if (selectedBrandCustomerId) {
          const customerSpecificLlms = await fetchCustomerLlms(
            selectedBrandCustomerId
          );

          // set the customer geographies
          let geo = await getGeographies();
          const geographyIds = [
            ...new Set(
              customerSpecificLlms?.data.map((obj) => obj?.geography_id)
            ),
          ];
          geo = geo?.data.filter((obj) => geographyIds.includes(obj?.id));
          setGeography(geo);

          // set customer geography markets

          let market = await getMarkets();
          const filtercustomeLlm = customerSpecificLlms?.data.filter(
            (obj) => obj?.geography_id == 1
          );
          const marketIds = [
            ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
          ];
          market = market?.data.filter((obj) => marketIds.includes(obj?.id));
          setMarkets(market);
          setCustomerLlm(customerSpecificLlms?.data);
          setSelectedGeographyItems(["USA"]);
          setSelectedMarketItems(["General Market"]);
        }
      }
    }

    fetchCustomerLlmsData();
  }, [selectedBrandItems, brands]);

  useEffect(() => {
    const dateMapping = {
      Current: 0,
      "last 3 days Average": 2,
      "last 7 days Average": 6,
      "last 15 days Average": 14,
      "last 21 days Average": 20,
      "last 30 days Average": 29,
    };
    if (!selectedSubFrequencyItems.includes("Custom Date")) {
      const currentDate = new Date();
      const timeFactorDate = new Date();

      if (selectedSubFrequencyItems && selectedSubFrequencyItems.length !== 0) {
        currentDate.setDate(currentDate.getDate());
        timeFactorDate.setDate(
          currentDate.getDate() - dateMapping[selectedSubFrequencyItems[0]]
        );

        const options = {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
        const endDate = currentDate.toLocaleDateString("en-CA", options); // ISO format: YYYY-MM-DD
        const startDate = timeFactorDate.toLocaleDateString("en-CA", options);
        setEndDate(endDate);
        setStartDate(startDate);
      }
    }
  }, [selectedSubFrequencyItems]);

  const frequencyOptions = ["Daily", "Weekly", "Monthly", "Yearly"];
  const daysOptions =
    value == 5
      ? [
          "Current",
          "last 3 days Average",
          "last 7 days Average",
          // "last 15 days Average",
          // "last 21 days Average",
          // "last 30 days Average",
          "Custom Date",
        ]
      : [
          "Current",
          "last 3 days Average",
          "last 7 days Average",
          // "last 15 days Average",
          // "last 21 days Average",
          "last 30 days Average",
          "Custom Date",
        ];

  const getDayWeekMonthYear = (date) => {
    if (!date) return { day: "", month: "", year: "" };
    const dateObj = new Date(`${date}T00:00:00Z`); // Add 'T00:00:00Z' to treat it as UTC
    const day = dateObj.getUTCDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed
    const year = dateObj.getFullYear();

    // Calculate the week number
    const getWeekNumber = (date) => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const week = getWeekNumber(dateObj);
    return { day, month, year, week };
  };

  const handleCustomDateCheckChange = (event) => {
    const selectedDate = event.target.value;
    setCustomDate(selectedDate);
    setEndDate(selectedDate);
    setStartDate(selectedDate);
  };

  const handleCheckChange = (key) => {
    if (selectedLlmItems.includes(key)) {
      setSelectedLlmItems(selectedLlmItems.filter((item) => item !== key));
    } else {
      const llms = value == 2 ? [...selectedLlmItems, key] : [key];
      setSelectedLlmItems(llms);
    }
  };

  const handleFrequencyCheckChange = (key) => {
    if (selectedFrequencyItems.includes(key)) {
      setSelectedFrequencyItems(
        selectedFrequencyItems.filter((item) => item !== key)
      );
    } else {
      setSelectedFrequencyItems([key]);
    }
  };

  const handlesubFrequencyCheckChange = (key) => {
    if (selectedSubFrequencyItems.includes(key)) {
      setSelectedSubFrequencyItems(
        selectedSubFrequencyItems.filter((item) => item !== key)
      );
      setIsCustomDate(false);
      setCustomDate();
    } else {
      if (key === "Custom Date") {
        setIsCustomDate(true);
      } else {
        setIsCustomDate(false);
      }
      setCustomDate();
      setSelectedSubFrequencyItems([key]);
    }
  };

  const handleMarketCheckChange = (key) => {
    if (selectedMarketItems.includes(key)) {
      setSelectedMarketItems(
        selectedMarketItems.filter((item) => item !== key)
      );
    } else {
      if (value === 3) {
        setSelectedMarketItems([...selectedMarketItems, key]);
      } else {
        setSelectedMarketItems([key]);
      }
    }
  };

  const handleGeographyCheckChange = async (key) => {
    if (selectedGeographyItems.includes(key)) {
      setSelectedGeographyItems(
        selectedGeographyItems.filter((item) => item !== key)
      );
    } else {
      let geographyItemsToUpdate = [key];

      if (value == 3) {
        geographyItemsToUpdate = [...selectedGeographyItems, key];
      }

      setSelectedGeographyItems(geographyItemsToUpdate);

      let geoId = geography.filter((obj) => obj?.name == key)[0]?.id;
      let market = await getMarkets();
      const filtercustomeLlm = customerLlm.filter(
        (obj) => obj?.geography_id == geoId
      );

      if (geographyItemsToUpdate && geographyItemsToUpdate.length == 1) {
        // set customer geography markets

        const marketIds = [
          ...new Set(filtercustomeLlm.map((obj) => obj?.market_id)),
        ];
        market = market?.data.filter((obj) => marketIds.includes(obj?.id));
        setMarkets(market);
      } else if (geographyItemsToUpdate && geographyItemsToUpdate.length > 1) {
        // set customer geography markets

        market = market?.data.filter((obj) => obj?.name == "General Market");
        setMarkets(market);
      }
    }
  };

  const handleBrandCheckChange = (key) => {
    if (selectedBrandItems.includes(key)) {
      setSelectedBrandItems(selectedBrandItems.filter((item) => item !== key));
    } else {
      if (value == 0) {
        setSelectedBrandItems([...selectedBrandItems, key]);
        setSelectedPersona(["General Persona"]);
        // const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        const selectedBrand = brands?.data?.data.filter(
          (data) => data?.brand?.name === key && data?.is_active
        );
        const persona = selectedBrand[0]?.persona;
        setPersonas(persona);
      } else {
        setSelectedBrandItems([key]);
        setSelectedPersona(["General Persona"]);
        const selectedBrand = brands?.data?.data.filter(
          (data) => data?.brand?.name === key && data?.is_active
        );
        const persona = selectedBrand[0]?.persona;
        setPersonas(persona);
      }
    }
  };

  const handlePersonaCheckChange = (key) => {
    if (selectedPersona.includes(key)) {
      setSelectedPersona(selectedPersona.filter((item) => item !== key));
    } else {
      if (value === 4) {
        setSelectedPersona([...selectedPersona, key]);
      } else {
        setSelectedPersona([key]);
      }
    }
  };

  const handleProductCheckChange = (key) => {
    if (selectedProductItems.includes(key)) {
      setSelectedProductItems(
        selectedProductItems.filter((item) => item !== key)
      );
    } else {
      // if (value == 0) {
      //   setSelectedProductItems([...selectedProductItems, key]);
      // } else {
      //   setSelectedProductItems([ key]);
      // }

      setSelectedProductItems([key]);

      setSelectedPersona([]);
      const selectedProduct = brandProducts?.data?.data.filter(
        (data) => data?.product?.name === key
      );

      const persona = selectedProduct[0]?.custProduct;
      setPersonas(persona);
    }
  };

  const handleDashboardInitialData = async (
    brandId,
    productId,
    llmId,
    personaId,
    brandName,
    productName,
    categoryName
  ) => {
    await getCompDashboardDataFromDB(
      brandId,
      productId,
      llmId,
      personaId,
      true,
      brandName,
      productName,
      categoryName
    );
    setIsFirstTime(false);
  };

  const handleClickShow = async () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }
    const brandId =
      brandItems.filter(
        (item) => item?.brand?.name === selectedBrandItems[0]
      )[0]?.id || null;

    const productId =
      productItems.filter(
        (item) => item?.product?.name === selectedProductItems[0]
      )[0]?.id || null;

    const brandIds = brandItems
      .filter((item) => selectedBrandItems.includes(item?.brand?.name))
      .map((obj) => obj?.id);

    const productIds = productItems
      .filter((item) =>
        selectedProductItems.includes(item?.product?.name)
      )
      .map((obj) => obj?.id);

    const globalLlmId = dbLlms.filter(
      (item) => item?.name === selectedLlmItems[0]
    )[0]?.id;
    const marketId = markets.filter(
      (item) => item?.name === selectedMarketItems[0]
    )[0]?.id;

    const geoId = geography.filter(
      (item) => item?.name === selectedGeographyItems[0]
    )[0]?.id;

    const llmId = customerLlm.filter(
      (item) =>
        item?.llm_id == globalLlmId &&
        item?.market_id == marketId &&
        item?.geography_id == geoId
    )[0]?.id;
    const personaId = personas
      .filter((item) => selectedPersona.includes(item?.persona?.name))
      .map((obj) => obj?.persona_id);
    if (value === 0) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false,
        undefined,
        undefined,
        undefined,
        brandIds,
        productIds
      );
    } else if (value === 1) {
      await getCompDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    } else if (value === 2 || value === 3 || value === 4 || value === 5) {
      await getLlmComparisonDashboardDataFromDB(
        brandId,
        productId,
        llmId,
        personaId,
        false
      );
    }
  };

  // calculating avg by considering total as occurence of attributes/llm/market etc. which has signal strength/score assigned in the each date and not selected total dates..

  function calculateAverageData(data) {
    if (data) {
      const result = {};
      const dates = Object.keys(data);

      // Initialize result object with all markets and attributes
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          if (!result[market]) {
            result[market] = {};
          }
          Object.keys(markets[market]).forEach((attribute) => {
            if (!result[market][attribute]) {
              result[market][attribute] = { sum: 0, count: 0 };
            }
          });
        });
      });

      // Accumulate sums and counts
      dates.forEach((date) => {
        const markets = data[date];
        Object.keys(markets).forEach((market) => {
          Object.keys(markets[market]).forEach((attribute) => {
            const value = parseFloat(markets[market][attribute]) || 0;
            result[market][attribute].sum += value;
            result[market][attribute].count += 1;
          });
        });
      });

      // Calculate averages
      const averages = {};
      Object.keys(result).forEach((market) => {
        averages[market] = {};
        Object.keys(result[market]).forEach((attribute) => {
          const { sum, count } = result[market][attribute];
          averages[market][attribute] = sum / count;
        });
      });

      return { avg: averages };
    }
  }

  const handleRadioSectionShow = (option) => {
    setSelectedOptionShow(option);
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedPersona([]);
    if (option === "Company/Brand") {
      if (brands?.data?.data && brands?.data?.data.length !== 0) {
        const activeData = brands?.data?.data.filter((obj) => obj.is_active);
        // const activeData = brands?.data?.data;
        setBrandItems(activeData);
      }
    }

    if (option === "Product") {
      if (brandProducts?.data?.data && brandProducts?.data?.data.length !== 0) {
        // const activeData = brandProducts?.data?.data.filter(
        //   (obj) => obj.is_active
        // );
        const activeData = brandProducts?.data?.data;
        setProductItems(activeData);
      }
    }
  };

  const getCompetitorIndexScores = (result) => {
    if (result?.brandIndexcumulativeData) {
      const brandIndexArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_index_score"
      );
      const brandFavArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_favorability_score"
      );
      const brandReachArray = transformData(
        result?.brandIndexcumulativeData,
        "avg_brand_reach_score"
      );
      const avgIndex = calculateAverageIndex(brandIndexArray);
      const avgFav = calculateAverageIndex(brandFavArray);
      const avgReach = calculateAverageIndex(brandReachArray);
      let finalObject = {
        "Brand Index Score": avgIndex,
        Favorability: avgFav,
        Rank: avgReach,
      };

      if (value == 0) {
        const brandIndexArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_index_score"
        );

        const brandFavArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_favorability_score"
        );
        const brandReachArray = brandIndextransformData(
          result?.brandIndexcumulativeData,
          "avg_brand_reach_score"
        );
        const avgIndex = calculateAverageIndex(brandIndexArray);
        const avgFav = calculateAverageIndex(brandFavArray);
        const avgReach = calculateAverageIndex(brandReachArray);
        const finalObject = {
          "Brand Index Score": avgIndex,
          Favorability: avgFav,
          Rank: avgReach,
        };

        setBrandIndexScore(finalObject);
      } else {
        setAvgBrandIndex(finalObject);
      }
    }
  };

  const getLlmComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);

    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const geoIds = geography
      .filter((item) => selectedGeographyItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          marketIds.includes(item?.market_id) &&
          geoIds.includes(item?.geography_id)
      )
      .map((obj) => obj?.id);

    let result;
    let result2;

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getBrandAttributes(payload);
      result2 = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getProductAttributes(payload);
      result2 = await getCompMetricsForProduct(payload);
    }

    const data = transformDataForAttributes(result?.cumulativeData);

    const sourceData = transformDataForAttributesSources(
      result?.cumulativeSourcesData
    );

    const categoryDimension = result2?.llmCategoryDimensions;

    const categoryDimensionsData =
      processCategoryDimensionArray(categoryDimension);

    console.log("all ok upto catgeory dim");

    const avgRankData =
      selectedOptionShow == "Product"
        ? result2?.compProductAvgRankData
        : result2?.compBrandAvgRankData;
    const averageRankData = processAverageRankArray(avgRankData);

    console.log("all ok upto avg");

    const cumulativeCompData =
      selectedOptionShow == "Product"
        ? result2?.brandIndexcumulativeData?.compProducts
        : result2?.brandIndexcumulativeData?.compBrands;
    const cumulativebrandOrProductData =
      selectedOptionShow == "Product"
        ? result2?.brandIndexcumulativeData?.product
        : result2?.brandIndexcumulativeData?.brand;
    const brandIndexData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_index_score"
    );

    const brandFavData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_favorability_score"
    );

    const brandReachData = transformBrandIndexMultiLlmData(
      cumulativeCompData,
      cumulativebrandOrProductData,
      "avg_brand_reach_score"
    );

    const cumulativebrandOrProductCoreAttData =
      selectedOptionShow == "Product"
        ? result2?.coreAttributescumulativeData?.product
        : result2?.coreAttributescumulativeData?.brand;
    const coreAttdata = brandCoreAttDataTransform(
      cumulativebrandOrProductCoreAttData
    );

    console.log("all ok");

    setLlmAttributes(
      calculateAverageData(data?.positiveAttributesStructuredData)?.["avg"]
    );
    setLlmNegativeAttributes(
      calculateAverageData(data?.negativeAttributesStructuredData)?.["avg"]
    );
    setLlmAttributesSources(
      calculateAverageData(sourceData?.positiveAttributesStructuredData)?.[
        "avg"
      ]
    );
    setLlmNegativeAttributesSources(
      calculateAverageData(sourceData?.negativeAttributesStructuredData)?.[
        "avg"
      ]
    );
    setLlmCompBrandIndex(calculateAverageData(brandIndexData)?.["avg"]);
    setLlmCompBrandFav(calculateAverageData(brandFavData)?.["avg"]);
    setLlmCompBrandReach(calculateAverageData(brandReachData)?.["avg"]);

    setBrandsAvgRankData(averageRankData?.result);
    setAvgRankSourcesData(averageRankData?.sourceResult);

    setCategoryDimensionData(
      calculateAverageData(categoryDimensionsData?.result)?.["avg"]
    );
    setCategoryDimensionSourceData(
      calculateAverageData(categoryDimensionsData?.sourceResult)?.["avg"]
    );
    setCompCoreAttributes(
      calculateAverageData(coreAttdata?.dateSeriesData)?.["avg"]
    );

    const selectedBrandCategory = brands?.data?.data?.filter(
      (data) => data?.brand?.name === selectedBrandItems[0]
    )[0]?.category?.name;

    const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
      (data) => data?.brand?.name === selectedProductItems[0]
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0],
      product: selectedProductItems[0],
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
      brandCategory: selectedBrandCategory,
      productCategory: selectedBrandProductCategory,
      llm: selectedLlmItems[0],
    });
  };

  const transformedGraphData = (data) => {
    if (data && data.length > 0) {
      const filterData = data.filter(
        (obj) => obj?.attribute?.attribute_category !== null
      );

      const positiveAttributesData = filterData.filter(
        (obj) => obj?.is_positive
      );
      const negativeAttributesData = filterData.filter(
        (obj) => !obj?.is_positive
      );

      const processAttributesData = (attributesData) => {
        return attributesData.reduce((acc, item) => {
          const categoryName =
            item?.attribute?.attribute_category?.category_name;
          const signalStrength = Number(item?.signal_strength);
          const attributeName = item?.attribute?.name;

          // Find existing category in the accumulator
          const existingCategory = acc.find(
            (cat) => cat?.name === categoryName
          );

          if (existingCategory) {
            const attributeIndex =
              existingCategory.altNames.indexOf(attributeName);

            if (attributeIndex !== -1) {
              // If the attribute already exists, update total and count for averaging
              existingCategory.signalStrengths[attributeIndex].total +=
                signalStrength;
              existingCategory.signalStrengths[attributeIndex].count += 1;
            } else {
              // Add new attribute and initialize total and count
              existingCategory.altNames.push(attributeName);
              existingCategory.signalStrengths.push({
                total: signalStrength,
                count: 1,
              });
            }

            // Recalculate the maximum value based on the average
            const maxAvgSignalStrength = Math.max(
              ...existingCategory.signalStrengths.map(
                (entry) => entry.total / entry.count
              )
            );
            existingCategory.value = maxAvgSignalStrength.toFixed(0);
          } else {
            // Create a new category entry
            acc.push({
              name: categoryName,
              value: signalStrength.toFixed(0),
              altNames: [attributeName],
              signalStrengths: [{ total: signalStrength, count: 1 }],
            });
          }

          return acc;
        }, []);
      };

      const positiveAttResult = processAttributesData(positiveAttributesData);
      const negativeAttResult = processAttributesData(negativeAttributesData);

      return { positiveAttResult, negativeAttResult };
    }
  };

  const getCompetitorComparisonData = async (
    brandId,
    productId,
    personaId,
    llmId,
    startDate,
    endDate,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    const startDateObj = getDayWeekMonthYear(startDate);
    const endDateObj = getDayWeekMonthYear(endDate);
    let result;

    const llmIds = dbLlms
      .filter((item) => selectedLlmItems.includes(item?.name))
      .map((obj) => obj?.id);

    const marketIds = markets
      .filter((item) => selectedMarketItems.includes(item?.name))
      .map((obj) => obj?.id);

    const geoIds = geography
      .filter((item) => selectedGeographyItems.includes(item?.name))
      .map((obj) => obj?.id);

    const customerLlmIds = customerLlm
      .filter(
        (item) =>
          llmIds.includes(item?.llm_id) &&
          marketIds.includes(item?.market_id) &&
          geoIds.includes(item?.geography_id)
      )
      .map((obj) => obj?.id);

    if (selectedOptionShow === "Company/Brand") {
      const payload = {
        brandId: brandId,
        llmId: llmId,
        brandIds: brandIds,
        llmIds: customerLlmIds.length == 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getCompMetricsForBrand(payload);
    } else {
      const payload = {
        productId: productId,
        llmId: llmId,
        llmIds: customerLlmIds.length == 0 ? [llmId] : customerLlmIds,
        personaIds: personaId,
        productIds: productIds,
        startDay: startDateObj.day,
        startWeek: startDateObj.week,
        startMonth: startDateObj.month,
        startYear: startDateObj.year,
        endDay: endDateObj.day,
        endWeek: endDateObj.week,
        endMonth: endDateObj.month,
        endYear: endDateObj.year,
        frequency: selectedFrequencyItems[0],
      };

      result = await getCompMetricsForProduct(payload);
    }

    getCompetitorIndexScores(result, isInitial);
    const cumulativeCompData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.compProducts
        : result?.coreAttributescumulativeData?.compBrands;
    const cumulativebrandOrProductData =
      selectedOptionShow == "Product"
        ? result?.coreAttributescumulativeData?.product
        : result?.coreAttributescumulativeData?.brand;
    const data = transformDataForCoreAttributes(
      cumulativeCompData,
      cumulativebrandOrProductData
    );

    const cumulativeCompAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.compProducts
        : result?.attributescumulativeData?.compBrands;
    const cumulativebrandOrProductAttributesData =
      selectedOptionShow == "Product"
        ? result?.attributescumulativeData?.product
        : result?.attributescumulativeData?.brand;

    const attributesData = transformDataForCompAttributes(
      cumulativeCompAttributesData,
      cumulativebrandOrProductAttributesData
    );

    if (value == 0) {
      const cumulativebrandOrProductData =
        selectedOptionShow == "Product"
          ? result?.coreAttributescumulativeData?.product
          : result?.coreAttributescumulativeData?.brand;
      const data = brandCoreAttDataTransform(cumulativebrandOrProductData);

      const cumulativebrandOrProductAttributesData =
        selectedOptionShow == "Product"
          ? result?.attributescumulativeData?.product
          : result?.attributescumulativeData?.brand;

      const attributesData = brandAttDataTransform(
        cumulativebrandOrProductAttributesData
      );

      const attDataForGraph = transformedGraphData(
        cumulativebrandOrProductAttributesData
      );

      const filteredData = calculateAverageData(data?.structuredData)?.["avg"];
      const filteredData2 = calculateAverageData(
        attributesData?.structuredData
      )?.["avg"];

      const filteredData3 = calculateAverageData(
        attributesData?.negativeAttributesStructuredData
      )?.["avg"];
      setBrandCoreAttributesScore(filteredData);
      setBrandPositiveAttributesStrength(filteredData2);
      setBrandNegativeAttributesStrength(filteredData3);
      setPositiveAttGraphData(attDataForGraph?.positiveAttResult || []);
      setNegativeAttGraphData(attDataForGraph?.negativeAttResult || []);
    } else {
      setCompCoreAttributes(calculateAverageData(data)?.["avg"]);

      setCompPositiveAttributes(
        calculateAverageData(attributesData?.mergedData)?.["avg"]
      );
      setCompNegativeAttributes(
        calculateAverageData(attributesData?.negativeAttributesMergedData)?.[
          "avg"
        ]
      );
    }

    const selectedBrandCategory = brands?.data?.data?.filter(
      (data) => data?.brand?.name === categoryName
    )[0]?.category?.name;

    const selectedBrandProductCategory = brandProducts?.data?.data?.filter(
      (data) => data?.brand?.name === selectedProductItems[0]
    )[0]?.customer_brand_product_categories[0]?.category?.name;

    setAdditionalDashboradInfo({
      startDate: startDate,
      endDate: endDate,
      brand: selectedBrandItems[0] || brandName,
      product: selectedProductItems[0] || productName,
      selectedFrequency: selectedFrequencyItems[0],
      selectedOptionShow: selectedOptionShow,
      isInitial: isInitial,
      brandCategory: selectedBrandCategory,
      productCategory: selectedBrandProductCategory,
      llm: selectedLlmItems[0],
    });
  };

  function transformBrandIndexMultiLlmData(compData, data, targetIndex) {
    const result = {};
    const structuredData = {};
    const selectedBrandOrProductName =
      selectedOptionShow === "Product"
        ? selectedProductItems[0] || "Main"
        : selectedBrandItems[0] || "Main";

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, month, year, customer_llm } = entry;
      const { llm, market, geography } = customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona
          : entry?.customer_brand_persona;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona?.name
          : value == 5
          ? `${month}-${day}-${year}`
          : llm?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [displayName]: entry[targetIndex],
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const month = item.month;
      const year = item.year;
      const brandName =
        selectedOptionShow === "Product"
          ? item.brand_product.name
          : item.brand.name;
      const llmName = item?.customer_llm?.llm?.name;
      const { market, geography } = item?.customer_llm;
      const { persona } =
        selectedOptionShow == "Product"
          ? item?.customer_brand_product_persona
          : item?.customer_brand_persona;
      const displayName =
        value === 3
          ? `${geography?.name} (${market?.name})`
          : value === 4
          ? persona?.name
          : value === 5
          ? `${month}-${day}-${year}`
          : llmName;
      const avgScore = item[targetIndex];

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][displayName] = avgScore;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByMarket(mergedData);
    return mergedData;
  }

  const transformData = (data, index) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const compBrandsData =
      selectedOptionShow == "Product" ? data?.compProducts : data?.compBrands;
    const selectedBrandOrProduct =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    const groupByDay = (arr, keyField, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const key =
          keyField === selectedBrandOrProduct
            ? keyField
            : selectedOptionShow == "Product"
            ? obj.brand_product.name
            : obj.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const compBrandsGrouped = compBrandsData.length
      ? groupByDay(compBrandsData, "name", index)
      : {};
    const brandGrouped = brandData.length
      ? groupByDay(brandData, selectedBrandOrProduct, index)
      : {};

    const allDays = new Set([
      ...Object.keys(compBrandsGrouped),
      ...Object.keys(brandGrouped),
    ]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(compBrandsGrouped[day] || {}),
        ...(brandGrouped[day] || {}),
      };
    });

    return finalArray;
  };

  const brandIndextransformData = (data, index) => {
    const brandData =
      selectedOptionShow == "Product" ? data?.product : data?.brand;
    const groupByDay = (arr, valueField) => {
      return arr.reduce((acc, obj) => {
        const day = obj.day;
        const month = obj.month;
        const year = obj.year;
        const key =
          selectedOptionShow == "Product"
            ? obj?.customer_brand_product_persona?.customer_brand_product
                ?.brand_product?.name ||
              selectedProductItems[0] ||
              `${month}-${day}-${year}`
            : obj?.customer_brand_persona?.customer_brand?.brand.name;
        const value = parseFloat(obj[valueField]);

        if (!acc[day]) {
          acc[day] = {};
        }

        acc[day][key] = value;
        return acc;
      }, {});
    };

    const brandGrouped = brandData.length ? groupByDay(brandData, index) : {};

    const allDays = new Set([...Object.keys(brandGrouped)]);

    const finalArray = Array.from(allDays).map((day) => {
      return {
        ...(brandGrouped[day] || {}),
      };
    });
    return finalArray;
  };

  const calculateAverageIndex = (arr) => {
    const totals = {};
    const counts = {};

    // Iterate over each object in the array
    arr.forEach((obj) => {
      for (const key in obj) {
        if (totals[key]) {
          totals[key] += obj[key];
          counts[key] += 1;
        } else {
          totals[key] = obj[key];
          counts[key] = 1;
        }
      }
    });

    // Calculate the averages
    const averages = {};
    for (const key in totals) {
      averages[key] = totals[key] / counts[key];
    }

    return averages;
  };

  const transformDataForAttributesSources = (data) => {
    if (data && data.length > 0) {
      let positiveAttributesStructuredData = {};
      let negativeAttributesStructuredData = {};

      const positiveAttributes = data.filter((obj) => obj.is_positive);
      const negativeAttributes = data.filter((obj) => !obj.is_positive);

      // Process each entry in the data array

      positiveAttributes.forEach((entry) => {
        const { day, month, year, source, signal_strength, customer_llm } =
          entry;
        const { domain_url } = source;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!positiveAttributesStructuredData[day]) {
          positiveAttributesStructuredData[day] = {};
        }

        positiveAttributesStructuredData[day][displayName] = {
          ...positiveAttributesStructuredData[day][displayName],
          [domain_url]: signal_strength,
        };
      });

      negativeAttributes.forEach((entry) => {
        const { day, month, year, source, signal_strength, customer_llm } =
          entry;
        const { domain_url } = source;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;
        if (!negativeAttributesStructuredData[day]) {
          negativeAttributesStructuredData[day] = {};
        }

        negativeAttributesStructuredData[day][displayName] = {
          ...negativeAttributesStructuredData[day][displayName],
          [domain_url]: signal_strength,
        };
      });

      positiveAttributesStructuredData = sortDataByTargetKey(
        positiveAttributesStructuredData
      );
      negativeAttributesStructuredData = sortDataByTargetKey(
        negativeAttributesStructuredData
      );

      return {
        positiveAttributesStructuredData,
        negativeAttributesStructuredData,
      };
    }
  };

  function processCategoryDimensionArray(data) {
    let result = {};
    let sourceResult = {};

    const dataArray = data?.llmCategoryDimensions;
    const sourceDataArray = data?.llmCategoryDimensionsSource;

    if (Array.isArray(dataArray)) {
      dataArray.forEach((entry) => {
        const {
          day,
          month,
          year,
          category_dimension,
          signal_strength,
          customer_llm,
        } = entry;
        const { name } = category_dimension;
        const { llm, market, geography } = customer_llm;
        const { persona } =
          selectedOptionShow == "Product"
            ? entry?.customer_brand_product_persona
            : entry?.customer_brand_persona;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona?.name
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!result[day]) {
          result[day] = {};
        }

        result[day][displayName] = {
          ...result[day][displayName],
          [name]: signal_strength,
        };
      });
    }

    if (Array.isArray(sourceDataArray)) {
      sourceDataArray.forEach((entry) => {
        const { day, month, year, source, signal_strength, customer_llm } =
          entry;
        const { domain_url } = source;
        const { llm, market, geography } = customer_llm;
        const { persona } =
          selectedOptionShow == "Product"
            ? entry?.customer_brand_product_persona
            : entry?.customer_brand_persona;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona?.name
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;
        if (!sourceResult[day]) {
          sourceResult[day] = {};
        }

        sourceResult[day][displayName] = {
          ...sourceResult[day][displayName],
          [domain_url]: signal_strength,
        };
      });
    }
    result = sortDataByMarket(result);
    sourceResult = sortDataByMarket(sourceResult);

    return { result, sourceResult };
  }

  function processAverageRankArray(dataArray) {
    let result = {};
    let sourceResult = {};

    if (Array.isArray(dataArray)) {
      dataArray.forEach((item) => {
        // Check if necessary properties exist to avoid undefined cases

        const displayName =
          value === 3
            ? item?.customer_llm?.market?.name
            : value === 4
            ? item?.customer_brand_persona?.persona?.name
            : item?.customer_llm?.llm?.name;
        const brands = item?.llm_execution_final_stat?.json_response?.brands;
        const sources = item?.llm_execution_final_stat?.json_response?.sources;

        if (displayName && brands) {
          result[displayName] = brands;
        }

        if (displayName && sources) {
          sourceResult[displayName] = sources;
        }
      });
    }
    result = sortSingleSetDataByMarket(result);
    sourceResult = sortSingleSetDataByMarket(sourceResult);
    return { result, sourceResult };
  }

  function sortSingleSetDataByMarket(data) {
    const sortedData = {};

    const locations = Object.keys(data).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    locations.forEach((location) => {
      sortedData[location] = data[location];
    });

    return sortedData;
  }

  function sortDataByMarket(data) {
    const sortedData = {};

    // Sort dates in ascending order
    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.values(data[date]).forEach((locations) => {
        Object.keys(locations).forEach((market) => {
          allMarkets.add(market);
        });
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      Object.keys(data[date]).forEach((company) => {
        sortedData[date][company] = {};
        sortedMarkets.forEach((market) => {
          if (data[date][company][market] !== undefined) {
            sortedData[date][company][market] = data[date][company][market];
          }
        });
      });
    });

    return sortedData;
  }

  function sortDataByTargetKey(data) {
    const sortedData = {};

    const dates = Object.keys(data).sort((a, b) => parseInt(a) - parseInt(b));

    // Collect all unique markets from all dates
    const allMarkets = new Set();
    dates.forEach((date) => {
      Object.keys(data[date]).forEach((market) => {
        allMarkets.add(market);
      });
    });

    // Sort markets with "General Market" first
    const sortedMarkets = Array.from(allMarkets).sort((a, b) => {
      if (
        a === "General Market" ||
        a === "General Persona" ||
        a === selectedBrandItems[0] ||
        a === selectedProductItems[0]
      )
        return -1;
      if (
        b === "General Market" ||
        b === "General Persona" ||
        b === selectedBrandItems[0] ||
        b === selectedProductItems[0]
      )
        return 1;
      return 0;
    });

    // Sort data according to the sorted markets
    dates.forEach((date) => {
      sortedData[date] = {};
      sortedMarkets.forEach((market) => {
        if (data[date][market] !== undefined) {
          sortedData[date][market] = data[date][market];
        }
      });
    });

    return sortedData;
  }

  const transformDataForAttributes = (data) => {
    if (data && data.length > 0) {
      let positiveAttributesStructuredData = {};
      let negativeAttributesStructuredData = {};

      const positiveAttributes = data.filter((obj) => obj.is_positive);
      const negativeAttributes = data.filter((obj) => !obj.is_positive);

      // Process each entry in the data array

      positiveAttributes.forEach((entry) => {
        const { day, month, year, attribute, signal_strength, customer_llm } =
          entry;
        const { name } = attribute;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!positiveAttributesStructuredData[day]) {
          positiveAttributesStructuredData[day] = {};
        }

        positiveAttributesStructuredData[day][displayName] = {
          ...positiveAttributesStructuredData[day][displayName],
          [name]: signal_strength,
        };
      });

      negativeAttributes.forEach((entry) => {
        const { day, month, year, attribute, signal_strength, customer_llm } =
          entry;
        const { name } = attribute;
        const { llm, market, geography } = customer_llm;
        const persona =
          entry?.customer_brand_persona?.persona?.name ||
          entry?.customer_brand_product_persona?.persona?.name;
        const displayName =
          value === 3
            ? `${geography?.name} (${market?.name})`
            : value === 4
            ? persona
            : value === 5
            ? `${month}-${day}-${year}`
            : llm?.name;

        if (!negativeAttributesStructuredData[day]) {
          negativeAttributesStructuredData[day] = {};
        }

        negativeAttributesStructuredData[day][displayName] = {
          ...negativeAttributesStructuredData[day][displayName],
          [name]: signal_strength,
        };
      });

      positiveAttributesStructuredData = sortDataByTargetKey(
        positiveAttributesStructuredData
      );
      negativeAttributesStructuredData = sortDataByTargetKey(
        negativeAttributesStructuredData
      );
      return {
        positiveAttributesStructuredData,
        negativeAttributesStructuredData,
      };
    }
  };

  function transformDataForCompAttributes(compData, data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);
    const positiveCompAttributesData = compData.filter(
      (obj) => obj.is_positive
    );
    const negativeAttributesData = data.filter((obj) => !obj.is_positive);
    const negativeCompAttributesData = compData.filter(
      (obj) => !obj.is_positive
    );
    // Initialize the result object
    const result = {};
    const structuredData = {};
    const negativeAttributesResult = {};
    const negativeAttributesStructuredData = {};

    const selectedBrandOrProductName =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    positiveCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.product?.name || "test"
          : item.brand.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      result[day][brandName][attributeName] = signalStrength;
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, attribute, signal_strength } = entry;
      const { name } = attribute;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Iterate through the positive comp attributes data array
    negativeCompAttributesData.forEach((item) => {
      const day = item.day;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.product?.name || "test"
          : item.brand.name;
      const attributeName = item.attribute.name;
      const signalStrength = item.signal_strength;

      // Initialize the day object if it doesn't exist
      if (!negativeAttributesResult[day]) {
        negativeAttributesResult[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!negativeAttributesResult[day][brandName]) {
        negativeAttributesResult[day][brandName] = {};
      }

      // Add the attribute and its signal strength to the brand object
      negativeAttributesResult[day][brandName][attributeName] = signalStrength;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    let negativeAttributesMergedData = mergeDeep(
      { ...negativeAttributesResult },
      negativeAttributesStructuredData
    );
    mergedData = sortDataByTargetKey(mergedData);
    negativeAttributesMergedData = sortDataByTargetKey(
      negativeAttributesMergedData
    );

    return { mergedData, negativeAttributesMergedData };
  }

  function brandAttDataTransform(data) {
    const positiveAttributesData = data.filter((obj) => obj.is_positive);

    const negativeAttributesData = data.filter((obj) => !obj.is_positive);

    // Initialize the result object

    const structuredData = {};

    const negativeAttributesStructuredData = {};

    // Process each entry in the positive attributes data array
    positiveAttributesData.forEach((entry) => {
      const { day, month, year, attribute, signal_strength } = entry;
      const name = attribute?.name;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    // Process each entry in the positive attributes data array
    negativeAttributesData.forEach((entry) => {
      const { day, month, year, attribute, signal_strength } = entry;
      const name = attribute?.name;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_produt?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;

      if (!negativeAttributesStructuredData[day]) {
        negativeAttributesStructuredData[day] = {};
      }

      negativeAttributesStructuredData[day][selectedBrandOrProductName] = {
        ...negativeAttributesStructuredData[day][selectedBrandOrProductName],
        [name]: signal_strength,
      };
    });

    return { structuredData, negativeAttributesStructuredData };
  }

  function brandCoreAttDataTransform(data) {
    const structuredData = {};
    const dateSeriesData = {};

    // Process each entry in the data array
    data.forEach((entry) => {
      console.log("entry==>", entry);
      const { day, month, year, core_attribute, avg_score } = entry;
      const { name } = core_attribute;
      const selectedBrandOrProductName =
        selectedOptionShow == "Product"
          ? entry?.customer_brand_product_persona?.customer_brand_product
              ?.brand_product?.name ||
            selectedProductItems[0] ||
            `${month}-${day}-${year}`
          : entry?.customer_brand_persona?.customer_brand?.brand?.name;
      const displayName = `${month}-${day}-${year}`;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }
      if (!dateSeriesData[day]) {
        dateSeriesData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: avg_score,
      };
      dateSeriesData[day][displayName] = {
        ...dateSeriesData[day][displayName],
        [name]: avg_score,
      };
    });

    return { structuredData, dateSeriesData };
  }

  function transformDataForCoreAttributes(compData, data) {
    // Initialize the result object
    const result = {};
    const structuredData = {};
    const selectedBrandOrProductName =
      selectedOptionShow == "Product"
        ? selectedProductItems[0]
        : selectedBrandItems[0];

    // Process each entry in the data array
    data.forEach((entry) => {
      const { day, month, year, core_attribute, avg_score } = entry;
      const { name } = core_attribute;

      if (!structuredData[day]) {
        structuredData[day] = {};
      }

      structuredData[day][selectedBrandOrProductName] = {
        ...structuredData[day][selectedBrandOrProductName],
        [name]: avg_score,
      };
    });

    // Iterate through the data array
    compData.forEach((item) => {
      const day = item.day;
      const month = item.month;
      const year = item.year;
      const brandName =
        selectedOptionShow == "Product"
          ? item?.product?.name || "test"
          : item.brand.name;
      const coreAttributeName = item.core_attribute.name;
      const avgScore = item.avg_score;

      // Initialize the day object if it doesn't exist
      if (!result[day]) {
        result[day] = {};
      }

      // Initialize the brand object if it doesn't exist
      if (!result[day][brandName]) {
        result[day][brandName] = {};
      }

      // Add the core attribute and its average score to the brand object
      result[day][brandName][coreAttributeName] = avgScore;
    });

    let mergedData = mergeDeep({ ...result }, structuredData);
    mergedData = sortDataByTargetKey(mergedData);
    return mergedData;
  }

  const mergeDeep = (target, source) => {
    for (const key in source) {
      if (source[key] instanceof Object && target[key] instanceof Object) {
        // Merge subkeys
        for (const subKey in source[key]) {
          target[key][subKey] = source[key][subKey];
        }
      } else if (source[key] instanceof Object) {
        // Add new nested object
        target[key] = { ...source[key] };
      } else {
        // Assign value from source to target
        target[key] = source[key];
      }
    }
    return target;
  };

  const getLlmComparisonDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getLlmComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const getCompDashboardDataFromDB = async (
    brandId,
    productId,
    llmId,
    personaId,
    isInitial,
    brandName,
    productName,
    categoryName,
    brandIds,
    productIds
  ) => {
    setShowData(false);
    setIsDone(false);

    try {
      await getCompetitorComparisonData(
        brandId,
        productId,
        personaId,
        llmId,
        startDate,
        endDate,
        isInitial,
        brandName,
        productName,
        categoryName,
        brandIds,
        productIds
      );

      setShowData(true);
      setIsDone(true);
    } catch (err) {
      setIsDone(true);
    }
  };

  const handleClickReset = () => {
    setSelectedLlmItems([]);
    setShowData(false);
    setIsDone(true);
    setStartDate("");
    setEndDate("");
    setSelectedBrandItems([]);
    setSelectedProductItems([]);
    setSelectedFrequencyItems([]);
    setSelectedPersona([]);
    setSelectedMarketItems([]);
    setSelectedGeographyItems([]);
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (
      selectedOptionShow === "Company/Brand" &&
      selectedBrandItems &&
      selectedBrandItems.length === 0
    ) {
      errorMessage = "Please Select a Brand !";
    } else if (
      selectedOptionShow === "Product" &&
      selectedProductItems &&
      selectedProductItems.length === 0
    ) {
      errorMessage = "Please Select a Product !";
    } else if (selectedPersona && selectedPersona.length == 0) {
      errorMessage = "Please select a Persona !";
    } else if (selectedFrequencyItems && selectedFrequencyItems.length == 0) {
      errorMessage = "Please select Frequency !";
    } else if (value == 0 && (!startDate || !endDate)) {
      errorMessage = "Date is Missing, Please Select!";
    } else if (
      value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length == 0
    ) {
      errorMessage = "Please Select a valid date";
    } else if (
      // value !== 0 &&
      selectedSubFrequencyItems &&
      selectedSubFrequencyItems.length !== 0 &&
      selectedSubFrequencyItems.includes("Custom Date") &&
      !customDate
    ) {
      errorMessage = "Please Select a valid date";
    } else if (selectedLlmItems && selectedLlmItems.length == 0) {
      errorMessage = "Please select LLM !";
    } else if (selectedMarketItems && selectedMarketItems.length == 0) {
      errorMessage = "Please select Market !";
    } else if (selectedGeographyItems && selectedGeographyItems.length == 0) {
      errorMessage = "Please select Geography !";
    }

    return errorMessage;
  };

  return (
    <div className="">
      <Container fluid>
        <Container className="border border-secondary-subtle borderSet mt">
          <h4 className="float-start text1">Dashboard</h4>

          <div className="p-3">
            <Container className="back">
              <Form className="form-inline form-5yquicksearch mx-auto mt-5 p-3">
                <Row className="mb-5">
                  <Form.Group as={Col} md="12">
                    <Row>
                      <ul className="nav brand-tabs">
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Company/Brand"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() =>
                                handleRadioSectionShow("Company/Brand")
                              }
                            >
                              <span></span> Company/Brand
                            </a>
                          </li>
                        </Col>
                        <Col md="auto">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className={`nav-link ${
                                selectedOptionShow === "Product"
                                  ? "active cursor-pointer"
                                  : ""
                              }`}
                              onClick={() => handleRadioSectionShow("Product")}
                            >
                              <span> </span> Product
                            </a>
                          </li>
                        </Col>
                        {selectedOptionShow === "Company/Brand" && (
                          <>
                            <Col md="2">
                              <DashboardBrandProductComponent
                                options={brandItems}
                                handleCheckChange={handleBrandCheckChange}
                                selectedBrandProductItems={selectedBrandItems}
                                isBrand={true}
                              />
                            </Col>
                            <Col md="2">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={true}
                              />
                            </Col>
                          </>
                        )}
                        {selectedOptionShow === "Product" && (
                          <>
                            <Col md="2">
                              <DashboardBrandProductComponent
                                options={productItems}
                                handleCheckChange={handleProductCheckChange}
                                selectedBrandProductItems={selectedProductItems}
                              />
                            </Col>
                            <Col md="2">
                              <DashboardBrandProductPersonaComponent
                                options={personas}
                                handleCheckChange={handlePersonaCheckChange}
                                selectedBrandProductItems={selectedPersona}
                                isBrand={false}
                              />
                            </Col>
                          </>
                        )}
                        <Col style={{ marginLeft: "1%" }} md="2">
                          <DashboardFrequencyComponent
                            options={frequencyOptions}
                            handleCheckChange={handleFrequencyCheckChange}
                            selectedFrequencyItems={selectedFrequencyItems}
                          />
                        </Col>
                        &nbsp; &nbsp;
                        <Col style={{ marginLeft: "2%" }} md="2">
                          <GeographyComponent
                            options={geography}
                            handleCheckChange={handleGeographyCheckChange}
                            selectedGeographyItems={selectedGeographyItems}
                          />
                        </Col>
                      </ul>
                    </Row>

                    <Row
                      style={{ marginBottom: "-25px" }}
                      // className="justify-content-center"
                    >
                      <ul className="nav brand-tabs">
                        <Col style={{}} md="2">
                          <MarketComponent
                            options={markets}
                            handleCheckChange={handleMarketCheckChange}
                            selectedMarketItems={selectedMarketItems}
                          />
                        </Col>
                        <Col
                          style={{ marginLeft: "1%", marginRight: "2%" }}
                          md="2"
                        >
                          <DashboardFrequencyComponent
                            options={daysOptions}
                            handleCheckChange={handlesubFrequencyCheckChange}
                            selectedFrequencyItems={selectedSubFrequencyItems}
                          />
                        </Col>
                        {isCustomDate && (
                          <Col style={{ marginRight: "4%" }} md="2">
                            <Form.Group controlId="exampleForm.DateInput">
                              <Form.Control
                                style={{ height: "51px", width: "120%" }}
                                type="date"
                                value={customDate}
                                onChange={handleCustomDateCheckChange}
                              />
                            </Form.Group>
                          </Col>
                        )}
                        <Col style={{ marginRight: "6.5%" }} md="2">
                          <DashboardLLMsComponent
                            options={dbLlms}
                            handleCheckChange={handleCheckChange}
                            selectedLlmItems={selectedLlmItems}
                          />
                        </Col>

                        {!isCustomDate && (
                          <>
                            {" "}
                            <Col md="2">
                              <Button
                                type="button"
                                name="firstName"
                                placeholder="Your Brand/Product"
                                className="height2 "
                                style={{
                                  width: "-webkit-fill-available",
                                  backgroundColor: "#3dc863",
                                  color: "white",
                                }}
                                onClick={handleClickShow}
                                disabled={!isDone}
                              >
                                {!isDone && !isFirstTime ? (
                                  <div style={{ fontSize: "19px" }}>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Please Wait...
                                  </div>
                                ) : (
                                  <div>LAUNCH</div>
                                )}
                              </Button>
                            </Col>
                            &nbsp;
                            <Col md="2">
                              <Button
                                type="button"
                                name="firstName"
                                placeholder="Your Brand/Product"
                                className="height2"
                                style={{
                                  width: "-webkit-fill-available",
                                  backgroundColor: "#3dc863",
                                  color: "white",
                                }}
                                onClick={handleClickReset}
                              >
                                RESET
                              </Button>
                            </Col>{" "}
                          </>
                        )}
                      </ul>
                    </Row>

                    {isCustomDate && (
                      <>
                        {" "}
                        <Row>
                          <ul
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="nav brand-tabs"
                          >
                            <Col md="2">
                              <Button
                                type="button"
                                name="firstName"
                                placeholder="Your Brand/Product"
                                className="height2 "
                                style={{
                                  width: "-webkit-fill-available",
                                  backgroundColor: "#3dc863",
                                  color: "white",
                                }}
                                onClick={handleClickShow}
                                disabled={!isDone}
                              >
                                {!isDone && !isFirstTime ? (
                                  <div style={{ fontSize: "19px" }}>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Please Wait...
                                  </div>
                                ) : (
                                  <div>LAUNCH</div>
                                )}
                              </Button>
                            </Col>
                            &nbsp;
                            <Col md="2">
                              <Button
                                type="button"
                                name="firstName"
                                placeholder="Your Brand/Product"
                                className="height2"
                                style={{
                                  width: "-webkit-fill-available",
                                  backgroundColor: "#3dc863",
                                  color: "white",
                                }}
                                onClick={handleClickReset}
                              >
                                RESET
                              </Button>
                            </Col>
                          </ul>
                        </Row>
                      </>
                    )}
                  </Form.Group>
                </Row>
              </Form>
              <Box
                sx={{
                  width: "100%", // Set your desired width
                  overflowX: "auto", // Enable horizontal scroll if content exceeds width
                  whiteSpace: "nowrap", // Prevent content from wrapping to a new line
                  margin: "auto",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  role="navigation"
                  variant="scrollable" // Use this variant to make Tabs scrollable
                  scrollButtons="auto"
                >
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={0}
                      label="Overview Dashboard"
                    />
                  }
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={1}
                      label="Competitor Comparison"
                    />
                  }
                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={2}
                      label="LLM Comparison"
                    />
                  }

                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={3}
                      label="Geo/Market Comparison"
                    />
                  }

                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={4}
                      label="Persona Comparison"
                    />
                  }

                  {
                    <Tab
                      sx={{ color: "black", fontWeight: "450" }}
                      value={5}
                      label="Time series analysis"
                    />
                  }
                </Tabs>
              </Box>
            </Container>
            {/*-------------------- Data Display Section --------------------*/}

            {showData && isDone ? (
              value == 0 ? (
                <Card>
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Index score
                      </p>
                    </div>

                    <p> </p>
                    <>
                      {brandIndexScore &&
                      Object.values(brandIndexScore) &&
                      Object.values(brandIndexScore).length !== 0 &&
                      Object.values(brandIndexScore)[0] !== undefined ? (
                        <BrandProductAttributesTable
                          selectedAttributes={brandIndexScore}
                          isBrandIndex={true}
                          isOverview={true}
                          brand={
                            additionalDashboradInfo?.brand ||
                            additionalDashboradInfo?.product
                          }
                          metrics="Brand Index Score"
                          type="Brand Overview"
                          llm={additionalDashboradInfo?.llm}
                          categoryName={
                            additionalDashboradInfo?.brandCategory ||
                            additionalDashboradInfo?.productCategory
                          }
                        />
                      ) : (
                        <>
                          <hr />
                          <p style={{ textAlign: "center" }}>
                            No brand index Data available !
                          </p>
                        </>
                      )}

                      {indexScoreDataForChart &&
                        indexScoreDataForChart.length > 1 && (
                          <>
                            <hr />
                            <LineChart
                              additionalDashboradInfo={additionalDashboradInfo}
                              brandIndexData={indexScoreDataForChart}
                              label="Brand Index Score"
                            />
                          </>
                        )}
                    </>

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Core Attributes Average score
                      </p>
                    </div>

                    <p> </p>
                    {brandCoreAttributesScore &&
                    Object.values(brandCoreAttributesScore) &&
                    Object.values(brandCoreAttributesScore).length !== 0 &&
                    Object.values(brandCoreAttributesScore)[0] !== undefined ? (
                      <BrandProductCoreAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        isOverview={true}
                        selectedCompCoreAttributes={brandCoreAttributesScore}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Core Attributes Average score"
                        type="Brand Overview"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Core attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Positive Attributes : Signal Strength
                      </p>
                    </div>

                    <p> </p>
                    {positiveAttGraphData &&
                    positiveAttGraphData.length !== 0 ? (
                      <CustomBarChart
                        isOverview={true}
                        data={positiveAttGraphData}
                        color="#C7F0EC"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Negative Attributes : Signal Strength
                      </p>
                    </div>

                    <p> </p>
                    {negativeAttGraphData &&
                    negativeAttGraphData.length !== 0 ? (
                      <CustomBarChart
                        isOverview={true}
                        data={negativeAttGraphData}
                        color="#FEE7E6"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Negative attributes Data available !
                        </p>
                      </>
                    )}

                    {/* <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Positive Attributes : Signal Strength
                      </p>
                    </div>

                    <p> </p>
                    {brandPositiveAttributesStrength &&
                    Object.values(brandPositiveAttributesStrength) &&
                    Object.values(brandPositiveAttributesStrength).length !==
                      0 &&
                    Object.values(brandPositiveAttributesStrength)[0] !==
                      undefined ? (
                      <BrandProductAttributesTable
                        isOverview={true}
                        selectedAttributes={brandPositiveAttributesStrength}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type="Brand Overview"
                        llm={additionalDashboradInfo?.llm}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Data available !
                        </p>
                      </>
                    )} */}

                    {/* 
                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Negative Attributes : Signal Strength
                      </p>
                    </div>

                    <p> </p>
                    {brandNegativeAttributesStrength &&
                    Object.values(brandNegativeAttributesStrength) &&
                    Object.values(brandNegativeAttributesStrength).length !==
                      0 &&
                    Object.values(brandNegativeAttributesStrength)[0] !==
                      undefined ? (
                      <BrandProductAttributesTable
                        selectedAttributes={brandNegativeAttributesStrength}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        isOverview={true}
                        metrics="Negative Attributes signal strength"
                        type="Brand Overview"
                        llm={additionalDashboradInfo?.llm}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No negative attributes Data available !
                        </p>
                      </>
                    )} */}
                  </Card.Body>
                </Card>
              ) : value == 1 ? (
                <Card>
                  <Card.Body>
                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Brand Index score
                    </p>

                    <p> </p>
                    {avgBrandIndex &&
                    Object.keys(avgBrandIndex) &&
                    Object.keys(avgBrandIndex).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={avgBrandIndex}
                        isBrandIndex={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Index Score"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No brand index Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Core Attributes Average score
                    </p>

                    <p> </p>
                    {compCoreAttributes &&
                    Object.keys(compCoreAttributes) &&
                    Object.keys(compCoreAttributes).length !== 0 ? (
                      <BrandProductCoreAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        selectedCompCoreAttributes={compCoreAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Core Attributes Average score"
                        type="Competitor comparison"
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp core attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Positive Attributes : Signal strength
                    </p>

                    <p> </p>
                    {compPositiveAttributes &&
                    Object.keys(compPositiveAttributes) &&
                    Object.keys(compPositiveAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={compPositiveAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <p style={{ padding: "5px", fontWeight: "600" }}>
                      Competitor Negative Attributes : Signal strength
                    </p>

                    <p> </p>
                    {compNegativeAttributes &&
                    Object.keys(compNegativeAttributes) &&
                    Object.keys(compNegativeAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        selectedAttributes={compNegativeAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes signal strength"
                        type="Competitor comparison"
                        llm={additionalDashboradInfo?.llm}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No comp negative attributes Data available !
                        </p>
                      </>
                    )}
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Index Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandIndex &&
                    Object.keys(llmCompBrandIndex) &&
                    Object.keys(llmCompBrandIndex).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandIndex}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Index Score"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Index Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Reach Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandReach &&
                    Object.keys(llmCompBrandReach) &&
                    Object.keys(llmCompBrandReach).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandReach}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Reach Score"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Reach Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand Favorability Score
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    {llmCompBrandFav &&
                    Object.keys(llmCompBrandFav) &&
                    Object.keys(llmCompBrandFav).length !== 0 ? (
                      <BrandProductIndexScoresTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={llmCompBrandFav}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Brand Favorability Score"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Brand Favorability Score Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Positive Attributes
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    <p> </p>
                    {llmAttributes &&
                    Object.keys(llmAttributes) &&
                    Object.keys(llmAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        selectedAttributes={llmAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Positive Attributes Sources
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    <p> </p>
                    {llmAttributesSources &&
                    Object.keys(llmAttributesSources) &&
                    Object.keys(llmAttributesSources).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        selectedAttributes={llmAttributesSources}
                        isSourceData={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Positive Attributes Source signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Positive attributes Sources Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Negative Attributes
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    {llmNegativeAttributes &&
                    Object.keys(llmNegativeAttributes) &&
                    Object.keys(llmNegativeAttributes).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        selectedAttributes={llmNegativeAttributes}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Negative attributes Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Signal Strength : Negative Attributes Sources
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product:
                        {additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product}
                      </p>
                    </div>

                    {llmNegativeAttributesSources &&
                    Object.keys(llmNegativeAttributesSources) &&
                    Object.keys(llmNegativeAttributesSources).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        selectedAttributes={llmNegativeAttributesSources}
                        isSourceData={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Negative Attributes Source signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Negative attributes Sources Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Category Dimension : Signal Strength
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {categoryDimensionData &&
                    Object.keys(categoryDimensionData) &&
                    Object.keys(categoryDimensionData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={categoryDimensionData}
                        isCategoryDimension={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Category Dimension signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Category Dimension Data available !
                        </p>
                      </>
                    )}

                    <hr />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Category Dimension Sources : Signal Strength
                      </p>
                      <p style={{ padding: "5px", fontWeight: "600" }}>
                        Brand/Product category:
                        {additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory}
                      </p>
                    </div>

                    <p> </p>
                    {categoryDimensionSourceData &&
                    Object.keys(categoryDimensionSourceData) &&
                    Object.keys(categoryDimensionSourceData).length !== 0 ? (
                      <BrandProductAttributesTable
                        llm={additionalDashboradInfo?.llm}
                        categoryName={
                          additionalDashboradInfo?.brandCategory ||
                          additionalDashboradInfo?.productCategory
                        }
                        selectedAttributes={categoryDimensionSourceData}
                        isCategoryDimensionSource={true}
                        brand={
                          additionalDashboradInfo?.brand ||
                          additionalDashboradInfo?.product
                        }
                        metrics="Category Dimensions Source signal strength"
                        type={`${
                          value == 2
                            ? "LLM"
                            : value == 3
                            ? "Market"
                            : value == 5
                            ? "Time-series"
                            : "Persona"
                        } comparison`}
                      />
                    ) : (
                      <>
                        <hr />
                        <p style={{ textAlign: "center" }}>
                          No Category Dimension source Data available !
                        </p>
                      </>
                    )}

                    {value == 5 && (
                      <>
                        <hr />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Core Attributes Average score
                          </p>
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Brand/Product:
                            {additionalDashboradInfo?.brand ||
                              additionalDashboradInfo?.product}
                          </p>
                        </div>

                        <p> </p>
                        {compCoreAttributes &&
                        Object.keys(compCoreAttributes) &&
                        Object.keys(compCoreAttributes).length !== 0 ? (
                          <BrandProductCoreAttributesTable
                            llm={additionalDashboradInfo?.llm}
                            selectedCompCoreAttributes={compCoreAttributes}
                            brand={
                              additionalDashboradInfo?.brand ||
                              additionalDashboradInfo?.product
                            }
                            metrics="Core Attributes Average score"
                            type="Time-series comparison"
                          />
                        ) : (
                          <>
                            <hr />
                            <p style={{ textAlign: "center" }}>
                              No core attributes Data available !
                            </p>
                          </>
                        )}
                      </>
                    )}

                    {value !== 5 && (
                      <>
                        {" "}
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Average Rank (%)
                          </p>
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Brand/Product category:
                            {additionalDashboradInfo?.brandCategory ||
                              additionalDashboradInfo?.productCategory}
                          </p>
                        </div>
                        <p> </p>
                        {brandsAvgRankData &&
                        Object.keys(brandsAvgRankData) &&
                        Object.keys(brandsAvgRankData).length !== 0 ? (
                          <BrandProductAttributesTable
                            llm={additionalDashboradInfo?.llm}
                            categoryName={
                              additionalDashboradInfo?.brandCategory ||
                              additionalDashboradInfo?.productCategory
                            }
                            selectedAttributes={brandsAvgRankData}
                            isAverageRank={true}
                            brand={
                              additionalDashboradInfo?.brand ||
                              additionalDashboradInfo?.product
                            }
                            metrics="Average Rank"
                            type={`${
                              value == 2
                                ? "LLM"
                                : value == 3
                                ? "Market"
                                : "Persona"
                            } comparison`}
                          />
                        ) : (
                          <>
                            <hr />
                            <p style={{ textAlign: "center" }}>
                              No Average Rank Data available !
                            </p>
                          </>
                        )}
                        <hr />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Average Rank Sources
                          </p>
                          <p style={{ padding: "5px", fontWeight: "600" }}>
                            Brand/Product category:
                            {additionalDashboradInfo?.brandCategory ||
                              additionalDashboradInfo?.productCategory}
                          </p>
                        </div>
                        <p> </p>
                        {avgRankSourcesData &&
                        Object.keys(avgRankSourcesData) &&
                        Object.keys(avgRankSourcesData).length !== 0 ? (
                          <BrandProductAttributesTable
                            llm={additionalDashboradInfo?.llm}
                            categoryName={
                              additionalDashboradInfo?.brandCategory ||
                              additionalDashboradInfo?.productCategory
                            }
                            selectedAttributes={avgRankSourcesData}
                            isSourceData={true}
                            brand={
                              additionalDashboradInfo?.brand ||
                              additionalDashboradInfo?.product
                            }
                            metrics="Average Rank Sources signal strength"
                            type={`${
                              value == 2
                                ? "LLM"
                                : value == 3
                                ? "Market"
                                : "Persona"
                            } comparison`}
                          />
                        ) : (
                          <>
                            <hr />
                            <p style={{ textAlign: "center" }}>
                              No Average Rank Sources Data available !
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>
              )
            ) : isFirstTime ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "22px",
                  alignItems: "center",
                  padding: "20px",
                  marginTop: "30px",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Dashboard is being prepared Please Wait...
              </div>
            ) : null}
            {/* <CustomBarChart  /> */}
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </div>
  );
}
export default Dashboard;
