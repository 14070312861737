import api from "../../BrandOnBoarding/api";
import {
  FETCH_COMPETITOR_PRODUCTS_REQUEST,
  FETCH_COMPETITOR_PRODUCTS_SUCCESS,
  FETCH_COMPETITOR_PRODUCTS_FAILURE,
  CREATE_COMPETITOR_PRODUCT_REQUEST,
  CREATE_COMPETITOR_PRODUCT_SUCCESS,
  CREATE_COMPETITOR_PRODUCT_FAILURE,
  UPDATE_COMPETITOR_PRODUCT_REQUEST,
  UPDATE_COMPETITOR_PRODUCT_SUCCESS,
  UPDATE_COMPETITOR_PRODUCT_FAILURE,
  DELETE_COMPETITOR_PRODUCT_REQUEST,
  DELETE_COMPETITOR_PRODUCT_SUCCESS,
  DELETE_COMPETITOR_PRODUCT_FAILURE,
} from "../constants/actionTypes";

export const fetchCompetitorProducts = (id) => async (dispatch) => {
  dispatch({ type: FETCH_COMPETITOR_PRODUCTS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/competitor-products`);
    dispatch({ type: FETCH_COMPETITOR_PRODUCTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_COMPETITOR_PRODUCTS_FAILURE, error: error.message });
  }
};

export const createCompetitorBrandProduct = (brand) => async (dispatch) => {
  dispatch({ type: CREATE_COMPETITOR_PRODUCT_REQUEST });
  try {
    const response = await api.post(`/competitor-products`, brand);
    dispatch({ type: CREATE_COMPETITOR_PRODUCT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_COMPETITOR_PRODUCT_FAILURE, error: error.message });
  }
};

export const updateCompetitorBrand = (id, brand) => async (dispatch) => {
  dispatch({ type: UPDATE_COMPETITOR_PRODUCT_REQUEST });
  try {
    const response = await api.put(`/competitor-products/${id}`, brand);
    dispatch({ type: UPDATE_COMPETITOR_PRODUCT_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_COMPETITOR_PRODUCT_FAILURE, error: error.message });
  }
};

export const deleteCompetitorBrand = (id) => async (dispatch) => {
  dispatch({ type: DELETE_COMPETITOR_PRODUCT_REQUEST });
  try {
    await api.delete(`/competitor-products/${id}`);
    dispatch({ type: DELETE_COMPETITOR_PRODUCT_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_COMPETITOR_PRODUCT_FAILURE, error: error.message });
  }
};
