import React, { useState, useEffect, useContext } from "react";
import CustomNavbar from "../MainDataComponent/Navbar";
import SupportEmailModal from "../MainDataComponent/SupportEmailModal";
import { fetchSlackAPI } from "../../services/apiService";
import { generateFeedbackMessageJSON } from "../../utils/helpers";
import { AuthContext } from "../../context/auth-context";

export const layout = (Component, isAdminPage) => {
  return function Layout() {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [isShowModal, setIsShowModal] = useState(false)
    const { authUserEmail } = useContext(AuthContext);
    const [email, setEmail] = useState(authUserEmail)

  
    const handleQueryChange = (e) => {
      setQuery(e.target.value);
    };

    const handleClose = () => {
      setIsShowModal(false);
      setQuery("");
    };

    const handleEmailClick = async () => {
      const currentTimeAndDate = new Date(Date.now())
      const feedbackMessage = generateFeedbackMessageJSON(email,currentTimeAndDate, query)
      try {
        await fetchSlackAPI(feedbackMessage);
        setQuery('');
        console.log("Message sent to Slack successfully");
      } catch (error) {
        setQuery("");
        console.error("Error sending message to Slack:", error);
      }

      handleClose();
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (isDropdownOpen) {
          const dropdownNode = document.getElementById("profileDropdown");
          const imageNode = document.getElementById("profileImage");

          if (
            dropdownNode &&
            !dropdownNode.contains(event.target) &&
            imageNode &&
            !imageNode.contains(event.target)
          ) {
            setDropdownOpen(false);
          }
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [isDropdownOpen]);
    const toggleDropdown = () => {
      setDropdownOpen(!isDropdownOpen);
    };
    return (
      <>
      <SupportEmailModal email={email} setEmail={setEmail} handleClose={handleClose } show={isShowModal} handleQueryChange={handleQueryChange} handleEmailClick={handleEmailClick} query={query} />
        <CustomNavbar
          subTitle={
            isAdminPage
              ? "Custom Prompt Creation"
              : window.location.pathname === '/dashboard' ? 'Dashboard' : window.location.pathname === '/dashboard2' ? 'Dashboard2' : "Brand Discovery and Monitoring"
          }
          toggleDropdown={toggleDropdown}
          isDropdownOpen={isDropdownOpen}
          setIsShowModal={setIsShowModal}
        />
        <Component />
      </>
    );
  };
};
