import { useRef } from "react";
import { Button, TextField } from "@mui/material";

export default function VerifyUserForm({ onSubmit }) {
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  function formSubmitHandler(e) {
    e.preventDefault();
    onSubmit(passwordRef.current.value);
  }

  return (
    <form className="d-flex flex-column" onSubmit={formSubmitHandler}>
      <TextField
        inputRef={passwordRef}
        id="outlined-basic"
        size="small"
        label="Password"
        className="mb-4"
        type="password"
        variant="outlined"
      />
      <TextField
        inputRef={confirmPasswordRef}
        id="outlined-basic"
        size="small"
        label="Confirm Password"
        variant="outlined"
        type="password"

      />
      <div className="d-flex flex-row mt-4 justify-content-end">
        <Button type="submit" variant="contained" className="ml-2">
          Confirm
        </Button>
      </div>
    </form>
  );
}
