import React, { useState, useEffect } from "react";

import { Modal, Button, Form, Spinner } from "react-bootstrap";

import classes from "./FormModal.module.css";
import JobInputSelectionComponent from "../Settings/Component/JobInputSelectionComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchCustomerLlms } from "../../services/apiService";

function JobInputFormModal({
  show,
  handleClose,
  isInProgress,
  llms,
  markets,
  geographies,
  personas,
  customerLlms,
  handleBrandMetricsJob,
  jobIsInprogress,
  brandId,
  brandCustomerId,
}) {
  const [selectedLlm, setSelectedLlm] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedGeo, setSelectedGeo] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState([]);
  const [customerMarkets, setCustomerMarkets] = useState([]);
  const [customerGeo, setCustomerGeo] = useState([]);
  const [customerLlmsData, setCustomerLlmsData] = useState([]);

  useEffect(() => {
    // async function fetchData() {
    // const customerLlms = await fetchCustomerLlms([brandCustomerId]);
    const geographyIds = [
      ...new Set(customerLlms?.data?.map((obj) => obj?.geography_id)),
    ];
    const geo = geographies?.filter((obj) => geographyIds.includes(obj?.id));

    const filtercustomeLlm = customerLlms?.data?.filter(
      (obj) => obj?.geography_id == 1
    );
    const marketIds = [
      ...new Set(filtercustomeLlm?.map((obj) => obj?.market_id)),
    ];
    const market = markets?.filter((obj) => marketIds.includes(obj?.id));

    setCustomerGeo(geo);
    setCustomerMarkets(market);
    setCustomerLlmsData(customerLlms);
    // }

    // fetchData();
  }, [customerLlms, markets, geographies]);

  const handleCheckLlmChange = (key) => {
    if (selectedLlm.includes(key)) {
      setSelectedLlm(selectedLlm.filter((item) => item !== key));
    } else {
      setSelectedLlm([key]);
    }
  };

  // console.log('jobinput==>', customerMarkets, customerGeo)

  const handleInputValidation = () => {
    let errorMessage = "";
    if (selectedPersona && selectedPersona.length == 0) {
      errorMessage = "Please select a persona !";
    } else if (selectedLlm && selectedLlm.length == 0) {
      errorMessage = "Please select a LLM !";
    } else if (selectedMarket && selectedMarket.length == 0) {
      errorMessage = "Please Select a Market";
    } else if (selectedGeo && selectedGeo.length == 0) {
      errorMessage = "Please Select a Geography";
    }

    return errorMessage;
  };

  const handleCheckMarketChange = (key) => {
    if (selectedMarket.includes(key)) {
      setSelectedMarket(selectedMarket.filter((item) => item !== key));
    } else {
      setSelectedMarket([key]);
    }
  };

  const handleCheckPersonaChange = (key) => {
    if (selectedPersona.includes(key)) {
      setSelectedPersona(selectedPersona.filter((item) => item !== key));
    } else {
      setSelectedPersona([key]);
    }
  };

  const handleCheckGeoChange = (key) => {
    if (selectedGeo.includes(key)) {
      setSelectedPersona(selectedGeo.filter((item) => item !== key));
    } else {
      setSelectedGeo([key]);
    }
  };

  const handleRunMonitoringJob = () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }

    const globalLlmId = llms.filter((item) => item?.name === selectedLlm[0])[0]
      ?.id;
    const marketId = customerMarkets.filter(
      (item) => item?.name === selectedMarket[0]
    )[0]?.id;
    const geoId = customerGeo.filter((item) => item?.name === selectedGeo[0])[0]
      ?.id;
    const llmId = customerLlmsData?.data?.filter(
      (item) =>
        item?.llm_id == globalLlmId &&
        item?.market_id == marketId &&
        item?.geography_id == geoId
    )[0]?.id;
    const personaId = personas
      .filter((item) => selectedPersona.includes(item?.persona?.name))
      .map((obj) => obj?.id)[0];

    // console.log(
    //   "test==>",
    //   llmId,
    //   personaId,
    //   marketId,
    //   globalLlmId,
    //   brandId,
    //   brandCustomerId
    // );
    if (llmId && personaId) {
      handleBrandMetricsJob(
        llmId,
        personaId,
        marketId,
        globalLlmId,
        brandId,
        brandCustomerId
      );
    } else {
      toast.error("please choose another LLM ! ", {
        autoClose: 700,
      });
    }
  };

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          Run Monitoring Job
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Persona</p>
          <JobInputSelectionComponent
            options={personas}
            handleCheckChange={handleCheckPersonaChange}
            selectedInputNames={selectedPersona}
            label="Personas"
          />
        </div>
        <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}>LLM</p>
          <JobInputSelectionComponent
            options={llms}
            handleCheckChange={handleCheckLlmChange}
            selectedInputNames={selectedLlm}
            label="LLM"
          />
        </div>
        <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Geography</p>
          <JobInputSelectionComponent
            options={customerGeo}
            handleCheckChange={handleCheckGeoChange}
            selectedInputNames={selectedGeo}
            label="Geo"
          />
        </div>
        <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Market</p>
          <JobInputSelectionComponent
            options={customerMarkets}
            handleCheckChange={handleCheckMarketChange}
            selectedInputNames={selectedMarket}
            label="Market"
          />
        </div>

        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          // disabled={brands?.loading || isInProgress}
          onClick={() => handleRunMonitoringJob()}
        >
          Run
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}

export default JobInputFormModal;
