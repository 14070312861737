import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPersonas,
  deletePersona,
} from "../../../redux/actions/personasActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { AuthContext } from "../../../context/auth-context";

const PersonasComponent = ({ handleSelectedData }) => {
  const dispatch = useDispatch();
  const personas = useSelector((state) => state.personas);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0

  const handleDeletePersona = (personaId) => {
    dispatch(deletePersona(customerId, personaId)).then(() => {
      dispatch(fetchPersonas(customerId));
    });
  };

  useEffect(() => {
    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }
  }, [dispatch]);

  if (personas.loading) {
    return <p>Loading...</p>;
  }

  if (personas.deleteLoading) {
    return <p>Persona is being deleted Please wait...</p>;
  }

  if (personas.error) {
    return <p>Error: {personas.error}</p>;
  }

  const personasData = personas.data.data;

  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Personas</th>
          <th>Details</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {personasData &&
          personasData.length !== 0 &&
          personasData.map((data) => {
            return (
              <tr>
                <td>{data?.persona?.name}</td>
                <td>{data?.persona?.description}</td>
                <td className={classes.actions}>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{
                        backgroundColor: "green",
                      }}
                      variant="success"
                      onClick={() => handleSelectedData(data)}
                    >
                      Update
                    </Button>{" "}
                    <Button
                      style={{
                        backgroundColor: "#d32f2f",
                      }}
                      variant="danger"
                      onClick={() => handleDeletePersona(data.id)}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default PersonasComponent;
