import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import { useSelector } from "react-redux";
import { getPromptForBrandCompetitorsData } from "../../../utils/helpers";
import { fetchGpt4API } from "../../../services/apiService";
import json5 from "json5";

const radioOption = [
  {
    value: "manual",
    label: "Enter Competitors Manually",
  },
  {
    value: "ai",
    label: "Add Competitors from AI Suggestion",
  },
];
const BrandForm = ({
  brandName,
  setBrandName,
  details,
  setDetails,
  brandCategoryName,
  setBrandCategoryName,
  brandCategoryDescription,
  setBrandCategoryDescription,
  competitorOption,
  setCompetitorOption,
  competitors,
  setCompetitors,
  availableCompetitors,
  handleCompetitorToggle,
  handleAddAll,
  brandPersona,
  setBrandPersona,
  classes,
  handleCloseModal,
  coreAttributes,
  createNewBrand,
  isUpdateModalOpen,
  updateCreatedBrand,
}) => {
  const personas = useSelector((state) => state.personas);
  const [competersAI, setCompetersAI] = useState("manual");
  const personasData = personas.data.data;
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevBrandName, setPrevBrandName] = useState("");

  useEffect(() => {
    console.log(brandName, "brandName", competersAI);
    if (competersAI === "ai" && brandName) {
      console.log("enter");
      async function getCompetitorsData() {
        if (prevBrandName !== brandName) {
          let prompt = getPromptForBrandCompetitorsData(brandName);
          setIsLoading(true);
          let result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Brand-onboarding"
          );
          result = result["GPT-4"][0];

          // Match the array of objects using the regular expression
          let matchedArray = result.match(
            /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
          );

          if (matchedArray) {
            // Parse the matched array into a JavaScript object
            let resultArray = json5.parse(matchedArray[0]);
            // console.log(resultArray);
            setIsLoading(false);
            setCompetitorsFromAi(resultArray);
            setPrevBrandName(brandName);
          }
        }
      }

      getCompetitorsData();
    }
  }, [competersAI]);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(
      "form ",
      competitors,
      brandName,
      brandPersona,
      details,
      brandCategoryName,
      brandCategoryDescription,
      coreAttributes,
      isUpdateModalOpen
    );
    if (isUpdateModalOpen) {
      updateCreatedBrand(
        competitors,
        brandName,
        brandPersona,
        details,
        brandCategoryName,
        brandCategoryDescription,
        coreAttributes
      );
    } else {
      createNewBrand(
        competitors,
        brandName,
        brandPersona,
        details,
        brandCategoryName,
        brandCategoryDescription,
        coreAttributes
      );
    }
  };
  return (
    <form onSubmit={handleFormSubmit}>
      {console.log(coreAttributes, "coreAttributes", personas)}
      <div className={classes.modalBody}>
        <div className={classes.formGrid}>
          <TextInput
            label="Brand Name"
            variant="outlined"
            fullWidth
            required
            value={brandName}
            onChange={(e) => {
              setCompetersAI("manual");
              setCompetitors([]);
              setBrandName(e.target.value);
            }}
            className={classes.textField}
          />

          <TextInput
            label="Details for Disambiguation"
            variant="outlined"
            fullWidth
            value={details}
            required
            onChange={(e) => setDetails(e.target.value)}
            className={classes.textField}
          />

          <TextInput
            label="Brand Category Name"
            variant="outlined"
            fullWidth
            required
            value={brandCategoryName}
            onChange={(e) => setBrandCategoryName(e.target.value)}
            className={classes.textField}
          />
          <TextInput
            label="Brand Category Description"
            variant="outlined"
            fullWidth
            required
            value={brandCategoryDescription}
            onChange={(e) => setBrandCategoryDescription(e.target.value)}
            className={classes.textField}
          />
        </div>

        <Typography variant="subtitle1">Brand Competitor</Typography>
        <RadioButtonsGroup
          options={radioOption}
          selectedvalue={competersAI}
          handleChange={(e) => {
            setCompetersAI(e.target.value);
            setCompetitors([]);
          }}
        ></RadioButtonsGroup>
        <div>
          <TextInput
            label="Competitors Brand Description"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={competitors?.join(", ")}
            onChange={(e) => setCompetitors(e.target.value.split(", "))}
            // className={classes.select}
          />
        </div>
         {
          isLoading ?
          <div>Competitor Loading....</div>:null
         }
        <div
          style={{
            marginTop: "10px",
          }}
        >
          {console.log(competitorsFromAi, "competitorsFromAi", competitors)}
          {/* <MUIButton variant="contained">Add all</MUIButton> */}
          {competersAI === "ai" &&
            competitorsFromAi?.map((competitor) => (
              <Chip
                style={{ margin: "5px", borderRadius: "3px" }}
                key={competitor}
                label={competitor.comp_brand_name}
                onClick={() =>
                  handleCompetitorToggle(competitor.comp_brand_name)
                }
                color={
                  competitors?.includes(competitor.comp_brand_name)
                    ? "primary"
                    : "default"
                }
              />
            ))}
        </div>

        <Select
          value={brandPersona}
          onChange={(e) => setBrandPersona(e.target.value)}
          displayEmpty
          fullWidth
          margin="normal"
          className={classes.select}
          required
          multiple
        >
          {personasData?.map((res) => {
            return (
              <MenuItem value={res.persona.id}>{res.persona.name}</MenuItem>
            );
          })}
        </Select>
        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            type="submit"
            variant="contained"
          >
            add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default BrandForm;
