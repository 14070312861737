import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../../context/auth-context";

import classes from "../Brand/Brand.module.css";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import api from "../../../BrandOnBoarding/api";
import Spinner from "../../../Component/Spinner/Spinner";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import CachedIcon from "@mui/icons-material/Cached";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";
import { CancelOutlined } from "@mui/icons-material";
const TABLE_HEADERS = [
  { id: "Metrics", name: "Metrics", sorting: true },
  { id: "Details", name: "Details", sorting: false },
  { id: "status", name: "Status", sorting: true },
  { id: "Action", name: "Action", sorting: true },
];

export default function Metrics() {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [error, setError] = useState(null);

  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  async function getMetrics() {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/customers/${customerId}/metrics`);
      setMetrics(response.data.data);
    } catch (error) {
      setError("Failed to get metrics. Try again later.");
    } finally {
      setLoading(false);
    }
  }

  async function updateMetrics(metricId, checked) {
    try {
      const response = await api.patch(
        `/customers/${customerId}/metrics/${metricId}`,
        { is_active: checked ? 1 : 0 }
      );
      console.log(response);
      if (response.status !== 200) {
        toast.error("Failed update metric status");
      }
    } catch (error) {
      toast.error("Failed update metric status");
    }
    getMetrics();
  }
  useEffect(() => {
    getMetrics();
  }, []);

  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 150 }}>
        <div className={classes.brand_product}>
          <span>{item.metric.name}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 180 }}>
        <div className={classes.brand_product}>
          <span>{item.metric.description}</span>
        </div>
      </StyledTableCell>

      <StyledTableCell align="left" style={{ width: 100 }}>
        <div className={classes.status}>
          {item.is_active == 1 ? (
            <CheckCircleIcon style={{ color: "#028101" }} />
          ) : (
            <CancelOutlined style={{ color: "red" }} />
          )}
          <span>{item.is_active == 1 ? "Active" : "Inactive"}</span>
        </div>
      </StyledTableCell>

      <StyledTableCell align="left" style={{ width: 100 }}>
        <Switch
          edge="end"
          defaultChecked={item.is_active}
          onChange={(e) => {
            updateMetrics(item.id, e.target.checked);
            console.log(e.target.checked, "e");
          }}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
  return (
    <>
      {!loading && metrics.length > 0 && (
        <Table headers={TABLE_HEADERS} data={metrics} render={renderRow} />
      )}
      {loading && <Spinner />}
    </>
  );
}
