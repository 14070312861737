import {
  FETCH_GEOMARKETS_REQUEST,
  FETCH_GEOMARKETS_SUCCESS,
  FETCH_GEOMARKETS_FAILURE,
  CREATE_GEOMARKET_REQUEST,
  CREATE_GEOMARKET_SUCCESS,
  CREATE_GEOMARKET_FAILURE,
  UPDATE_GEOMARKET_REQUEST,
  UPDATE_GEOMARKET_SUCCESS,
  UPDATE_GEOMARKET_FAILURE,
  DELETE_GEOMARKET_REQUEST,
  DELETE_GEOMARKET_SUCCESS,
  DELETE_GEOMARKET_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  deleteLoading: false,
  data: [],
  error: null,
};

const geoMarketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GEOMARKETS_REQUEST:
    case CREATE_GEOMARKET_REQUEST:
    case UPDATE_GEOMARKET_REQUEST:
      // case DELETE_GEOMARKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GEOMARKET_REQUEST:
      return {
        ...state,
        deleteLoading: true,
      };
    case FETCH_GEOMARKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_GEOMARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...state.data, action.payload],
      };
    case UPDATE_GEOMARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: state.data.map((persona) =>
        //   persona.id === action.payload.id ? action.payload : persona
        // ),
      };
    case DELETE_GEOMARKET_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // data: state.data.filter(persona => persona.id !== action.payload),
      };
    case FETCH_GEOMARKETS_FAILURE:
    case CREATE_GEOMARKET_FAILURE:
    case UPDATE_GEOMARKET_FAILURE:
    case DELETE_GEOMARKET_FAILURE:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // error: action.error,
      };
    default:
      return state;
  }
};

export default geoMarketsReducer;
