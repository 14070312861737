import React from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import classes from "../settings.module.css";
const PersonasForm = ({
  personasName,
  setPersonasName,
  personasDetails,
  setpersonasDetails,
  handleCloseModal,
  handleAddNewPersona,
  isUpdateModalOpen,
  updatePersonas
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const payload = {
      persona_name: personasName,
      persona_description: personasDetails,
    };
    console.log(isUpdateModalOpen,"isUpdateModalOpen")
    if(isUpdateModalOpen){
      updatePersonas(payload)
    }else{
      handleAddNewPersona(payload);
    }
    // handleAddNewPersona(payload);
    console.log(payload, "userName",personasDetails);
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.divContainer}>
        <TextInput
          label="Personas Name"
          variant="outlined"
          fullWidth
          value={personasName}
          required
          onChange={(e) => setPersonasName(e.target.value)}
          // className={classes.textField}
        />

        <TextInput
          label="Details for Personas"
          variant="outlined"
          fullWidth
          value={personasDetails}
          required
          onChange={(e) => setpersonasDetails(e.target.value)}
        />
        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            variant="contained"
            type="submit"
          >
            add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PersonasForm;
