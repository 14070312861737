import Markdown from "markdown-to-jsx";
import html2pdf from "html2pdf.js";
import { ToastContainer, toast } from "react-toastify";
import PDFLayout from "../../MainDataComponent/PDFLayout";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import classes from "./DashboardLLMResults.module.css";
import * as ReactDOMServer from "react-dom/server";

function LLMResultOutput({ LLMResponses, aggregatedResult, brand, type, llm, promptCount }) {
  const downloadPDF = async () => {
    const mainDiv = document.getElementById("llmData1");
    const clonedMainDiv = mainDiv.cloneNode(true);

    // Select all child divs under the targeted div ID and apply styles
    const childDivs = clonedMainDiv.querySelectorAll("#llmData1 > div");
    childDivs.forEach(div => {
        div.style.backgroundColor = "white";
        div.style.color = "black";
    });

    const options = {
      margin: [15, 15],
      filename: `${llm || ""}_${brand || ""}_${type ?? ""}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || "--"}
        llm={llm || "--"}
        type={type ?? "--"}
        targetEle={clonedMainDiv}
        promptCount={promptCount}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      toast.error("Failed to generate PDF");
      console.error("Failed to download: ", err);
    } finally {
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className={classes.actionBtn}
          variant="link"
          onClick={(e) => {
            downloadPDF();
          }}
        >
          <FaDownload />
        </Button>
      </div>
      <div id="llmData1" className={classes.container}>
        {Object.entries(LLMResponses).map((data) => {
          // const aggregatedDataToDisplay = Object.entries(
          //   aggregatedResult
          // ).filter((data1) => data[0] === data1[0]);
          return (
            <>
              <h5 style={{ textAlign: "center" }}>
                Multiprompt Results For <b> {data[0]} </b>
              </h5>
              {data[1] &&
                data[1].length !== 0 &&
                data[1].map((LLMRes, i) => (
                  <div key={i} className={classes.results}>
                    <div className={classes.badge_wrapper}>
                      <span className={classes.badge}>{`Result ${i + 1}`}</span>
                    </div>
                    <div className={classes.md}>
                      <Markdown>{LLMRes}</Markdown>
                    </div>
                  </div>
                ))}
              {/* {aggregatedDataToDisplay &&
                aggregatedDataToDisplay.length !== 0 &&
                aggregatedDataToDisplay.map((data) => {
                  return (
                    <>
                      <h5 style={{ textAlign: "center" }}>
                        Aggregated Result for <b> {data[0]} </b> and their
                        Sources
                      </h5>
                      <div className={`${classes.results} ${classes.md}`}>
                        {data[1] &&
                          data[1].length !== 0 &&
                          data[1].map((aggregatedResult) => (
                            <Markdown>{aggregatedResult}</Markdown>
                          ))}
                      </div>
                    </>
                  );
                })} */}
            </>
          );
        })}
        <ToastContainer />
      </div>
    </>
  );
}

export default LLMResultOutput;
