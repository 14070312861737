import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Expandable({ data = [], render }) {
  return data.map((item, index) => {
    return (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Data Set {index + 1}
        </AccordionSummary>
        <AccordionDetails>
          {render(item)}
        </AccordionDetails>
      </Accordion>
    );
  });
}