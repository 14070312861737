import { Modal, Button, FormControl } from "react-bootstrap";

import classes from "./ResetPassword.module.css";

function ResetPasswordModal({
  show,
  handleClose,
  currentPassword,
  newPassword,
  confirmNewPassword,
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
  successMessage,
  handleResetPassword,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Your Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          type="text"
          placeholder="Enter current password"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
        />
        <p></p>
        <FormControl
          type="text"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
         <p></p>
        <FormControl
          type="text"
          placeholder="Re-Enter new password"
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value);
          }}
        />
        {successMessage !== "" && (
          <>
            {" "}
            <hr /> <p> {successMessage} </p>{" "}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button style={{backgroundColor:'green'}} variant="success" onClick={() => handleResetPassword()}>
          Reset
        </Button>
        <Button style={{backgroundColor:'#d32f2f'}} variant="danger" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ResetPasswordModal;
