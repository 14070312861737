import { useState } from "react";
import Markdown from "markdown-to-jsx";
import * as moment from "moment";
import { IconButton } from "@mui/material";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";

import BrandIndexBreakups from "./BrandIndexBreakups";
import Modal from "./../Modal/Modal";
import classes from "./BrandHistoryDetailItem.module.css";

let mapping = {
  "gpt-4": "GPT-4",
  "gpt-4o": "GPT-4o",
  "gpt-3.5-turbo": "GPT-3.5 Turbo",
  Gemini: "Gemini",
  Perplexity: "Perplexity",
  llama2_70b_chat: "Llama2",
  llama3: "LLama3",
  Claude: "Claude3",
  "GPT-4_Web": "GPT-4_Web",
};

function BrandHistoryDetailItem({ hierarchy, markTable, item = "" }) {
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);

  return (
    <div className={`d-flex flex-column ml-2 ${classes.container}`}>
      <Modal
        open={showBrandIndexBreakups}
        handleClose={() => setShowBrandIndexBreakups(false)}
        width={1200}
      >
        <BrandIndexBreakups data={item?.extras} />
      </Modal>
      <div className="d-flex flex-row justify-content-between w-100">
        <strong></strong>
        <div className="d-flex gap-4">
          {item.extras && (
            <IconButton
              aria-label="settings"
              size="medium"
              onClick={() => setShowBrandIndexBreakups(true)}
            >
              <HandymanOutlinedIcon
                fontSize="inherit"
                style={{ color: "white" }}
              />
            </IconButton>
          )}
          <div className="d-flex flex-column">
            <strong>{item?.brand_category}</strong>
            <span className={classes.date}>
              {moment(item.date).format("DD-MM-YYYY, h:mm:ss a")}
            </span>
          </div>
        </div>
      </div>
      <hr className={classes.divider} />
      <Markdown>{markTable}</Markdown>
      <span className={classes.badge}>{mapping[item.llm]}</span>
    </div>
  );
}

export default BrandHistoryDetailItem;
