import React, { useEffect, useState } from "react";
import { Container, Card, Col, Form } from "react-bootstrap";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { fetchGpt4API } from "../../../../services/apiService";
import BrandSuggession from "./BrandSuggession";
import Competitors from "./Competitors";
import "../../../../App.css";
import BrandUserInput from "../../../BrandUserInput/BrandUserInput";

function IndexScore({
  handlePromptBrandReachChange1,
  promptBrandReach1,
  onProductCountChange,
  productCount,
  industry,
  onChangeIndustry,
  brandIndexType,
  setBrandIndexType,
  brandIndexCompetitors,
  setBrandIndexCompetitors,
  setIsInputValid = () => {}
}) {
  const [suggessions, setSuggessions] = useState([]);
  const [enteredCompetitorText, setEnteredCompetitorText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isValidInput, setIsValidInput] = useState(false);
  const [userInput, setUserInput] = useState("");

  const getCompetitorSuggessions = async (promptBrandReach1) => {
    let prompt = `I am an end consumer seeking information on ${promptBrandReach1}. Provide list of ${brandIndexType} for the provided category. `;

    if (industry) {
      prompt = `I am an end consumer seeking information on ${promptBrandReach1} located in ${industry}. Please provide a list of ${brandIndexType} for the provided category.`;
    }
    
    prompt += `Give me ${brandIndexType} list.
      Prodvide me JSON structure like this.
      {
        data: ["item1", "item2", ...] (array)
      }
      replace item1, item2 etc. with ${brandIndexType} name.
      if you don't understand the category or it is invalid, then return empty data array
    `;

    try {
      setLoading(true);
      const response = await fetchGpt4API(
        prompt,
        new AbortController(),
        undefined,
        undefined,
        "gpt-3.5-turbo-0125",
        { type: "json_object" }
      );
      const data = JSON.parse(response["GPT-4"]);
      setSuggessions(data.data.slice(0, 3));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const addCompetitorHandler = (newCompetitor) => {
    if (newCompetitor.trim().length === 0) return;
    if (brandIndexCompetitors.length === 3) {
      setError("Maximum 3 competitor are allowed");
      return;
    }
    setError(null);
    setBrandIndexCompetitors((prevCompetitors) => {
      const isCompetitorExist = prevCompetitors.findIndex(
        (comp) => comp === newCompetitor.trim()
      );
      if (isCompetitorExist > -1) {
        setError(`You have already added ${newCompetitor}`);
        return prevCompetitors;
      }
      return [...prevCompetitors, newCompetitor.trim()];
    });
    setEnteredCompetitorText("");
  };

  const deleteCompetitorHandler = (competitor) => {
    setError(null);
    setBrandIndexCompetitors((prevCompetitors) => {
      return prevCompetitors.filter((comp) => comp != competitor);
    });
  };

  useEffect(() => {
    if(!userInput) return;
    setBrandIndexCompetitors([]);
    let timer = setTimeout(() => {
      getCompetitorSuggessions(userInput);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [brandIndexType, userInput, industry]);

  return (
    <Container className="mb-3">
      <Card as={Col} md="12" className="border-0 whi">
        <Card.Body>
          <Card.Title className="mb-4">Brand Index Score</Card.Title>
          <Form.Group as={Col} md="5" style={{ width: "28rem" }}>
            <BrandUserInput
              placeholder="Enter Brand Category"
              className="big1"
              style={{ height: "70px" }}
              onValid={(input) => {
                setIsInputValid(true);
                setUserInput(input);
                handlePromptBrandReachChange1({target: { value: input } })
              }}
              onInvalid={() => {
                setIsInputValid(false);
                setBrandIndexCompetitors([]);
                setSuggessions([]);
              }}
            />
          </Form.Group>
          {/* <Form.Group
            as={Col}
            md="5"
            className="mt-4"
            style={{ width: "28rem" }}
          >
            <Form.Label>
              Choose an entity you want to return for entered category
              <Tooltip
                title="Selected option list will be returned in Brand Index Score for specified category"
                placement="right"
              >
                <IconButton aria-label="help" size="small">
                  <HelpIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Form.Label>
            <div className="d-flex flex-row">
              <Form.Check
                type="radio"
                name="type"
                label="Products"
                value="products"
                onClick={(e) => {
                  setBrandIndexType(e.target.value);
                }}
                defaultChecked={brandIndexType === "products"}
              />
              <Form.Check
                type="radio"
                name="type"
                label="Companies"
                value="companies"
                onClick={(e) => {
                  setBrandIndexType(e.target.value);
                }}
                defaultChecked={brandIndexType === "companies"}
              />
            </div>
          </Form.Group> */}
          <Form.Group style={{ width: "40rem", marginTop: "1rem" }}>
            <Form.Label>
              Specify the location for the entered category (Optional)
            </Form.Label>
            <Form.Control
              type="text"
              style={{ width: "28rem" }}
              placeholder="e.g California, US"
              name="industry"
              value={industry}
              onChange={(e) => onChangeIndustry(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md="5"
            className="mt-3"
            style={{ width: "28rem" }}
          >
            <Form.Label>Choose or type {brandIndexType} (optional)</Form.Label>
            <div style={{ position: "relative " }}>
              <Form.Control
                placeholder="Competitors"
                name="Competitors"
                value={enteredCompetitorText}
                onChange={(e) => setEnteredCompetitorText(e.target.value)}
              />
              <IconButton
                style={{ position: "absolute", top: 0, right: 3 }}
                aria-label="add"
                onClick={() => addCompetitorHandler(enteredCompetitorText)}
              >
                <AddCircleIcon />
              </IconButton>
            </div>
          </Form.Group>
          <Competitors
            data={brandIndexCompetitors}
            onDelete={deleteCompetitorHandler}
          />
          {error && <span style={{ fontSize: 12, color: "red" }}>{error}</span>}
          <BrandSuggession
            loading={loading}
            suggessions={suggessions}
            onItemClick={(val) => addCompetitorHandler(val)}
          />
          <Form.Group style={{ width: "28rem", marginTop: "1rem" }}>
            <Form.Label>
              Number of products/brands you want to return
            </Form.Label>
            <Form.Select
              onChange={(e) => onProductCountChange(e.target.value)}
              value={productCount}
            >
              {Array.from({ length: 3 }).map((_, i) => {
                return (
                  <option key={i + 5} value={i + 5}>
                    {i + 5}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default IndexScore;
