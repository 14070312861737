import { Skeleton } from "@mui/material";

import Message from "./Message";
import classes from "./MessageList.module.css";

const MessageList = ({ messages, loading }) => {
  return (
    <div className={classes["message-list"]}>
      {messages.map((message) => (
        <Message key={message.id} {...message} />
      ))}
      {loading && (
        <div>
          <Skeleton width={"70%"} />
          <Skeleton width={"40%"} />
          <div>&nbsp;</div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
