import Accordion from "react-bootstrap/Accordion";

import ReportInput from "./ReportInput";

const options = [
  { name: "GPT-4 (OpenAI/Microsoft)", value: "gpt-4" },
  { name: "GPT-4o (OpenAI/Microsoft)", value: "gpt-4o" },
  { name: "GPT-3.5 (OpenAI/Microsoft)", value: "gpt-3.5-turbo" },
  { name: "Gemini (Google)", value: "Gemini" },
  { name: "Perplexity", value: "Perplexity" },
  { name: "Llama2 (Meta)", value: "llama2_70b_chat" },
  { name: "Llama3 (Meta)", value: "llama3" },
  { name: "Claude3 (Anthropic)", value: "Claude" },
  // { name: "GPT-4 Web", value: "GPT-4_Web" },
  // { name: "Google SGE", value: "Google-SGE" },
  // { name: "Palm2 (Google/Bard)", value: "palm2_text" },
  // { name: "GPT-4 Turbo (OpenAI)", value: "gpt_4_turbo" },
];

function ReportItem({
  report,
  index,
  onSelect,
  onChangeInput,
  LLMs,
  attributes,
  inputs,
}) {
  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>
        <strong>
          {index + 1}. {report.title}
        </strong>
      </Accordion.Header>
      <Accordion.Body
        onEntered={(_) => onSelect(index)}
        onExited={(_) => onSelect(null)}
      >
        {report.selections.map((selection, index) => {
          if (selection.text === "Select LLMs") {
            selection = {
              ...selection,
              values: options,
            };
          }
          return (
            <ReportInput
              key={index}
              selection={selection}
              index={index}
              allSelections={report.selections}
              onChange={(e) => {
                onChangeInput(e, selection, report.title);
              }}
              LLMs={LLMs}
              attributes={attributes}
              inputs={inputs}
            />
          );
        })}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export default ReportItem;
