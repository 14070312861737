import {
  FETCH_COMPETITOR_BRANDS_REQUEST,
  FETCH_COMPETITOR_BRANDS_SUCCESS,
  FETCH_COMPETITOR_BRANDS_FAILURE,
  FETCH_COMPETITOR_BRAND_REQUEST,
  FETCH_COMPETITOR_BRAND_FAILURE,
  CREATE_COMPETITOR_BRAND_REQUEST,
  CREATE_COMPETITOR_BRAND_SUCCESS,
  CREATE_COMPETITOR_BRAND_FAILURE,
  UPDATE_COMPETITOR_BRAND_REQUEST,
  UPDATE_COMPETITOR_BRAND_SUCCESS,
  UPDATE_COMPETITOR_BRAND_FAILURE,
  DELETE_COMPETITOR_BRAND_REQUEST,
  DELETE_COMPETITOR_BRAND_SUCCESS,
  DELETE_COMPETITOR_BRAND_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const competitorBrandsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPETITOR_BRANDS_REQUEST:
    case FETCH_COMPETITOR_BRAND_REQUEST:
    case CREATE_COMPETITOR_BRAND_REQUEST:
    case UPDATE_COMPETITOR_BRAND_REQUEST:
    case DELETE_COMPETITOR_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPETITOR_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_COMPETITOR_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...state.data, action.payload],
      };
    case UPDATE_COMPETITOR_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((brand) =>
          brand.id === action.payload.id ? action.payload : brand
        ),
      };
    case DELETE_COMPETITOR_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((brand) => brand.id !== action.payload),
      };
    case FETCH_COMPETITOR_BRANDS_FAILURE:
    case FETCH_COMPETITOR_BRAND_FAILURE:
    case CREATE_COMPETITOR_BRAND_FAILURE:
    case UPDATE_COMPETITOR_BRAND_FAILURE:
    case DELETE_COMPETITOR_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default competitorBrandsReducer;
