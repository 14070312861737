import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { FaEye } from "react-icons/fa6";
import { TbRefresh } from "react-icons/tb";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";

import PDFLayout from "../../MainDataComponent/PDFLayout";
import classes from "./ReportMenu.module.css";

function ReportMenu({ setTogglePrompt, setToggleLLMResponse, reportTitle }) {
  const downloadPDF = async () => {
    const input = document.getElementById("report");

    const options = {
      margin: [15, 15],
      filename: reportTitle ?? "LLM_report",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    let storedQueries = localStorage.getItem("queries") || "[]";
    let { reportType } = JSON.parse(storedQueries);

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout targetEle={input} category="report" reportType={reportType} />
    );

    await html2pdf(contentHTML, options);
  };

  return (
    <div className="d-flex justify-content-between w-100 mb-3">
      <div className="d-flex">
        <Button
          className={classes.actionBtn}
          variant="link"
          onClick={() => window.location.reload()}
        >
          <TbRefresh />
        </Button>
        <Button
          className={classes.actionBtn}
          variant="link"
          onClick={() => {
            setTogglePrompt((val) => !val);
            setToggleLLMResponse(false);
          }}
        >
          <IoSettings />
        </Button>
        <Button
          className={classes.actionBtn}
          variant="link"
          onClick={() => {
            setToggleLLMResponse((val) => !val);
            setTogglePrompt(false);
          }}
        >
          <FaEye />
        </Button>
      </div>
      <Button
        className={classes.actionBtn}
        variant="link"
        onClick={downloadPDF}
      >
        <FaDownload />
      </Button>
    </div>
  );
}

export default ReportMenu;
