import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // Import the necessary Chart.js components

const MultiLineChart = ({ brandIndexData, additionalDashboradInfo, label }) => {
  function getDates(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  // console.log('data==>', brandIndexData)

  function filterProductNames(array) {
    let threshold;

    if (array.length < 5) {
      threshold = 2;
    } else if (array.length >= 5 && array.length <= 10) {
      threshold = 4;
    } else {
      threshold = 5;
    }

    const keyCount = {};

    // Count occurrences of each key
    array.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        keyCount[key] = (keyCount[key] || 0) + 1;
      });
    });

    // Filter keys based on threshold
    return array.map((obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        if (keyCount[key] >= threshold) {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    });
  }

  const filteredBrandIndexData = filterProductNames(brandIndexData);

  const dates = getDates(
    additionalDashboradInfo.startDate,
    additionalDashboradInfo.endDate
  );

  // Extract unique product names

  const productNamesSet = filteredBrandIndexData
    .map((obj) => Object.keys(obj))
    .flat();
  const productNames = Array.from(new Set(productNamesSet));

  // Raw data for the datasets
  function generateColor(index) {
    const hue = ((index + 2) * 137.508) % 360; // Use the golden ratio to ensure distinct hues
    const saturation = 70 + ((index * 15) % 30); // Vary saturation between 70% and 100%
    const lightness = 50 + ((index * 10) % 20); // Vary lightness between 50% and 70%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  const rawData = productNames.map((productName, index) => ({
    label: productName,
    data: dates.map((date, subIndex) => {
      return filteredBrandIndexData[subIndex]?.[productName];
    }),
    backgroundColor: generateColor(index)
      .replace("hsl", "hsla")
      .replace(")", ", 0.4)"),
    borderColor: generateColor(index),
  }));

  // Transform data to match the chart.js format
  const data = {
    labels: dates,
    datasets: rawData,
  };

  // Options for the line chart
  const options = {
    indexAxis: "x", // Renders the bars horizontally
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: label === "Brand Index Score" ? 100 : 50,
      },
    },
  };

  return (
    <>
      {/* <p style={{ padding: "5px", fontWeight: "600" }}>{label}</p> */}
      <Line data={data} options={options} />
    </>
  );
};

export default MultiLineChart;
