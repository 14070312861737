import { useContext, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";

import Modal from "./../Modal/Modal";
import { deleteDataFromFirebase } from "../../DatabaseFirebase/firebaseService";
import { BrandIndexContext } from "../../context/brand-index-context";
import classes from './BrandIndexHistoryItem.module.css';

function BrandIndexHistoryItem({ item, onSelected }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const brandIndexCtx = useContext(BrandIndexContext)

  async function handleConfirmDelete() {
    setShowDeleteDialog(false);
    await deleteDataFromFirebase(item.id, "brand-index");
    toast.success('History item deleted successfully');
    brandIndexCtx.setShowHistory(false);
  }

  return (
    <>
      <Modal
        open={showDeleteDialog}
        title="Delete?"
        showActions
        handleClose={() => setShowDeleteDialog(false)}
        handleConfirm={handleConfirmDelete}
        confirmBtnText="Yes"
        confirmBtnColor="error"
      >
        <p>
          Do you want to delete this history item -{" "}
          <strong>{item.brand_category}</strong>?
        </p>
      </Modal>
      <div className={classes.item} role="button" onClick={() => onSelected(item)}>
        <div className="d-flex flex-row align-items-center">
          <ChatBubbleOutlineIcon />
          <h4 className="card-title2 mt-1 px-2">&nbsp;{item.brand_category}</h4>
        </div>
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            e.stopPropagation();
            setShowDeleteDialog(true);
          }}
        >
          <DeleteOutlineIcon style={{ color: "white" }} />
        </IconButton>
      </div>
    </>
  );
}

export default BrandIndexHistoryItem;
