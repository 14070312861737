import { useState, useEffect, useContext, useRef } from "react";

import BrandSpecItem from "./../BrandSpecItem/BrandSpecItem";
import classes from "./BrandSnippets.module.css";
import { extractJSONFromString } from "../../../utils/helpers";
import { DiscoveryContext } from "../discovery-context";
import ErrorView from "./../../../Component/ErrorView/ErrorView";
import api from "../../../BrandOnBoarding/api";

const THEMES = [
  {
    id: 1,
    name: "GPT-4",
    llm: "gpt-4o",
    url: "/llm-execute",
    img: "https://cdn.oaistatic.com/assets/apple-touch-icon-mz9nytnj.webp",
    text: "",
  },
  {
    id: 2,
    name: "Gemini",
    llm: "Gemini",
    url: "/llm-execute",
    img: "https://www.gstatic.com/lamda/images/gemini_sparkle_v002_d4735304ff6292a690345.svg",
    text: "",
  },
  {
    id: 3,
    name: "Claude",
    llm: "Claude3",
    url: "/llm-execute",
    img: "https://claude.ai/images/claude_app_icon.png",
    text: "",
  },
  {
    id: 4,
    name: "Llama",
    llm: "llama3",
    url: "/llm-execute",
    img: "https://static.xx.fbcdn.net/rsrc.php/y5/r/m4nf26cLQxS.ico",
    text: "",
  },
  {
    id: 5,
    name: "Perplexity",
    llm: "Perplexity",
    url: "/llm-execute",
    img: "https://mintlify.s3-us-west-1.amazonaws.com/perplexity/_generated/favicon/favicon-32x32.png?v=3",
    text: "",
  },
];

export default function BrandSnippets({ entity, category }) {
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);
  const discoveryType = discoveryCtx.type || "brand";

  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (discoveryCtx?.brand_themes?.length > 0) return;
    getBrandSnippets();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };

  }, [discoveryCtx?.brand_themes?.length]);

  useEffect(()=>{
    if(discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  },[discoveryCtx.abort_launch])

  async function getBrandSnippets() {
    setError(null);
    const promiseArr = [];

    abortControllerRef.current = new AbortController();

    for (let theme of THEMES) {
      const prompt = `
      You are a LLM describing a brand ${entity} for category ${category} to a user. Give me a 8-10 word phrase that you would use. Respond with only a JSON structure as following:
      {
      "data": ["phrase"]
      }
      Do not include any explanatory text before or after the JSON.
      `;
      let payload = {
        prompt: prompt,
        llm: theme.llm,
      };
      promiseArr.push(api.post(theme.url, payload, { signal: abortControllerRef.current.signal }));
    }

    try {
      const responses = await Promise.all(promiseArr);
      const snippetsArr = responses.map((res, index) => {
        return res.data.data.data;
      });
      discoveryCtx.updatedBrandThemes(snippetsArr);
    } catch (error) {
      console.log(`Error: ${error.message}`);
      setError(error.message);
    }
  }

  let brandSnippets = THEMES;
  let loading = true;
  if (discoveryCtx.brand_themes && discoveryCtx?.brand_themes?.length > 0) {
    discoveryCtx.brand_themes.forEach((items, index) => {
      brandSnippets[index].text = items
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(", ");
    });
    loading = false;
  }

  return (
    <div className={classes.container}>
      {brandSnippets.length > 0 && !error && (
        <BrandSpecItem
          loading={loading}
          data={brandSnippets}
          title={discoveryType === "brand" ? "Brand Snippets" : "Product Snippets"}
        />
      )}
      {error && (
        <ErrorView title="Error fetching brand snippets" message={error} retry onRetry={() => getBrandSnippets()} />
      )}
    </div>
  );
}
