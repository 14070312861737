import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Skeleton,
} from "@mui/material";

import MUIList from "./../../../Component/UI/MUIList/MUIList";
import classes from "./BrandSpecItem.module.css";

export default function BrandSpec({ title, loading, data }) {
  function renderRow(item) {
    let textContent = item.text;
    if(title === "Brand Snippets") {
      textContent = `"${item.text}"`;
    }
    return (
      <ListItem className={classes.item} key={item.id}>
        <ListItemAvatar className={classes.item_avatar}>
          <Avatar src={item.img} className={classes.avatar}></Avatar>
        </ListItemAvatar>
        <ListItemText>
          <div>
            <span className={classes.llm}>{item.name}:</span>
            <span className={classes.snippet}>{textContent}</span>
          </div>
        </ListItemText>
      </ListItem>
    );
  }

  function LoadingSkeleton() {
    return (
      <div className="m-3">
        {Array(5)
          .fill(0)
          .map((_, index) => {
            return (
              <Skeleton
                key={index}
                sx={{ fontSize: "15px", marginTop: "2px" }}
                width={"100%"}
              />
            );
          })}
      </div>
    );
  }
  return (
    <>
      <div className={classes.title}>{title}</div>
      {data.every((item) => item.text) && !loading && (
        <MUIList className={classes.list} data={data} render={renderRow}></MUIList>
      )}
      {loading && <LoadingSkeleton />}
    </>
  );
}
