import api from "../../BrandOnBoarding/api";
import {
  FETCH_PRODUCT_CATEGORIES_REQUEST,
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  FETCH_PRODUCT_CATEGORIES_FAILURE,
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAILURE,
  UPDATE_PRODUCT_CATEGORY_REQUEST,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_CATEGORY_FAILURE,
  DELETE_PRODUCT_CATEGORY_REQUEST,
  DELETE_PRODUCT_CATEGORY_SUCCESS,
  DELETE_PRODUCT_CATEGORY_FAILURE,
} from "../constants/actionTypes";

export const fetchProductCategories = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_CATEGORIES_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/product-catgories`);
    dispatch({ type: FETCH_PRODUCT_CATEGORIES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_PRODUCT_CATEGORIES_FAILURE, error: error.message });
  }
};

export const createProductCategory = (id, category) => async (dispatch) => {
  dispatch({ type: CREATE_PRODUCT_CATEGORY_REQUEST });
  try {
    const response = await api.post(`/customers/${id}/brand-product-category`, category);
    dispatch({ type: CREATE_PRODUCT_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_PRODUCT_CATEGORY_FAILURE, error: error.message });
  }
};

export const updateBrandCategory = (id, category) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_CATEGORY_REQUEST });
  try {
    const response = await api.put(`/brand-categories/${id}`, category);
    dispatch({ type: UPDATE_PRODUCT_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_PRODUCT_CATEGORY_FAILURE, error: error.message });
  }
};

export const deleteBrandCategory = (id) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_CATEGORY_REQUEST });
  try {
    await api.delete(`/brand-categories/${id}`);
    dispatch({ type: DELETE_PRODUCT_CATEGORY_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_PRODUCT_CATEGORY_FAILURE, error: error.message });
  }
};
