import React, { useState, useRef } from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import PDFLayout from "../../MainDataComponent/PDFLayout";
import { ToastContainer, toast } from "react-toastify";

const BrandProductAttributesTable = ({
  selectedAttributes,
  isAverageRank,
  isCategoryDimension,
  isCategoryDimensionSource,
  isSourceData,
  isBrandIndex,
  metrics,
  type,
  brand,
  llm,
  categoryName,
  isOverview,
}) => {
  const [loading, setLoading] = useState(false);
  const pdfContentRef = useRef(null);

  const downloadPDF = async () => {
    setLoading(true);
    const options = {
      margin: [15, 15],
      filename: `${brand || "--"} ${metrics || ""}_${type || ""}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || "--"}
        metrics={metrics || "--"}
        type={type || "--"}
        llm={llm || "--"}
        categoryName={categoryName}
        targetEle={pdfContentRef.current}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      toast.error("Failed to generate PDF");
      console.error("Failed to download: ", err);
    } finally {
      setLoading(false);
    }
  };

  const brands = Object.keys(selectedAttributes);
  const attributes = [
    ...new Set(
      brands.flatMap((brand) => Object.keys(selectedAttributes[brand]))
    ),
  ];

  // Calculate the sum of values for each attribute across all brands
  const attributeSums = attributes.map((attribute) => {
    const sum = brands.reduce((acc, brand) => {
      const value = selectedAttributes[brand][attribute];
      return acc + (value !== undefined ? parseFloat(value) : 0);
    }, 0);
    return { attribute, sum };
  });

  // Sort attributes by the sum of their values in descending order
  attributeSums.sort((a, b) => b.sum - a.sum);

  // let jsonData = JSON.stringify(selectedAttributes, null, 2); // The `null` and `2` parameters are optional but make the JSON string pretty-printed.
  // console.log(jsonData);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={(e) => {
            if (loading) return;
            downloadPDF();
          }}
        >
          {!loading && <FileDownloadOutlinedIcon />}
          {loading && <Spinner animation="border" />}
        </IconButton>
      </div>
      <div
        className="pdfContent"
        // style={{ padding: "10px" }}
        ref={pdfContentRef}
      >
        <Table>
          <thead style={{ backgroundColor: "#CCCCCC" }}>
            <tr>
              <th>
                {isAverageRank || (isBrandIndex && !isOverview) || isOverview 
                  ? "Brand/Product"
                  : isCategoryDimension
                  ? "Buying Dimension"
                  : isCategoryDimensionSource
                  ? "Source (domain URL)"
                  : isSourceData
                  ? "Source (domain URL)"
                  : isOverview && isBrandIndex
                  ? "Date/Range"
                  : type == "Time-series comparison"
                  ? "Core Attribute"
                  : "Attribute"}
              </th>
              {brands.map((brand, index) => (
                <th key={index}>{brand}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ backgroundColor: "#FFFFFF" }}>
            {attributeSums.map(({ attribute }, index) => (
              <tr key={index}>
                <td>{attribute}</td>
                {brands.map((brand, brandIndex) => (
                  <td key={brandIndex}>
                    {selectedAttributes[brand][attribute] !== undefined
                      ? !isBrandIndex
                        ? `${Number(
                            selectedAttributes[brand][attribute]
                          ).toFixed(0)} %`
                        : `${Number(
                            selectedAttributes[brand][attribute]
                          ).toFixed(0)}`
                      : "N/A"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </>
  );
};

export default BrandProductAttributesTable;
