import React from "react";
import MUICard from "./../../../../Component/UI/MUICard/MUICard";
import CompetitorItem from "./CompetitorItem";
import classes from "./Competitors.module.css";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

export default function Competitors({
  compBrandIndexScore,
  pastCompBrandIndexScore,
  brandDomains,
  compIndexScoreMetaData,
}) {
  function transformData(data) {
    const brandNames = data && Object.keys(data["Brand Index Score"]);

    const transformedArray = brandNames?.map((brand) => ({
      name: brand,
      BrandIndexScore: parseFloat(data["Brand Index Score"][brand]),
      Favorability: data.Favorability[brand],
      Rank: data.Rank[brand],
    }));

    return transformedArray;
  }

  function calculateBrandIndexDelta(currentData, previousData, metaData) {
    // Helper function to find brand by name in previous data
    const findBrandInPrevious = (prevData, brandName) => {
      return prevData?.find((prevObj) => prevObj?.name === brandName);
    };

    const findMetaDataInBrand = (metaData, brandName) => {
      return metaData?.find((obj) => obj?.name === brandName);
    };

    return currentData?.map((currentBrand) => {
      // Find the corresponding brand in previous data
      const prevBrand = findBrandInPrevious(previousData, currentBrand.name);
      const brandMetaData = findMetaDataInBrand(metaData, currentBrand.name);

      // Get previous BrandIndexScore, Favorability, and Rank, if they exist
      const prevScore = prevBrand ? prevBrand?.BrandIndexScore : null;
      const prevFav = prevBrand ? prevBrand?.Favorability : null;
      const prevRank = prevBrand ? prevBrand?.Rank : null;

      // Calculate delta for BrandIndexScore, Favorability, and Rank
      const scoreDelta =
        prevScore !== null ? currentBrand?.BrandIndexScore - prevScore : null;
      const favDelta =
        prevFav !== null ? currentBrand?.Favorability - prevFav : null;
      const rankDelta = prevRank !== null ? currentBrand?.Rank - prevRank : null;

      // Return updated brand object with deltas
      return {
        ...currentBrand,
        delta: scoreDelta,
        favDelta: favDelta,
        rankDelta: rankDelta,
        metaData: brandMetaData,
      };
    });
  }

  let compIndexScoreData = transformData(compBrandIndexScore)?.slice(0, 4);
  let pastCompIndexScoreData = transformData(pastCompBrandIndexScore);

  const indexScoreDataWithDelta = calculateBrandIndexDelta(
    compIndexScoreData,
    pastCompIndexScoreData,
    compIndexScoreMetaData
  );

  return (
    <MUICard
      title="Competitor Analysis"
      variant="elevated"
      elevation={0}
      className={classes.card}
      titleClassName={classes.title}
      width={"100%"}
    >
      <div className={classes.container}>
        {indexScoreDataWithDelta && indexScoreDataWithDelta.length ? (
          indexScoreDataWithDelta?.map((data, index) => {
            return (
              <React.Fragment key={index}>
                <CompetitorItem
                  brandDomains={brandDomains}
                  indexScoreData={data}
                />
              </React.Fragment>
            );
          })
        ) : (
          <EmptyView message="Data Not Available" />
        )}
      </div>
    </MUICard>
  );
}
