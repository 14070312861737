import { Modal, Button } from "react-bootstrap";

import classes from './ReportPromptPreview.module.css';

function ReportPromptPreview({ show, handleClose, prompt }) {
  const promptText = prompt.split('\n').map((str, i) => <p key={i}>{str}</p>);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Prompt Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>{promptText}</Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button style={{backgroundColor:'#d32f2f'}} variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReportPromptPreview;