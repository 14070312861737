import api from "../../BrandOnBoarding/api";
import {
  FETCH_CORE_ATTRIBUTES_REQUEST,
  FETCH_CORE_ATTRIBUTES_FAILURE,
  FETCH_CORE_ATTRIBUTES_SUCCESS,
} from "../constants/actionTypes";

export const fetchcoreAttributes = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CORE_ATTRIBUTES_REQUEST });
  try {
    const response = await api.get(`/customers/core-attributes`);
    dispatch({ type: FETCH_CORE_ATTRIBUTES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_CORE_ATTRIBUTES_FAILURE, error: error.message });
  }
};
