import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; 
import "react-notifications/lib/notifications.css";
import "./assets/css/style.css";
import "./assets/css/feather.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { Provider } from "react-redux";

import "./index.css";
import App from "./App";
import AuthContextProvider from "./context/auth-context";
import BrandIndexContextProvider from "./context/brand-index-context";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrandIndexContextProvider>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrandIndexContextProvider>
  </Provider>
);
