import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";

import classes from "./BrandUserInput.module.css";
import { validateBrandInput } from "../../utils/helpers";

export default function BrandUserInput({
  placeholder,
  className,
  style,
  disabled = false,
  onValid = () => {},
  onInvalid = () => {}
}) {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);

  async function checkIfInputValid(input) {
    setLoading(true);
    try {
      let response = await validateBrandInput(input);
      setLoading(false);
      if (response.data === "Yes") {
        setIsInputValid(true);
        onValid(input)
      } else {
        setIsInputValid(false);
        onInvalid()
      }
    } catch (error) {
      setLoading(false);
    }
  }

  /*useEffect(() => {
    if(disabled) return
    onInvalid();
    if (!input) return;
    let timer = setTimeout(() => {
      checkIfInputValid(input);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [input]);*/

  useEffect(()=>{
    onValid(input)
  },[input])

  return (
    <div className={classes.container}>
      <Form.Control
        as="textarea"
        rows={1}
        cols={2}
        placeholder={placeholder}
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
          setIsInputValid(true);
        }}
        className={className}
        style={style}
        disabled={disabled}
      />
      {loading && (
        <Spinner
          size="sm"
          animation="border"
          role="status"
          className={classes.loading}
        />
      )}
      {!isInputValid && !loading && input && !disabled && (
        <span className={classes.error}>Please enter valid input</span>
      )}
    </div>
  );
}
