import { Button } from "react-bootstrap";
import { IoSettings } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import PDFLayout from "../MainDataComponent/PDFLayout";
import html2canvas from "html2canvas";

import classes from "./MonitorMenu.module.css";

function MonitorMenu({
  setTogglePrompt,
  setToggleLLMResponse,
  data,
  togglePrompt,
  toggleLLMResponse,
}) {
  // const downloadPDF = async () => {
  //   const input = document.getElementById("monitoringreport");
  //   const options = {
  //     margin: [15, 15],
  //     filename: `${data?.brandOrProductToMonitor} Monitoring_report`,
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2, letterRendering: true },
  //     jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   const contentHTML = ReactDOMServer.renderToStaticMarkup(
  //     <PDFLayout
  //       brand={data?.brandOrProductToMonitor}
  //       type={data?.focussingArea}
  //       llm={data?.llm}
  //       targetEle={input}
  //       category="monitoring"
  //     />
  //   );

  //   await html2pdf(contentHTML, options);
  // };

  const downloadPDF = async () => {
    const dashboardDiv = document.getElementById("monitoringreport");
    const spacerDiv = document.getElementById("extraSpacer");

    // Create a canvas element to render the content onto
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Add a small padding to canvas size to avoid black borders
    const padding = 0;
    const contentWidth = dashboardDiv.scrollWidth + padding;
    const contentHeight = dashboardDiv.scrollHeight + padding;
    canvas.width = contentWidth;
    canvas.height = contentHeight;

    // Render the content of the dashboard div onto the canvas
    html2canvas(dashboardDiv, { canvas: canvas, scale: 1 }) // Increase scale for better quality
      .then((canvas) => {
        // Convert canvas to data URL with higher quality
        const canvasDataURL = canvas.toDataURL("image/jpeg", 1.0); // Increase quality

        // Construct HTML content with canvas image
        const contentHTML = ReactDOMServer.renderToStaticMarkup(
          <div>
            <PDFLayout
              brand={data?.brandOrProductToMonitor}
              type={data?.focussingArea}
              llm={data?.llm}
              targetEle={spacerDiv}
              category="Dashboard"
            />
            <img
              src={canvasDataURL}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            {/* Ensure the image takes full width and height */}
          </div>
        );

        // Options for PDF generation
        const options = {
          margin: [15, 15],
          filename: `${data?.brandOrProductToMonitor}_Analysis_report.pdf`,
          image: { type: "jpeg", quality: 2 }, // Adjust quality
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "pt", format: "A3", orientation: "portrait" },
          // pagebreak: {
          //   mode: ["avoid-all"],
          //   avoid: ".PDFLayout", // Adjust to avoid breaking before PDFLayout
          // }, // Specify page break
        };

        // Generate PDF with canvas image and other content
        html2pdf().from(contentHTML).set(options).save();
      })
      .catch((error) => {
        console.error("Error rendering dashboard to canvas:", error);
      });
  };

  return (
    <div className="d-flex justify-content-between w-100 mb-3">
      <div className="d-flex">
        {!toggleLLMResponse && !togglePrompt && (
          <>
            {" "}
            {/* <Button
              className={classes.actionBtn}
              variant="link"
              onClick={() => {
                setTogglePrompt(true);
                setToggleLLMResponse(false);
              }}
            >
              <IoSettings />
            </Button> */}
            <Button
              className={classes.actionBtn}
              variant="link"
              onClick={() => {
                setToggleLLMResponse(true);
                setTogglePrompt(false);
              }}
            >
              <FaEye />
            </Button>{" "}
          </>
        )}

        {(toggleLLMResponse || togglePrompt) && (
          <Button
            className={classes.actionBtn}
            variant="link"
            onClick={() => {
              setToggleLLMResponse(false);
              setTogglePrompt(false);
            }}
          >
            <FaArrowLeft />
          </Button>
        )}
      </div>
      <Button
        className={classes.actionBtn}
        variant="link"
        onClick={downloadPDF}
      >
        <FaDownload />
      </Button>
    </div>
  );
}

export default MonitorMenu;
