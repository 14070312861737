import React, { useState, useEffect, useMemo } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast, ToastContainer } from "react-toastify";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";

import Spinner from "./../Component/Spinner/Spinner";
import PromptResultPreview from "./PromptResultPreview";
import api from "../BrandOnBoarding/api";

const PromptEditor = React.memo(
  ({
    prompt,
    version,
    handleVersionChange,
    onPromptSave,
    onDeletePromptVesion,
  }) => {
    const [promptData, setPromptData] = useState(null);
    const [inputValues, setInputValues] = useState(null);
    const [constructedPrompt, setConstructedPrompt] = useState("");
    const [showRunPrompt, setShowRunPrompt] = useState(false);
    const [promptVersionStatus, setPromptVersionStatus] = useState(
      prompt.versions[version].is_active
    );

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    const handlePromptChange = (index, newPrompt) => {
      setPromptData((prevPromptData) => {
        const updatedSegments = prevPromptData.prompt_segments.map(
          (segment, i) =>
            i === index ? { ...segment, prompt: newPrompt } : segment
        );
        return { ...prevPromptData, prompt_segments: updatedSegments };
      });
    };

    const handleInputChange = (e) => {
      setInputValues((prevValues) => {
        const updatedInputValues = [...prevValues];
        const index = prevValues.findIndex(
          (input) => input.name === e.target.name
        );
        updatedInputValues[index].value = e.target.value;
        return updatedInputValues;
      });
    };

    const constructPrompt = () => {
      let finalPrompt = "";
      promptData.prompt_segments.forEach((segment) => {
        let segmentText = "";

        if (typeof segment.prompt === "object") {
          const matchingValue = segment.prompt.values.find(
            (item) => item.value === inputValues[segment.prompt.name]
          );
          if (matchingValue) {
            segmentText = matchingValue.prompt;
          }
        } else {
          segmentText = segment.prompt;
        }

        if (segment.dependencies) {
          segment.dependencies.forEach((dependency) => {
            const input = inputValues.find(
              (input) => input.name === dependency
            );

            let inputVal = input.value;
            if(typeof input.value === 'object') {
              inputVal = input.value.value;
            }

            segmentText = segmentText.replaceAll(
              `[${dependency}]`,
              inputVal || ""
            );
          });
        }

        finalPrompt += segmentText + " ";
      });

      setConstructedPrompt(finalPrompt.trim());
    };

    useEffect(() => {
      const promptJSON = JSON.parse(prompt.versions[version].json);
      setPromptData(promptJSON);
      const promptInputValues = [];
      for (let key in promptJSON.prompt_values) {
        promptInputValues.push({
          name: key,
          value: promptJSON.prompt_values[key],
        });
      }
      setInputValues(promptInputValues);
      setPromptVersionStatus(prompt.versions[version].is_active);
    }, [prompt, version]);

    useEffect(() => {
      if (inputValues != null && promptData != null) {
        constructPrompt();
      }
    }, [inputValues, promptData]);

    return (
      <div className="row">
        <div className="col-md-8">
          <div>
            <Tabs
              value={version}
              onChange={handleVersionChange}
              textColor="secondary"
              className="mb-3"
              indicatorColor="secondary"
            >
              {prompt.versions.map((version, index) => (
                <Tab
                  key={version.id}
                  label={`Version ${index + 1}`}
                  {...a11yProps(0)}
                />
              ))}
              <Tab icon={<AddIcon />} />
            </Tabs>
            <div>
              <div className="d-flex flex-row justify-content-between align-items-center mb-4 mx-2">
                <h4>Segments</h4>
                <div>
                  <Switch
                    checked={prompt.versions[version].is_active == 1}
                    onChange={(_, val) => {
                      if (prompt.versions.length == 1 && !val) {
                        toast.warn(
                          "Unable to change status. At least one version must be active."
                        );
                        return;
                      }
                      onPromptSave(promptData, val);
                    }}
                  />
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      if (prompt.versions.length == 1) {
                        toast.warn(
                          "Unable to delete. At least one version must be retained."
                        );
                        return;
                      }
                      const activeVersionLeft = prompt.versions
                        .filter((v) => v.id != prompt.versions[version].id)
                        .some((v) => v.is_active === 1);
                      if (!activeVersionLeft) {
                        toast.warn(
                          "Unable to delete. This is the only active version left"
                        );
                        return;
                      }
                      onDeletePromptVesion();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
              {promptData &&
                promptData?.prompt_segments.map((segment, index) => {
                  if (typeof segment.prompt === "object") {
                    const customInput = segment.prompt.values.find(
                      (i) => i.value === inputValues.type
                    );
                    return (
                      <div className="mb-3" key={segment.id}>
                        <TextField
                          className="mb-3"
                          label={`Segment ${segment.id}`}
                          value={customInput?.prompt ?? ""}
                          multiline
                          style={{ width: "100%" }}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div className="mb-3" key={segment.id}>
                        <TextField
                          label={`Segment ${segment.id}`}
                          value={segment.prompt}
                          className="mb-3"
                          onChange={(e) =>
                            handlePromptChange(index, e.target.value)
                          }
                          multiline
                          style={{ width: "100%" }}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                onPromptSave(promptData, promptVersionStatus);
              }}
              variant="contained"
            >
              Save Changes
            </Button>
          </div>
        </div>
        <div className="col-md-4">
          <h4 className="mb-3">Input</h4>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
            {inputValues &&
              inputValues.map((input) => {
                let value = input.value;
                let allowedEditing = true;
                if(typeof input.value === "object") {
                  value = input.value.value;
                  allowedEditing = input.value.editable;
                }
                return (
                  <div key={input.name} style={{ flex: "1 1 48%" }}>
                    <TextField
                      name={input.name}
                      label={
                        input.name.charAt(0).toUpperCase() + input.name.slice(1)
                      }
                      disabled={!allowedEditing}
                      value={value}
                      onChange={handleInputChange}
                    />
                  </div>
                );
              })}
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h4>Preview</h4>
              <IconButton
                aria-label="run"
                onClick={() => setShowRunPrompt(true)}
              >
                <PlayCircleFilledWhiteOutlinedIcon fontSize="inherit" />
              </IconButton>
            </div>
            <div
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid #ddd",
                padding: "10px",
                wordBreak: "break-word"
              }}
            >
              {constructedPrompt}
            </div>

            <h4 className="mt-3">Result</h4>
            <PromptResultPreview
              prompt={constructedPrompt}
              runScript={showRunPrompt}
              setShowRunPrompt={setShowRunPrompt}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default function Prompts() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [versionIndex, setVersionIndex] = useState(0);
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getPrompts() {
    setLoading(true);
    try {
      const response = await api.get("/prompts");
      let { data: allPrompts } = response.data;
      allPrompts = allPrompts
        .map((prompt) => {
          return {
            ...prompt,
            versions: prompt.versions.sort((a, b) => a.id - b.id),
          };
        })
        .sort((a, b) => a.id - b.id);
      setPrompts(allPrompts);
    } catch (error) {
      //handle error
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPrompts();
  }, []);

  const activePrompt = useMemo(() => {
    return prompts[activeIndex];
  }, [prompts.length, activeIndex]);

  const onVersionChange = async (_, val) => {
    if (val >= 5) {
      toast.warn("Maximum limit reached: You can add up to 5 versions.");
      return;
    }

    if (prompts[activeIndex].versions.length === val) {
      const version = {
        ...prompts[activeIndex].versions[
          prompts[activeIndex].versions.length - 1
        ],
        is_active: 0,
        id: Math.random(),
      };

      setPrompts((previousPrompts) => {
        const updatedPrompts = [...previousPrompts];

        updatedPrompts[activeIndex].versions.push(version);
        return updatedPrompts;
      });
      setVersionIndex(val);

      const payload = {
        version_number: 1,
        prompt_id: version.prompt_id,
        json: version.json,
        is_active: 0,
      };
      const response = await handleAddNewPromptVersion(payload);

      if (response.status != 201) {
        toast.error("failed to add new version");
      } else {
        setPrompts((previousPrompts) => {
          const updatedPrompts = [...previousPrompts];
          updatedPrompts[activeIndex].versions[val] = response.data;
          return updatedPrompts;
        });
        toast.success("New version added successfully");
      }
      return;
    }
    setVersionIndex(val);
  };

  const handleAddNewPromptVersion = async (payload) => {
    const response = await api.post("/prompts/versions", payload);
    return response;
  };

  const handlePromptUpdate = async (promptData, status) => {
    const version = activePrompt.versions[versionIndex];
    const payload = {
      json: JSON.stringify(promptData),
      version_number: 1,
      is_active: status ? 1 : 0,
    };
    const response = await api.put(`/prompts/versions/${version.id}`, payload);
    if (response.status === 200) {
      setPrompts((previousPrompts) => {
        const updatedPrompts = [...previousPrompts];
        updatedPrompts[activeIndex].versions[versionIndex] = response.data;
        return updatedPrompts;
      });
      toast.success("Prompt segment updated successfully");
    } else {
      toast.error("Failed to update prompt segment");
    }
  };

  const handleDeletePromptVesion = async () => {
    const version = activePrompt.versions[versionIndex];
    const response = await api.delete(`/prompts/versions/${version.id}`);
    if (response.status == 200) {
      setVersionIndex(0);
      setPrompts((previousPrompts) => {
        const updatedPrompts = [...previousPrompts];
        updatedPrompts[activeIndex].versions = updatedPrompts[
          activeIndex
        ].versions.filter((v) => v.id != version.id);
        return updatedPrompts;
      });
    }
  };

  return (
    <div className="row col-md-12">
      <ToastContainer />
      {loading && <Spinner />}
      <div className="col-md-2">
        {prompts &&
          prompts.length > 0 &&
          prompts.map((prompt, index) => (
            <ListItemButton
              key={prompt.id}
              component="a"
              selected={index === activeIndex}
              onClick={() => {
                setVersionIndex(0);
                setActiveIndex(index);
              }}
            >
              <ListItemText
                primary={`${index + 1}. ${prompt.name}`}
                secondary={prompt.description}
              />
            </ListItemButton>
          ))}
      </div>
      <div className="col-md-10">
        {prompts && prompts.length > 0 && (
          <PromptEditor
            prompt={activePrompt}
            version={versionIndex}
            handleVersionChange={onVersionChange}
            onPromptSave={handlePromptUpdate}
            onDeletePromptVesion={handleDeletePromptVesion}
          />
        )}
      </div>
    </div>
  );
}
