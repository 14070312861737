import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import classes from "./FormModal.module.css";

function BrandCategoryFormModal({
  show,
  handleClose,
  handleAddBrandCategory,
  customerBrands,
}) {
  const brandCategories = useSelector((state) => state.brandCategories);
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");

  const handleAddBrandCategoryInfo = () => {
    const brandId = customerBrands.filter(
      (brand) => brand?.brand?.name === selectedBrand
    )[0]?.id;
    const payload = {
      category_name: categoryName,
      category_description: categoryDescription,
      brand_id: brandId,
    };

    handleAddBrandCategory(payload);
  };

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          Add Brand category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Category Name"
          // className="big"
          value={categoryName}
          onChange={(e) => setcategoryName(e.target.value)}
        />
        <p> </p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={2}
          cols={2}
          name="firstName"
          placeholder="Details for disambigution"
          // className="big"
          value={categoryDescription}
          onChange={(e) => setcategoryDescription(e.target.value)}
        />
        <p> </p>

        <Form.Group>
          <Form.Control
            as="select"
            value={selectedBrand}
            onChange={(e) => {
              setSelectedBrand(e.target.value);
            }}
            style={{ height: "50px", width: "330px", margin: "auto" }}
          >
            <option value="">select Brand </option>
            {customerBrands &&
              customerBrands.length !== 0 &&
              customerBrands.map((brand) => (
                <option value={brand?.brand?.name}>{brand?.brand?.name}</option>
              ))}
          </Form.Control>
        </Form.Group>

        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          disabled={brandCategories?.loading}
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          onClick={() => handleAddBrandCategoryInfo()}
        >
          {brandCategories?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div>Add</div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BrandCategoryFormModal;
