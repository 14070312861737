import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import classes from "./LLMIndicator.module.css";
import { LLM_OPTIONS } from "../../../utils/constant";

const LLMIndicator = ({ llm }) => {
  const llmName = LLM_OPTIONS.find((option) => option.value === llm)?.name;

  return (
    <div className={classes.container}>
      <InfoOutlinedIcon className="icon" />
      <span className={classes.truncate}>Generated by {llmName}</span>
    </div>
  );
};

export default LLMIndicator;
