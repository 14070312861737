import AiIcon from "../../../../assets/svgs/AIIcon";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import Spinner from "../../../../Component/Spinner/Spinner";
import { getAttDataWithDelta } from "../../../../utils/helpers";
import ReBarChart from "../../common/ReBarchart";

export default function PositiveAttributes({
  positiveAttGraphData,
  pastPositiveAttGraphData,
  loading,
  errors,
  summaries,
  getAttributesSummary,
}) {
  return (
    <div className="col-md-12 mt-4">
      <div className="card p-3 custCard">
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Positive Attributes</h5>
            </div>
            {positiveAttGraphData && positiveAttGraphData.length ? (
              <div>
                <ReBarChart
                  data={
                    getAttDataWithDelta(
                      positiveAttGraphData,
                      pastPositiveAttGraphData
                    ) || []
                  }
                  color="#C7F0EC"
                />
              </div>
            ) : (
              <EmptyView message="Data Not Available" />
            )}
          </div>
          <div className="col-md-4">
            <div className="graphGreyBg">
              <p className="mb-3 font14-600">
                Summary
                <button
                  onClick={() => {
                    getAttributesSummary("positive-attributes");
                  }}
                  className="btn-purple ms-2 text-white font-10"
                >
                  <AiIcon />
                  AI Generate
                </button>
              </p>
              <div className="graphsummary font-11">
                {loading?.positiveAttributes ? (
                  <Spinner />
                ) : summaries?.positiveAttributes ? (
                  <p className="mb-1"> {summaries?.positiveAttributes} </p>
                ) : errors?.positiveAttributes ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.positiveAttributes}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
