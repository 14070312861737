import { useState } from "react";
import { Form, Button } from "react-bootstrap";

import classes from "./ReportPrompt.module.css";
import ReportPromptPreview from "./ReportPromptPreview";

function ReportPrompt({
  startPrompt,
  endPrompt,
  setStartPrompt,
  setEndPrompt,
  onSubmit,
  isLoading,
}) {
  const [showPreview, setShowPreview] = useState(false);

  const prompt = `
    ${startPrompt}

    Raw string: <Raw string>

    ${endPrompt}
  `

  return (
    <>
      <ReportPromptPreview
        handleClose={() => setShowPreview(false)}
        show={showPreview}
        prompt={prompt}
      />
      <Form className={classes.form}>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Start Prompt</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={startPrompt}
            onChange={(e) => setStartPrompt(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>End Prompt</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={endPrompt}
            onChange={(e) => setEndPrompt(e.target.value)}
          />
        </Form.Group>
        <div className={classes.actions}>
          <Button
            variant="outline"
            disabled={isLoading}
            onClick={() => setShowPreview(true)}
          >
            Preview Prompt
          </Button>
          <Button onClick={onSubmit} disabled={isLoading}>
            Regenerate
          </Button>
        </div>
      </Form>
    </>
  );
}

export default ReportPrompt;
