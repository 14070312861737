import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

import MUIIconButton from "../../Component/UI/MUIIconButton/MUIIconButton";
import TextInput from "../../Component/UI/TextInput/TextInput";
import classes from "./Form.module.css";

export default function Form({ onSubmit, prompt, setPrompt, isLoading }) {
  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextInput
        fullWidth
        variant="outlined"
        placeholder="Ask AI a question or make a request..."
        value={prompt}
        className={classes.textInput}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <MUIIconButton
        loading={isLoading}
        variant="contained"
        sx={{ mx: 1 }}
        type="submit"
      >
        <SendOutlinedIcon />
      </MUIIconButton>
    </form>
  );
}
