import React from "react";
import { Form, Col, Row, Dropdown, Button, Spinner } from "react-bootstrap";

const LLMSelectionComponent = ({
  selectedCount,
  selectedItems,
  selectAll,
  options,
  showData,
  handleSelectAllChange,
  handleCheckChange,
  handleClickShow,
  handleClickReset,
  selectedOption,
  selectedOptionFirstShow,
  isCurrentUserAdmin,
  limitReached,
  remainingCalls,
  apiCallLimitToCurrentUser,
  currentUser,
  location,
  setLocation,
  isInputValid,
}) => {
  const isSpecialUser = currentUser?.userData?.isSpecialUser;
  return (
    <Form.Group as={Col} md="12">
      {selectedOption != "Brand Index Score v2" && (
        <Row className="mb-3">
          <Col md="4">
            <Form.Group>
              <Form.Label>Specify the location (Optional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g California, US"
                name="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="4">
          <Dropdown className="dropdownllms">
            <Dropdown.Toggle variant="default" id="dropdown-basic">
              <span className="dropdown-text lucnhbtn">
                {selectedCount ? (
                  <>
                    ({selectedCount})<span>Selected </span>
                  </>
                ) : (
                  <>
                    Select LLM<span>s</span>
                  </>
                )}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                width: "100%",
                marginTop: "0px",
                marginLeft: "-13px",
                borderRadius: "4px",
              }}
            >
              {selectedOptionFirstShow == "Discover" &&
                selectedOption != "Brand Index Score v2" && (
                  <>
                    <Form.Check
                      type="checkbox"
                      label="Select All"
                      className="mb-2 text-xxl data"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    <Dropdown.Divider />
                  </>
                )}

              {options.map((option) => (
                <Form.Check
                  key={option.name}
                  type="checkbox"
                  label={option.name}
                  disabled={
                    selectedOption === "Brand Index Score"
                      ? ![
                          "Claude",
                          "llama2_70b_chat",
                          "Gemini",
                        ].includes(option.value)
                      : ["Google-SGE", "GPT-4_Web"].includes(option.value)
                  }
                  className={`mb-2 customData`}
                  checked={selectedItems[option.value] || false}
                  onChange={(event) =>
                    handleCheckChange(option.value, event.target.checked)
                  }
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md="2">
          <Button
            type="button"
            name="firstName"
            placeholder="Your Brand/Product"
            className="height2 mb-3"
            style={{
              width: "-webkit-fill-available",
              backgroundColor: "#3dc863",
              color: "white",
            }}
            onClick={handleClickShow}
            disabled={
              showData ||
              selectedOption === "Dashboard and Reporting" ||
              (!isCurrentUserAdmin && !isSpecialUser && limitReached) ||
              ( !isInputValid)
            }
          >
            {showData ? (
              <div style={{ fontSize: "19px" }}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Please Wait...
              </div>
            ) : (
              <div>LAUNCH</div>
            )}
          </Button>
        </Col>

        <Col md="2">
          <Button
            type="button"
            name="firstName"
            placeholder="Your Brand/Product"
            className="height2 mb-3"
            style={{
              width: "-webkit-fill-available",
              backgroundColor: "#3dc863",
              color: "white",
            }}
            onClick={handleClickReset}
            disabled={selectedOption === "Dashboard and Reporting"}
          >
            RESET
          </Button>
        </Col>
        {!isCurrentUserAdmin && !isSpecialUser && (
          <Col style={{ marginTop: "13px" }} md="4">
            {limitReached ? (
              <p style={{ color: "red", fontSize: "1.1rem" }}>
                Daily Launch limit reached ! try again tomorrow.
              </p>
            ) : (
              <p style={{ color: "blue", fontSize: "1.1rem" }}>
                {`${remainingCalls} launch${
                  remainingCalls !== 1 ? "es" : ""
                } remaining out of ${apiCallLimitToCurrentUser} daily limit.`}
              </p>
            )}
          </Col>
        )}
      </Row>
    </Form.Group>
  );
};

export default LLMSelectionComponent;