import { useState, useEffect, useContext } from "react";
import { IconButton } from "@mui/material";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { Card, Container } from "react-bootstrap";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { fetchDataFromFirebase } from "../../DatabaseFirebase/firebaseService";
import { BrandIndexContext } from "../../context/brand-index-context";
import Spinner from "./../Spinner/Spinner";
import { AuthContext } from "../../context/auth-context";
import { createMarkdownTable } from "../../utils/helpers";
import BrandIndexHistoryItem from "./BrandIndexHistoryItem";
import classes from "./BrandIndexHistory.module.css";
import EmptyView from "../EmptyView/EmptyView";
import BrandIndexSectionHeader from "./BrandIndexSectionHeader";

function BrandIndexHistory() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const brandIndexCtx = useContext(BrandIndexContext);
  const { authUserEmail } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    fetchDataFromFirebase(getHistory, "brand-index", authUserEmail);

    async function getHistory(data) {
      if (!data) {
        setLoading(false);
        setHistory([]);
        return;
      }
      setLoading(false);
      let historyItems = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .sort((a, b) => b.date - a.date)
        .filter((historyItem) => historyItem.email === authUserEmail);

      let groupedHistory = Object.entries(groupObjectsByDate(historyItems)).map(
        ([date, items]) => ({
          date,
          data: items,
        })
      );
      setHistory(groupedHistory);
      brandIndexCtx.setHistory(groupedHistory);
    }
  }, []);

  function groupObjectsByDate(objects) {
    const grouped = {};
    objects.forEach((obj) => {
      const dateKey = new Date(obj.date).toISOString().split("T")[0];
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(obj);
    });
    return grouped;
  }

  function handleShowCategorizedHistory() {
    if (history.length === 0) return;
    if (brandIndexCtx.showHistory) {
      brandIndexCtx.setShowHistory({ show: false, type: "list" });
    } else {
      brandIndexCtx.setShowHistory({ show: true, type: "list" });
    }
  }

  function handleHistoryItemClick(hierarchy) {
    const normalizedArrayForMarkdown = hierarchy.data.map((prod) => [
      prod.name,
      prod.rank,
      prod.favorability,
      prod.score,
    ]);

    normalizedArrayForMarkdown.unshift([
      "Brand/Product",
      "Rank",
      "Favorability",
      "Brand Index Score",
    ]);
    const markTable = createMarkdownTable(normalizedArrayForMarkdown);

    brandIndexCtx.setHistoryItem({
      hierarchy,
      markTable,
    });
  }

  return (
    <Card className={classes.container}>
      <Card.Header
        className={`d-flex flex-row justify-content-between align-items-center p-3 bottom ${classes.header}`}
      >
        <strong>Brand Index History</strong>
        <IconButton
          aria-label="category"
          onClick={handleShowCategorizedHistory}
        >
          <CategoryOutlinedIcon />
        </IconButton>
      </Card.Header>
      <Container className={classes.section}>
        {loading && <Spinner />}
        {history.map((item) => {
          return (
            <div key={item.date}>
              <BrandIndexSectionHeader date={item.date} top={68} />
              {item.data.map((item, index) => {
                return (
                  <BrandIndexHistoryItem
                    item={item}
                    key={index}
                    onSelected={(item) => handleHistoryItemClick(item)}
                  />
                );
              })}
            </div>
          );
        })}
        {history.length === 0 && !loading ? (
          <EmptyView icon={<AccessTimeIcon />} message="Nothing here." />
        ) : null}
      </Container>
    </Card>
  );
}

export default BrandIndexHistory;
