import React, { useContext } from "react";
import { Skeleton } from "@mui/material";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import MUIList from "./../../../Component/UI/MUIList/MUIList";
import { DiscoveryContext } from "../discovery-context";

import classes from "./Sources.module.css";
import SourceItem from "./SourceItem";

function filterUniqueDomains(urls) {
  const domainMap = new Map();
  urls.forEach((url) => {
    try {
      const fullDomain = new URL(url).hostname.replace(/^www\./, "");
      const parts = fullDomain.split(".");
      const mainDomain = parts.slice(-2).join(".");
      if (!domainMap.has(mainDomain)) {
        domainMap.set(mainDomain, { src: mainDomain, items: [] });
      }
      if (
        fullDomain !== mainDomain &&
        !domainMap.get(mainDomain).items.some((item) => item.src === fullDomain)
      ) {
        domainMap.get(mainDomain).items.push({ src: fullDomain });
      }
    } catch (error) {}
  });
  return Array.from(domainMap.values()).map((domain) => ({
    src: domain.src,
    ...(domain.items.length > 0 ? { items: domain.items } : {}),
  }));
}

export default function Sources() {
  const discoveryCtx = useContext(DiscoveryContext);

  function LoadingSkeletion() {
    return (
      <div className="p-3">
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={index} width={"50%"} height={50} />
          ))}
      </div>
    );
  }

  const sources = filterUniqueDomains(discoveryCtx.sources).slice(0, 12);

  function renderLinks(link, index) {
    return <SourceItem key={Math.random()} index={index} item={link} />;
  }

  return (
    <MUICard
      title="Top Sources"
      variant="elevated"
      elevation={0}
      className={classes.card}
      titleClassName={classes.title}
    >
      {sources.length > 0 && (
        <MUIList title="" data={sources} render={renderLinks} />
      )}
      {sources.length == 0 && <LoadingSkeletion />}
    </MUICard>
  );
}
