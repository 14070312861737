import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import classes from "./FormModal.module.css";

function ProductCompetitorFormModal({
  show,
  handleClose,
  handleAddProductCompetitor,
  customerBrands,
  customerBrandProducts,
}) {
  const brandProducts = useSelector((state) => state.brandProducts);
  const [productName, setproductName] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedBrandProduct, setSelectedBrandProduct] = useState("");

  const handleAddProductCompetitorInfo = () => {
    const brandId = customerBrands.filter(
      (brand) => brand?.brand?.name === selectedBrand
    )[0]?.brand?.id;
    const brandProductId = customerBrandProducts.filter(
      (brand) => brand?.brand_product?.name === selectedBrandProduct
    )[0]?.id;
    const payload = {
      product_name: productName,
      product_description: productDescription,
      customer_brand_product_id: brandProductId,
      brand_id: brandId,
    };
    
    // handleAddProductCompetitor(payload);
  };

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          Add Competitor product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Product Name"
          // className="big"
          value={productName}
          onChange={(e) => setproductName(e.target.value)}
        />
        <p> </p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={2}
          cols={2}
          name="firstName"
          placeholder="Details for disambigution"
          // className="big"
          value={productDescription}
          onChange={(e) => setproductDescription(e.target.value)}
        />
        <p> </p>

        <Form.Group>
          <Form.Control
            as="select"
            value={selectedBrand}
            onChange={(e) => {
              setSelectedBrand(e.target.value);
            }}
            style={{ height: "50px", width: "330px", margin: "auto" }}
          >
            <option value="">select Brand </option>
            {customerBrands &&
              customerBrands.length !== 0 &&
              customerBrands.map((brand) => (
                <option value={brand?.brand?.name}>{brand?.brand?.name}</option>
              ))}
          </Form.Control>
        </Form.Group>

        <p> </p>
        <Form.Group>
          <Form.Control
            as="select"
            value={selectedBrandProduct}
            onChange={(e) => {
              setSelectedBrandProduct(e.target.value);
            }}
            style={{ height: "50px", width: "330px", margin: "auto" }}
          >
            <option value="">select Brand-Product </option>
            {customerBrandProducts &&
              customerBrandProducts.length !== 0 &&
              customerBrandProducts.map((brand) => (
                <option value={brand?.brand_product?.name}>
                  {brand?.brand_product?.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>

        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          disabled={brandProducts?.loading}
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          onClick={() => handleAddProductCompetitorInfo()}
        >
          {brandProducts?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div>Add</div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProductCompetitorFormModal;
