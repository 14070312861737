import { useEffect, useState, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router";
import { LinearProgress } from "@mui/material";

import Header from "./../../Component/Header/Header";
import classes from "./Discovery.module.css";
import BrandScore from "./BrandScore/BrandScore";
import CoreAttributes from "./CoreAttributes/CoreAttributes";
import Competitors from "./Competitors/Competitors";
import { DiscoveryContext } from "./discovery-context";
import Sources from "./Sources/Sources";
import TopProducts from "./TopProducts/TopProducts";
import Tabs from "./../../Component/UI/Tabs/Tabs";
import Form from "./Form";
import AIGenerated from "../../Component/AIGenerated/AIGenerated";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import CategoryDimensions from "./CategoryDimensions/CategoryDimensions";
import History from "./History/History";
import { useDailyLimits } from "../../hooks/useDailyLimits";
import PDFHeader from "../../Component/PDFHeader/PDFHeader";
import PDFFooter from "../../Component/PDFFooter/PDFFooter";
import Taxonomy from "./Taxonomy/Taxonomy";
import { toast } from "react-toastify";
import { getSingleDocFromFirebase } from "../../DatabaseFirebase/firebaseService";
import api from "../../BrandOnBoarding/api";

const TAB_OPTIONS = [
  {
    value: "overview",
    name: "Overview",
  },
  {
    value: "key_prompt",
    name: "Custom Prompt",
  },
];

export default function Discovery() {
  const [inputValues, setInputValues] = useState({
    brand: "",
    category: "",
    market: "",
    llm: "",
    type: "brand",
  });
  const [tabSelected, setTabSelected] = useState("overview");
  const [externalReportLoading, setExternalReportLoading] = useState(false);
  const { launchText, updateLaunches, launchesRemaining, isSpecial } =
    useDailyLimits("Discovery", 5);

  const discoveryCtx = useContext(DiscoveryContext);
  const abortControllerRef = useRef(null);
  const location = useLocation();

  const entity = discoveryCtx.entity;

  const pdfRef = useRef();

  async function getCompetitors() {
    const prompt = `
    You are a market research expert. For the given product category, provide the top 3 ${inputValues.type} in that category, excluding the specified ${inputValues.type}. Also include the specified ${inputValues.type} in the list. Your response must be in valid JSON format with the following structure:
    {
      "competitors": [
        {
          "name": "${inputValues.brand}",
          "domain": "domain.com"
        },
        {
          "name": "Top Competitor 1 Name",
          "domain": "topcompetitor1.com"
        },
        {
          "name": "Top Competitor 2 Name",
          "domain": "topcompetitor2.com"
        },
        {
          "name": "Top Competitor 3 Name",
          "domain": "topcompetitor3.com"
        }
      ]
    }

    Ensure that:
    - The JSON is properly formatted and valid.
    - The "competitors" array always contains exactly 4 items (including the provided ${inputValues.type} itself).
    - The first competitor is always the provided ${inputValues.type} with its exact name and the main website URL (without "http://" or "https://" prefixes).
    - Each competitor is represented by an object with "name" and "domain" keys.
    - The competitor names are strings and do not include any additional information.
    - The domain URLs are the main website addresses for each brand or product.
    - The top 3 competitors are the most prominent brands in the specified category, excluding the provided ${inputValues.type}.

    Product category to analyze: ${inputValues.category}
    Brand to exclude from top 3: ${inputValues.brand}`;

    abortControllerRef.current = new AbortController();

    const payload = {
      llm: inputValues.llm,
      prompt: prompt,
    };
    try {
      const response = await api.post(
        "/llm-execute",
        payload,
        { signal: abortControllerRef.current.signal }
      );
      const comp = response.data.data;
      discoveryCtx.updatedCompetitors(comp.competitors);
    } catch (error) {
      console.log("Error", error.message);
    }
  }

  function tabChangeHandler(val) {
    setTabSelected(val);
  }

  useEffect(() => {
    if (location.search && location.search.includes("?id=")) {
      const id = location.search.split("?id=")[1];
      loadDiscovery(id);
    }
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  async function loadDiscovery(id) {
    try {
      setExternalReportLoading(true);
      const item = await getSingleDocFromFirebase(id, "discovery_history");
      let coreAttributeSummary = item?.core_attributes?.summary ?? "";
      if (!coreAttributeSummary) {
        item?.core_attributes &&
          item?.core_attributes?.products &&
          item?.core_attributes?.products.map((product) => {
            coreAttributeSummary += product.summary;
            coreAttributeSummary += "\n\n";
          });
      }
      discoveryCtx.updateEntireState({
        ...item,
        id: id,
        core_attributes: {
          ...item.core_attributes,
          summary: coreAttributeSummary,
        },
        is_launched: true,
      });
    } catch (error) {
      toast.error("Failed to load discovery report. Please check if URL is correct")
    } finally {
      setExternalReportLoading(false);
    }
  }

  useEffect(() => {
    if (!inputValues.brand || !inputValues.category) return;
    getCompetitors();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [inputValues]);

  let coreAttributesSummary = "";
  let categoryDimensionsSummary = "";
  let productDimensionSummary = "";
  if (
    discoveryCtx?.core_attributes &&
    Object.keys(discoveryCtx?.core_attributes).length > 0
  ) {
    coreAttributesSummary = discoveryCtx.core_attributes.summary;
  }
  if (
    discoveryCtx?.category_dimensions &&
    Object.keys(discoveryCtx?.category_dimensions).length > 0
  ) {
    categoryDimensionsSummary = discoveryCtx.category_dimensions.summary;
  }

  if (
    discoveryCtx?.taxonomies &&
    Object.keys(discoveryCtx?.taxonomies).length > 0
  ) {
    productDimensionSummary = discoveryCtx.taxonomies.summary;
  }

  let competitors = discoveryCtx.competitors;

  const exportToPDF = useReactToPrint({
    contentRef: pdfRef,
    documentTitle: `${entity} Overview`,
    removeAfterPrint: true,
    pageStyle: `
      @page {
        size: 380mm 400mm;
        margin-left: 10mm;
        margin-right: 10mm;
        margin-bottom: 5mm;
        margin-top: 15mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact; /* Ensures accurate color printing */
        }
        .page-footer {
          position: fixed;
          bottom: 10mm;
          left: 10mm;
          right: 10mm;
          font-size: 10pt;
          color: #777;
          text-align: center;
        }
      }
    `,
  });

  const discoveryType = discoveryCtx.type || "brand";

  return (
    <>
      {externalReportLoading && <LinearProgress />}
      <section className={classes.content_section}>
        <Header actionText={launchText} actionVariant="text" />
        <div className={classes.container}>
          <div className={classes.content}>
            <Tabs
              options={[]}
              onChange={tabChangeHandler}
              selected={tabSelected}
            />
            <Form
              onSubmit={(values) => {
                if (launchesRemaining == 0 && !isSpecial) {
                  toast.warn("You have reached your daily limit");
                  return;
                }
                if (!isSpecial) updateLaunches();

                if (externalReportLoading) return;

                discoveryCtx.updateEntity(values.brand);
                discoveryCtx.updateMarket(values.market);
                discoveryCtx.updateCategory(values.category);
                discoveryCtx.updateLLM(values.llm);
                discoveryCtx.updateType(values.type);
                discoveryCtx.updateName(`${values.brand} Brand Overview`);
                discoveryCtx.updatedLaunched(true);
                setInputValues(values);
              }}
              onTypeChanged={(val) => {
                setInputValues((prevVals) => {
                  return {
                    ...prevVals,
                    type: val,
                  };
                });
              }}
            />
          </div>
          <div className={classes.history}>
            <History filter={inputValues.type} />
          </div>
        </div>
        {discoveryCtx.is_launched && (
          <div className={classes.result} id="print-pdf" ref={pdfRef}>
            <PDFHeader />
            <BrandScore
              category={inputValues.category}
              market={inputValues.market}
              entity={inputValues.brand}
            />
            <div className="page-break print-only" />
            <div className={classes.section}>
              {competitors.length > 0 && (
                <Competitors
                  category={inputValues.category}
                  entity={inputValues.brand}
                />
              )}
            </div>

            {discoveryType === "brand" && discoveryCtx?.core_attributes && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors.length > 0 && (
                    <CoreAttributes category={inputValues.category} />
                  )}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit
                    text={coreAttributesSummary}
                    loading={coreAttributesSummary === ""}
                    firebaseNode="core_attributes"
                  />
                </div>
              </div>
            )}

            {discoveryType === "product" && (
              <div className={classes.section_summary}>
                <div className={classes.flex_one}>
                  {competitors && competitors.length > 0 && <Taxonomy />}
                </div>
                <div className={classes.flex_one}>
                  <AIGenerated
                    edit
                    text={productDimensionSummary}
                    loading={productDimensionSummary === ""}
                    firebaseNode="taxonomies"
                  />
                </div>
              </div>
            )}

            <div className="page-break print-only" />
            <div className={classes.section_summary}>
              <div className={classes.flex_one}>
                {competitors && competitors.length > 0 && (
                  <CategoryDimensions category={inputValues.category} />
                )}
              </div>
              <div className={classes.flex_one}>
                <AIGenerated
                  edit
                  text={categoryDimensionsSummary}
                  loading={categoryDimensionsSummary === ""}
                  firebaseNode="category_dimensions"
                />
              </div>
            </div>

            {discoveryType == "brand" && (
              <div className={classes.section}>
                <TopProducts entity={inputValues.brand} />
              </div>
            )}

            <div className="page-break print-only" />
            <div className={classes.section}>
              <Sources />
            </div>

            <div className={`${classes.action_btn_group} no-print`}>
              <MUIButton>Add Monitoring</MUIButton>
              <MUIButton onClick={exportToPDF}>Export</MUIButton>
            </div>
            <PDFFooter />
          </div>
        )}
      </section>
    </>
  );
}
