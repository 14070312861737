import React, { useRef } from "react";

const DateRangePicker = ({ activeTarget, handleChange }) => {
  const navLinksRef = useRef({});

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default anchor behavior

    // Get the target from data attribute
    const target = event.currentTarget.getAttribute("data-target");

    // Set the clicked link as active
    handleChange(target);

    // Update the class names for active and inactive links
    Object.values(navLinksRef.current).forEach((link) => {
      link.classList.remove("active");
    });

    if (navLinksRef.current[target]) {
      navLinksRef.current[target].classList.add("active");
    }
  };

  return (
    <div className="col-md-4">
      <div className="mb-3 w-100 d-flex justify-content-between align-items-center">
        <div className="dmqGroup">
          <button
            className={`${activeTarget === "Current" ? "active" : ""}`}
            data-target="Current"
            ref={(el) => (navLinksRef.current["Current"] = el)}
            onClick={handleClick}
          >
            Current
          </button>
          <button
            className={`${activeTarget === "3Days" ? "active" : ""}`}
            data-target="3Days"
            ref={(el) => (navLinksRef.current["3Days"] = el)}
            onClick={handleClick}
          >
            3Days
          </button>
          <button
            className={`${activeTarget === "7Days" ? "active" : ""}`}
            data-target="7Days"
            ref={(el) => (navLinksRef.current["7Days"] = el)}
            onClick={handleClick}
          >
            7Days
          </button>
          <button
            className={`${activeTarget === "Month" ? "active" : ""}`}
            data-target="Month"
            ref={(el) => (navLinksRef.current["Month"] = el)}
            onClick={handleClick}
          >
            Month
          </button>
          <button
            className={`${activeTarget === "3Month" ? "active" : ""}`}
            data-target="3Month"
            ref={(el) => (navLinksRef.current["3Month"] = el)}
            onClick={handleClick}
          >
            3Month
          </button>
          {/* <button
            className={`${activeTarget === "Custom" ? "active" : ""}`}
            data-target="Custom"
            ref={(el) => (navLinksRef.current["Custom"] = el)}
            onClick={handleClick}
          >
            Custom
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
