import AccessTimeIcon from '@mui/icons-material/AccessTime';

function EmptyView({ icon = <AccessTimeIcon />, message }) {
    return (
        <div className="d-flex flex-column align-items-center py-4">
            <span>{icon}</span>
            <span className="mt-2">{message}</span>
        </div>
    )
}

export default EmptyView;