import { useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";

import VerifyUserForm from "./VerifyUserForm";
import api from "../BrandOnBoarding/api";

export default function VerifyUser() {
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const token = query.get("token");

  if (!token) {
    window.location.href = "/";
  }

  async function verifyAccountHandler(password) {
    try {
      const response = await api.patch(
        `/users/verify-and-reset?token=${token}`,
        {
          password: password,
        }
      );
      if (response.status === 200) {
        toast.success(
          "Email verification and Password reset has done successfully"
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } else {
        toast.error(
          `Unable to verify account and reset password. Try again later.`
        );
      }
    } catch (error) {
      console.log(error.message);
      toast.error(
        `Unable to verify account and reset password. ${error.message}`
      );
    }
  }

  return (
    <>
      <ToastContainer />
      <h3>Set New Password</h3>
      <p>
        Please enter new password for your account and verify your email address
        as well.
      </p>
      <VerifyUserForm onSubmit={verifyAccountHandler} />
    </>
  );
}
