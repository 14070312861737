import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { BsPlus, BsDash } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import {
  addDataToFirebase,
  fetchDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";
import {
  monitorPromptNamesForDB,
  monitoringPromptHeaders,
  monitoringPromptsBackup,
  childMonitoringPromptNamesForDB
} from "../../utils/constant";

const PromptCreationComponent = () => {
  const initialPrompts = {
    ...monitoringPromptsBackup
  };
  const [prompts, setPrompts] = useState(initialPrompts);
  const [documentIds, setDocumentIds] = useState(null);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedChildIndexes, setSelectedChildIndexes] = useState([]);

  const toggleExpandChild = (itemIndex) => {
    if (selectedChildIndexes.includes(itemIndex)) {
      const currentIndexes = selectedChildIndexes.filter(
        (index) => index !== itemIndex
      );
      setSelectedChildIndexes(currentIndexes);
    } else {
      setSelectedChildIndexes([...selectedChildIndexes, itemIndex]);
    }
  };

  useEffect(() => {
    fetchDataFromFirebase((data) => {
      if (data !== null) {
        const promptsData = Object.values(data);
        const ids = Object.keys(data);
        // setPrompts(initialPrompts);
        setPrompts(promptsData[0]);
        setDocumentIds(ids);
      } else {
        setPrompts(initialPrompts);
        setDocumentIds(null);
      }
    }, "MonitoringPrompts");
  }, []);

  const handleChangeForChild = (category, field, value) => {
    setPrompts((prevPrompts) => ({
      ...prevPrompts,
      [category]: {
        ...prevPrompts[category],
        [field]: value,
      },
    }));
  };

  const toggleExpand = (itemIndex) => {
    if (selectedIndexes.includes(itemIndex)) {
      const currentIndexes = selectedIndexes.filter(
        (index) => index !== itemIndex
      );
      setSelectedIndexes(currentIndexes);
    } else {
      setSelectedIndexes([...selectedIndexes, itemIndex]);
    }
    // setIsPreview(false);
  };

  const handleChange = (e) => {
    setPrompts({ ...prompts, [e.target.name]: e.target.value });
  };

  const handleSaveDataToDB = () => {
    const collectionName = "MonitoringPrompts";

    if (documentIds && documentIds.length !== 0) {
      updateDataInFirebase(collectionName, documentIds[0], prompts)
        .then(() => {
          toast.success("Prompts Updated successfully");
        })
        .catch((error) => {
          console.error("Error updating prompts:", error);
        });
    } else {
      addDataToFirebase(collectionName, prompts)
        .then(() => {
          toast.success("Prompts added successfully");
        })
        .catch((error) => {
          console.error("Error adding prompts:", error);
        });
    }
  };

  return (
    <div>
      <h5>Monitoring Prompt</h5>
      {monitoringPromptHeaders.map((mainObj, index) => {
        return (
          <>
            <div
              onClick={() => toggleExpand(index)}
              style={{
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <h6> {mainObj.title} </h6>
              {selectedIndexes.includes(index) ? <BsDash /> : <BsPlus />}
            </div>

            {selectedIndexes.includes(index) && (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  width: "80%",
                }}
              >
                {/* {mainObj.subItems.map((subItem, subItemIndex) => {
                  return (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <h8>{subItem.title} : </h8>
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name={monitrorPromptNamesForDB[subItem.title]}
                          value={
                            prompts[monitrorPromptNamesForDB[subItem.title]]
                          }
                          onChange={(e) => handleChange(e)}
                          placeholder="Add custom prompt"
                        />
                      </div>

                      {mainObj.title ===
                        "Custom Prompts for Company/Brand/Products" && <hr />}
                    </>
                  );
                })} */}

                {mainObj.subItems.map((subItem, subItemIndex) => {
                  return (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <h8>{subItem.title} : </h8>
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name={monitorPromptNamesForDB[subItem.title]}
                          value={
                            prompts[monitorPromptNamesForDB[subItem.title]]
                          }
                          onChange={(e) => handleChange(e)}
                          placeholder="Add custom prompt"
                        />
                      </div>
                      {subItem.subChecks.length !== 0 && (
                        <>
                          <div
                            onClick={() => toggleExpandChild(subItemIndex)}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #ccc",
                              padding: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              width: "95%",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                            }}
                          >
                            <h6> {`${subItem.title} Additional Checks`} </h6>
                            {selectedChildIndexes.includes(subItemIndex) ? (
                              <BsDash />
                            ) : (
                              <BsPlus />
                            )}
                          </div>

                          {selectedChildIndexes.includes(subItemIndex) && (
                            <div
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                width: "95%",
                                marginLeft: "10px",
                              }}
                            >
                              {subItem.subChecks.map((subCheck) => {
                                return (
                                  <div style={{ marginBottom: "10px" }}>
                                    <h8>{subCheck} : </h8>
                                    <br />
                                    <input
                                      style={{
                                        width: "100%",
                                      }}
                                      type="text"
                                      value={
                                        prompts[
                                          childMonitoringPromptNamesForDB[subItem.title]
                                        ][childMonitoringPromptNamesForDB[subCheck]]
                                      }
                                      onChange={(e) =>
                                        handleChangeForChild(
                                          childMonitoringPromptNamesForDB[subItem.title],
                                          childMonitoringPromptNamesForDB[subCheck],
                                          e.target.value
                                        )
                                      }
                                      placeholder="Add custom prompt"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      )}
                      {mainObj.title ===
                        "Custom Prompts for Company/Brand/Products" && <hr />}
                    </>
                  );
                })}

                <div>
                  <Button
                    style={{
                      width: "10%",
                      backgroundColor: "#3dc863",
                      color: "white",
                    }}
                    onClick={handleSaveDataToDB}
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    style={{
                      width: "10%",

                      color: "white",
                    }}
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </>
        );
      })}
      <ToastContainer />
    </div>
  );
};

export default PromptCreationComponent;
