import { useEffect } from "react";
import moment from "moment";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { fetchDataFromFirebase } from "./DatabaseFirebase/firebaseService";
import BrandTable from "./BrandTable";
import classes from "./BrandReport.module.css";
import Spinner from "./Component/Spinner/Spinner";
import { Button, Card, CardContent, Typography } from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 !important" }}>{children}</Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function groupBy(array, property) {
  return array.reduce((grouped, obj) => {
    const key = String(obj[property]).toLowerCase();
    if (!grouped[key]) grouped[key] = [];
    grouped[key].push(obj);
    return grouped;
  }, {});
}

export default function BrandReport() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [reportData, setReportData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(dayjs("2024-06-25"));
  const [toDate, setToDate] = React.useState(dayjs("2024-07-09"));

  const [firebaseData, setFirebaseData] = React.useState([]);
  const [categorizeBy, setCategorizeBy] = React.useState("category");

  useEffect(() => {
    setLoading(true);
    fetchDataFromFirebase(
      getHistory,
      "brand-index",
      "kiran.koravi@buzzybrains.com"
    );

    async function getHistory(data) {
      setLoading(true);
      let historyItems = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .sort((a, b) => b.date - a.date);

      setFirebaseData(historyItems);
      setLoading(false);
    }
  }, []);

  function onFilter(from, to) {
    const newData = [...firebaseData]
      .filter(
        (item) =>
          moment(item.date) >= moment(new Date(from)) &&
          moment(item.date) < moment(new Date(to))
      )
      .map((item) => {
        return {
          ...item,
          data: item.data.map((p) => ({ ...p, llm: item.llm })),
        };
      });
    const groupedByCategory = groupBy(newData, categorizeBy);
    let revised = {};
    for (let key in groupedByCategory) {
      revised[key] = groupedByCategory[key].map((i) => i.data);
    }

    console.log(
      "LOG r",
      newData
        .filter((item) => item.llm === "gpt-4o")
        .filter((item) => {
          return item.data.findIndex((p) => p.name === "Tesla Cybertruck") > -1;
        })
    );

    setReportData(revised);
  }

  useEffect(() => {
    const from = fromDate.format("YYYY-MM-DD");
    const to = toDate.format("YYYY-MM-DD");
    if (firebaseData.length > 0) onFilter(from, to);
  }, [categorizeBy, firebaseData]);

  // useEffect(() => {
  //   if (firebaseData.length > 0) onFilter("2024-06-25", "2024-07-09");
  // }, [firebaseData]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box
      className="container container-fluid"
      style={{ width: 1600, minWidth: 1600, padding: "20px 0" }}
    >
      <div className="d-flex flex-row justify-content-between">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label="From"
              value={fromDate}
              onChange={(val) => setFromDate(val)}
            />
            <DatePicker
              label="To"
              value={toDate}
              onChange={(val) => setToDate(val)}
            />
            <Button
              variant="contained"
              sx={{ height: 40, top: 5, position: "relative" }}
              size="small"
              onClick={() => {
                const from = fromDate.format("YYYY-MM-DD");
                const to = toDate.format("YYYY-MM-DD");

                onFilter(from, to);
              }}
            >
              Submit
            </Button>
          </DemoContainer>
        </LocalizationProvider>
        <Card sx={{ minWidth: 190 }}>
          <CardContent
            style={{
              padding: "5px 10px",
              marginBottom: "8px",
            }}
          >
            <Typography
              sx={{ fontSize: 12 }}
              className="mb-2"
              color="text.secondary"
            >
              Group By
            </Typography>
            <div className="d-flex flex-row justify-content-between">
              <Button
                onClick={() => setCategorizeBy("category")}
                variant={categorizeBy === "category" ? "contained" : `outlined`}
                size="small"
              >
                Category
              </Button>
              <Button
                onClick={() => setCategorizeBy("llm")}
                variant={categorizeBy === "llm" ? "contained" : `outlined`}
                size="small"
              >
                LLM
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      <hr />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {Object.entries(reportData).map((item) => {
            const [key, data] = item;
            const groupedData = {};
            data.flat().forEach((item) => {
              if (!item) return;
              if (!groupedData[item.name]) {
                groupedData[item.name] = [];
              }
              groupedData[item.name].push({
                favorability: item.favorability,
                rank: item.rank,
                score: item.score,
              });
            });

            return (
              <Tab
                label={`${key} (${Object.entries(groupedData).length})`}
                key={key}
                {...a11yProps(0)}
              />
            );
          })}
        </Tabs>
      </Box>
      {Object.entries(reportData).map((x, i) => {
        const [key, data] = x;
        const groupedData = {};
        data.flat().forEach((item) => {
          if (!item) return;
          if (!groupedData[item.name]) {
            groupedData[item.name] = [];
          }
          groupedData[item.name].push({
            favorability: item.favorability,
            rank: item.rank,
            score: item.score,
            llm: item.llm,
          });
        });
        return (
          <CustomTabPanel value={value} index={i} key={i}>
            <div className={classes.container}>
              {Object.entries(groupedData).map((item) => {
                const [productName, brandIndexScores] = item;
                brandIndexScores.sort((a, b) => a.llm.localeCompare(b.llm));
                return (
                  <div className={classes.item} key={productName}>
                    <BrandTable name={productName} data={brandIndexScores} />
                  </div>
                );
              })}
            </div>
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}
