import React, { useState, useEffect } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { BsPlus, BsDash } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import {
  addDataToFirebase,
  fetchDataFromFirebase,
  updateDataInFirebase,
} from "../../DatabaseFirebase/firebaseService";
import {
  promptNamesForDB,
  childPromptNamesForDB,
  llms,
  promptsBackup,
} from "../../utils/constant";
import { layout } from "../LayoutComponent/LayoutComponent";
import ReportPrompt from "./ReportPrompt";
import MonitoringPromptComponent from "./MonitoringPromptComponent";
import DashboardPromptsComponent from "./DashboardPromptComponent";
import BackgroundJobPrompt from "./BackgroundJobPrompt";
// import { promptsBackup } from "../../utils/constant";

const PromptCreationComponent = () => {
  const initialPrompts = {
    ...promptsBackup,
  };
  const [prompts, setPrompts] = useState(initialPrompts);
  const [selectedItem, setSelectedItem] = useState("llama2_70b_chat");
  const [documentIds, setDocumentIds] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedChildIndexes, setSelectedChildIndexes] = useState([]);

  const options = [
    // { name: "GPT-4 Turbo (OpenAI)", value: "gpt_4_turbo" },
    // { name: "Palm2 (Google/Bard)", value: "palm2_text" },
    { name: "Llama2 (Meta)", value: "llama2_70b_chat" },
    { name: "GPT-4 (OpenAI/Microsoft)", value: "GPT4" },
    { name: "Gemini (Google)", value: "Gemini" },
    // { name: "GPT-4 Web Browsing", value: "GPT-4_Web" },
    { name: "Claude3 (Anthropic)", value: "Claude" },
  ];

  const promptHeaders = [
    {
      title: "Global Start and End Prompts",
      subItems: [
        { title: "Start prompt with", subChecks: [] },
        { title: "End prompt with", subChecks: [] },
      ],
    },
    {
      title: "Custom Key Prompts",
      subItems: [
        { title: "Key Prompt Prefix", subChecks: [] },
        { title: "Key Prompt Suffix", subChecks: [] },
        { title: "Key Prompt Sources", subChecks: [] },
      ],
    },
    {
      title: "Custom Prompts for Company/Brand/Products",
      subItems: [
        {
          title: "Brand/Product Overview",
          subChecks: [
            "Brand Description",
            "Brand Attributes",
            "Product Description",
            "Product Attributes",
            "Competitive Set",
            "Sources",
          ],
        },
        {
          title: "Brand/Product Favorability",
          subChecks: [
            "Top 5 Positive and Negative Attributes",
            "Top 5 Positive and Negative Statements",
            "Competitor Comparison",
            "Sources",
          ],
        },
        {
          title: "Brand Category Dimensions",
          subChecks: [
            "Frequently recommended buying criteria/considerations",
            "Sources",
          ],
        },
        {
          title: "Category Leadership",
          subChecks: [
            "Top 5 Brands in Category",
            "Best Brands in Category",
            "Sources",
          ],
        },
        {
          title: "Product Dimensions",
          subChecks: [
            "Frequently recommended buying criteria/considerations",
            "Sources",
          ],
        },
        {
          title: "Product Reach",
          subChecks: [
            "Top 5 Products in Category",
            "Best Products in Category",
            "Sources",
          ],
        },
        {
          title: "Competition",
          subChecks: [
            "Competitors",
            "Brand/Product Description",
            "Top 5 Positive and Negative Attributes",
            "Top 5 Positive and Negative Statements",
            "Top Positive and Negative Facts",
          ],
        },
      ],
    },
  ];

  const handleRadioChange = (value) => {
    setSelectedItem(value);
  };

  useEffect(() => {
    fetchDataFromFirebase((data) => {
      if (data !== null) {
        const promptsData = Object.values(data);
        const ids = Object.keys(data);
        setPrompts(promptsData[0]);
        // setPrompts(initialPrompts);
        setDocumentIds(ids);
      } else {
        setPrompts(initialPrompts);
        setDocumentIds(null);
      }
    }, llms[selectedItem]);
  }, [selectedItem]);

  const toggleExpand = (itemIndex) => {
    if (selectedIndexes.includes(itemIndex)) {
      const currentIndexes = selectedIndexes.filter(
        (index) => index !== itemIndex
      );
      setSelectedIndexes(currentIndexes);
    } else {
      setSelectedIndexes([...selectedIndexes, itemIndex]);
    }
    setIsPreview(false);
  };

  const getPromptsForAdditionalChecks = (title) => {
    let initialPromptObj = initialPrompts[childPromptNamesForDB[title]];
    let currentPromptObj = prompts[childPromptNamesForDB[title]];
    const reorderedObject = {};

    for (const key of Object.keys(initialPromptObj)) {
      reorderedObject[key] = currentPromptObj[key];
    }

    let promptForChecks = Object.values(reorderedObject);
    promptForChecks = promptForChecks
      .filter((item) => {
        return item !== "";
      })
      .join(",");

    return promptForChecks;
  };

  const toggleExpandChild = (itemIndex) => {
    if (selectedChildIndexes.includes(itemIndex)) {
      const currentIndexes = selectedChildIndexes.filter(
        (index) => index !== itemIndex
      );
      setSelectedChildIndexes(currentIndexes);
    } else {
      setSelectedChildIndexes([...selectedChildIndexes, itemIndex]);
    }
    setIsPreview(false);
  };

  const handleChange = (e) => {
    setPrompts({ ...prompts, [e.target.name]: e.target.value });
  };

  const handleChangeForChild = (category, field, value) => {
    setPrompts((prevPrompts) => ({
      ...prevPrompts,
      [category]: {
        ...prevPrompts[category],
        [field]: value,
      },
    }));
  };

  const handleIsPreview = () => {
    setIsPreview(true);
  };

  const handleIsPreviewCancel = () => {
    setIsPreview(false);
  };

  const handleSaveDataToDB = () => {
    const selectedLLM = llms[selectedItem];

    if (documentIds && documentIds.length !== 0) {
      updateDataInFirebase(selectedLLM, documentIds[0], prompts)
        .then(() => {
          toast.success("Prompts Updated successfully");
        })
        .catch((error) => {
          console.error("Error updating prompts:", error);
        });
    } else {
      addDataToFirebase(selectedLLM, prompts)
        .then(() => {
          toast.success("Prompts added successfully");
        })
        .catch((error) => {
          console.error("Error adding prompts:", error);
        });
    }
  };

  let keyPromptMessage = `${prompts.globalStartPrompt} ${prompts.keyPromptPrefix} <User Entered Prompt> ${prompts.keyPromptSuffix}..${prompts.keyPromptSources}.. ${prompts.globalEndPrompt}`;
  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <h5>Choose a LLM:</h5>
      <Dropdown
        style={{
          width: "30%",
        }}
        className="dropdownllms"
      >
        <Dropdown.Toggle variant="default" id="dropdown-basic">
          <span className="dropdown-text lucnhbtn">
            {selectedItem ? (
              <>{selectedItem}</>
            ) : (
              <>
                Select LLM<span>s</span>
              </>
            )}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            width: "103%",
            marginTop: "0px",
            marginLeft: "-13px",
            borderRadius: "4px",
          }}
        >
          {options.map((option, index) => (
            <Form.Check
              key={option.value}
              type="radio"
              label={option.name}
              className={`mb-2 customData`}
              checked={selectedItem === option.value}
              onChange={() => handleRadioChange(option.value)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {promptHeaders.map((mainObj, index) => {
        return (
          <>
            <div
              onClick={() => toggleExpand(index)}
              style={{
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <h6> {mainObj.title} </h6>
              {selectedIndexes.includes(index) ? <BsDash /> : <BsPlus />}
            </div>

            {selectedIndexes.includes(index) && (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  width: "80%",
                }}
              >
                {mainObj.subItems.map((subItem, subItemIndex) => {
                  let promptMessage = "";

                  return (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <h8>{subItem.title} : </h8>
                        <br />
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          name={promptNamesForDB[subItem.title]}
                          value={prompts[promptNamesForDB[subItem.title]]}
                          onChange={(e) => handleChange(e)}
                          placeholder="Add custom prompt"
                        />
                      </div>
                      {subItem.subChecks.length !== 0 && (
                        <>
                          <div
                            onClick={() => toggleExpandChild(subItemIndex)}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #ccc",
                              padding: "10px",
                              marginBottom: "10px",
                              display: "flex",
                              width: "95%",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                            }}
                          >
                            <h6> {`${subItem.title} Additional Checks`} </h6>
                            {selectedChildIndexes.includes(subItemIndex) ? (
                              <BsDash />
                            ) : (
                              <BsPlus />
                            )}
                          </div>

                          {selectedChildIndexes.includes(subItemIndex) && (
                            <div
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                width: "95%",
                                marginLeft: "10px",
                              }}
                            >
                              {subItem.subChecks.map((subCheck) => {
                                let promptForChecks =
                                  getPromptsForAdditionalChecks(subItem.title);
                                promptMessage = `${
                                  prompts.globalStartPrompt
                                } <User Entered Brand/Product/category or competitors>.${
                                  prompts[promptNamesForDB[subItem.title]]
                                } ${
                                  subItem.title === "Brand/Product Overview" ||
                                  subItem.title ===
                                    "Brand/Product Favorability" ||
                                  ""
                                  // ||
                                  // subItem.title === "Competition"
                                  // ? "<User Entered Brand/Product/category or competitors>."
                                  // : ""
                                } ${promptForChecks} ${
                                  prompts.globalEndPrompt
                                }`;

                                return (
                                  <div style={{ marginBottom: "10px" }}>
                                    <h8>{subCheck} : </h8>
                                    <br />
                                    <input
                                      style={{
                                        width: "100%",
                                      }}
                                      type="text"
                                      value={
                                        prompts[
                                          childPromptNamesForDB[subItem.title]
                                        ][childPromptNamesForDB[subCheck]]
                                      }
                                      onChange={(e) =>
                                        handleChangeForChild(
                                          childPromptNamesForDB[subItem.title],
                                          childPromptNamesForDB[subCheck],
                                          e.target.value
                                        )
                                      }
                                      placeholder="Add custom prompt"
                                    />
                                  </div>
                                );
                              })}
                              <Button
                                style={{
                                  width: "21%",
                                  backgroundColor: isPreview ? "" : "#3dc863",
                                  color: "white",
                                }}
                                variant={isPreview ? "danger" : ""}
                                onClick={
                                  isPreview
                                    ? handleIsPreviewCancel
                                    : handleIsPreview
                                }
                              >
                                {isPreview ? "Close Preview" : "Prompt Preview"}
                              </Button>
                              <br /> <br />
                              {isPreview && (
                                <textarea
                                  value={promptMessage}
                                  rows={5}
                                  cols={93}
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {mainObj.title ===
                        "Custom Prompts for Company/Brand/Products" && <hr />}
                    </>
                  );
                })}
                {mainObj.title === "Custom Key Prompts" && (
                  <div>
                    <Button
                      style={{
                        width: "21%",
                        backgroundColor: isPreview ? "" : "#3dc863",
                        color: "white",
                      }}
                      variant={isPreview ? "danger" : ""}
                      onClick={
                        isPreview ? handleIsPreviewCancel : handleIsPreview
                      }
                    >
                      {isPreview ? "Close Preview" : "Prompt Preview"}
                    </Button>
                    <br /> <br />
                    {isPreview && (
                      <textarea value={keyPromptMessage} rows={5} cols={93} />
                    )}
                    <hr />
                  </div>
                )}

                {/* <br /> */}
                <div>
                  <Button
                    style={{
                      width: "10%",
                      backgroundColor: "#3dc863",
                      color: "white",
                    }}
                    onClick={handleSaveDataToDB}
                  >
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    style={{
                      width: "10%",

                      color: "white",
                    }}
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </>
        );
      })}
      <ToastContainer />
      <hr />
      <MonitoringPromptComponent />
      <hr />
      <DashboardPromptsComponent />
      <hr />
      <ReportPrompt LLMs={options} />
      <hr />
      <BackgroundJobPrompt />
    </div>
  );
};

export default layout(PromptCreationComponent, true);
