import React, { useState, useRef, useContext, useMemo } from "react";
import { useNavigate } from "react-router";

import useQuery from "../Component/devDashboard2/common/useQuery";
import MUIButton from "../Component/UI/MUIButton/MUIButton";

import { AuthContext } from "../context/auth-context";
import { useDispatch } from "react-redux";
import { Button, Popover, Typography } from "@mui/material";
import Sidebar from "../Component/Sidebar/Sidebar";

const Layout = ({ children }) => {
  const { clearSession } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const isBrand = query.get("brandId");
  const isProduct = query.get("brandProductId");
  const initialTarget = isBrand
    ? "brand-monitoring"
    : isProduct
    ? "product-monitoring"
    : "home";

  const [activeTarget, setActiveTarget] = useState(initialTarget);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Memoize the active component to prevent unnecessary re-renders
  const activeComponent = useMemo(() => {
    return React.Children.toArray(children).find(
      (child) => child.props.target === activeTarget
    );
  }, [activeTarget, children]);

  const handleClickpop = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    clearSession();
    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <main>
      <div className="sidepanel d-flex flex-column flex-shrink-0 bg-light">
        <div style={{ borderBottom: "0.5px solid #B7C3CD", height: "70px" }}>
          <a
            href="#"
            className="p-3 d-flex align-items-center link-dark text-decoration-none"
            style={{ cursor: "pointer", marginLeft: "18px", height: "100%" }}
          >
            <img
              // src={require("../assets/images/logo.png")}
              src={"/menu.png"}
              width={26}
              height={26}
              alt="Brand-Luminaire"
            />
            <span className="brand"> Brand Luminaire </span>
          </a>
        </div>
        <Sidebar />
      </div>
      <div className="main-content">
        <header>
          <div className="d-flex align-items-center justify-content-end mt-2">
            {/* <MUIButton
              className="mx-4"
              onClick={() => navigate("/")} // Use navigate instead of window.location.href
            >
              Switch to Old UI
            </MUIButton> */}

            <span className="me-4">
              <i className="feather icon-headphones"></i>
            </span>
            <span className="me-4">
              <i className="feather icon-bell"></i>
            </span>
            <span className="me-4">
              <div
                aria-describedby={id}
                onClick={handleClickpop}
                style={{ color: "#000" }}
              >
                <i
                  alt=""
                  className="feather icon-user"
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                  id="profileImage"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <Popover
                style={{ cursor: "pointer" }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography sx={{ p: 2 }} onClick={handleLogOut}>
                  Sign Out
                </Typography>
              </Popover>
            </span>
          </div>
        </header>
        {children}
      </div>
    </main>
  );
};

export default Layout;
