import { useState } from "react";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";

import api from "../../../BrandOnBoarding/api";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./HistoryItem.module.css";

export default function HistoryItem({ item, onDelete, onSelect, onError }) {
  const [loading, setLoading] = useState(false);

  async function deleteHistory() {
    try {
      setLoading(true);
      onError(null);
      const response = await api.delete(`/llm-conversations/${item.id}`);
      if (response.data.status === 200) {
        onDelete(item.id);
      } else {
        onError("Failed to delete history item.")
      }
    } catch (error) {
      onError("Failed to delete history item.")
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={classes.container} onClick={() => onSelect(item)}>
      <div className={classes.content_wrapper}>
        <AccessTimeOutlinedIcon className={classes.icon} />
        <div className={classes.content}>
          <span className={classes.heading}>{item.title}</span>
          <span className={classes.date}>
            {moment(item.updated_at).fromNow()}
          </span>
        </div>
      </div>
      <MUIIconButton
        loading={loading}
        disabled={loading}
        onClick={(e) => {
          e.stopPropagation();
          deleteHistory();
        }}
      >
        <DeleteOutlineIcon />
      </MUIIconButton>
    </div>
  );
}
