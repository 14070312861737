import React, { useState, useEffect, useContext } from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchBrands,
  createBrand,
  updateBrand,
} from "../../redux/actions/brandsActions";

import {
  fetchCompetitorBrands,
  createCompetitorBrand,
} from "../../redux/actions/competitorBrandActions";
import {
  fetchCompetitorProducts,
  createCompetitorBrandProduct,
} from "../../redux/actions/competitorProductActions";
import {
  createBrandCategory,
  fetchBrandCategories,
} from "../../redux/actions/brandCategoriesActions";
import {
  fetchBrandProducts,
  createBrandProduct,
  updateBrandProduct,
} from "../../redux/actions/brandProductsActions";
import {
  createPersona,
  fetchPersonas,
  updatePersona,
} from "../../redux/actions/personasActions";

import {
  fetchProductCategories,
  createProductCategory,
} from "../../redux/actions/productCategoriesActions";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import { layout } from "../common/LayoutComponent.js/Layout";
import BrandComponent from "./Component/BrandComponent";
import BrandCategoryComponent from "./Component/BrandCategoryComponent";
import ProductComponent from "./Component/ProductComponent";
import PersonasComponent from "./Component/PersonasComponent";
import CompetitorBrandsComponent from "./Component/CompetitorBrandsComponent";
import CompetitorProductsComponent from "./Component/CompetitorProductsComponent";
import ProductCategoryComponent from "./Component/ProductCategoryComponent";
import FormModal from "../forms/FormModal";
import BrandCategoryFormModal from "../forms/BrandCategoryFormModal";
import BrandCompetitorFormModal from "../forms/BrandCompetitorComponent";
import BrandProductFormModal from "../forms/BrandProductFormModal";
import ProductCompetitorFormModal from "../forms/ProductCompetitorFormModal";
import ProductCategoryFormModal from "../forms/ProductCategoryFormModal";
import PersonaFormModal from "../forms/PersonaFormModal";
import Users from "../Users/Users";
import AddCompanyUserForm from "./../../Admin/AddCompanyUserForm";
import Modal from "../../Component/Modal/Modal";
import { AuthContext } from "./../../context/auth-context";
import { addUser } from "../../redux/actions/userActions";
import {
  fetchCustomerLlms,
  generateBrandMetrics,
  generateProductMetrics,
  getGeographies,
} from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import Metrics from "./Component/Metrics";
import { getLlms, getMarkets } from "../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeoMarketsComponent from "./Component/GeoMarketsComponent";
import GeoMarketFormModal from "../forms/GeoMarketFormModal";
import {
  fetchGeoMarkets,
  createGeoMarket,
} from "../../redux/actions/geoMarketsActions";
import Controller from "./Component/MonitoringControllers/Controller";

const SettingsListItem = ({
  item,
  isOpen,
  onClick,
  openItem,
  customerBrands,
  customerBrandProducts,
  customerLlms,
  personas,
  llms,
  markets,
  geographies,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;
  const { addUserError, adduserSuccess } = useSelector((state) => state.user);

  const handleSelectedData = (data) => {
    setSelectedData(data);
    setIsUpdate(true);
    setShowModal(true);
  };

  const handleAddNewBrand = (brandInfo) => {
    const llm = customerLlms?.data.filter(
      (obj) => obj.llm_id == 1 && obj.market_id == 1
    )[0]?.id;

    dispatch(createBrand(customerId, brandInfo))
      .then((response) => {
        const customerBrandPersonaId = response?.data?.newBrandPersona[0]?.id;

        dispatch(fetchBrands(customerId));
        // setShowModal(false);
        if (customerBrandPersonaId) {
          setIsInProgress(true);
          generateBrandMetrics(llm, customerBrandPersonaId)
            .then(() => {
              navigate(
                `/brandonboarding/dashboard?brandId=${response?.data?.newBrand?.id}&isDetailPage=1`
              );
              setShowModal(false);
              setIsInProgress(false);
            })
            .catch((error) => {
              console.error("Error generating brand metrics:", error);
            });
        } else {
          console.error("Failed to get newBrandPersona ID");
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
        setShowModal(false);
        console.error("Error in handleAddNewBrand:", error);
      });
  };

  const handleUpdateBrand = (brandId, brandInfo) => {
    dispatch(updateBrand(customerId, brandId, brandInfo))
      .then(() => {
        setShowModal(false);
        dispatch(fetchBrands(customerId));
      })
      .catch((error) => {
        // console.log('err==>', error)
        setShowModal(false);
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };

  const handleAddNewPersona = (personaInfo) => {
    dispatch(createPersona(customerId, personaInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchPersonas(customerId));
    });
  };

  const handleUpdatePersona = (personaId, personaInfo) => {
    dispatch(updatePersona(customerId, personaId, personaInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchPersonas(customerId));
    });
  };

  const handleAddBrandCategory = (categoryInfo) => {
    dispatch(createBrandCategory(1, categoryInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchBrandCategories(1));
    });
  };

  const handleAddBrandCompetitor = (competitorBrandInfo) => {
    dispatch(createCompetitorBrand(1, competitorBrandInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchCompetitorBrands(1));
    });
  };

  // const handleAddBrandProduct = (brandProductInfo) => {
  //   dispatch(createBrandProduct(customerId, brandProductInfo)).then(() => {
  //     setShowModal(false);
  //     dispatch(fetchBrandProducts(customerId));
  //   });
  // };

  const handleAddBrandProduct = (brandProductInfo) => {
    const llm = customerLlms?.data.filter(
      (obj) => obj.llm_id == 1 && obj.market_id == 1
    )[0]?.id;
    dispatch(createBrandProduct(customerId, brandProductInfo))
      .then((response) => {
        dispatch(fetchBrandProducts(customerId));
        const customerBrandProductPersonaId =
          response?.data?.newBrandProductPersona[0]?.id;

        if (customerBrandProductPersonaId) {
          setIsInProgress(true);
          generateProductMetrics(llm, customerBrandProductPersonaId)
            .then(() => {
              navigate(
                `/brandonboarding/dashboard?brandProductId=${response?.data?.newBrandProduct?.id}&isDetailPage=1`
              );
              setShowModal(false);
              setIsInProgress(false);
            })
            .catch((error) => {
              console.error(
                "Error generating brand product metrics:",
                error?.message
              );
            });
        } else {
          console.error("Failed to get newBrandProductPersona ID");
        }
      })
      .catch((error) => {
        dispatch(fetchBrandProducts(customerId));
        setShowModal(false);
        setIsInProgress(false);
        console.error("Error in handleAddNewBrandProduct:", error);
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };

  const handleUpdateBrandProduct = (brandProductId, brandProductInfo) => {
    dispatch(updateBrandProduct(customerId, brandProductId, brandProductInfo))
      .then(() => {
        setShowModal(false);
        dispatch(fetchBrandProducts(authCtx?.user?.customer_id || 0));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };

  const handleAddProductCompetitor = (brandProductInfo) => {
    dispatch(createCompetitorBrandProduct(1, brandProductInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchCompetitorProducts(1));
    });
  };

  const handleAddProductCategory = (categoryInfo) => {
    dispatch(createProductCategory(1, categoryInfo)).then(() => {
      setShowModal(false);
      dispatch(fetchProductCategories(1));
    });
  };

  const handleAddGeoMarkets = (geoMarkets) => {
    dispatch(createGeoMarket(customerId, geoMarkets))
      .then(() => {
        dispatch(fetchGeoMarkets(customerId));
        setShowModal(false);
      })
      .catch((error) => {
        setShowModal(false);
        dispatch(fetchGeoMarkets(customerId));
        toast.error(
          error?.response?.data?.message || "something went wrong !",
          {
            autoClose: 700,
          }
        );
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (adduserSuccess || addUserError) {
      setShowModal(false);
    }
  }, [adduserSuccess, addUserError]);

  const addUserHandler = async (_, email) => {
    const payload = {
      email: email,
      password: 123456, //temp password
      customer_id: authCtx.user.customer_id,
      role_id: 3,
    };
    dispatch(addUser(authCtx.user, payload));
  };

  const tableMapping = {
    Brands: BrandComponent,
    "Brand competitors": CompetitorBrandsComponent,
    "Brand category": BrandCategoryComponent,
    Products: ProductComponent,
    Personas: PersonasComponent,
    "Product Competitors": CompetitorProductsComponent,
    "Product category": ProductCategoryComponent,
    Metrics: Metrics,
    "Geography and Markets": GeoMarketsComponent,
    "Monitoring set up": Controller,
    // "Admin",
    Users: Users,
  };

  const ComponentToRender = tableMapping[item];

  return (
    <div>
      {showModal && openItem === "Brands" && (
        <FormModal
          handleAddNewBrand={handleAddNewBrand}
          show={showModal && openItem === "Brands"}
          handleClose={handleCloseModal}
          isUpdate={isUpdate}
          selectedData={selectedData}
          handleUpdateBrand={handleUpdateBrand}
          isInProgress={isInProgress}
        />
      )}

      <BrandCategoryFormModal
        handleAddBrandCategory={handleAddBrandCategory}
        show={showModal && openItem === "Brand category"}
        handleClose={handleCloseModal}
        customerBrands={customerBrands}
      />
      <BrandCompetitorFormModal
        handleAddBrandCompetitor={handleAddBrandCompetitor}
        show={showModal && openItem === "Brand competitors"}
        handleClose={handleCloseModal}
        customerBrands={customerBrands}
      />
      {showModal && openItem === "Products" && (
        <BrandProductFormModal
          handleAddBrandProduct={handleAddBrandProduct}
          show={showModal && openItem === "Products"}
          handleClose={handleCloseModal}
          customerBrands={customerBrands}
          isUpdate={isUpdate}
          selectedData={selectedData}
          handleUpdateBrandProduct={handleUpdateBrandProduct}
          isInProgress={isInProgress}
        />
      )}

      <ProductCompetitorFormModal
        handleAddProductCompetitor={handleAddProductCompetitor}
        show={showModal && openItem === "Product Competitors"}
        handleClose={handleCloseModal}
        customerBrands={customerBrands}
        customerBrandProducts={customerBrandProducts}
      />

      <ProductCategoryFormModal
        handleAddProductCategory={handleAddProductCategory}
        show={showModal && openItem === "Product category"}
        handleClose={handleCloseModal}
        customerBrandProducts={customerBrandProducts}
      />

      {showModal && openItem === "Personas" && (
        <PersonaFormModal
          handleAddNewPersona={handleAddNewPersona}
          personas={personas}
          show={showModal && openItem === "Personas"}
          handleClose={handleCloseModal}
          customerBrandProducts={customerBrandProducts}
          handleUpdatePersona={handleUpdatePersona}
          isUpdate={isUpdate}
          selectedData={selectedData}
        />
      )}

      {showModal && openItem === "Geography and Markets" && (
        <GeoMarketFormModal
          handleAddGeoMarkets={handleAddGeoMarkets}
          show={showModal && openItem === "Geography and Markets"}
          handleClose={handleCloseModal}
          isUpdate={isUpdate}
          selectedData={selectedData}
          // handleUpdateBrand={handleUpdateBrand}
        />
      )}

      {showModal && openItem === "Users" && (
        <Modal
          open={showModal}
          handleClose={() => setShowModal(false)}
          title="Add User"
        >
          <AddCompanyUserForm
            type="user"
            customerId={authCtx.user.customer_id}
            selectedCompany={authCtx?.user}
            onCancel={() => setShowModal(false)}
            onSubmit={addUserHandler}
          />
        </Modal>
      )}

      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <Button
            type="button"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "black",
              display: "flex",
              alignItems: "center",
            }}
            onClick={ComponentToRender && onClick}
          >
            {isOpen ? <FaCaretDown /> : <FaCaretRight />}{" "}
            <span style={{ marginLeft: "10px" }}> {item}</span>
          </Button>
        </Card.Header>
        {isOpen && (
          <Card.Body style={{ padding: "0px 65px 0px 65px" }}>
            {ComponentToRender ? (
              <ComponentToRender
                llms={llms}
                markets={markets}
                geographies={geographies}
                handleSelectedData={handleSelectedData}
                customerBrands={customerBrands}
                customerBrandProducts={customerBrandProducts}
                customerLlms={customerLlms}
              />
            ) : null}
            <div
              onClick={() => {
                setShowModal(true);
                setIsUpdate(false);
              }}
              style={{
                height: "30px",
                border: "1px solid lighblack",
                backgroundColor: "lightgrey",
                width: "3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                display: ["Metrics", "Controller"].includes(
                  ComponentToRender?.name
                )
                  ? "none"
                  : "flex",
              }}
            >
              <BsPlus />
            </div>
          </Card.Body>
        )}
      </Card>
      <ToastContainer />
    </div>
  );
};

const SettingsList = () => {
  const [openItem, setOpenItem] = useState(null);
  const [llms, setLlms] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [geographies, setGeographies] = useState([]);
  const [customerLlm, setCustomerLlm] = useState([]);
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands);
  const personas = useSelector((state) => state.personas);
  const brandProducts = useSelector((state) => state.brandProducts);

  const brandsData = brands?.data?.data;
  const brandProductsData = brandProducts?.data?.data;
  const personasData = personas?.data?.data;
  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  useEffect(() => {
    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }
    if (brandProducts?.data && brandProducts?.data.length === 0) {
      dispatch(fetchBrandProducts(customerId));
    }
    async function fetchLlmsData() {
      const llm = await getLlms();
      const market = await getMarkets();
      const geo = await getGeographies();
      const customerLlms = await fetchCustomerLlms([customerId]);
      setGeographies(geo?.data);
      setMarkets(market?.data);
      setLlms(llm?.data);
      setCustomerLlm(customerLlms);
    }
    fetchLlmsData();
  }, [dispatch]);

  const settingsItem = [
    "Brands",
    // "Brand competitors",
    // "Brand category",
    "Products",
    // "Product Competitors",
    // "Product category",
    "Personas",
    "Metrics",
    "Geography and Markets",
    "Monitoring set up",
    "Admin",
    "Users",
  ];

  const handleToggle = (item) => {
    setOpenItem(openItem === item ? null : item);
  };

  return (
    <>
      <div style={{ backgroundColor: "white", height: "800px" }}>
        <h4 style={{ textAlign: "center", color: "grey" }}> Settings </h4>
        <p> </p>
        {settingsItem.map((item) => (
          <SettingsListItem
            key={item}
            item={item}
            openItem={openItem}
            isOpen={openItem === item}
            onClick={() => handleToggle(item)}
            customerBrands={brandsData}
            customerBrandProducts={brandProductsData}
            customerLlms={customerLlm}
            personas={personasData}
            llms={llms}
            markets={markets}
            geographies={geographies}
          />
        ))}
      </div>
    </>
  );
};

export default layout(SettingsList);
