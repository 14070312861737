import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import classes from "./FormModal.module.css";

function PersonaFormModal({
  show,
  handleClose,
  handleAddNewPersona,
  handleUpdatePersona,
  personas,
  isUpdate,
  selectedData,
}) {
  const personasState = useSelector((state) => state.personas);
  const [personaName, setpersonaName] = useState("");
  const [personaDescription, setpersonaDescription] = useState("");

  useEffect(() => {
    if (isUpdate) {
      setpersonaName(selectedData?.persona?.name);
      setpersonaDescription(selectedData?.persona?.description);
    }
  }, [isUpdate, selectedData]);

  const handleInputValidation = () => {
    let errorMessage = "";
    if (!personaName) {
      errorMessage = "Please Enter a Persona Name !";
    }

    return errorMessage;
  };

  const handleAddUpdatePersonaInfo = () => {
    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }

    const payload = {
      persona_name: personaName,
      persona_description: personaDescription,
    };

    if (isUpdate) {
      handleUpdatePersona(selectedData?.id, payload);
    } else {
      handleAddNewPersona(payload);
    }
  };

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          {isUpdate ? "Update Persona" : "Add Persona"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Persona Name"
          // className="big"
          value={personaName}
          onChange={(e) => setpersonaName(e.target.value)}
        />
        <p> </p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={2}
          cols={2}
          name="firstName"
          placeholder="Persona description"
          // className="big"
          value={personaDescription}
          onChange={(e) => setpersonaDescription(e.target.value)}
        />
        <p> </p>

        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          disabled={personasState?.loading}
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          onClick={() => handleAddUpdatePersonaInfo()}
        >
          {personasState?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div> {isUpdate ? "Update" : "Add"} </div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}

export default PersonaFormModal;
