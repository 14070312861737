import MUIButton from "./../../Component/UI/MUIButton/MUIButton";
import classes from "./Header.module.css";

export default function Header({
  title,
  actionText,
  actionIcon,
  actionBtnVariant = "outlined",
  actionVariant = "btn",
  actionBtnClassName,
  className,
  titleClassName,
  onActionClick = () => {},
  renderAction = () => {}
}) {
  return (
    <header className={`${classes.container} ${className}`}>
      <h4 className={`${classes.title} ${titleClassName}`}>{title}</h4>
      {actionText && actionVariant == "btn" ? (
        <MUIButton
          data-html2canvas-ignore
          variant={actionBtnVariant}
          startIcon={actionIcon ? actionIcon : null}
          onClick={onActionClick}
          className={actionBtnClassName}
        >
          {actionText}
        </MUIButton>
      ) :  actionVariant != "btn" ? (
        <span className={classes.action_txt}>{actionText}</span>
      ) : null}
      { renderAction && renderAction() }
    </header>
  );
}
