import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainCard from "../Card/Card";
import Dashboard2 from "../../Component/devDashboard2/Dashboard";
import { FaArrowLeft } from "react-icons/fa";
import useQuery from "../../Component/devDashboard2/common/useQuery";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";
import { AuthContext } from "../../context/auth-context";
import { fetchPersonas } from "../../redux/actions/personasActions";

import { layout } from "../common/LayoutComponent.js/Layout";
import {
  getSummaryDataForBrandProduct,
  fetchCustomerLlms,
} from "../../services/apiService";

const Dashboard = () => {
  const [summaryData, setSummaryData] = useState(null);
  const [dbLlms, setDbLlms] = useState([]);
  const [selectedLLM, setSelectedLLM] = useState();

  const personas = useSelector((state) => state.personas);

  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const customerId = user?.customer_id || 0;

  useEffect(() => {
    async function fetchData(personaList) {
      try {
        const llms = await fetchCustomerLlms([customerId]);

        setDbLlms(llms?.data);
        const customerLLMs = llms?.data;
        const selectedllm = "gpt-4o";
        setSelectedLLM(selectedllm);
        const selectedLLMArray = customerLLMs.filter(
          (obj) => obj?.llm?.name === selectedllm
        );

        const llmId = selectedLLMArray[0]?.id || -1;
        const personaId = personaList[0]?.id;

        const fetchDataWithTimeout = async () => {
          return await Promise.race([
            getSummaryDataForBrandProduct(llmId, personaId),
            new Promise((_, reject) => 
              setTimeout(() => reject(new Error('Request timed out')), 5000) // 15 seconds
            )
          ]);
        };

        let data = await fetchDataWithTimeout();
        data = data && JSON.parse(data);
        const nestedData = data?.brands.map((item) => [item]);
        setSummaryData(nestedData);

      } catch (err) {
        console.error(err); // Optional: log the error
        setSummaryData([]);
      }
    }

    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId)).then((persona) => {
        if (persona?.data && persona?.data.length > 0) {
          fetchData(persona?.data);
        } else {
          setSummaryData([]);
        }
      });
    } else {
      fetchData(personas?.data?.data);
    }
  }, []);


  // useEffect(() => {
  //   async function fetchData(personaList) {
  //     try {
  //       const llms = await fetchCustomerLlms([customerId]);

  //       setDbLlms(llms?.data);
  //       const customerLLMs = llms?.data;
  //       const selectedllm = "gpt-4o";
  //       setSelectedLLM(selectedllm);
  //       const selectedLLMArray = customerLLMs.filter(
  //         (obj) => obj?.llm?.name === selectedllm
  //       );

  //       const llmId = selectedLLMArray[0]?.id || -1;

  //       const personaId = personaList[0]?.id;

  //       let data = await getSummaryDataForBrandProduct(llmId, personaId);

  //       data = data && JSON.parse(data);
  //       const nestedData = data?.brands.map((item) => [item]);
  //       setSummaryData(nestedData);
  //     } catch (err) {
  //       setSummaryData([]);
  //     }
  //   }

  //   // fetchData();

  //   if (personas?.data && personas?.data.length === 0) {
  //     dispatch(fetchPersonas(customerId)).then((persona) => {
  //       if (persona?.data && persona?.data.length > 0) {
  //         fetchData(persona?.data);
  //       } else {
  //         setSummaryData([]);
  //       }
  //     });
  //   } else {
  //     fetchData(personas?.data?.data);
  //   }
  // }, []);

  const handleLLMChange = async (e) => {
    setSummaryData(null);
    try {
      const selectedllm = e.target.value;
      setSelectedLLM(selectedllm);
      const selectedLLMArray = dbLlms.filter(
        (obj) => obj?.llm?.name === selectedllm
      );
      const llmId = selectedLLMArray[0]?.id || -1;
      const personaId = personas?.data.data[0]?.id;
      let data = await getSummaryDataForBrandProduct(llmId, personaId);
      data = data && JSON.parse(data);
      const nestedData = data?.brands.map((item) => [item]);
      setSummaryData(nestedData);
    } catch (err) {
      setSummaryData([]);
    }
  };

  const query = useQuery();
  const navigate = useNavigate();

  const [isDetailPage, setIsDetailPage] = useState(
    query.get("isDetailPage") || false
  );

  const handleIsDetailPage = () => {
    setIsDetailPage(true);
  };

  if (summaryData == null) {
    return (
      <p style={{ textAlign: "center", fontSize: "20px" }}> Loading... </p>
    );
  }

  return (
    <div style={{ height: "800px", backgroundColor: "white" }}>
      {isDetailPage ? (
        <>
          <div style={{ paddingLeft: "11px", marginBottom: "-60px" }}>
            <FaArrowLeft
              style={{ cursor: "pointer" }}
              size={20}
              onClick={() => {
                navigate("/brandonboarding/dashboard");
                setIsDetailPage(false);
              }}
            />
          </div>

          <Dashboard2 />
        </>
      ) : (
        <>
          {" "}
          <Form.Group>
            {/* <p style={{ textAlign: "center" }}>LLM</p> */}
            <Form.Control
              as="select"
              value={selectedLLM}
              onChange={(e) => {
                handleLLMChange(e);
              }}
              style={{ padding: "12px 12px", width: "20%", margin: "auto" }}
            >
              <option value="">select LLM</option>
              {dbLlms &&
                dbLlms.length !== 0 &&
                dbLlms.map((llms) => (
                  <option value={llms?.llm?.name}>{llms?.llm?.name}</option>
                ))}
            </Form.Control>
          </Form.Group>{" "}
          <p> </p>
          {summaryData &&
          summaryData.length !== 0 &&
          dbLlms &&
          dbLlms.length !== 0 ? (
            summaryData.map((data) => (
              <>
                <MainCard handleIsDetailPage={handleIsDetailPage} data={data} />
                <p />
              </>
            ))
          ) : (
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              No Brands/Products !
            </p>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default layout(Dashboard);
