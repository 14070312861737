import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaDownload } from "react-icons/fa";
import { FaEye } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa";
import { Row, Col, Card, Container, Button, CardHeader } from "react-bootstrap";
import classes from "./DashboardDataDisplay.module.css";
import PDFLayout from "../../MainDataComponent/PDFLayout";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import BarChart from "../common/Barchart";
import html2canvas from "html2canvas";
import LLMResultOutput from "./DashboardLLMResults";
import DashboardHistory from "./DashboardHistory";
const DashboardDataDisplayComponent = ({
  showData,
  data,
  toggleLLMResponse,
  setToggleLLMResponse,
  handleViewHistoryData,
  isHistoryData,
  llmAnalysisHistory,
  additionalDashboradInfo,
}) => {
  const downloadPDF = async (
    brandProductInput,
    focusArea,
    llm,
    promptCount
  ) => {
    const dashboardDiv = document.getElementById("dashboard");
    const spacerDiv = document.getElementById("spacer");

    // Create a canvas element to render the content onto
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Add a small padding to canvas size to avoid black borders
    const padding = 0;
    const contentWidth = dashboardDiv.scrollWidth + padding;
    const contentHeight = dashboardDiv.scrollHeight + padding;
    canvas.width = contentWidth;
    canvas.height = contentHeight;

    // Render the content of the dashboard div onto the canvas
    html2canvas(dashboardDiv, { canvas: canvas, scale: 1 }) // Increase scale for better quality
      .then((canvas) => {
        // Convert canvas to data URL with higher quality
        const canvasDataURL = canvas.toDataURL("image/jpeg", 1.0); // Increase quality

        // Construct HTML content with canvas image
        const contentHTML = ReactDOMServer.renderToStaticMarkup(
          <div>
            <PDFLayout
              brand={brandProductInput}
              type={focusArea}
              llm={llm}
              targetEle={spacerDiv}
              category="Dashboard"
              promptCount={promptCount}
            />
            <img
              src={canvasDataURL}
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
            {/* Ensure the image takes full width and height */}
          </div>
        );

        // Options for PDF generation
        const options = {
          margin: [15, 15],
          filename: `${brandProductInput}_Analysis_report.pdf`,
          image: { type: "jpeg", quality: 2 }, // Adjust quality
          html2canvas: { scale: 4, letterRendering: true },
          jsPDF: { unit: "pt", format: "A3", orientation: "portrait" },
          // pagebreak: {
          //   mode: ["avoid-all"],
          //   avoid: ".PDFLayout", // Adjust to avoid breaking before PDFLayout
          // }, // Specify page break
        };

        // Generate PDF with canvas image and other content
        html2pdf().from(contentHTML).set(options).save();
      })
      .catch((error) => {
        console.error("Error rendering dashboard to canvas:", error);
      });
  };

  return (
    <Row
      style={{ backgroundColor: "white", paddingTop: "30px" }}
      className="mt-5 mb-4"
    >
      <Col md="8">
        {showData && data && data.length !== 0 ? (
          <>
            <Card>
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!toggleLLMResponse ? (
                  <h4>
                    {additionalDashboradInfo?.brandProductInput}:{" "}
                    {additionalDashboradInfo?.focusItem}
                  </h4>
                ) : (
                  <Button
                    className={classes.actionBtn}
                    variant="link"
                    // onClick={downloadPDF}
                  >
                    <FaArrowLeft
                      onClick={() => {
                        setToggleLLMResponse(false);
                      }}
                    />
                  </Button>
                )}

                <div>
                  {!toggleLLMResponse && (
                    <>
                      <Button
                        className={classes.actionBtn}
                        variant="link"
                        onClick={() => {
                          setToggleLLMResponse(true);
                        }}
                      >
                        <FaEye />
                      </Button>

                      <Button
                        className={classes.actionBtn}
                        variant="link"
                        onClick={() => {
                          downloadPDF(
                            additionalDashboradInfo?.brandProductInput,
                            additionalDashboradInfo?.focusItem,
                            additionalDashboradInfo?.selectedLlmItem,
                            additionalDashboradInfo?.promptCount
                          );
                        }}
                      >
                        <FaDownload />
                      </Button>
                    </>
                  )}
                </div>
              </Card.Header>
            </Card>
            {toggleLLMResponse ? (
              <LLMResultOutput
                brand={additionalDashboradInfo?.brandProductInput}
                type={additionalDashboradInfo?.focusItem}
                llm={additionalDashboradInfo?.selectedLlmItem}
                promptCount={additionalDashboradInfo?.promptCount}
                LLMResponses={additionalDashboradInfo?.multipleLlmResponses}
                aggregatedResult={additionalDashboradInfo?.aggregatedResults}
              />
            ) : (
              <div
                id="dashboard"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {data &&
                  data.length !== 0 &&
                  data.map((subArray, index) => {
                    return (
                      <React.Fragment key={index}>
                        <p> </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {subArray.map((obj, objIndex) => (
                            <React.Fragment key={objIndex}>
                              <Col md="12">
                                {showData && (
                                  <Card className="border  rounded-0">
                                    <Container className="mt-3 p-0">
                                      <Card.Body className="p-0">
                                        <BarChart
                                          metricItem={
                                            additionalDashboradInfo?.metricItem
                                          }
                                          outputData={obj}
                                        />
                                        <p
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {obj[0]?.category
                                            ? "Signal Strength"
                                            : additionalDashboradInfo?.metricItem}
                                          (%)
                                        </p>
                                      </Card.Body>
                                    </Container>
                                  </Card>
                                )}
                              </Col>
                            </React.Fragment>
                          ))}
                        </div>
                        <p id="spacer"> </p>
                        {/* <hr id="spacer" className="my-0" /> */}
                      </React.Fragment>
                    );
                  })}
              </div>
            )}
          </>
        ) : isHistoryData &&
          Object.keys(llmAnalysisHistory) &&
          Object.keys(llmAnalysisHistory).length !== 0 ? (
          <>
            <Card>
              <Card.Header
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!toggleLLMResponse ? (
                  <h4>
                    {`${llmAnalysisHistory?.brandProductInput}: ${llmAnalysisHistory?.focusItem}`}
                  </h4>
                ) : (
                  <Button
                    className={classes.actionBtn}
                    variant="link"
                    // onClick={downloadPDF}
                  >
                    <FaArrowLeft
                      onClick={() => {
                        setToggleLLMResponse(false);
                      }}
                    />
                  </Button>
                )}

                <div>
                  {!toggleLLMResponse && (
                    <>
                      <Button
                        className={classes.actionBtn}
                        variant="link"
                        onClick={() => {
                          setToggleLLMResponse(true);
                        }}
                      >
                        <FaEye />
                      </Button>

                      <Button
                        className={classes.actionBtn}
                        variant="link"
                        onClick={() => {
                          downloadPDF(
                            llmAnalysisHistory?.brandProductInput,
                            llmAnalysisHistory?.focusItem,
                            llmAnalysisHistory?.selectedLlmItem,
                            llmAnalysisHistory?.promptCount
                          );
                        }}
                      >
                        <FaDownload />
                      </Button>
                    </>
                  )}
                </div>
              </Card.Header>
            </Card>

            {toggleLLMResponse ? (
              <LLMResultOutput
                brand={llmAnalysisHistory?.brandProductInput}
                type={llmAnalysisHistory?.focusItem}
                llm={llmAnalysisHistory?.selectedLlmItem}
                promptCount={llmAnalysisHistory?.promptCount}
                LLMResponses={
                  typeof llmAnalysisHistory?.multipleLlmResponses === "string"
                    ? JSON.parse(llmAnalysisHistory?.multipleLlmResponses)
                    : llmAnalysisHistory?.multipleLlmResponses
                }
                aggregatedResult={llmAnalysisHistory?.aggregatedResults}
              />
            ) : (
              <div
                id="dashboard"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {llmAnalysisHistory?.llmAnalysisData &&
                  JSON.parse(llmAnalysisHistory?.llmAnalysisData).length !==
                    0 &&
                  JSON.parse(llmAnalysisHistory?.llmAnalysisData).map(
                    (subArray, index) => {
                      return (
                        <React.Fragment key={index}>
                          <p> </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {subArray.map((obj, objIndex) => (
                              <React.Fragment key={objIndex}>
                                <Col md="12">
                                  <Card className="border  rounded-0">
                                    <Container className="mt-3 p-0">
                                      <Card.Body className="p-0">
                                        <BarChart
                                          metricItem={
                                            llmAnalysisHistory?.metricItem
                                          }
                                          outputData={obj}
                                        />
                                        <p
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {obj[0]?.category
                                            ? "Signal Strength"
                                            : llmAnalysisHistory?.metricItem}
                                          (%)
                                        </p>
                                      </Card.Body>
                                    </Container>
                                  </Card>
                                </Col>
                              </React.Fragment>
                            ))}
                          </div>
                          <p id="spacer"> </p>
                          {/* <hr id="spacer" className="my-0" /> */}
                        </React.Fragment>
                      );
                    }
                  )}
              </div>
            )}
          </>
        ) : null}
      </Col>
      <Col md="4">
        <DashboardHistory handleViewHistoryData={handleViewHistoryData} />
      </Col>

      <ToastContainer />
    </Row>
  );
};

export default DashboardDataDisplayComponent;
