import { IconButton, CircularProgress, Tooltip } from "@mui/material";

export default function MUIIconButton({
  icon,
  tooltip = "",
  loading,
  ...props
}) {
  let content = <IconButton {...props}></IconButton>;

  if (tooltip) {
    content = (
      <Tooltip title={tooltip}>
        <IconButton {...props}></IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center px-3">
          <CircularProgress size={16} />
        </div>
      ) : content}
    </>
  );
}
