import { Avatar } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";

import SentimentBar from "./SentimentBar";
import classes from "./SentimentItem.module.css";

function SentimentItem({ sentiment }) {
  return (
    <List>
      <ListItem disablePadding className="d-flex flex-column">
        {sentiment.map((product, index) => {
          return (
            <div key={index}>
              <span className="d-flex flex-row align-items-center mb-1">
                <h6 className={classes.productName}>
                  {index + 1}. {product.name}
                </h6>
                <Chip
                  variant="outlined"
                  color="primary"
                  className="mx-2"
                  label="Sentiment"
                  avatar={<Avatar className="px-3">{product.sentiment || product?.llm_sentiment_score}</Avatar>}
                />
              </span>
              {product?.confidenceScores && (
                <SentimentBar
                  confidenceScores={product?.confidenceScores}
                  sentiment={product?.llm_sentiment}
                />
              )}
              { product?.llm_sentiment && <span className="d-block mb-2">Sentiment: <b>{product?.llm_sentiment}</b></span> }
              {product?.question && <b className="d-block">{product?.question}</b>}
              <p className={classes.response_text}>{product.response}</p>
              
              {product?.explanation && (
                <div>
                  <b>Explanation:</b>
                  <br />
                  <span className={classes.response_text}>
                    {product?.explanation}
                  </span>
                </div>
              )}
              <hr />
            </div>
          );
        })}
      </ListItem>
    </List>
  );
}

export default SentimentItem;
