import api from "../../BrandOnBoarding/api";
import {
  FETCH_GEOMARKETS_REQUEST,
  FETCH_GEOMARKETS_SUCCESS,
  FETCH_GEOMARKETS_FAILURE,
  CREATE_GEOMARKET_REQUEST,
  CREATE_GEOMARKET_SUCCESS,
  CREATE_GEOMARKET_FAILURE,
  UPDATE_GEOMARKET_REQUEST,
  UPDATE_GEOMARKET_SUCCESS,
  UPDATE_GEOMARKET_FAILURE,
  DELETE_GEOMARKET_REQUEST,
  DELETE_GEOMARKET_SUCCESS,
  DELETE_GEOMARKET_FAILURE,
} from "../constants/actionTypes";

export const fetchGeoMarkets = (id) => async (dispatch) => {
  dispatch({ type: FETCH_GEOMARKETS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/customer-geo-markets`);
    dispatch({ type: FETCH_GEOMARKETS_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: FETCH_GEOMARKETS_FAILURE, error: error.message });
  }
};

export const createGeoMarket = (id, geoMarket) => async (dispatch) => {
  dispatch({ type: CREATE_GEOMARKET_REQUEST });
  try {
    const response = await api.post(
      `/customers/${id}/customer-geo-market`,
      geoMarket
    );
    dispatch({ type: CREATE_GEOMARKET_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_GEOMARKET_FAILURE, error: error.message });
  }
};

export const updateGeoMarket =
  (id, geoMarketId, geoMarket) => async (dispatch) => {
    dispatch({ type: UPDATE_GEOMARKET_REQUEST });
    try {
      const response = await api.put(
        `/customers/${id}/customer-geo-market/${geoMarketId}`,
        geoMarket
      );
      dispatch({ type: UPDATE_GEOMARKET_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPDATE_GEOMARKET_FAILURE, error: error.message });
    }
  };

export const deleteGeoMarket = (id, geoMarketId) => async (dispatch) => {
  dispatch({ type: DELETE_GEOMARKET_REQUEST });
  try {
    await api.delete(`/customers/${id}/customer-geo-market/${geoMarketId}`);
    dispatch({ type: DELETE_GEOMARKET_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_GEOMARKET_FAILURE, error: error.message });
  }
};

export const updateCustomerGeoLlmMarket =
  (id, targetId, payload) => async (dispatch) => {
    dispatch({ type: UPDATE_GEOMARKET_REQUEST });
    try {
      const response = await api.patch(
        `/customers/${id}/customer-llm/${targetId}`,
        payload
      );
      dispatch({ type: UPDATE_GEOMARKET_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: UPDATE_GEOMARKET_FAILURE, error: error.message });
    }
  };
