import React, { useState, useRef } from "react";
import { IconButton } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import PDFLayout from "../../MainDataComponent/PDFLayout";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";

const BrandProductIndexScoresTable = ({
  selectedAttributes,
  brand,
  metrics,
  type,
  llm,
  categoryName,
}) => {
  const [loading, setLoading] = useState(false);
  const pdfContentRef = useRef(null);

  const downloadPDF = async () => {
    setLoading(true);
    const options = {
      margin: [15, 15],
      filename: `${brand || "--"} ${metrics || ""}_${type || ""}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || "--"}
        metrics={metrics || "--"}
        type={type || "--"}
        llm={llm || "--"}
        categoryName={categoryName}
        targetEle={pdfContentRef.current}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      toast.error("Failed to generate PDF");
      console.error("Failed to download: ", err);
    } finally {
      setLoading(false);
    }
  };

  const attributes = [
    ...new Set(
      Object.values(selectedAttributes).flatMap((brand) => Object.keys(brand))
    ),
  ];

  // Calculate the sum of scores for each brand
  const brandSums = Object.keys(selectedAttributes).map((brand) => {
    const sum = Object.values(selectedAttributes[brand]).reduce(
      (acc, score) => {
        return acc + (score !== undefined ? parseFloat(score) : 0);
      },
      0
    );
    return { brand, sum };
  });

  // Sort brands by the sum of their scores in descending order
  brandSums.sort((a, b) => b.sum - a.sum);

  // let jsonData = JSON.stringify(selectedAttributes, null, 2); // The `null` and `2` parameters are optional but make the JSON string pretty-printed.
  // console.log(jsonData);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={(e) => {
            if (loading) return;
            downloadPDF();
          }}
        >
          {!loading && <FileDownloadOutlinedIcon />}
          {loading && <Spinner animation="border" />}
        </IconButton>
      </div>
      <div
        className="pdfContent"
        style={{ padding: "10px" }}
        ref={pdfContentRef}
      >
        <Table>
          <thead style={{ backgroundColor: "#CCCCCC" }}>
            <tr>
              <th>Brand/Product</th>
              {attributes.map((attribute, index) => (
                <th key={index}>{attribute}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {brandSums.map(({ brand }, index) => (
              <tr key={index}>
                <td>{brand}</td>
                {attributes.map((attribute, index) => {
                  return (
                    <td key={index}>
                      {selectedAttributes[brand][attribute] !== undefined
                        ? Number(selectedAttributes[brand][attribute]).toFixed(
                            0
                          )
                        : "N/A"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </>
  );
};

export default BrandProductIndexScoresTable;
