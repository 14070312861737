import React from "react";
import { Card } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";

const ProductCard = ({ productName, productData, handleIsDetailPage }) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "350px",
        // height: "80px",
        padding: "6px",
        backgroundColor: "#DCD6D0",
      }}
    >
      {" "}
      <div
        style={{
          display: "flex",
          fontSize: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6> Brand-Product : {productName} </h6>
        <FaArrowRight
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleIsDetailPage();
            navigate(
              `/brandonboarding/dashboard?brandProductId=${productData?.customer_brand_product_id}`
            );
          }}
        />
      </div>
      <div style={{ display: "flex", fontSize: "16px" }}>
        <p> Index Score {productData?.index_score} </p>
        <p> Favorability Score {productData?.favorability_score} </p>
        <p> Reach Score {productData?.reach_score} </p>
      </div>
    </Card>
  );
};

export default ProductCard;
