import React from "react";
import { Chip } from "@mui/material";

import classes from "./MUIChip.module.css";

export default function MUIChip({ label, color, ...props }) {
  return (
    <Chip
      label={label}
      size="small"
      className={classes.chip}
      sx={{ backgroundColor: color }}
      {...props}
    />
  );
}
