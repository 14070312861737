import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrandCategories } from "../../../redux/actions/brandCategoriesActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";

const BrandCategoryComponent = () => {
  const dispatch = useDispatch();
  const brandCategories = useSelector((state) => state.brandCategories);

  useEffect(() => {
    if (brandCategories?.data && brandCategories?.data.length === 0) {
      dispatch(fetchBrandCategories(1));
    }
  }, [dispatch]);

  if (brandCategories.loading) {
    return <p>Loading...</p>;
  }

  if (brandCategories.error) {
    return <p>Error: {brandCategories.error}</p>;
  }
  const brandCategoriesData = brandCategories.data.data;
 
  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Brand category</th>
          <th>Details</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {brandCategoriesData &&
          brandCategoriesData.length !== 0 &&
          brandCategoriesData.map((data) => {
            return (
              <tr>
                <td>{data?.name}</td>
                <td>{data?.description}</td>
                <td className={classes.actions}>
                  <Button
                    style={{
                      backgroundColor: "green",
                    }}
                    variant="success"
                    // onClick={() => handleUpdate(data.id)}
                  >
                    Update
                  </Button>{" "}
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                    }}
                    variant="danger"
                    // onClick={() => handleDelete(data.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default BrandCategoryComponent;
