import React, { useEffect, useState, useContext } from "react";

import { AuthContext } from "../../../../context/auth-context";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import {
  fetchGeoMarkets,
  updateCustomerGeoLlmMarket,
} from "../../../../redux/actions/geoMarketsActions";

const GeoMarketController = () => {
  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const llms = useSelector((state) => state.geoMarkets);
  const dispatch = useDispatch();

  useEffect(() => {
    if (llms?.data && llms?.data.length === 0) {
      dispatch(fetchGeoMarkets(customerId));
    }
  }, [dispatch]);

  const uniqueGeo = llms?.data?.data
    ?.map((item) => ({
      id: item?.geography?.id,
      name: item?.geography?.name,
      monitoring: item?.geography_monitoring,
    }))
    .filter(
      (geo, index, self) => index === self.findIndex((t) => t.id === geo?.id)
    );

  const uniqueMarket = llms?.data?.data
    ?.map((item) => ({
      id: item?.market?.id,
      name: item?.market?.name,
      monitoring: item?.market_monitoring,
    }))
    .filter(
      (market, index, self) =>
        index === self.findIndex((t) => t.id === market?.id)
    );

  if (
    (uniqueGeo && uniqueGeo.length === 0) ||
    (uniqueMarket && uniqueMarket.length === 0)
  ) {
    return <p> Loading.. </p>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        <strong>
          <p>Geographies </p>
        </strong>
        {uniqueGeo?.map((obj) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h6>
                <LanguageIcon /> &nbsp; &nbsp; {obj?.name}
              </h6>
              <Switch
                defaultChecked={obj?.monitoring}
                onChange={(e) =>
                  dispatch(
                    updateCustomerGeoLlmMarket(customerId, obj?.id, {
                      geography_monitoring: e.target.checked ? 1 : 0,
                    })
                  )
                    .then(() => {
                      //   dispatch(fetchGeoMarkets(customerId));
                    })
                    .catch(() => {
                      //   dispatch(fetchGeoMarkets(customerId));
                    })
                }
              />
            </div>
          );
        })}
      </div>
      <hr />
      <div>
        <strong>
          <p>Markets </p>
        </strong>
        {uniqueMarket?.map((obj, index) => {
          return (
            <div
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h6>
                <PlaceIcon /> &nbsp; &nbsp; {obj?.name}
              </h6>
              <Switch
                defaultChecked={obj?.monitoring}
                onChange={(e) =>
                  dispatch(
                    updateCustomerGeoLlmMarket(customerId, obj?.id, {
                      market_monitoring: e.target.checked ? 1 : 0,
                    })
                  )
                    .then(() => {
                      //   dispatch(fetchGeoMarkets(customerId));
                    })
                    .catch(() => {
                      //   dispatch(fetchGeoMarkets(customerId));
                    })
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GeoMarketController;
