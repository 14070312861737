import { Chip } from "@mui/material";

export default function Competitors({ data, onDelete }) {
  return (
    <div className="my-2">
      {data.map((item) => {
        return (
          <Chip
            key={item}
            label={item}
            variant="filled"
            className="mx-1"
            onDelete={()=> onDelete(item)}
          />
        );
      })}
    </div>
  );
}
