import MUIChip from "./../../../Component/UI/MUIChip/MUIChip";
import classes from "./SummaryList.module.css";

export default function SummaryList({ summaryData, activeItemId, onItemSelect }) {
  return (
    <div className={classes.list}>
      {summaryData.map((summary, index) => {
        return (
          <MUIChip
            key={summary.id}
            label={index + 1}
            className={
              summary.id === activeItemId ? classes.active : classes.inactive
            }
            onClick={() => {
              onItemSelect(summary);
            }}
          ></MUIChip>
        );
      })}
    </div>
  );
}
