import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import PersonaController from "./PersonaController";
import MainJobController from "./MainJobController";
import Monitoring from "../Monitoring";
import LlmController from "./LlmController";
import GeoMarketController from "./GeoMarketController";
import { useDispatch } from "react-redux";
import { fetchGeoMarkets } from "../../../../redux/actions/geoMarketsActions";
import { fetchPersonas } from "../../../../redux/actions/personasActions";
import { fetchCustomer } from "../../../../redux/actions/userActions";
import { AuthContext } from "../../../../context/auth-context";
import Spinner from "../../../../Component/Spinner/Spinner";

const AccordionContent = [
  {
    title: "Brand/Product & Competitors",
  },
  {
    title: "Personas",
  },
  {
    title: "Llms",
  },
  {
    title: "Geography & Markets",
  },
  {
    title: "Metrics",
  },
];

const Controller = () => {
  const [loading, setLoading] = useState(true);

  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCustomer(customerId));
        await dispatch(fetchPersonas(customerId));
        await dispatch(fetchGeoMarkets(customerId));
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, customerId]);

  const renderComponent = (componentName) => {
    switch (componentName) {
      case "Brand/Product & Competitors":
        return <MainJobController />;
      case "Personas":
        return <PersonaController />;
      case "Metrics":
        return <Monitoring />;
      case "Llms":
        return <LlmController />;
      case "Geography & Markets":
        return <GeoMarketController />;

      default:
        return null;
    }
  };

  if (loading) {
    return <Spinner />; // Replace with your actual loader component
  }

  return (
    <>
      <Accordion defaultActiveKey="0">
        {AccordionContent.map((section, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>
              <b> {section.title} </b>
            </Accordion.Header>
            <Accordion.Body>
              <Card key={index} className="mb-3">
                <Card.Body>{renderComponent(section.title)}</Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default Controller;
