import {
  FETCH_BRAND_PRODUCTS_REQUEST,
  FETCH_BRAND_PRODUCTS_SUCCESS,
  FETCH_BRAND_PRODUCTS_FAILURE,
  CREATE_BRAND_PRODUCT_REQUEST,
  CREATE_BRAND_PRODUCT_SUCCESS,
  CREATE_BRAND_PRODUCT_FAILURE,
  UPDATE_BRAND_PRODUCT_REQUEST,
  UPDATE_BRAND_PRODUCT_SUCCESS,
  UPDATE_BRAND_PRODUCT_FAILURE,
  DELETE_BRAND_PRODUCT_REQUEST,
  DELETE_BRAND_PRODUCT_SUCCESS,
  DELETE_BRAND_PRODUCT_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
  deleteLoading: false,
};

const brandProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BRAND_PRODUCTS_REQUEST:
    case CREATE_BRAND_PRODUCT_REQUEST:
    case UPDATE_BRAND_PRODUCT_REQUEST:
      // case DELETE_BRAND_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BRAND_PRODUCT_REQUEST:
      return {
        ...state,
        deleteLoading: true,
      };
    case FETCH_BRAND_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_BRAND_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...state.data, action.payload],
      };
    case UPDATE_BRAND_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: state.data.map((product) =>
        //   product.id === action.payload.id ? action.payload : product
        // ),
      };
    case DELETE_BRAND_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // data: state.data.filter(product => product.id !== action.payload),
      };
    case FETCH_BRAND_PRODUCTS_FAILURE:
    case CREATE_BRAND_PRODUCT_FAILURE:
    case UPDATE_BRAND_PRODUCT_FAILURE:
    case DELETE_BRAND_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        // error: action.error,
        deleteLoading: false,
      };
    default:
      return state;
  }
};

export default brandProductsReducer;
