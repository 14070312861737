import { Modal, Button } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import { useState } from "react";

import classes from "./MonitorPromptPreview.module.css";

function ConfirmToProceedModal({
  show,
  handleClose,
  allAPIResponses,
  processBaselineData,
  handleCheckBoxChange,
  monitoringCheckedItems,
  llm,
}) {
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const toggleExpand = (itemIndex) => {
    if (selectedIndexes.includes(itemIndex)) {
      const currentIndexes = selectedIndexes.filter(
        (index) => index !== itemIndex
      );
      setSelectedIndexes(currentIndexes);
    } else {
      setSelectedIndexes([...selectedIndexes, itemIndex]);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm To Proceed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {allAPIResponses.map((mainData, mainIndex) => (
          <>
            <div key={mainIndex} className={classes.cardHeaders}>
              <div
                className={classes.header_badge_wrapper}
                onClick={() => toggleExpand(mainIndex)}
                style={{ cursor: "pointer" }}
              >
                <span className={classes.headerbadge}>{mainData[0].title}</span>
              </div>

              {selectedIndexes.includes(mainIndex) &&
                mainData &&
                mainData.length > 0 && (
                  <>
                    {mainData[0].data.map((data, index) => (
                      <>
                        <div key={index} className={classes.results}>
                          <div className={classes.badge_wrapper}>
                            {index === mainData[0].data.length - 1 ? (
                              <span
                                className={classes.badge}
                              >{`New Response`}</span>
                            ) : (
                              <span className={classes.badge}>{`Response ${
                                index + 1
                              }`}</span>
                            )}
                          </div>
                          <div className={classes.md}>
                            <Markdown>{data}</Markdown>
                          </div>
                        </div>
                      </>
                    ))}
                    <hr />
                    {mainData[0].deviationResponse && (
                      <div className={classes.md} style={{ color: "black" }}>
                        <Markdown>{mainData[0].deviationResponse}</Markdown>
                      </div>
                    )}
                    <hr style={{ color: "black" }} />
                    <p className={classes.md} style={{ color: "black" }}>
                      <input
                        type="checkbox"
                        name=""
                        value=""
                        checked={monitoringCheckedItems.includes(
                          mainData[0]?.title
                        )}
                        onChange={() =>
                          handleCheckBoxChange(mainData[0]?.title)
                        }
                      /> &nbsp;
                      {"Add to set up Monitoring"}
                    </p>
                  </>
                )}
            </div>
            <p></p>
          </>
        ))}
        <hr />
        {monitoringCheckedItems.length !== 0 ? (
          <div>
            <h6>Do you want to set up Monitoring for below attributes? </h6>
            <ul>
              {monitoringCheckedItems.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        ) : (
         <p> Please select at least one Dataset to set up monitoring !</p>
        )}
      </Modal.Body>
      <Modal.Footer className={classes.actions}>
        {monitoringCheckedItems.length !== 0 && (
          <Button style={{backgroundColor:'green'}} onClick={() => processBaselineData(llm)} variant="success">
            Yes
          </Button>
        )}

        <Button  style={{backgroundColor:'#d32f2f'}}  variant="danger" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmToProceedModal;
