import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import JSON5 from "json5";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { BsPlus, BsX } from "react-icons/bs";
import { fetchGpt4API } from "../../services/apiService";
import { getPromptForBrandCompetitorsData } from "../../utils/helpers";
import CompetitorListComponent from "../Settings/Component/CompetitorListComponent";
import CoreAttributesSelectionComponent from "../Settings/Component/CoreAttributesSelectionComponent";
import PersonaSelectionComponent from "../Settings/Component/PersonaSelectionComponent";

import classes from "./FormModal.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FormModal({
  show,
  handleClose,
  handleAddNewBrand,
  handleUpdateBrand,
  isUpdate,
  selectedData,
  isInProgress,
}) {
  const brands = useSelector((state) => state.brands);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const [brandName, setBrandName] = useState("");
  const [brandDescription, setBrandDescription] = useState("");
  const [compBrandName, setCompBrandName] = useState("");
  const [compBrandDescription, setCompBrandDescription] = useState("");
  const [categoryName, setcategoryName] = useState("");
  const [categoryDescription, setcategoryDescription] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [compBrands, setCompBrands] = useState([]);

  const [selectedOption, setSelectedOption] = useState("manual");
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompetitorItems, setselectedCompetitorItems] = useState([]);
  const [prevBrandName, setPrevBrandName] = useState("");
  const [selectedCoreAttributes, setSelectedCoreAttributes] = useState([]);
  const [selectedPersonaNames, setSelectedPersonaNames] = useState([
    "General Persona",
  ]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setselectedCompetitorItems([]);
    setCompBrands([]);
  };

  const handleInputValidation = () => {
    let errorMessage = "";
    if (!brandName) {
      errorMessage = "Please Enter a Brand Name !";
    } else if (!categoryName) {
      errorMessage = "Please Enter a Brand Category Name !";
    } else if (selectedPersonaNames && selectedPersonaNames.length == 0) {
      errorMessage = "Please select a persona !";
    }

    return errorMessage;
  };

  const handleCheckChange = (key) => {
    if (selectedCompetitorItems.includes(key)) {
      setselectedCompetitorItems(
        selectedCompetitorItems.filter((item) => item !== key)
      );
    } else {
      setselectedCompetitorItems([...selectedCompetitorItems, key]);
    }
  };

  const handleCheckCoreAttributeChange = (key) => {
    if (selectedCoreAttributes.includes(key)) {
      setSelectedCoreAttributes(
        selectedCoreAttributes.filter((item) => item !== key)
      );
    } else {
      setSelectedCoreAttributes([...selectedCoreAttributes, key]);
    }
  };

  const handleCheckPersonaChange = (key) => {
    if (selectedPersonaNames.includes(key)) {
      setSelectedPersonaNames(
        selectedPersonaNames.filter((item) => item !== key)
      );
    } else {
      setSelectedPersonaNames([...selectedPersonaNames, key]);
    }
  };

  const handleAddNewBrandInfo = () => {
    // const persona_id = personas?.data?.data.filter(
    //   (persona) => persona?.persona?.name === selectedPersona
    // )[0]?.id;

    const coreAttribtesArray = coreAttributes?.data?.data.filter(
      (coreAttribute) => selectedCoreAttributes.includes(coreAttribute?.name)
    );

    const personasArray = personas?.data?.data
      .filter((persona) =>
        selectedPersonaNames.includes(persona?.persona?.name)
      )
      .map((obj) => obj?.persona_id);

    let compArray;
    if (selectedCompetitorItems && selectedCompetitorItems.length !== 0) {
      const competitor = competitorsFromAi.filter((obj) => {
        return selectedCompetitorItems.includes(obj.comp_brand_name);
      });

      compArray = competitor;
    } else {
      compArray = compBrands;
    }
    const payload = {
      brand_name: brandName,
      brand_description: brandDescription,
      category_name: categoryName,
      category_description: categoryDescription,
      comp_brands: compArray,
      personaIds: personasArray,
      coreAttributesList: coreAttribtesArray,
    };

    const error = handleInputValidation();
    if (error) {
      toast.error(error, {
        autoClose: 700,
      });
      return;
    }

    if (isUpdate) {
      handleUpdateBrand(selectedData?.id, payload);
    } else {
      handleAddNewBrand(payload);
    }
  };

  const handleBrandList = () => {
    if (compBrandName && compBrandDescription) {
      const obj = {
        comp_brand_name: compBrandName,
        comp_brand_description: compBrandDescription,
      };
      setCompBrands([...compBrands, obj]);
    }
  };

  const handleDeleteCompBrand = (brandIndex) => {
    let currentCompBrands = [...compBrands];
    currentCompBrands = currentCompBrands.filter((brandInfo, index) => {
      return index !== brandIndex;
    });

    setCompBrands(currentCompBrands);
  };

  useEffect(() => {
    if (selectedOption === "throughAi" && brandName) {
      async function getCompetitorsData() {
        if (prevBrandName !== brandName) {
          let prompt = getPromptForBrandCompetitorsData(brandName);
          setIsLoading(true);
          let result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Brand-onboarding"
          );
          result = result["GPT-4"][0];

          // Match the array of objects using the regular expression
          let matchedArray = result.match(
            /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
          );

          if (matchedArray) {
            // Parse the matched array into a JavaScript object
            let resultArray = JSON5.parse(matchedArray[0]);
            // console.log(resultArray);
            setIsLoading(false);
            setCompetitorsFromAi(resultArray);
            setPrevBrandName(brandName);
          }
        }
      }

      getCompetitorsData();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (isUpdate) {
      const coreAttributesArray = selectedData?.coreAttribute.map(
        (obj) => obj.core_attribute.name
      );

      const personaArray =
        selectedData?.persona &&
        selectedData?.persona.length !== 0 &&
        selectedData?.persona.map((obj) => {
          return obj?.persona?.name;
        });
      const compArray =
        selectedData?.competitors &&
        selectedData?.competitors.length !== 0 &&
        selectedData?.competitors.map((obj) => {
          return {
            comp_brand_name: obj?.brand?.name,
            comp_brand_description: obj?.brand?.description,
          };
        });
      setSelectedCoreAttributes(coreAttributesArray);
      setBrandName(selectedData?.brand?.name);
      setBrandDescription(selectedData?.brand?.description);
      setcategoryName(selectedData?.category?.name);
      setcategoryDescription(selectedData?.category?.description);
      setSelectedPersona(selectedData?.persona[0]?.persona?.name);
      // setCompBrandDescription(selectedData?.competitors[0]?.brand?.description);
      // setCompBrandName(selectedData?.competitors[0]?.brand?.name);
      setCompBrands(compArray);
      setSelectedPersonaNames(personaArray || []);
    } else {
      const coreAttributesArray = coreAttributes?.data?.data.map(
        (obj) => obj.name
      );
      setSelectedCoreAttributes(coreAttributesArray);
    }

    const prompt = getPromptForBrandCompetitorsData();
  }, [isUpdate, selectedData]);

  return (
    <Modal
      style={{ border: "none", borderRadius: "0px" }}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "grey", fontSize: "20px" }}>
          {isUpdate ? "Update Brand Info" : "Add New Brand"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Brand Name"
          // className="big"
          value={brandName}
          onChange={(e) => {
            setBrandName(e.target.value);
            setSelectedOption("manual");
          }}
        />
        <p> </p>
        <Form.Control
          style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          as="textarea"
          rows={1}
          cols={2}
          name="firstName"
          placeholder="Details for disambigution"
          // className="big"
          value={brandDescription}
          onChange={(e) => setBrandDescription(e.target.value)}
        />
        <hr />
        <Form.Group>
          <p style={{ textAlign: "center" }}> Brand Category</p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Brand category name"
            // className="big"
            value={categoryName}
            onChange={(e) => setcategoryName(e.target.value)}
          />
          <p> </p>
          <Form.Control
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
            as="textarea"
            rows={1}
            cols={2}
            name="firstName"
            placeholder="Brand category description"
            // className="big"
            value={categoryDescription}
            onChange={(e) => setcategoryDescription(e.target.value)}
          />
        </Form.Group>
        <hr />
        <Form.Group>
          <p style={{ textAlign: "center" }}> Brand Competitor</p>
          <div style={{ display: "flex" }}>
            <label for="manual">
              <input
                checked={selectedOption === "manual"}
                onChange={handleOptionChange}
                type="radio"
                id="option1"
                name="options"
                value="manual"
              />{" "}
              &nbsp; Enter Competitors Manually
            </label>{" "}
            &nbsp; &nbsp;
            <label for="throughAi">
              <input
                checked={selectedOption === "throughAi"}
                onChange={handleOptionChange}
                type="radio"
                id="option2"
                name="options"
                value="throughAi"
              />{" "}
              &nbsp; Get Competitors from AI
            </label>
          </div>
          <p> </p>
          {selectedOption === "manual" && (
            <>
              {" "}
              <Form.Control
                style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
                as="textarea"
                rows={1}
                cols={2}
                name="firstName"
                placeholder="Competitor brand name"
                // className="big"
                value={compBrandName}
                onChange={(e) => setCompBrandName(e.target.value)}
              />
              <p> </p>
              <div style={{ display: "flex" }}>
                <Form.Control
                  style={{
                    padding: "12px 12px",
                    width: "70%",
                    marginLeft: "69px",
                  }}
                  as="textarea"
                  rows={1}
                  cols={2}
                  name="firstName"
                  placeholder="Competitor brand description"
                  // className="big"
                  value={compBrandDescription}
                  onChange={(e) => setCompBrandDescription(e.target.value)}
                />
                &nbsp; &nbsp;
                <div
                  onClick={() => {
                    handleBrandList();
                  }}
                  style={{
                    height: "29px",
                    border: "1px solid lighblack",
                    backgroundColor: "lightgrey",
                    width: "8%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <BsPlus />
                </div>
              </div>{" "}
            </>
          )}
        </Form.Group>

        <p> </p>
        {compBrands && compBrands.length !== 0 && (
          <ul style={{ padding: "12px 12px", width: "65%", margin: "auto" }}>
            {compBrands.map((brandInfo, index) => {
              return (
                <li>
                  <div
                    onClick={() => {
                      handleDeleteCompBrand(index);
                    }}
                    style={{
                      height: "29px",
                      border: "1px solid lighblack",
                      backgroundColor: "lightgrey",
                      // width: "8%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "10px",
                      borderRadius: "8px",
                      padding: "0px 8px 0px 8px",
                    }}
                  >
                    {brandInfo?.comp_brand_name} &nbsp; <BsX size={24} />
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {selectedOption === "throughAi" ? (
          isLoading ? (
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : brandName ? (
            <div style={{ width: "70%", margin: "auto" }}>
              <CompetitorListComponent
                options={competitorsFromAi}
                handleCheckChange={handleCheckChange}
                selectedCompetitorItems={selectedCompetitorItems}
              />
            </div>
          ) : (
            <p style={{ color: "red", textAlign: "center" }}>
              Enter Brand Name 1st to get its competitors !!{" "}
            </p>
          )
        ) : null}

        {/* <hr />

        <Form.Group>
          <p style={{ textAlign: "center" }}>Brand Persona</p>
          <Form.Control
            as="select"
            value={selectedPersona}
            onChange={(e) => {
              setSelectedPersona(e.target.value);
            }}
            style={{ padding: "12px 12px", width: "70%", margin: "auto" }}
          >
            <option value="">select Brand persona</option>
            {personas?.data?.data &&
              personas?.data?.data.length !== 0 &&
              personas?.data?.data.map((persona) => (
                <option value={persona?.persona?.name}>
                  {persona?.persona?.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group> */}

        <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Brand Persona</p>
          <PersonaSelectionComponent
            options={personas?.data?.data}
            handleCheckChange={handleCheckPersonaChange}
            selectedPersonaNames={selectedPersonaNames}
          />
        </div>

        {/* <hr />
        <div style={{ width: "70%", margin: "auto" }}>
          <p style={{ textAlign: "center" }}> Brand Core Attribute</p>
          <CoreAttributesSelectionComponent
            options={coreAttributes?.data?.data}
            handleCheckChange={handleCheckCoreAttributeChange}
            selectedCoreAttributes={selectedCoreAttributes}
          />
        </div> */}

        {isInProgress && (
          <>
            <hr />
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Brand is on-boarded successfully, Dashboard data is being
              generated !!
            </div>
          </>
        )}
        {/* <p> </p>
        <br /> */}

        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Group>
            <Form.Label>Brand category</Form.Label>
            <Form.Control
              as="select"
              value={categoryId}
              onChange={(e) => {
                setCategoryId(e.target.value);
              }}
              style={{ height: "40px", width: "200px" }}
            >
              <option value="">select Brand category</option>
              {categories &&
                categories.length !== 0 &&
                categories.map((category) => (
                  <option value={category?.name}>{category?.name}</option>
                ))}
            
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Competitors</Form.Label>
            <Form.Control
              as="select"
              // value={functionalityUsage}
              // onChange={(e) => {
              //   handleChangeFunctionalityUsage(e);
              // }}
              style={{ height: "40px", width: "200px" }}
            >
              <option value="">select competitors</option>
              <option value="Discovery">Discovery</option>
              <option value="Reporting">Reporting</option>
              <option value="Frontend Monitoring">Frontend Monitoring</option>
              <option value="Backend Monitoring">Backend Monitoring</option>
              <option value="Dashboard">Dashboard</option>
            </Form.Control>
          </Form.Group>
        </div>
        <p> </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Group>
            <Form.Label>Brand persona</Form.Label>
            <Form.Control
              as="select"
              // value={functionalityUsage}
              // onChange={(e) => {
              //   handleChangeFunctionalityUsage(e);
              // }}
              style={{ height: "40px", width: "200px" }}
            >
              <option value="">select Persona</option>
              <option value="Discovery">Discovery</option>
              <option value="Reporting">Reporting</option>
              <option value="Frontend Monitoring">Frontend Monitoring</option>
              <option value="Backend Monitoring">Backend Monitoring</option>
              <option value="Dashboard">Dashboard</option>
            </Form.Control>
          </Form.Group>
        </div> */}
        <br />
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: "center" }}
        className={classes.actions}
      >
        <Button
          style={{ backgroundColor: "green", width: "30%" }}
          variant="success"
          disabled={brands?.loading || isInProgress}
          onClick={() => handleAddNewBrandInfo()}
        >
          {brands?.loading ? (
            <div style={{ fontSize: "14px" }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Please Wait...
            </div>
          ) : (
            <div> {isUpdate ? "Update" : "Add"} </div>
          )}
        </Button>
        <Button
          style={{ backgroundColor: "#d32f2f", width: "30%" }}
          variant="danger"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
}

export default FormModal;
