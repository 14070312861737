import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetitorProducts } from "../../../redux/actions/competitorProductActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";

const CompetitorProductsComponent = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.competitorProducts);

  useEffect(() => {
    if (products?.data && products?.data.length === 0) {
      dispatch(fetchCompetitorProducts(1));
    }
  }, [dispatch]);

  if (products.loading) {
    return <p>Loading...</p>;
  }

  if (products.error) {
    return <p>Error: {products.error}</p>;
  }

  const productsData = products.data.data;

  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Product</th>
          <th>Details</th>
          <th>Tags</th>
          <th>Category</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {productsData &&
          productsData.length !== 0 &&
          productsData.map((data) => {
            return (
              <tr>
                <td>{data.name}</td>
                <td>{data.description}</td>
                <td>Competitor</td>
                <td>
                  {/* {data.categories.map((category, index) => (
                    <p key={index}>{category.name}</p>
                  ))} */}
                </td>
                <td className={classes.actions}>
                  <Button
                    style={{
                      backgroundColor: "green",
                    }}
                    variant="success"
                    // onClick={() => handleUpdate(data.id)}
                  >
                    Update
                  </Button>{" "}
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                    }}
                    variant="danger"
                    // onClick={() => handleDelete(data.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default CompetitorProductsComponent;
