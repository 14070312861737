import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard({
  title,
  subText,
  children,
  elevation = 2,
  borderRadius,
  variant = "outlined",
  className,
  titleClassName,
  width
}) {
  return (
    <Card
      sx={{ minWidth: 275, borderRadius, width: width }}
      variant={variant}
      elevation={variant != "outlined" ? elevation : 0}
    >
      <CardContent
        className={className}
        style={{ padding: !className ? 16 : "auto" }}
      >
        {title && (
          <Typography variant="h6" component="div" className={titleClassName}>
            {title}
          </Typography>
        )}
        {subText && (
          <Typography sx={{ color: "text.secondary" }}>{subText}</Typography>
        )}
        {children}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
