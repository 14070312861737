import {
    FETCH_COMPETITOR_PRODUCTS_REQUEST,
    FETCH_COMPETITOR_PRODUCTS_SUCCESS,
    FETCH_COMPETITOR_PRODUCTS_FAILURE,
    FETCH_COMPETITOR_PRODUCT_REQUEST,
    FETCH_COMPETITOR_PRODUCT_FAILURE,
    CREATE_COMPETITOR_PRODUCT_REQUEST,
    CREATE_COMPETITOR_PRODUCT_SUCCESS,
    CREATE_COMPETITOR_PRODUCT_FAILURE,
    UPDATE_COMPETITOR_PRODUCT_REQUEST,
    UPDATE_COMPETITOR_PRODUCT_SUCCESS,
    UPDATE_COMPETITOR_PRODUCT_FAILURE,
    DELETE_COMPETITOR_PRODUCT_REQUEST,
    DELETE_COMPETITOR_PRODUCT_SUCCESS,
    DELETE_COMPETITOR_PRODUCT_FAILURE,
  } from "../constants/actionTypes";
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const competitorProductsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPETITOR_PRODUCTS_REQUEST:
      case FETCH_COMPETITOR_PRODUCT_REQUEST:
      case CREATE_COMPETITOR_PRODUCT_REQUEST:
      case UPDATE_COMPETITOR_PRODUCT_REQUEST:
      case DELETE_COMPETITOR_PRODUCT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_COMPETITOR_PRODUCTS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case CREATE_COMPETITOR_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: [...state.data, action.payload],
        };
      case UPDATE_COMPETITOR_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.map((brand) =>
            brand.id === action.payload.id ? action.payload : brand
          ),
        };
      case DELETE_COMPETITOR_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: state.data.filter((brand) => brand.id !== action.payload),
        };
      case FETCH_COMPETITOR_PRODUCTS_FAILURE:
      case FETCH_COMPETITOR_PRODUCT_FAILURE:
      case CREATE_COMPETITOR_PRODUCT_FAILURE:
      case UPDATE_COMPETITOR_PRODUCT_FAILURE:
      case DELETE_COMPETITOR_PRODUCT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default competitorProductsReducer;
  