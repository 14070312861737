import { Fragment } from "react";
import { Spinner } from "react-bootstrap";

import classes from "./BrandIndexProgress.module.css";

const BrandIndexProgress = ({ step }) => {
  return (
    <Fragment>
      {step ? (
        <div className={classes.container}>
          <div className={classes.responseText}>
            <div>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className={classes.step}> {step}...</span>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default BrandIndexProgress;
