import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";

import Layout from "./Layout";
import DiscoveryProvider from "./Discovery/discovery-context";
import { AuthContext } from "../context/auth-context";

const ContentLayout = () => {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (!authCtx.token) return;

    const tokenExpiration = localStorage.getItem("expiration");
    const now = new Date();
    const tokenDuration = new Date(tokenExpiration).getTime() - now.getTime();

    if (tokenDuration < 0) {
      //authCtx.clearSession();
      return;
    }
    
    setTimeout(() => {
      //authCtx.clearSession();
    }, tokenDuration);
  }, [authCtx.token]);

  return (
    <DiscoveryProvider>
      <Layout>
        <Outlet />
      </Layout>
    </DiscoveryProvider>
  );
};

export default ContentLayout;
