import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import { CircularProgress } from "@mui/material";

import { fetchGpt4API } from "../services/apiService";

const PromptResultPreview = ({ prompt, runScript, setShowRunPrompt }) => {
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function runPrompt() {
    setIsLoading(true);
    let result = await fetchGpt4API(
      prompt,
      new AbortController(),
      1200,
      0.1,
      "gpt-4o"
    );
    setResponse(result["GPT-4"][0]);
    setShowRunPrompt(false);
    setIsLoading(false);
  }

  useEffect(() => {
    if (runScript) {
      runPrompt();
    }
  }, [runScript]);

  return (
    <div className="d-flex justify-content-center">
      {!isLoading && response && (
        <Markdown
          style={{
            display: "table",
            borderCollapse: "separate",
            boxSizing: "border-box",
            textIndent: "initial",
            borderSpacing: "2px",
            borderColor: "gray",
            tableLayout: "fixed",
            width: "100%",
            textWrap: "wrap",
            backgroundColor: "#000000cf",
            borderRadius: "4px",
            color: "white",
          }}
          className="markTable dboxcont"
        >
          {response}
        </Markdown>
      )}
      {isLoading && (
        <div className="my-5">
          <CircularProgress size={22} />
        </div>
      )}
    </div>
  );
};

export default PromptResultPreview;
