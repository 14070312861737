import React from "react";
import { Card } from "react-bootstrap";
import BrandCard from "./BrandCard";
import ProductCard from "./ProductCard";

const MainCard = ({ data, handleIsDetailPage }) => {
  return (
    <Card
    // style={{ backgroundColor: "lightgray" }}
    >
      {data &&
        data.length !== 0 &&
        data.map((obj) => {
          return (
            <>
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <BrandCard
                  brandData={obj}
                  handleIsDetailPage={handleIsDetailPage}
                  brandName={obj?.brand_name}
                />
              </div>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {obj.products.map((product) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        padding: "0 10px 0 10px",
                      }}
                    >
                      <ProductCard
                        productData={product}
                        handleIsDetailPage={handleIsDetailPage}
                        productName={product?.product_name}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
    </Card>
  );
};

export default MainCard;
