import { useState } from "react";
import { Form, Button } from "react-bootstrap";

import classes from "./MonitorPrompt.module.css";
import MonitorPromptPreview from "./MonitorPromptPreview";

function MonitorPrompt({
  aggregationPrompt,
  baselineTablePrompt,
  setAggregationPrompt,
  comparisonPrompt,
  monitoringTablePrompt,
  setComparisonPrompt,
  setBaselineTablePrompt,
  setMonitoringTablePrompt,
  isLoading,
  regenerateBaselineResult,
  regenerateBaselineTable,
  regenerateMonitoringResult,
  regenerateMonitoringTable,
  data,
}) {
  const [showPreview, setShowPreview] = useState(false);
  const [isBaselineTablePrompt, setIsBaselineTablePrompt] = useState(false);
  const [isMonitoringTablePrompt, setIsMonitoringTablePrompt] = useState(false);

  const baselinePrompt = isBaselineTablePrompt
    ? `${baselineTablePrompt}.here is raw markdown string ==>. <Raw String>`
    : `${aggregationPrompt}.multiple results in raw strings are==> <strings with multiple set of data>`;

  const monitoringPrompt = isMonitoringTablePrompt
    ? `${monitoringTablePrompt}.Existing markdown table is==> <Existing Table> and new dataset is ==> <comparison data>`
    : `${comparisonPrompt} baseline data is==> <baseline Data>  & latest version data is===> <Recent API Data>`;

  return (
    <>
      <MonitorPromptPreview
        handleClose={() => setShowPreview(false)}
        show={showPreview}
        prompt={data[0]?.isMonitored ? monitoringPrompt : baselinePrompt}
      />
      <Form className={classes.form}>
        <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            
            {data[0]?.isMonitored
              ? "Data Comparison Prompt :"
              : "Unique Data Synthesization Prompt :"}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={data[0]?.isMonitored ? comparisonPrompt : aggregationPrompt}
            onChange={(e) =>
              data[0]?.isMonitored
                ? setComparisonPrompt(e.target.value)
                : setAggregationPrompt(e.target.value)
            }
          />
          <br />
          <div className={classes.actions1}>
            <Button
              variant="outlined"
              size="small"
              style={{
                backgroundColor: "#37ad52",
                // margin: "3px 6px",
                fontSize: "14px",
                padding: "10px 12px",
                color: "white",
              }}
              disabled={isLoading}
              onClick={() => {
                setShowPreview(true);
                data[0]?.isMonitored
                  ? setIsMonitoringTablePrompt(false)
                  : setIsBaselineTablePrompt(false);
              }}
            >
              Preview Prompt
            </Button>
          </div>
        </Form.Group>
        <hr />
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            
            {data[0]?.isMonitored
              ? "Monitoring Table Prompt :"
              : "Baseline Table Prompt :"}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={
              data[0]?.isMonitored ? monitoringTablePrompt : baselineTablePrompt
            }
            onChange={(e) =>
              data[0]?.isMonitored
                ? setMonitoringTablePrompt(e.target.value)
                : setBaselineTablePrompt(e.target.value)
            }
          />
          <br />
          <div className={classes.actions1}>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#37ad52",
                // margin: "3px 6px",
                fontSize: "14px",
                padding: "10px 12px",
                color: "white",
              }}
              disabled={isLoading}
              onClick={() => {
                setShowPreview(true);
                data[0]?.isMonitored
                  ? setIsMonitoringTablePrompt(true)
                  : setIsBaselineTablePrompt(true);
              }}
            >
              Preview Prompt
            </Button>
          </div>
        </Form.Group>
        <div className={classes.actions}>
          <Button
            onClick={() =>
              data[0]?.isMonitored
                ? regenerateMonitoringResult(data)
                : regenerateBaselineResult(data)
            }
            disabled={isLoading}
          >
            {data[0]?.isMonitored
              ? "Compare and Regenerate Table"
              : "Aggregate and Regenerate Table"}
          </Button>
          <Button
            onClick={() =>
              data[0]?.isMonitored
                ? regenerateMonitoringTable(data)
                : regenerateBaselineTable(data)
            }
            disabled={isLoading}
          >
            {data[0]?.isMonitored
              ? "Regenerate Monitoring Table"
              : "Regenerate Baseline Table"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default MonitorPrompt;
