import React from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import classes from "../settings.module.css"
const MetricsForm = ({
  metricsName,
  setMetricsName,
  metricsDetails,
  setMetricsDetails,
}) => {
  return (
    <div className={classes.divContainer}>
      <TextInput
        label="Metrics Name"
        variant="outlined"
        fullWidth
        value={metricsName}
        onChange={(e) => setMetricsName(e.target.value)}
        // className={classes.textField}
      />

      <TextInput
        label="Details for Metrics"
        variant="outlined"
        fullWidth
        value={metricsDetails}
        onChange={(e) => setMetricsDetails(e.target.value)}
        // className={classes.textField}
      />
    </div>
  );
};

export default MetricsForm;
