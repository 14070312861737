import Markdown from "markdown-to-jsx";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import MUIIconButton from "./../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./Message.module.css";

export default function Message({ content, role }) {
  let container = classes.messageContainer;
  let message = classes.message;

  if (role === "user") {
    message += ` ${classes.user}`;
    container += ` ${classes.userContainer}`;
  } else {
    message += ` ${classes.assistant}`;
  }

  return (
    <div className={container}>
      <span className={message}>
        <Markdown className={classes.content}>{content}</Markdown>
        {/* <MUIIconButton className={classes.copy}>
          <ContentCopyOutlinedIcon style={{ fontSize: 10 }} fontSize={"12"} />
        </MUIIconButton> */}
      </span>
    </div>
  );
}
