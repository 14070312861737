import { useEffect, useState } from "react";
import Markdown from "markdown-to-jsx";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import html2pdf from "html2pdf.js";
import * as ReactDOMServer from "react-dom/server";
import { Spinner } from "react-bootstrap";

import { extractLinks } from "../../../utils/helpers";
import classes from "./LLMResultOutput.module.css";
import { checkWebLink } from "../../../services/apiService";
import PDFLayout from "../../MainDataComponent/PDFLayout";

function LLMResultOutput({ report }) {
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const { data: markdown, LLMs, inputs, title } = report;

  useEffect(() => {
    const markdownLinks = extractLinks(markdown);
    validateLinks(markdownLinks);
  }, []);

  async function validateLinks(links = []) {
    links = links ?? [];
    const linkPromises = links.map((link) =>
      checkWebLink(link, new AbortController())
    );
    const anchorsOnWeb = document.getElementsByTagName("a");

    for (let a of anchorsOnWeb) {
      a.innerText = a.href;
    }

    try {
      linkPromises.forEach((promise) => {
        promise
          .then((response) => {
            for (let anchorWeb of anchorsOnWeb) {
              if (anchorWeb.href === response.link.toString().trim()) {
                if (response.status == "success") {
                  anchorWeb.style.color = "rgb(13 110 253)";
                  anchorWeb.setAttribute("target", "_blank");
                } else {
                  anchorWeb.style.color = "gray";
                  anchorWeb.href = "#";
                  anchorWeb.classList.add("disabled");
                }
              } else {
                anchorWeb.setAttribute("target", "_blank");
                anchorWeb.classList.add("pending_link");
              }
            }
          })
          .catch((error) => {
            console.log("LOG ERRR", error);
          });
      });
    } catch (error) {
      console.log("Something went wrong: ", error.message);
    }
  }

  function copyToClipboard() {
    navigator.clipboard
      .writeText(markdown)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        setIsCopied(false);
      });
  }

  const downloadPDF = async (targetEle, brand, type, llm) => {
    setLoading(true);
    const options = {
      margin: [15, 15],
      filename: `${type}_${llm}_${brand}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    const contentHTML = ReactDOMServer.renderToStaticMarkup(
      <PDFLayout
        brand={brand || "--"}
        llm={llm}
        type={type}
        targetEle={targetEle}
      />
    );
    try {
      await html2pdf(contentHTML, options);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.results}>
        <div className={classes.md} id="md">
          <span className={classes.actions}>
            <IconButton
              aria-label="copy"
              className={classes.btn}
              size="small"
              onClick={copyToClipboard}
            >
              {isCopied ? (
                <DoneOutlinedIcon fontSize="inherit" />
              ) : (
                <ContentCopyOutlinedIcon fontSize="inherit" />
              )}
            </IconButton>
            <IconButton
              aria-label="download"
              className={classes.btn}
              size="large"
              onClick={(e) => {
                const target = e.target
                  .closest("#md")
                  .querySelector(".content");

                downloadPDF(target, inputs.toString(), title.split(" ").join("_"), LLMs.join("_"));
              }}
            >
              {!loading && <FileDownloadOutlinedIcon />}
              {loading && (
                <Spinner
                  animation="border"
                  style={{ color: "whitesmoke" }}
                  size="sm"
                />
              )}
            </IconButton>
          </span>
          <div className="content">
            <Markdown>{markdown}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LLMResultOutput;
