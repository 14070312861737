import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Skeleton,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../Component/UI/Table/Table";
import { DiscoveryContext } from "../discovery-context";
import { extractDomain } from "../../../utils/helpers";
import classes from "./Taxonomy.module.css";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import MUIIconButton from "../../../Component/UI/MUIIconButton/MUIIconButton";
import api from "../../../BrandOnBoarding/api";

const TABLE_HEADERS = [
  {
    id: "taxonomy",
    name: "Product Dimensions",
  },
];

function Row({ category }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment key={category.name}>
      <StyledTableRow>
        <StyledTableCell className={classes.attribute_headings}>
          <div className={classes.heading_wrapper}>
            <MUIIconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MUIIconButton>
            <span className={classes.heading_text}>{category.name}</span>
          </div>
        </StyledTableCell>
        {category.products.map((product) => {
          let color = "#dc3545";
          if (product.score >= 7) color = "#2196F3";
          if (product.score >= 9) color = "#198754";

          return (
            <StyledTableCell
              style={{ color }}
              className={classes.attribute_score}
              key={product.name}
            >
              {product.score}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
      {open &&
        category.products[0].subcategories.map((sub, idx) => (
          <StyledTableRow key={idx} className={classes.sub_row}>
            <StyledTableCell className={classes.attribute_sub_headings}>
              {sub.name}
            </StyledTableCell>
            {category.products.map((product, jdx) => {
              let color = "#dc3545";
              if (product.subcategories[idx].score >= 7) color = "#2196F3";
              if (product.subcategories[idx].score >= 9) color = "#198754";
              return (
                <Tooltip
                  key={jdx}
                  title={product.subcategories[idx].explanation}
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -18],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <StyledTableCell
                    style={{ color }}
                    className={classes.attribute_score}
                  >
                    {product.subcategories[idx].score}
                  </StyledTableCell>
                </Tooltip>
              );
            })}
          </StyledTableRow>
        ))}
    </React.Fragment>
  );
}

export default function Taxonomy() {
  const [error, setError] = useState(null);
  const discoveryCtx = useContext(DiscoveryContext);

  const abortControllerRef = useRef(null);

  async function getProductTaxonomy() {
    setError(null);
    abortControllerRef.current = new AbortController();
    const competitors = discoveryCtx.competitors.map((c) => c.name);

    const payload = {
      llm: discoveryCtx.llm,
      category: discoveryCtx.category,
      products: competitors,
    };

    try {
      if (discoveryCtx.llm === "Perplexity") {
        await new Promise((res) => setTimeout(() => res(""), 120000));
      }
      const response = await api.post(
        "/metrics/product-taxonomy",
        payload,
        { signal: abortControllerRef.current.signal }
      );

      discoveryCtx.updatedTaxonomies(response.data.data);
    } catch (error) {
      console.log("Error: ", error.message);
      setError(error.message);
    }
  }

  useEffect(()=>{
    if(discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  },[discoveryCtx.abort_launch])

  function LoadingSkeletion() {
    return (
      <div className="p-3">
        <Skeleton variant="rectangular" height={30} />
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              variant="rectangular"
              width={index % 2 === 0 ? "70%" : "97%"}
              key={index}
              className="my-3"
              height={20}
            />
          ))}
      </div>
    );
  }

  useEffect(() => {
    if (
      discoveryCtx?.taxonomies &&
      Object.keys(discoveryCtx?.taxonomies).length > 0
    ) {
      return;
    }
    getProductTaxonomy();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  function renderHeaders() {
    const headers = [
      ...TABLE_HEADERS,
      ...discoveryCtx.competitors.map((item) => ({
        id: item.name,
        name: item.name,
      })),
    ];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers.map((header, index) => {
            let ctxCompetitor = null;
            if (index !== 0) {
              ctxCompetitor = discoveryCtx.competitors[index - 1];
            }

            let domain = "";
            if (ctxCompetitor && ctxCompetitor?.domain) {
              domain = extractDomain(ctxCompetitor?.domain);
            }

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  function roundNumber(num) {
    return num % 1 < 0.5 ? Math.floor(num) : Math.ceil(num);
  }

  function renderRows(category) {
    return <Row key={category.name} category={category} />;
  }

  let loading = true;
  let categories = [];

  if (
    discoveryCtx?.taxonomies &&
    Object.keys(discoveryCtx?.taxonomies).length > 0
  ) {
    categories = discoveryCtx?.taxonomies.categories.map((category) => ({
      name: category.name,
      products: discoveryCtx?.taxonomies.products.map((product) => {
        const totalScore = Object.values(
          product.scores[category.name].subcategories
        ).reduce((acc, item) => acc + item.score, 0);

        const average =
          totalScore /
          Object.values(product.scores[category.name].subcategories).length;

        return {
          name: product.name,
          score: roundNumber(average),
          subcategories: Object.keys(
            product.scores[category.name].subcategories
          ).map((subcat) => ({
            name: subcat,
            score: product.scores[category.name].subcategories[subcat].score,
            explanation:
              product.scores[category.name].subcategories[subcat].explanation,
          })),
        };
      }),
    }));
    loading = false;
  }

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      {!loading && !error && categories?.length > 0 && (
        <MUITable
          elevation={0}
          data={categories}
          renderHeaders={() => renderHeaders()}
          render={renderRows}
        />
      )}
      {error && (
        <div className={classes.error}>
          <ErrorView
            title={"Error fetching category dimensions"}
            message={error}
            retry
            onRetry={() => getProductTaxonomy()}
          />
        </div>
      )}
      {loading && !error && <LoadingSkeletion />}
    </MUICard>
  );
}
