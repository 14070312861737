import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { layout } from "../../LayoutComponent/LayoutComponent";
import classes from "./Reporting.module.css";
import Modal from "../../Modal/Modal";
import LLMResultOutput from "./LLMResultOutput";
import {
  fetchDataFromFirebase,
  deleteDataFromFirebase,
} from "../../../DatabaseFirebase/firebaseService";
import { AuthContext } from "../../../context/auth-context";
import { triggerReportingJob } from "../../../services/apiService";

let firstLoad = false;

const LLM_OPTIONS = {
  "gpt-4": "GPT-4",
  "gpt-4o": "GPT-4o",
  "gpt-3.5-turbo": "GPT-3.5",
  "gpt-4_Web": "GPT-4 Web",
  perplexity: "Perplexity",
  gemini: "Gemini",
  llama2_70b_chat: "Llama2",
  llama3: "Llama3",
  claude: "Claude3",
  palm2_text: "Palm2",
  gpt_4_turbo: "GPT-4 Turbo",
};

function ReportingTable() {
  const [reports, setReports] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [reportSelected, setReportSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [triggering, setTriggering ] = useState(false);

  const { authUserEmail } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!firstLoad) setLoading(true);
    fetchDataFromFirebase(
      (data) => {
        const updatedReports = [];
        for (let key in data) {
          updatedReports.push({
            ...data[key],
            id: key,
          });
        }
        firstLoad = true;
        setLoading(false);

        const filteredReports = updatedReports
          .filter((report) => report.email === authUserEmail)
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        setReports(filteredReports);
      },
      "reporting",
      localStorage.getItem("authEmail")
    );
  }, []);

  const handleDeleteConfirm = () => {
    deleteDataFromFirebase(reportToDelete, "reporting")
      .then(() => {
        setReports(reports.filter((report) => report.id !== reportToDelete));
        setShowDeleteConfirmation(false);
        setReportToDelete(null);
      })
      .catch((error) => {
        console.error("Failed to delete report:", error);
      });
  };

  const handleAddClick = () => {
    localStorage.setItem("fromReporting", "true");
    navigate("/add-report?tab=-1");
  };

  const handleReportJobTrigger = async (report) => {
    try {
      setTriggering(true);
      const payload = { queries: report.queries, title: report.title, id: report.id }
      await triggerReportingJob(payload, new AbortController());
      setTriggering(false);
    } catch (err) {
      setTriggering(false);
    }
  }

  useEffect(() => {
    if (reportSelected && !showReport) {
      setShowReport(true);
    }
  }, [reportSelected]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3>Reporting</h3>
        <Button onClick={handleAddClick} className={classes.report_btn}>
          Add Report
        </Button>
      </div>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <TableContainer component={Paper}>
        {showReport && (
          <Modal
            open={showReport}
            handleClose={() => {
              setShowReport(false);
              setReportSelected(null);
            }}
            title={reportSelected.title}
            width={930}
          >
            <LLMResultOutput report={reportSelected} />
          </Modal>
        )}
        {!loading && reports.length == 0 && (
          <div className={classes.no_reports}>
            No reports found here. Start creating new one.
          </div>
        )}

        {showDeleteConfirmation && (
          <Modal
            open={showDeleteConfirmation}
            handleClose={() => setShowDeleteConfirmation(false)}
            title="Confirm Delete?"
            showActions={true}
            confirmBtnText={"Delete"}
            width="600"
            handleConfirm={handleDeleteConfirm}
          >
            <p>Are you sure you want to delete this report?</p>
          </Modal>
        )}

        {!loading && reports.length > 0 && (
          <Table
            sx={{ minWidth: 650 }}
            className={classes.table}
            aria-label="simple table"
          >
            <TableHead className={classes.thead}>
              <TableRow className={classes.row}>
                <TableCell className={classes.cell} align="center">
                  Report
                </TableCell>
                <TableCell
                  className={`${classes.head} ${classes.cell}`}
                  align="center"
                >
                  Company/Brand in focus
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  LLMs
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Attributes
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Date
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Status
                </TableCell>
                <TableCell className={classes.cell} align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((row) => {
                const toolTipText =
                  row.statusText == "individual-prompt"
                    ? "Running prompts..."
                    : "Summarizing...";
                return (
                  <TableRow key={row.id} className={classes.row}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      className={classes.cell}
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {row.inputs.filter((i) => i).join(", ")}
                    </TableCell>
                    <TableCell align="right" className={classes.cell}>
                      {row.LLMs.map((item) => (
                        <Chip
                          key={item}
                          size="small"
                          className={classes.chip}
                          label={
                            LLM_OPTIONS[item.toString().trim().toLowerCase()]
                          }
                        />
                      ))}
                    </TableCell>
                    <TableCell align="right" className={classes.cell}>
                      {row.updatedAttr.map((item) => (
                        <Chip
                          key={item}
                          size="small"
                          className={classes.chip}
                          label={item}
                        />
                      ))}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {moment(row.date).format("DD-MM-YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {row.status == "done" ? (
                        <IconButton>
                          <CheckCircleIcon sx={{ color: "green" }} />
                        </IconButton>
                      ) : row.status == "failed" ? (
                        <IconButton>
                          <ErrorOutlineIcon sx={{ color: "red" }} />
                        </IconButton>
                      ) : (
                        <Tooltip title={toolTipText}>
                          <IconButton className={classes.progress}>
                            <ScheduleIcon sx={{ color: "orange" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.cell}>
                      {row.data && (
                        <IconButton
                          aria-label="view"
                          onClick={() => {
                            if (!row.data) return;
                            setReportSelected(row);
                          }}
                        >
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      )}
                      {row.status === "failed" && (
                        <Tooltip title="Re-run report">
                          <IconButton
                            disabled={triggering}
                            aria-label="view"
                            onClick={() => {
                              handleReportJobTrigger(row)
                            }}
                          >
                            <AutorenewIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setReportToDelete(row.id);
                          setShowDeleteConfirmation(true);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}
export default layout(ReportingTable, false);
