import { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import classes from "../Brand/Brand.module.css";
import { getUsers, deleteUser } from "../../../redux/actions/userActions";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "../../../Component/UI/Table/Table";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import { AuthContext } from "../../../context/auth-context";
import Spinner from "./../../../Component/Spinner/Spinner";

const TABLE_HEADERS = [
  { id: "id", name: "Id", sorting: true },
  { id: "email", name: "Email", sorting: false },
  { id: "verified", name: "Verified", sorting: true },
  { id: "active", name: "Active", sorting: true },
  { id: "created", name: "Created at", sorting: true },
  { id: "actions", name: "Actions", sorting: false },
];

export default function Users() {
  const dispatch = useDispatch();

  const { users, deleteUserError, updateUserError, addUserError, isLoading } =
    useSelector((state) => state.user);

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    dispatch(getUsers(authCtx?.user?.customer_id || -1));
  }, []);

  let errorMessage = null;
  if (deleteUserError) {
    errorMessage = `Failed to delete an user - ${deleteUserError}`;
  }
  if (updateUserError) {
    errorMessage = `Failed to update an user - ${updateUserError}`;
  }
  if (addUserError) {
    errorMessage = `Failed to add an user - ${addUserError}`;
  }
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    // Format date as DD-MM-YYYY
    const formattedDate = date.toLocaleDateString("en-GB"); // en-GB provides DD-MM-YYYY format

    // Format time as 12-hour format with AM/PM
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  };
  const renderRow = (item) => (
    <StyledTableRow key={item.id}>
      <StyledTableCell component="th" scope="row" style={{ width: 150 }}>
        {item.id}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" style={{ width: 300 }}>
        {item.email}
      </StyledTableCell>

      <StyledTableCell align="left" style={{ width: 200 }}>
        <div className={classes.status}>
          <CheckCircleIcon style={{ color: "#028101" }} />
          <span>{item.is_verified == true ? "Verified" : "Not Verified"}</span>
        </div>
      </StyledTableCell>

      <StyledTableCell align="left" style={{ width: 200 }}>
        <div className={classes.status}>
          <CheckCircleIcon style={{ color: "#028101" }} />
          <span>{item.is_active == 1 ? "Active" : "Inactive"}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell align="left" style={{ width: 300 }}>
        {formatDate(item.date_added)}
      </StyledTableCell>
      <StyledTableCell align="left">
        <div>
          <MUIIconButton
            tooltip="Update"
            style={{ color: "#007aff" }}
            className={classes.action_btn}
          >
            <CachedIcon />
          </MUIIconButton>
          <MUIIconButton
            tooltip="Delete"
            style={{ color: "#da1a04" }}
            className={classes.action_btn}
            onClick={() => {
              console.log(item, "item");
              deleteUserCreated(item);
            }}
          >
            <DeleteOutlineIcon />
          </MUIIconButton>
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );

  const deleteUserCreated = (item) => {
    dispatch(deleteUser(authCtx.user, item.id));
  };
  return (
    <>
      {!isLoading && users.length > 0 && (
        <Table headers={TABLE_HEADERS} data={users} render={renderRow} />
      )}
      {isLoading && <Spinner />}
    </>
  );
}
