import React, { useEffect, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBrands, deleteBrand } from "../../../redux/actions/brandsActions";
import { fetchPersonas } from "../../../redux/actions/personasActions";
import { fetchcoreAttributes } from "../../../redux/actions/coreAttributeActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { AuthContext } from "../../../context/auth-context";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router";
import JobInputFormModal from "../../forms/JobInputFormModal";
import Typography from "@mui/material/Typography";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import { generateBrandMetrics } from "../../../services/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BrandComponent = ({
  handleSelectedData,
  llms,
  markets,
  geographies,
  customerLlms,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState();
  const [selectedBrandCustomerId, setSelectedBrandCustomerId] = useState();
  const [brandPersonas, setBrandPersonas] = useState([]);
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands);
  const personas = useSelector((state) => state.personas);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;
  const [jobIsInprogress, setJobIsInProgress] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBrandMetricsJob = async (
    llmId,
    brandPersonaId,
    marketId,
    globalLlmId,
    selectedId,
    brandcustomerId
  ) => {
    setJobIsInProgress(selectedId);
    setShowModal(false);
    generateBrandMetrics(llmId, brandPersonaId)
      .then(() => {
        setJobIsInProgress(false);
        navigate(
          `/brandonboarding/dashboard?brandId=${selectedBrandId}
          &personaId=${brandPersonaId}&marketId=${marketId}
          &llmId=${globalLlmId}&brandCustomerId=${brandcustomerId}&isDetailPage=1`
        );
      })
      .catch((error) => {
        toast.error(error?.message || "something went wrong !", {
          autoClose: 700,
        });
        setJobIsInProgress(false);
      });
  };

  useEffect(() => {
    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchBrands(customerId));
    }
    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }

    if (coreAttributes?.data && coreAttributes?.data.length === 0) {
      dispatch(fetchcoreAttributes(customerId));
    }
  }, [dispatch]);

  const handleDeleteBrand = (brandId) => {
    dispatch(deleteBrand(customerId, brandId)).then(() => {
      dispatch(fetchBrands(customerId));
    });
  };
  const handleBrandPersonas = (persona, id, customerId) => {
    setBrandPersonas(persona);
    setSelectedBrandId(id);
    setSelectedBrandCustomerId(customerId);
    setShowModal(true);
  };
  if (brands.loading) {
    return <p>Loading...</p>;
  }

  if (brands.deleteLoading) {
    return <p> Brand is being deleted Please wait...</p>;
  }

  if (brands.error) {
    return <p>Error: {brands.error}</p>;
  }

  const brandsData = brands?.data?.data.filter((obj) => obj.is_active);

  return (
    <>
      <JobInputFormModal
        show={showModal}
        handleClose={handleCloseModal}
        llms={llms}
        markets={markets}
        geographies={geographies}
        personas={brandPersonas}
        customerLlms={customerLlms}
        handleBrandMetricsJob={handleBrandMetricsJob}
        jobIsInprogress={jobIsInprogress}
        brandId={selectedBrandId}
        brandCustomerId={selectedBrandCustomerId}
      />
      <Table>
        <thead style={{ backgroundColor: "#CCCCCC" }}>
          <tr>
            <th>Brand</th>
            <th>Details</th>
            <th>Tags</th>
            <th>Category</th>
            <th>Competitor</th>
            <th>Persona</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {brandsData &&
            brandsData.length !== 0 &&
            brandsData.map((data) => {
              return (
                <tr>
                  <td>{data?.brand?.name}</td>
                  <td>{data?.brand?.description}</td>
                  <td>Main</td>
                  <td>
                    {/* {data.categories.map((category, index) => (
                    <p key={index}>{category.name}</p>
                  ))} */}
                    {data?.category?.name}
                  </td>
                  <td>
                    {data?.competitors &&
                      data?.competitors.length !== 0 &&
                      data?.competitors.filter((obj)=>obj?.is_active).map((data) => {
                        return <p> {data?.brand?.name}</p>;
                      })}
                  </td>
                  <td>
                    {data?.persona &&
                      data?.persona.length !== 0 &&
                      data?.persona.map((data) => {
                        return <p> {data?.persona?.name}</p>;
                      })}
                  </td>
                  <td>
                    {jobIsInprogress != data?.id ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleIcon
                          style={{ color: "#4CAF50", marginRight: 4 }}
                        />
                        <Typography style={{ color: "#4CAF50" }}>
                          ACTIVE
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <HourglassTopIcon
                          style={{ color: "#FFA500", marginRight: 4 }}
                        />
                        <Typography style={{ color: "#FFA500" }}>
                          In-Process
                        </Typography>
                      </Box>
                    )}
                  </td>
                  <td className={classes.actions}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        style={{
                          backgroundColor: "green",
                        }}
                        disabled={data?.customer?.id != customerId}
                        variant="success"
                        onClick={() =>
                          handleBrandPersonas(
                            data?.persona,
                            data?.id,
                            data?.customer?.id
                          )
                        }
                      >
                        Run
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "green",
                        }}
                        variant="success"
                        onClick={() => handleSelectedData(data)}
                      >
                        Update
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "#d32f2f",
                        }}
                        variant="danger"
                        onClick={() => handleDeleteBrand(data.id)}
                      >
                        Delete
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "blue",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        // variant="danger"
                        onClick={() =>
                          navigate(
                            `/brandonboarding/dashboard?brandId=${data?.id}&brandCustomerId=${data?.customer?.id}&isDetailPage=1`
                          )
                        }
                      >
                        Dashboard
                        <FaArrowRight
                          style={{ cursor: "pointer" }}
                          // size={20}
                          onClick={() => {
                            // navigate("/brandonboarding/dashboard");
                            // setIsDetailPage(false);
                          }}
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ToastContainer />
    </>
  );
};

export default BrandComponent;
