import Expandable from "../Expandable/Expandable";
import RankingItem from "./RankingItem";

export default function Rankings({ rankings }) {
  return (
    <Expandable
      data={rankings}
      render={(ranking) => <RankingItem ranking={ranking} />}
    />
  );
}