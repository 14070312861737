import { Skeleton } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <div className="p-3">
      {Array(4)
        .fill(0)
        .map((_, index) => {
          return <Skeleton key={index} variant="rectangular" className="mb-2" height={50} />;
        })}
    </div>
  );
}
