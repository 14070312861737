const PDFLayout = ({
  brand,
  type,
  llm,
  targetEle,
  category = "discovery",
  reportType = "",
  promptCount,
  metrics,
  categoryName,
}) => {
  return (
    <div
      className="container"
      style={{ color: "black", backgroundColor: "white" }}
    >
      <div className="d-flex flex-column mb-4">
        <div className="d-flex flex-column align-items-center">
          <span className="d-flex flex-row align-items-center align-self-center">
            <img src="/menu.png" width={26} height={26} />
            <span className="m-2"></span>
            <h3 className="mt-1">Revere: Brand Luminaire</h3>
          </span>
          {reportType && <div>{reportType}</div>}
        </div>
        <hr />
        <div className="d-flex flex-column">
          {category == "discovery" ||
          category == "monitoring" ||
          category == "Dashboard" ? (
            <>
              {categoryName ? (
                <span>
                  <b>Brand/Product Category:</b> {categoryName}
                </span>
              ) : (
                <span>
                  <b>Brand/Product:</b> {brand}
                </span>
              )}

              <span>
                <b>Type:</b> {type}
              </span>

              <span>
                <b>LLM:</b> {llm}
              </span>
              {metrics && (
                <span>
                  <b>Metrics:</b> {metrics}
                </span>
              )}

              {promptCount && (
                <span>
                  <b>Number of Prompts:</b> {promptCount}
                </span>
              )}
            </>
          ) : null}
          <span>
            <b>Date:</b> {new Date().toLocaleString()}
          </span>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: targetEle?.innerHTML.trim() }}
        style={{ color: "black", backgroundColor: "white" }}
      ></div>
    </div>
  );
};

export default PDFLayout;
