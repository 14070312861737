export default function Tabs({ options, selected, onChange }) {
  return (
    <nav className="customTabs">
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        {options.map((option) => {
          return (
            <button
              key={option.value}
              onClick={() => {
                onChange(option.value);
              }}
              className={`nav-link ${selected === option.value ? "active" : ""}`}
              id="nav-overview-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-overview"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              {option.name}
            </button>
          );
        })}
      </div>
    </nav>
  );
}
