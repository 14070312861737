import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default function CustomTooltip({ children, explaination }) {
  return (
    <div>
      <CustomWidthTooltip title={explaination}>{children}</CustomWidthTooltip>
    </div>
  );
}
