import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Skeleton,
} from "@mui/material";
import Markdown from "markdown-to-jsx";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import api from "../../../BrandOnBoarding/api";
import ErrorView from "./../../../Component/ErrorView/ErrorView";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import classes from "./Summary.module.css";
import SummaryList from "./SummaryList";

const Summary = ({
  messages,
  loading: isResponseLoading,
  enabled,
  conversationId,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [summary, setSummary] = useState("");
  const [summaryData, setSummaryData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const isHistoryResetted = messages.every((item) => item.msgs.length == 0);

  useEffect(() => {
    if (isHistoryResetted) {
      setSummary("");
    }
  }, [isHistoryResetted]);

  function getLastMessageFromConversations() {
    return messages.map((msg) => {
      return {
        name: msg.llm,
        message: msg.msgs.slice(-1)[0]?.content,
      };
    });
  }

  async function generateSummary() {
    const messages = getLastMessageFromConversations();
    if (messages.every((msg) => !msg.message || msg.message.length == 0)) {
      return;
    }
    if (isResponseLoading) return;

    let prompt = "";
    messages.forEach((msg) => {
      prompt += `#${msg.name}: ${msg.message}\n\n`;
    });
    prompt += `take the following outputs across LLMs and identify the common themes and major differences. When analyzing and describing common themes and major differences focus on fact and content common themes and differences. DO NOT focus on level of analysis, level of detail, overall comprehensiveness, level of conciseness, or structure of the results. If sources are listed in the outputs, please be sure to provide themes and major differences for that category. Keep your response concise. It is fine to use bullets. Create a brief summary paragraph at the end listing the 3 major themes, and 3 major differences.`;

    const payload = {
      llm: "gpt-4o",
      payload: {
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 1,
        max_completion_tokens: 60536,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        model: "o1-mini",
      },
    };

    try {
      setLoading(true);
      setExpanded(true);
      const response = await api.post("/v2/llm-execute", payload);
      const data = response.data.data;
      setSummary({ data: data });
      saveSynthesis(data);
    } catch (error) {
      setError(error?.message || "Something has went wrong");
    } finally {
      setLoading(false);
    }
  }

  async function saveSynthesis(data) {
    const response = await api.post(
      `/llm-conversations/sythesis/${conversationId}`,
      { content: data }
    );
    if (response.status === 200) {
      setSummaryData((prevData) => {
        return [...prevData, response.data.data];
      });
      const newSummaryItem = response.data.data;
      setSummary({ id: newSummaryItem.id, data: newSummaryItem.content });
    }
  }

  async function deleteSynthesis() {
    try {
      const response = await api.delete(
        `/llm-conversations/sythesis/${summary?.id}`
      );
      if (response.status === 200) {
        setSummaryData((prevData) => {
          return prevData.filter((item) => item.id != summary.id);
        });
        setSummary("");
      } else {
        setError("failed to delete synthesis");
      }
    } catch (error) {
      setError("failed to delete synthesis");
    }
  }

  function getSkeleton() {
    return (
      <>
        <Skeleton width={"100%"} />
        <Skeleton width={"80%"} />
        <Skeleton width={"90%"} />
        <Skeleton width={"95%"} />
        <Skeleton width={"70%"} />
      </>
    );
  }

  let content = "";
  if (!loading && summary && !error) {
    content = <Markdown className={classes.text}>{summary.data}</Markdown>;
  }
  if (loading) {
    content = getSkeleton();
  }
  if (error) {
    content = (
      <div className="mt-4">
        <ErrorView title="An error occured" message={error} />
      </div>
    );
  }

  function historyItemSelectHandler(item) {
    setSummary({ id: item.id, data: item.content });
  }

  async function getSummaryData() {
    try {
      const response = await api.get(
        `/llm-conversations/sythesis/${conversationId}`
      );
      setSummaryData(response.data.data);
    } catch (error) {
      setError("failed to fetch synthesis history");
    }
  }

  useEffect(() => {
    if (!conversationId) {
      setSummaryData([]);
      setSummary(null);
      setError(null);
      return;
    }
    setSummary(null);
    getSummaryData();
    setError(null);
  }, [conversationId]);

  const regenEnabled = !loading && !isResponseLoading && enabled && !error;

  return (
    <div className={classes.box}>
      <Accordion
        elevation={0}
        expanded={true}
        onChange={() => setExpanded((value) => !value)}
        disableGutters
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.title}
        >
          LLM Synthesis and Comparison
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <SummaryList
            activeItemId={summary?.id}
            summaryData={summaryData}
            conversationId={conversationId}
            onItemSelect={historyItemSelectHandler}
          />
          <div className={classes.content}>
            <div>{content}</div>
            {summary?.id && !loading && (
              <MUIIconButton
                className={classes.trash_btn}
                onClick={deleteSynthesis}
              >
                <DeleteOutlineIcon />
              </MUIIconButton>
            )}
            {regenEnabled && !summary?.data && (
              <div className={classes.hint}>
                Click 'Generate' to compare responses from multiple language
                models. This tool synthesizes outputs from different LLMs to
                provide a comprehensive analysis and comparison of their
                capabilities on LLM responses
              </div>
            )}
            {summaryData.length === 0 &&
              !regenEnabled &&
              !loading &&
              !error && (
                <div className={classes.hint}>
                  Start your conversation to unlock AI comparisons. The
                  'Generate' button will appear once you begin.
                </div>
              )}
          </div>
        </AccordionDetails>
        <AccordionActions>
          {regenEnabled && (
            <MUIButton
              onClick={generateSummary}
              variant="outlined"
              className={classes.regenerate}
              startIcon={<AutoAwesomeOutlinedIcon />}
            >
              Generate
            </MUIButton>
          )}
        </AccordionActions>
      </Accordion>
    </div>
  );
};

export default Summary;
