import { useEffect, useState, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

import ReportItem from "./ReportItem";
import { REPORTS } from "../../../utils/helpers";
import { addReportingJob } from "../../../services/apiService";
import { AuthContext } from "../../../context/auth-context";

function ReportingList({
  gpt4TurboPrompts,
  llama2Prompts,
  palm2Prompts,
  gpt4Prompts,
  geminiPrompts,
  claudePrompts,
  promptData,
}) {
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [LLMs, setLLMs] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authUserEmail } = useContext(AuthContext);

  useEffect(() => {
    setLLMs([]);
    setAttributes([]);
    setInputs([]);
  }, [activeItemIndex]);

  const promptMessage = (prompts) => {

    let inputQuery = promptData;
    if(activeItemIndex === 3) {
      inputQuery = inputs.toString();
    }

    let message = `You are an AI assistant providing information about ${inputQuery} for an end consumer. Please create a JSON object with the following structure:`

    if (activeItemIndex === 1) {
      message = `You are an AI assistant providing information about one or more specific brands or products for an end consumer. The brand/product name(s) will be provided in ${promptData} and optionally ${inputs.toString()}. Please create a JSON object with the following structure:`
    }

    let jsonKeys = [];

    // LLM Comparision
    if (activeItemIndex === 0) {
      if (attributes.includes("Brand Description")) {
        jsonKeys.push("brand_description");
      }
      if (attributes.includes("Brand Attributes")) {
        jsonKeys.push("brand_attributes");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Competitive Set")) {
        jsonKeys.push("competitive_set");
      }
      if (attributes.includes("Sources")) {
        jsonKeys.push("sources");
      }
    }

    // Competitors
    if (activeItemIndex === 1) { 
      if (attributes.includes("Brand Description")) {
        jsonKeys.push("brand_description");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Sources")) {
        jsonKeys.push("sources");
      }
    }

    // Key sources
    if (activeItemIndex === 2) {
      if (attributes.includes("Brand Description")) {
        jsonKeys.push("brand_description");
      }
      if (attributes.includes("Brand Attributes")) {
        jsonKeys.push("brand_attributes");
      }
      if (attributes.includes("Top 5 Positive Attributes")) {
        jsonKeys.push("top_5_positive_attributes");
      }
      if (attributes.includes("Top 5 Negative Attributes")) {
        jsonKeys.push("top_5_negative_attributes");
      }
      if (attributes.includes("Top 5 Positive Statements")) {
        jsonKeys.push("top_5_positive_statements");
      }
      if (attributes.includes("Top 5 Negative Statements")) {
        jsonKeys.push("top_5_negative_statements");
      }
      if (attributes.includes("Competitive Set")) {
        jsonKeys.push("competitive_set");
      }
      jsonKeys.push("sources");
    }

    // Brand category dimensions
    if (activeItemIndex === 3) {
      if (
        attributes.includes(
          "Frequently recommended buying criteria/considerations"
        )
      ) {
        jsonKeys.push("buying_criteria");
      }
      if (attributes.includes("Sources")) {
        jsonKeys.push("sources");
      }
    }

    let jsonObject = {};

    jsonKeys.forEach((key) => {
      if (
        key.includes("attributes") ||
        key.includes("statements") ||
        key.includes("sources") ||
        key.includes("buying_criteria")
      ) {
        jsonObject[key] = "string (array)";
      } else {
        jsonObject[key] = "value (string)";
      }
    });

    let competitorObj = {};
    for(let i=0; i < inputs.length + 1; i ++) {
      competitorObj[`brand${i+1}`] = {
        "name": `[BRAND${i+1}]`,
        ...jsonObject
      }
    }

    if(activeItemIndex === 1) {
      message += '\n'+ JSON.stringify(competitorObj, null, 2);
    } else {
      message += '\n'+ JSON.stringify(jsonObject, null, 2);
    }

    console.log("JSON keys", jsonKeys)

    message += `\n Fill in the JSON object as follows:`
    jsonKeys.forEach((key, index) => {
      if(key === "brand_description") {
        message += ` ${index+1}. For "${key}", provide a concise description.`
      } 
      if(key === "top_5_positive_attributes") {
        message += ` ${index+1}. For "${key}", list the top 5 positive attributes.`
      }
      if(key === "top_5_negative_attributes") {
        message += ` ${index+1}. For "${key}", list the top 5 negative attributes.`
      }
      if(key === "top_5_positive_statements") {
        message += ` ${index+1}. For "${key}", list the top 5 positive statements.`
      }
      if(key === "top_5_negative_statements") {
        message += ` ${index+1}. For "${key}", list the top 5 negative statements.`
      }
      if(key === "competitive_set") {
        message += ` ${index+1}. For "${key}", list competitive sets of ${promptData}.`
      }
      if(key === "sources") {
        message += ` ${index+1}. For "${key}", Include the sources used in your analysis in the 'sources' array. Each source should be a valid website URL, not just a text description. If a specific page URL is not available, use the main domain of the source.`
      }
      if(key === "buying_criteria") {
        message += ` ${index+1}. For "${key}", List the top 5 decision criteria or considerations that are important for end customer`
      }    
    })
     // Competitors Report
    if (activeItemIndex == 1) {
      message += "for each brands mentioned in an array.";
    }

    message += `
    Guidelines:
    - Ensure all text is enclosed in double quotes.
    - Do not use single quotes or apostrophes in the JSON.
    - Attributes should be short phrases or individual words.
    - The response should contain only the JSON object, with no additional text before or after.`
    return message.replace(/\s+/g, " ").trim();
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }

  const handleInputChange = (e, selection, title) => {
    const { type, text } = selection;
    if (type === "checkbox") {
      if (text === "Select LLMs") {
        if (title === "Competitor Comparison") {
          e.target.checked
            ? setLLMs([e.target.id])
            : setLLMs(LLMs.filter((llm) => llm !== e.target.id));
          return;
        }
        e.target.checked
          ? setLLMs([...LLMs, e.target.id])
          : setLLMs(LLMs.filter((llm) => llm !== e.target.id));
      } else if (text === "Select Attributes") {
        e.target.checked
          ? setAttributes([...attributes, e.target.id])
          : setAttributes(attributes.filter((attr) => attr !== e.target.id));
      }
    } else {
      setInputs((prevInputs) => {
        const updatedInputs = [...prevInputs];
        updatedInputs[e.target.id] = e.target.value;
        return updatedInputs;
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if(!activeItemIndex && activeItemIndex > -1 && activeItemIndex != 0) {
      toast.warn("Choose a report you want to build");
      return;
    }
    const queries = [];
    for (let llm of LLMs) {
      let inputPrompt = "";
      if (llm === "gpt_4_turbo") {
        inputPrompt = promptMessage(gpt4TurboPrompts);
      } else if (llm === "palm2_text") {
        inputPrompt = promptMessage(palm2Prompts);
      } else if (llm === "llama2_70b_chat" || llm === "llama3") {
        inputPrompt = promptMessage(llama2Prompts);
      } else if (llm === "gpt-4" || llm === "gpt-4o" || llm === "gpt-3.5-turbo" || llm === "Perplexity") {
        inputPrompt = promptMessage(gpt4Prompts);
      } else if (llm === "Gemini") {
        inputPrompt = promptMessage(geminiPrompts);
      } else if (llm === "Claude") {
        inputPrompt = promptMessage(claudePrompts);
      }
      queries.push({
        llm,
        inputPrompt,
      });
    }

    if (
      ["LLM Comparison", "Competitor Comparison", "Key Sources"].includes(
        REPORTS[activeItemIndex].title
      ) &&
      !promptData.toString().trim()
    ) {
      toast.warn("Company/Brand input required");
      return;
    }

    if (
      REPORTS[activeItemIndex].title === "Competitor Comparison" &&
      (inputs.length === 0 || inputs.every((input) => !input))
    ) {
      toast.warn("Please enter at least one competitor");
      return;
    }

    if (
      REPORTS[activeItemIndex].title === "Brand Category Dimensions" &&
      (inputs.length === 0 || inputs.some((input) => !input))
    ) {
      toast.warn("Please enter brand category first");
      return;
    }

    if (LLMs.length === 0) {
      toast.warn("Please select at least one LLM");
      return;
    }
    if (attributes.length === 0) {
      toast.warn("Please select at least one attribute");
      return;
    }

    const updatedAttr = [...attributes];
    const itemIndex = updatedAttr.findIndex(
      (i) => i === "Top 5 Positive and Negative Attributes"
    );
    if (itemIndex > -1) {
      updatedAttr.splice(
        itemIndex,
        1,
        "Top 5 positive Attributes",
        "Top 5 negative Attributes"
      );
    }

    const itemIndex2 = updatedAttr.findIndex(
      (i) => i === "Top 5 Positive and Negative Statements"
    );
    if (itemIndex2 > -1) {
      updatedAttr.splice(
        itemIndex2,
        1,
        "Top 5 positive Statements",
        "Top 5 negative Statements"
      );
    }

    setLoading(true);
    const abortCtrl = new AbortController();
    await addReportingJob(
      {
        queries,
        updatedAttr,
        inputs: [promptData, ...inputs],
        date: new Date().toISOString(),
        id: crypto.randomUUID(),
        title: REPORTS[activeItemIndex].title,
        email: authUserEmail,
        status: "in-progress",
        LLMs: LLMs,
      },
      abortCtrl
    );

    setLoading(false);

    toast.success("Report has been submitted for processing");

    setTimeout(() => {
      const url = `/reporting?tab=2`;
      window.open(url, "_blank");
    }, 1200);
  };

  return (
    <div>
      <Accordion>
        {REPORTS.map((report, index) => (
          <ReportItem
            key={report.title}
            report={report}
            index={index}
            onSelect={setActiveItemIndex}
            onChangeInput={handleInputChange}
            LLMs={LLMs}
            attributes={attributes}
            inputs={inputs}
          />
        ))}
      </Accordion>
      <button
        className="btn btn-primary mt-4"
        style={{
          backgroundColor: "rgb(61, 200, 99)",
          color: "white",
          fontSize: "14px",
          padding: "5px 16px",
        }}
        disabled={loading}
        onClick={submitHandler}
      >
        {loading ? (
          <div className="d-flex align-items-center">
            <CircularProgress className="mr-1" size={16} /> &nbsp;Please wait{" "}
          </div>
        ) : (
          "Submit"
        )}
      </button>
    </div>
  );
}

export default ReportingList;