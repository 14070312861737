import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const BAR_HEIGHT = 55; // Height per bar
const MIN_HEIGHT = 300; // Minimum height for the chart

const CustomBarChart = ({ data, color }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      const { name, altNames, value } = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p>
            <strong>Category : {name || ""}</strong> <br />
            <strong>Signal Strength : {value || ""}</strong>
          </p>
          <p>Attributes:</p>
          <ul>
            {altNames.map((altName, index) => (
              <li key={index}>{altName}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  // Calculate the dynamic height
  const chartHeight = Math.max(MIN_HEIGHT, data?.length * BAR_HEIGHT);

  return (
    <ResponsiveContainer
      width="95%"
      height={chartHeight}
      style={{ backgroundColor: "white" }}
    >
      <BarChart
        layout="vertical" // Switches the axes
        width={700}
        height={chartHeight}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number" // X-axis now represents the values
          domain={[0, 100]} // Set limit from 0 to 100
          tickCount={11} // Number of ticks including 0
          tickLine={false}
          tick={{
            fill: "#b6b7b8",
            fontFamily: "Roboto Flex, sans-serif",
            fontSize: 14,
          }}
          axisLine={{ stroke: "#b6b7b8", strokeWidth: 1 }}
        />
        <YAxis
          dataKey="name" // Y-axis now represents the names
          type="category"
          tickLine={false}
          tick={{
            fill: "#808080",
            fontFamily: "Roboto Flex, sans-serif",
            fontSize: 14,
            // textAnchor: "start",
            // whiteSpace: "post-wrap",
            // wordWrap: "break-word",
          }}
          width={150} // Increase the width to allow for wrapping
          axisLine={{ stroke: "white" }}
          padding={{ top: 10, bottom: 10 }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="value"
          fill={color}
          radius={[10, 10, 10, 10]}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {data?.map((entry, index) => (
            <g key={`bar-${index}`}>
              <rect
                y={index * BAR_HEIGHT + 10} // Adjust according to bar position
                x={0} // Bars start from the X-axis
                width={Number(entry.value).toFixed(0)}
                height={BAR_HEIGHT}
                fill={index === activeIndex ? "#76E4D6" : color}
              />
            </g>
          ))}
          {/* <LabelList
            dataKey="value"
            position="right" // Labels are positioned at the right of the bars
            fill="red"
            style={{
              fontFamily: "Roboto Flex, sans-serif",
              fontSize: 14,
            }}
          /> */}
        </Bar>
      </BarChart>
      <p style={{ textAlign: "center", marginTop: "-29px" }}>
        Signal Strength (%)
      </p>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
