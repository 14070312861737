
import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Collapse } from "react-bootstrap";
import { FaAngleDown } from "react-icons/fa";

const DashboardFocusAreaComponent = ({
  options,
  subOptionsData,
  handleMainOptionClick,
  handleSubOptionClick,
  mainOption,
  subOptions,
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  // Function to handle click outside the component
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const itemStyle = { textWrap: "nowrap", textOverflow: "ellipsis", overflow: "hidden" };

  return (
    <Form ref={ref}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant="default"
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "50px",
          padding: "11.5px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1rem",
            fontWeight: "360",
          }}
        >
        
            {mainOption.length !== 0 ? (
              mainOption.length === 1 ? (
                <span style={itemStyle}> {mainOption[0]} </span>
              ) : (
                <span style={itemStyle}> ({mainOption.length})Selected </span>
              )
            ) : (
              <span style={itemStyle}>Select Focus Area</span>
            )}
        
          <FaAngleDown
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0)", // Rotate the icon based on the dropdown state
              transition: "transform 0.3s ease-in-out", // Add transition for smooth animation
            }}
          />
        </div>
      </Button>
      <Collapse
        style={{
          position: "absolute",
          backgroundColor: "white",
          width: "13.8%",
          zIndex:1
        }}
        in={open}
      >
        <div id="example-collapse-text">
          <Form.Group controlId="exampleForm.SelectCustom">
            {options.map((option) => (
              <div key={option}>
                <Form.Check
                  style={{ fontSize: "0.72rem", fontWeight: "600" }}
                  type="checkbox"
                  label={option}
                  className={`mb-2 customData`}
                  checked={mainOption.includes(option)}
                  onChange={() => handleMainOptionClick(option)}
                />
                {open &&
                  mainOption.includes(option) &&
                  subOptionsData[option].map((subOption) => (
                    <Form.Check
                      key={subOption}
                      style={{
                        fontSize: "0.70rem",
                        fontWeight: "600",
                        marginLeft: "20px",
                      }}
                      type="checkbox"
                      label={subOption}
                      className={`mb-2 customData`}
                      checked={subOptions.includes(subOption)}
                      onChange={() => handleSubOptionClick(subOption)}
                    />
                  ))}
              </div>
            ))}
          </Form.Group>
        </div>
      </Collapse>
    </Form>
  );
};

export default DashboardFocusAreaComponent;
