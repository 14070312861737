import * as moment from 'moment';

import classes from './BrandIndexSectionHeader.module.css';

function BrandIndexSectionHeader({ date, top = 0 }) {

  const providedDate = moment(date);
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  return (
    <span className={classes.section_header} style={{ top }}>
      {providedDate.isSame(today, 'day')
        ? "Today"
        : providedDate.isSame(yesterday, 'day')
        ? "Yesterday"
        : "Past"}
      - {moment(providedDate).format("dddd, MMMM Do YYYY")}
    </span>
  );
}

export default BrandIndexSectionHeader;
