import { Modal, Button } from "react-bootstrap";

import classes from "./ConfirmModal.module.css";

function ConfirmModal({
  show,
  handleClose,
  confirmMessage,
  idToBeDeleted,
  rowData,
  testMonitoring,
  handleDeleteData,
  isDeleteAction,
}) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmMessage}</Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button
          style={{backgroundColor:'green'}}
          variant="success"
          onClick={
            isDeleteAction
              ? () => handleDeleteData(idToBeDeleted)
              : () => testMonitoring(rowData)
          }
        >
          Yes
        </Button>
        <Button style={{backgroundColor:'#d32f2f'}} variant="danger" onClick={handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
