import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import { ToastContainer } from "react-toastify";

import Spinner from "../../../Component/Spinner/Spinner";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import classes from "./SettingsToggle.module.css";

export default function SettingsToggle({ data, render, loading, error }) {
  return (
    <div>
      <ToastContainer />
      {loading && <Spinner />}
      {error && (
        <div className={classes.error}>
          <ErrorView title="Something went wrong" message={error} />
        </div>
      )}
      {!loading && !error && (
        <List className={classes.list} component="nav">
          {data.map((metric) => render(metric))}
        </List>
      )}
    </div>
  );
}
