import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetitorBrands } from "../../../redux/actions/competitorBrandActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";

const CompetitorBrandsComponent = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.competitorBrands);

  useEffect(() => {
    if (brands?.data && brands?.data.length === 0) {
      dispatch(fetchCompetitorBrands(1));
    }
  }, [dispatch]);

  if (brands.loading) {
    return <p>Loading...</p>;
  }

  if (brands.error) {
    return <p>Error: {brands.error}</p>;
  }

  const brandsData = brands.data.data;

  return (
    <Table>
      <thead style={{ backgroundColor: "#CCCCCC" }}>
        <tr>
          <th>Brand</th>
          <th>Details</th>
          <th>Tags</th>
          <th>Category</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {brandsData &&
          brandsData.length !== 0 &&
          brandsData.map((data) => {
            return (
              <tr>
                <td>{data.name}</td>
                <td>{data.description}</td>
                <td>Competitor</td>
                <td>
                  {/* {data.categories.map((category, index) => (
                    <p key={index}>{category.name}</p>
                  ))} */}
                </td>
                <td className={classes.actions}>
                  <Button
                    style={{
                      backgroundColor: "green",
                    }}
                    variant="success"
                    // onClick={() => handleUpdate(data.id)}
                  >
                    Update
                  </Button>{" "}
                  <Button
                    style={{
                      backgroundColor: "#d32f2f",
                    }}
                    variant="danger"
                    // onClick={() => handleDelete(data.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default CompetitorBrandsComponent;
