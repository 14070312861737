import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import { useSelector } from "react-redux";
import { getPromptForBrandCompetitorsData } from "../../../utils/helpers";
import { fetchGpt4API } from "../../../services/apiService";
import json5 from "json5";

const radioOption = [
  {
    value: "manual",
    label: "Enter Competitors Manually",
  },
  {
    value: "ai",
    label: "Add Competitors from AI Suggestion",
  },
];
const Addproduct = ({
  productName,
  setProductName,
  productDetails,
  setProductDetails,
  productCategory,
  setProductCategory,
  productCategoryDescription,
  setProductCategoryDescription,
  brandPersona,
  setBrandPersona,
  classes,
  competitors,
  setCompetitors,
  availableCompetitors,
  handleCompetitorToggle,
  handleCloseModal,
  createNewproduct,
  updateCreatedProduct,
  isUpdateModalOpen,
}) => {
  const brands = useSelector((state) => state.brands);
  const brandProducts = useSelector((state) => state.brandProducts);
  const coreAttributes = useSelector((state) => state.coreAttributes);
  const personas = useSelector((state) => state.personas);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [competersAI, setCompetersAI] = useState("manual");
  const [competitorsFromAi, setCompetitorsFromAi] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevProductName, setPrevProductName] = useState("");

  useEffect(() => {
    if (competersAI === "ai" && productName&& selectedBrand) {
      console.log("enter");
      async function getCompetitorsData() {
        if (prevProductName !== productName ) {
          let prompt = getPromptForBrandCompetitorsData(productName, "product");
          setIsLoading(true);
          let result = await fetchGpt4API(
            prompt,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            "Brand-onboarding"
          );
          result = result["GPT-4"][0];

          // Match the array of objects using the regular expression
          let matchedArray = result.match(
            /\[\s*\{[^\}]*\}\s*(,\s*\{[^\}]*\}\s*)*\]/g
          );

          if (matchedArray) {
            // Parse the matched array into a JavaScript object
            let resultArray = json5.parse(matchedArray[0]);
            console.log(resultArray);
            setIsLoading(false);
            setCompetitorsFromAi(resultArray);
            setPrevProductName(productName);
          }
        }
      }

      getCompetitorsData();
    }
  }, [competersAI]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (isUpdateModalOpen) {
      updateCreatedProduct(
        productName,
        selectedBrand,
        productDetails,
        productCategory,
        productCategoryDescription,
        competitors,
        brandPersona
      );
    } else {
      createNewproduct(
        productName,
        selectedBrand,
        productDetails,
        productCategory,
        productCategoryDescription,
        competitors,
        brandPersona
      );
    }
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.modalBody}>
        <Select
          value={selectedBrand}
          onChange={(e) => setSelectedBrand(e.target.value)}
          displayEmpty
          fullWidth
          required
          // style={{ margin: "15px", width: "95%" }}
          // margin="normal"
          className={classes.select}
        >
          <MenuItem value="">Select Brand</MenuItem>
          {brands?.data?.data &&
            brands?.data?.data.length !== 0 &&
            brands?.data?.data
              .filter((obj) => obj?.is_active)
              .map((brand) => (
                <MenuItem value={brand?.brand?.id}>
                  {" "}
                  {brand?.brand?.name}
                </MenuItem>
              ))}
        </Select>
        <div className={classes.formGrid}>
          <TextInput
            label="Product Name"
            variant="outlined"
            fullWidth
            required
            value={productName}
            onChange={(e) => {
              setCompetitors([]);
              setProductName(e.target.value);
              setCompetitorsFromAi([])
            }}
            className={classes.textField}
          />

          <TextInput
            label=" Product Description"
            variant="outlined"
            fullWidth
            required
            value={productDetails}
            onChange={(e) => setProductDetails(e.target.value)}
            className={classes.textField}
          />

          <TextInput
            label="Product Category Name"
            variant="outlined"
            fullWidth
            required
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
            className={classes.textField}
          />
          <TextInput
            label="Product Category Description"
            variant="outlined"
            fullWidth
            required
            value={productCategoryDescription}
            onChange={(e) => setProductCategoryDescription(e.target.value)}
            className={classes.textField}
          />
        </div>
        <Typography variant="subtitle1">Brand Competitor</Typography>
        <RadioButtonsGroup
          options={radioOption}
          handleChange={(e) => {
            setCompetersAI(e.target.value);
            setCompetitors([]);
          }}
        ></RadioButtonsGroup>
        <div>
          <TextInput
            required
            label="Competitors Brand Description"
            variant="outlined"
            fullWidth
            margin="normal"
            value={competitors?.join(", ")}
            onChange={(e) => setCompetitors(e.target.value.split(", "))}
            // className={classes.select}
          />
        </div>
        {isLoading ? <div>Competitor Loading....</div> : null}
        <div
          style={{
            marginTop: "10px",
          }}
        >
       
       {console.log(competitorsFromAi,"competitorsFromAi")}
          {competersAI === "ai" &&
            competitorsFromAi?.map((competitor) => (
              <Chip
                style={{ margin: "5px", borderRadius: "3px" }}
                key={competitor}
                label={competitor.comp_product_name}
                onClick={() =>
                  handleCompetitorToggle(competitor.comp_product_name)
                }
                color={
                  competitors?.includes(competitor.comp_product_name)
                    ? "primary"
                    : "default"
                }
              />
            ))}
        </div>
        <Select
          value={brandPersona}
          onChange={(e) => setBrandPersona(e.target.value)}
          displayEmpty
          fullWidth
          required
          multiple // style={{ margin: "15px", width: "95%" }}
          // margin="normal"
          className={classes.select}
        >
          <MenuItem value="">General Persona</MenuItem>
          {personas?.data?.data?.map((res) => {
            return (
              <MenuItem value={res.persona.id}>{res.persona.name}</MenuItem>
            );
          })}
        </Select>
        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            type="submit"
            variant="contained"
          >
            add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Addproduct;
