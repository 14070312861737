import React, { useEffect, useState, useContext } from "react";
import {
  fetchPersonas,
  updatePersonaMonitoring,
} from "../../../../redux/actions/personasActions";
import { AuthContext } from "../../../../context/auth-context";
import { useSelector, useDispatch } from "react-redux";

import { Switch } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const PersonaController = () => {
  const authCtx = useContext(AuthContext);
  const customerId = authCtx?.user?.customer_id || 0;

  const personas = useSelector((state) => state.personas);
  const dispatch = useDispatch();

  useEffect(() => {
    if (personas?.data && personas?.data.length === 0) {
      dispatch(fetchPersonas(customerId));
    }
  }, [dispatch]);

  if (personas?.data?.data && personas?.data?.data.length === 0) {
    return <p> Loading.. </p>;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {personas?.data?.data?.map((obj) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6>
              <PersonIcon /> &nbsp; &nbsp; {obj?.persona?.name}
            </h6>
            <Switch
              defaultChecked={obj?.monitoring}
              onChange={(e) =>
                dispatch(
                  updatePersonaMonitoring(customerId, obj?.persona?.id, {
                    monitoring: e.target.checked ? 1 : 0,
                  })
                )
                  .then(() => {
                    // dispatch(fetchPersonas(customerId));
                  })
                  .catch(() => {
                    // dispatch(fetchPersonas(customerId));
                  })
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default PersonaController;
