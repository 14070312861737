import Markdown from "markdown-to-jsx";

import classes from "./MonitorLLMResultOutput.module.css";

function LLMResultOutput({
  LLMResponses,
  frequency,
  aggregatedResult,
  LLM,
  comparedResults,
  data,
  monitoringMultipromptResults,
}) {
  const FREQUENCY_MAPPING = {
    Weekly: "Week",
    Monthly: "Month",
    Daily: "Day",
  };

  return (
    <div className={classes.container}>
      {!data?.isMonitored && (
        <>
          <h5 style={{ textAlign: "center" }}>
            {" "}
            Multiple Responses From <b> {LLM} </b> LLM
          </h5>
          {LLMResponses.map((LLMRes, i) => (
            <div key={i} className={classes.results}>
              <div className={classes.badge_wrapper}>
                <span className={classes.badge}>{`Response ${i + 1}`}</span>
              </div>
              <div className={classes.md}>
                <Markdown>{LLMRes}</Markdown>
              </div>
            </div>
          ))}
        </>
      )}

      {monitoringMultipromptResults &&
        monitoringMultipromptResults.length !== 0 &&
        monitoringMultipromptResults.map((data, mainIndex) => {
          <>
            <h5 style={{ textAlign: "center" }}>
              {" "}
              Multiple Responses{" "}
              <b> {`${FREQUENCY_MAPPING[frequency]}${mainIndex + 1}`} </b>
            </h5>
            {data.map((LLMRes, i) => (
              <div key={i} className={classes.results}>
                <div className={classes.badge_wrapper}>
                  <span className={classes.badge}>{`Response ${i + 1}`}</span>
                </div>
                <div className={classes.md}>
                  <Markdown>{LLMRes}</Markdown>
                </div>
              </div>
            ))}
          </>;
        })}

      {/* <h5 style={{ textAlign: "center" }}>
        {" "}
        {data?.isMonitored ? "Baseline Data" : "Aggregated Response"}{" "}
      </h5>
      <div className={`${classes.results} ${classes.md}`}>
        <Markdown>{aggregatedResult}</Markdown>
      </div>
      {data?.isMonitored && (
        <>
          <h5 style={{ textAlign: "center" }}> Comparison Results </h5>
          {Object.entries(comparedResults).map((LLMRes, i) => (
            <div key={i} className={classes.results}>
              <div className={classes.badge_wrapper}>
                <span className={classes.badge}>{LLMRes[0]}</span>
              </div>
              <div className={classes.md}>
                <Markdown>{LLMRes[1]}</Markdown>
              </div>
            </div>
          ))}
        </>
      )} */}
    </div>
  );
}

export default LLMResultOutput;
