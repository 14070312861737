import { ListGroup } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";

export default function BrandSuggession({ suggessions, onItemClick, loading }) {
  return (
    suggessions.length > 0 && (
      <ListGroup style={{ width: "28rem", marginTop: "5px" }}>
        <span className="my-2">Suggested: </span>
        { loading && <LinearProgress /> }
        {suggessions.map((suggession) => (
          <ListGroup.Item
            key={suggession}
            onClick={() => onItemClick(suggession)}
            style={{
              fontSize: "12px",
              cursor: "pointer",
              background: "#f8f8f8",
            }}
          >
            {suggession}
          </ListGroup.Item>
        ))}
      </ListGroup>
    )
  );
}
