import api from "../../BrandOnBoarding/api";

import {
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
} from "../constants/actionTypes";

export const fetchBrands = (id) => async (dispatch) => {
  dispatch({ type: FETCH_BRANDS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/brands`);
    dispatch({ type: FETCH_BRANDS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_BRANDS_FAILURE, error: error.message });
  }
};

export const createBrand = (id, brand) => async (dispatch) => {
  dispatch({ type: CREATE_BRAND_REQUEST });
  try {
    const response = await api.post(`/customers/${id}/brand`, brand);
    dispatch({ type: CREATE_BRAND_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: CREATE_BRAND_FAILURE, error: error.message });
    throw error;
  }
};

export const updateBrand = (id, brandId, brand) => async (dispatch) => {
  dispatch({ type: UPDATE_BRAND_REQUEST });
  try {
    const response = await api.put(`/customers/${id}/brand/${brandId}`, brand);
    dispatch({ type: UPDATE_BRAND_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({ type: UPDATE_BRAND_FAILURE, error: error.message });
    throw error;
  }
};

export const deleteBrand = (id, brandId) => async (dispatch) => {
  dispatch({ type: DELETE_BRAND_REQUEST });
  try {
    await api.delete(`/customers/${id}/brand/${brandId}`);
    dispatch({ type: DELETE_BRAND_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_BRAND_FAILURE, error: error.message });
  }
};
