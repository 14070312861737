import React from "react";
import { Container, Card, Col, Form } from "react-bootstrap";
import "../../../../App.css";

function MFavorabilityComponent({
  checkedItems,
  handleCheckBoxChange,
  isBrand,
  promptBrandReach,
  handlePromptBrandReachChange,
}) {
  return (
    <Container className="mb-3">
      <Card as={Col} md="12" className="border-0 whi">
        <Card.Body>
          <Card.Title>Sources</Card.Title>
          {/*  <Card.Text className="mt-4">
                 What dimensions do you want to focus on (choose all that apply)
                 </Card.Text> */}
          <Card.Text>
            <ul className="focus-on mt-4">
              <li>
                <Form.Group as={Col} md="5">
                  <Form.Control
                    as="textarea"
                    rows={1}
                    cols={2}
                    placeholder={
                      isBrand
                        ? "Enter Brand Category"
                        : "Enter Product Category"
                    }
                    name="promptBrandReach"
                    value={promptBrandReach}
                    onChange={handlePromptBrandReachChange}
                    disabled={
                      !checkedItems.some(item =>
                        item === "Frequently recommended buying criteria/considerations" ||
                        item === "Competitive Set"
                    ) }
                    className="big1"
                    // style={{ height: "70px", width: "28rem" }}
                  />
                </Form.Group>
              </li>
            </ul>
          </Card.Text>
          <ul className="focus-on">
            {[
              `${isBrand ? "Brand" : "Product"} Description`,
              "Top 5 Positive Attributes",
              "Top 5 Negative Attributes",
              `Top 5 ${isBrand ? "Brands" : "Products"} in Category`,
              "Frequently recommended buying criteria/considerations",
              "Competitive Set",
            ].map((key) => (
              <li key={key}>
                <input
                  type="checkbox"
                  name=""
                  value=""
                  checked={checkedItems.includes(key)}
                  onChange={() =>
                    handleCheckBoxChange("Brand Favorability", key)
                  }
                />{" "}
                {key}
              </li>
            ))}
          </ul>
          <small></small>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default MFavorabilityComponent;
