import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import classes from "./RankingItem.module.css";

function RankIcon({ rank }) {
  return <span className={classes.rankIcon}>{rank}</span>;
}

export default function RankingItem({ ranking }) {
  return (
    <List dense>
      {ranking.map((item, index) => (
        <ListItem key={item}>
          <ListItemIcon>
            <RankIcon rank={index + 1} />
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
}