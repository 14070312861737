import { createContext, useReducer } from "react";
import { createMarkdownTable } from "../utils/helpers";

export const BrandIndexContext = createContext({
  history: [],
  showHistory: false,
  type: "list",
  historyItem: null,
  setHistory: () => {},
  setShowHistory: () => {},
  setHistoryItem: () => {}
});

const intialState = {
  history: [],
  showHistory: false,
  type: "list",
  historyItem: null,
};

function brandIndexReducer(state, action) {
  if (action.type === "SET_HISTORY") {
    return {
      ...state,
      history: action.payload,
    };
  }
  if (action.type === "SHOW_HISTORY") {
    return {
      ...state,
      showHistory: action.payload.show,
      type: action.payload.type,
    };
  }
  if (action.type === "SET_HISTORY_ITEM") {
    if(!action.payload?.hierarchy) {
       return {
        ...state,
        historyItem: action.payload,
        showHistory: true,
        type: "individual"
      }
    }
    const normalizedArrayForMarkdown = action.payload.hierarchy.data.map((prod, _, array) => {

      const getSortedIndex = (arr, prop, name) => arr.slice().sort((a, b) => b[prop] - a[prop])
          .findIndex(item => item.name === name);

      const rankIndex = getSortedIndex(array, 'rank', prod.name);
      const favIndex = getSortedIndex(array, 'favorability', prod.name);

      return [
        prod.name,
        `${prod.rank} <sup>(${rankIndex+1})</sup>`,
        `${prod.favorability} <sup>(${favIndex+1})</sup>`,
        `${prod.score} <sup>(${_+1})</sup>`,
      ]
    });
    normalizedArrayForMarkdown.unshift([
      "Brand/Product",
      "Rank",
      "Favorability",
      "Brand Index Score",
    ]);
    const markTable = createMarkdownTable(normalizedArrayForMarkdown);
    return {
      ...state,
      historyItem: { markTable, hierarchy: { ...action.payload.hierarchy } },
      showHistory: true,
      type: "individual"
    }
  }
  return state;
}

function BrandIndexContextProvider({ children }) {
  const [state, dispatch] = useReducer(brandIndexReducer, intialState);

  function handleSetHistory(payload) {
    dispatch({ type: "SET_HISTORY", payload });
  }

  function handleShowHistory(payload) {
    dispatch({ type: "SHOW_HISTORY", payload });
  }

  function handleSetHistoryItem(payload) {
    dispatch({ type: "SET_HISTORY_ITEM", payload });
  }

  const brandIndexValue = {
    history: state.history,
    showHistory: state.showHistory,
    type: state.type,
    historyItem: state.historyItem,
    setHistory: handleSetHistory,
    setShowHistory: handleShowHistory,
    setHistoryItem: handleSetHistoryItem,
  };

  return (
    <BrandIndexContext.Provider value={brandIndexValue}>
      {children}
    </BrandIndexContext.Provider>
  );
}

export default BrandIndexContextProvider;
