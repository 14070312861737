import BrandSnippets from "./BrandSnippets/BrandSnippets";
import BrandThemes from "./BrandThemes/BrandThemes";
import Description from "./Description/Description";
import classes from "./BrandInsights.module.css";

export default function BrandInsights({
  entity,
  category,
  type,
  brandProductInfo,
}) {
  let brandDesc =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
  return (
    <>
      <Description
        brandDescription={
          brandProductInfo?.["gpt-4o"]?.description || brandDesc
        }
      />
      <div className={classes.brand}>
        <div className={classes.brand_specs}>
          <BrandSnippets
            brandProductInfo={brandProductInfo}
            type={type}
            entity={entity}
            category={category}
          />
          <div className={classes.partition}></div>
          <BrandThemes
            brandProductInfo={brandProductInfo}
            type={type}
            entity={entity}
            category={category}
          />
        </div>
      </div>
    </>
  );
}
