import { Modal, Button, FormControl } from "react-bootstrap";

import classes from "./ForgotPassword.module.css";

function ForgotPasswordModal({ show, handleClose,emailToResetPass, setEmailToResetPass,handleResetPassword, successMessage }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Your Password?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Enter your registered email address below and we’ll send you a link to
        reset your password
        <p></p>
        <FormControl
          type="text" 
          placeholder="Enter Email Address"
          value={emailToResetPass}
          onChange={(e)=>{setEmailToResetPass(e.target.value)}}
        />
        {successMessage !== '' && <> <hr/> <p> {successMessage} </p> </>}
      </Modal.Body>
      <Modal.Footer className={classes.actions}>
        <Button
          style={{backgroundColor:'green'}}
          variant="success"
          onClick={() => handleResetPassword()}
        >
          Send
        </Button>
        <Button style={{backgroundColor:'#d32f2f'}} variant="danger" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ForgotPasswordModal;
