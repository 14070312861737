import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

import classes from "./Table.module.css";

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4fd1c5",
    color: theme.palette.common.white,
    height: "52px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#4fd1c5",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      verticalAlign: "top",
      padding: "20px 10px",
    },
  };
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eff4f8",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.headers.map((headCell) => (
          <StyledTableHeadCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className={classes.active}
              >
                {headCell.name}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.name}</span>
            )}
          </StyledTableHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CustomizedTables({
  headers,
  renderHeaders,
  render,
  data,
  orderBy,
  order,
  onSort,
  elevation = 2
}) {
  return (
    <TableContainer component={Paper} elevation={elevation}>
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        {headers && (
          <EnhancedTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={onSort}
          />
        )}
        { renderHeaders && renderHeaders()}
        <TableBody>{data.map((item, index) => render(item, index))}</TableBody>
      </Table>
    </TableContainer>
  );
}
