import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { Button } from '@mui/material';

export default function ErrorView({ title, message, retry, onRetry }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <ReportGmailerrorredOutlinedIcon style={{ fontSize: 35 }} />
      <h5 className='mt-2'>{title}</h5>
      <p className="text-danger" style={{ fontSize: 14 }}>{message}</p>
      {retry && <Button variant='text' onClick={onRetry}>Retry</Button>}
    </div>
  );
}
