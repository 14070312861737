import { ListItem, ListItemAvatar, Avatar, ListItemText } from "@mui/material";

import MUIList from "../../../../../../Component/UI/MUIList/MUIList";
import classes from "./BrandSpecItem.module.css";

export default function BrandSpec({ title, data }) {
  function renderRow(item) {
    let textContent = item?.text;
    if (title === "Brand Snippets") {
      textContent = `"${item?.text}"`;
    }
    return (
      <ListItem className={classes.item} key={item?.id}>
        <ListItemAvatar className={classes.item_avatar}>
          <Avatar src={item.img} className={classes.avatar}></Avatar>
        </ListItemAvatar>
        <ListItemText>
          <div>
            <span className={classes.llm}>{item?.name}:</span>
            <span className={classes.snippet}>{textContent}</span>
          </div>
        </ListItemText>
      </ListItem>
    );
  }

  return (
    <>
      <div className={classes.title}>{title}</div>

      <MUIList
        className={classes.list}
        data={data}
        render={renderRow}
      ></MUIList>
    </>
  );
}
