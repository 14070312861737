import { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import api from "../../BrandOnBoarding/api";

function BackgroundJobPrompt() {
  const [prompts, setPrompts] = useState([]);

  async function getPrompts() {
    const response = await api.get("/prompts");
    const { data: allPrompts } = response.data;
    setPrompts(allPrompts.sort((a, b)=> a.id - b.id));
  }

  useEffect(() => {
    getPrompts();
  }, []);

  return (
    <div>
      <h5>Background Job Prompt</h5>
      <form className="w-50">
        {prompts.map((prompt, index) => {
          return (
            <Card key={prompt.id} className="mb-2">
              <CardContent>
                <h6>
                  <strong>{index + 1}. </strong>
                  {prompt.name}
                </h6>
                <div className="mb-2" sx={{ color: "text.secondary" }}>
                  {prompt.description}
                </div>
                <h6>Prompt Function:</h6>
                <SyntaxHighlighter
                  language="javascript"
                  customStyle={{
                    overflowY: "auto",
                    minHeight: 100,
                  }}
                  lineProps={{
                    style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                  }}
                  wrapLines={true}
                  style={atomOneDark}
                >
                  {prompt.function_block}
                </SyntaxHighlighter>
                <h6>Example Prompt:</h6>
                <textarea
                  rows={4}
                  style={{
                    width: "100%",
                    overflowY: "scroll",
                    minHeight: 300,
                    padding: "15px",
                  }}
                  disabled
                  defaultValue={prompt.prompt_example}
                >
                </textarea>
              </CardContent>
            </Card>
          );
        })}
      </form>
    </div>
  );
}

export default BackgroundJobPrompt;
