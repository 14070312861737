import api from "../../BrandOnBoarding/api";
import {
  FETCH_COMPETITOR_BRANDS_REQUEST,
  FETCH_COMPETITOR_BRANDS_SUCCESS,
  FETCH_COMPETITOR_BRANDS_FAILURE,
  CREATE_COMPETITOR_BRAND_REQUEST,
  CREATE_COMPETITOR_BRAND_SUCCESS,
  CREATE_COMPETITOR_BRAND_FAILURE,
  UPDATE_COMPETITOR_BRAND_REQUEST,
  UPDATE_COMPETITOR_BRAND_SUCCESS,
  UPDATE_COMPETITOR_BRAND_FAILURE,
  DELETE_COMPETITOR_BRAND_REQUEST,
  DELETE_COMPETITOR_BRAND_SUCCESS,
  DELETE_COMPETITOR_BRAND_FAILURE,
} from "../constants/actionTypes";

export const fetchCompetitorBrands = (id) => async (dispatch) => {
  dispatch({ type: FETCH_COMPETITOR_BRANDS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/competitor-brands`);
    dispatch({ type: FETCH_COMPETITOR_BRANDS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_COMPETITOR_BRANDS_FAILURE, error: error.message });
  }
};

export const createCompetitorBrand = (id,brand) => async (dispatch) => {
  dispatch({ type: CREATE_COMPETITOR_BRAND_REQUEST });
  try {
    const response = await api.post(`/customers/${id}/competitor-brand`, brand);
    dispatch({ type: CREATE_COMPETITOR_BRAND_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_COMPETITOR_BRAND_FAILURE, error: error.message });
  }
};

export const updateCompetitorBrand = (id, brand) => async (dispatch) => {
  dispatch({ type: UPDATE_COMPETITOR_BRAND_REQUEST });
  try {
    const response = await api.put(`/competitor-brands/${id}`, brand);
    dispatch({ type: UPDATE_COMPETITOR_BRAND_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_COMPETITOR_BRAND_FAILURE, error: error.message });
  }
};

export const deleteCompetitorBrand = (id) => async (dispatch) => {
  dispatch({ type: DELETE_COMPETITOR_BRAND_REQUEST });
  try {
    await api.delete(`/competitor-brands/${id}`);
    dispatch({ type: DELETE_COMPETITOR_BRAND_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_COMPETITOR_BRAND_FAILURE, error: error.message });
  }
};
