import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import RadarOutlinedIcon from '@mui/icons-material/RadarOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import ListItem from "@mui/material/ListItem";

const Icon = {
  "Positive and negative attributes": <CompareOutlinedIcon/>,
  "Core attribute": <RadarOutlinedIcon />,
  "Brand index": <LeaderboardOutlinedIcon/>
}

export default function SettingsToggleItem({
  textPrimary,
  textSecondary,
  secondaryAction,
}) {
  return (
    <ListItem secondaryAction={secondaryAction}>
      <ListItemIcon>
        { Icon[textPrimary] ?? <DonutLargeOutlinedIcon/> }
      </ListItemIcon>
      <ListItemText primary={textPrimary} secondary={textSecondary} />
    </ListItem>
  );
}