import classes from "./PDFHeader.module.css";

export default function PDFHeader() {
  return (
    <div className={`${classes.container} print-only`}>
      <div className={classes.title}>
        <img src="/menu.png" className={classes.logo} />
        <h2 className={classes.header}>Revere: Brand Luminaire Discovery</h2>
      </div>
    </div>
  );
}
