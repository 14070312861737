import React from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";
import RadioButtonsGroup from "../../../Component/UI/RadioGroup/RadioGroup";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import TextInput from "../../../Component/UI/TextInput/TextInput";
import classes from "../settings.module.css";
const UserForm = ({
  userName,
  setUserName,
  userEmail,
  setUserEmail,
  handleCloseModal,
  onSubmit
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(userEmail, "userName");
    onSubmit(userEmail)
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.divContainer}>
        <TextInput
          label="Add Email "
          variant="outlined"
          fullWidth
          type="email"
          value={userEmail}
          required
          onChange={(e) => {
            setUserEmail(e.target.value);
          }}
          // className={classes.textField}
        />

        <div className={classes.buttonDiv}>
          <Button
            // onClick={handleConfirm}
            // color={confirmBtnColor}
            type="submit"
            variant="contained"
          >
            add
          </Button>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserForm;
