import React from "react";

import { layout } from "../common/LayoutComponent.js/Layout";
const HomePage = () => {
  return (
    <div
      style={{ textAlign: "center", height: "800px", backgroundColor: "white" }}
    >
      HomePage
    </div>
  );
};

export default layout(HomePage);
