import {
  FETCH_PERSONAS_REQUEST,
  FETCH_PERSONAS_SUCCESS,
  FETCH_PERSONAS_FAILURE,
  CREATE_PERSONA_REQUEST,
  CREATE_PERSONA_SUCCESS,
  CREATE_PERSONA_FAILURE,
  UPDATE_PERSONA_REQUEST,
  UPDATE_PERSONA_SUCCESS,
  UPDATE_PERSONA_FAILURE,
  DELETE_PERSONA_REQUEST,
  DELETE_PERSONA_SUCCESS,
  DELETE_PERSONA_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  deleteLoading: false,
  data: [],
  error: null,
};

const personasReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONAS_REQUEST:
    case CREATE_PERSONA_REQUEST:
    case UPDATE_PERSONA_REQUEST:
      // case DELETE_PERSONA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PERSONA_REQUEST:
      return {
        ...state,
        deleteLoading: true,
      };
    case FETCH_PERSONAS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...state.data, action.payload],
      };
    case UPDATE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: state.data.map((persona) =>
        //   persona.id === action.payload.id ? action.payload : persona
        // ),
      };
    case DELETE_PERSONA_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // data: state.data.filter(persona => persona.id !== action.payload),
      };
    case FETCH_PERSONAS_FAILURE:
    case CREATE_PERSONA_FAILURE:
    case UPDATE_PERSONA_FAILURE:
    case DELETE_PERSONA_FAILURE:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default personasReducer;
