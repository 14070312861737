import { useState, useEffect, useContext } from "react";
import * as moment from "moment";

import { Card, Container } from "react-bootstrap";

import {
  fetchDataFromFirebase,
  deleteDataFromFirebase,
  updateDataInFirebase,
} from "../../../DatabaseFirebase/firebaseService";
import Spinner from "./../../Spinner/Spinner";
import { AuthContext } from "../../../context/auth-context";

function DashboardHistory({ handleViewHistoryData }) {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDateIds, setSelectedDateIds] = useState([]);
  const [historyTitle, setHistoryTitle] = useState("");

  const { authUserEmail } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    fetchDataFromFirebase(getHistory, "dashboard-data", authUserEmail);

    async function getHistory(data) {
      if (!data) {
        setLoading(false);
        setHistory([]);
        return;
      }
      setLoading(false);
      let historyItems = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .sort((a, b) => b.date - a.date);

      let groupedHistory = Object.entries(groupObjectsByDate(historyItems)).map(
        ([date, items]) => ({
          date,
          data: items,
        })
      );
      setHistory(groupedHistory);
    }
  }, []);

  function groupObjectsByDate(objects) {
    const grouped = {};
    objects.forEach((obj) => {
      const dateKey = new Date(obj.date).toISOString().split("T")[0];
      if (!grouped[dateKey]) {
        grouped[dateKey] = [];
      }
      grouped[dateKey].push(obj);
    });
    return grouped;
  }

  const handleDeleteData = (itemKey) => {
    deleteDataFromFirebase(itemKey, "dashboard-data", () => {
      setHistory((prevData) => prevData.filter((item) => item.key !== itemKey));
    });
  };

  const handleOpenEditHistoryInput = (itemIndex, dateItemIndex, title) => {
    setSelectedIds([itemIndex]);
    setSelectedDateIds([dateItemIndex]);
    setHistoryTitle(title);
  };

  const handleEditHistoryTitle = (data) => {
    let currentData = data;
    currentData["customLabel"] = historyTitle;
    let collectionName = "dashboard-data";
    let documentId = data.id;
    updateDataInFirebase(collectionName, documentId, currentData).then(() => {
      setSelectedIds([]);
      setSelectedDateIds([]);
    });
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center p-3 bottom">
        <strong>Dashboard History</strong>
      </Card.Header>
      <Container className="px-3 py-2 mt-2">
        {loading && <Spinner />}
        {history.map((item, mainIndex) => {
          return (
            <div key={item.date}>
              <span className="d-block">
                {moment(item.date).isSame(new Date(), "day")
                  ? "Today"
                  : moment(item.date).subtract(1, "days")
                  ? "Yesterday"
                  : "Past"}
                - {moment(item.date).format("dddd, MMMM Do YYYY")}
              </span>

              {item.data.map((i, index) => {
                const title = i.customLabel
                  ? i.customLabel
                  : `${i?.brandProductInput}-${i?.focusItem}`;

                if (
                  selectedIds.includes(index) &&
                  selectedDateIds.includes(mainIndex)
                ) {
                  return (
                    <div className="histoblck" key={index}>
                      <span>
                        <input
                          style={{ width: "240px" }}
                          value={historyTitle}
                          placeholder="History Label"
                          onChange={(e) => {
                            setHistoryTitle(e.target.value);
                          }}
                        />
                      </span>
                      <svg
                        style={{ marginTop: "3px" }}
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="1.2em"
                        width="1.5em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleEditHistoryTitle(i)}
                      >
                        <title>Save</title>
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                      <svg
                        style={{ marginTop: "3px" }}
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="1.2em"
                        width="1.5em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          setSelectedDateIds([]);
                          setSelectedIds([]);
                        }}
                      >
                        <title>Cancel</title>
                        <path d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </div>
                  );
                }

                return (
                  <div className="histoblck" key={i.id}>
                    <h4 className="card-title2 mt-1">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="15px"
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                      </svg>
                      &nbsp;{title}
                    </h4>
                    <span style={{ cursor: "pointer" }}>
                      {/*------------- View  -------------*/}
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="1.2em"
                        width="1.5em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          handleViewHistoryData(i);
                        }}
                      >
                        <title>View</title>
                        <ellipse cx="12" cy="12" rx="10" ry="6"></ellipse>
                        <circle cx="12" cy="12" r="3.5"></circle>
                      </svg>
                      &nbsp;
                      {/*------------- Edit Name -------------*/}
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          handleOpenEditHistoryInput(index, mainIndex, title)
                        }
                      >
                        <title>Edit</title>
                        <path d="M12 20h9"></path>
                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                      </svg>
                      &nbsp;
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon-sm"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleDeleteData(i.id)}
                      >
                        <title>Delete</title>
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Container>
    </Card>
  );
}

export default DashboardHistory;
