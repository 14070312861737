import api from "../../BrandOnBoarding/api";
import {
  FETCH_BRAND_CATEGORIES_REQUEST,
  FETCH_BRAND_CATEGORIES_SUCCESS,
  FETCH_BRAND_CATEGORIES_FAILURE,
  CREATE_BRAND_CATEGORY_REQUEST,
  CREATE_BRAND_CATEGORY_SUCCESS,
  CREATE_BRAND_CATEGORY_FAILURE,
  UPDATE_BRAND_CATEGORY_REQUEST,
  UPDATE_BRAND_CATEGORY_SUCCESS,
  UPDATE_BRAND_CATEGORY_FAILURE,
  DELETE_BRAND_CATEGORY_REQUEST,
  DELETE_BRAND_CATEGORY_SUCCESS,
  DELETE_BRAND_CATEGORY_FAILURE,
} from "../constants/actionTypes";

export const fetchBrandCategories = (id) => async (dispatch) => {
  dispatch({ type: FETCH_BRAND_CATEGORIES_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/brand-catgories`);
    dispatch({ type: FETCH_BRAND_CATEGORIES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FETCH_BRAND_CATEGORIES_FAILURE, error: error.message });
  }
};

export const createBrandCategory = (id, category) => async (dispatch) => {
  dispatch({ type: CREATE_BRAND_CATEGORY_REQUEST });
  try {
    const response = await api.post(`/customers/${id}/brand-category`, category);
    dispatch({ type: CREATE_BRAND_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_BRAND_CATEGORY_FAILURE, error: error.message });
  }
};

export const updateBrandCategory = (id, category) => async (dispatch) => {
  dispatch({ type: UPDATE_BRAND_CATEGORY_REQUEST });
  try {
    const response = await api.put(`/brand-categories/${id}`, category);
    dispatch({ type: UPDATE_BRAND_CATEGORY_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_BRAND_CATEGORY_FAILURE, error: error.message });
  }
};

export const deleteBrandCategory = (id) => async (dispatch) => {
  dispatch({ type: DELETE_BRAND_CATEGORY_REQUEST });
  try {
    await api.delete(`/brand-categories/${id}`);
    dispatch({ type: DELETE_BRAND_CATEGORY_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_BRAND_CATEGORY_FAILURE, error: error.message });
  }
};
