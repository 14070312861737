import React from "react";
import { Form, Col } from "react-bootstrap";

function MProductRadioOptions({
  promptData,
  setPromptData,
  selectedOption,
  handleRadioSelection,
}) {
  return (
    <>

     {/* <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Product Attributes")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Product Attributes"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Product Attributes"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Campaign Messages")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Campaign Messages"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Campaign Messages"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Category Dimensions")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Category Dimensions"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Category Dimensions"}
        />
      </Form.Group>

  

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Frequency")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Frequency"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Frequency"}
        />
      </Form.Group> */}
      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Product Overview and Favorability")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Product Overview and Favorability"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Product Overview and Favorability"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Category Leadership")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Category Leadership"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Category Leadership"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Category Dimensions")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Category Dimensions"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Category Dimensions"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        // onClick={() => handleRadioSelection("Competition")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Competition"
          className="height1 custom-checkbox mb-3"
          disabled
          // checked={selectedOption === "Competition"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        // onClick={() => handleRadioSelection("Sources")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Sources"
          className="height1 custom-checkbox mb-3"
          disabled
          // checked={selectedOption === "Sources"}
        />
      </Form.Group>

      {/* <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Brand Index Score")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Brand Index Score"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Brand Index Score"}
        />
      </Form.Group>
      
      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Hallucination Detection")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Hallucination Detection"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Hallucination Detection"}
        />
      </Form.Group>

      <Form.Group
        as={Col}
        md="4"
        className="cursor-pointer"
        onClick={() => handleRadioSelection("Indexing")}
      >
        <Form.Check
          type="radio"
          name="firstName"
          label="Indexing"
          className="height1 custom-checkbox mb-3"
          checked={selectedOption === "Indexing"}
        />
      </Form.Group> */}
      
    </>
  );
}

export default MProductRadioOptions;
