import * as React from "react";
import SentimentItem from "./SentimentItem";
import Expandable from "../Expandable/Expandable";

export default function Sentiments({ sentiments }) {
  return (
    <div>
      <Expandable
        data={sentiments}
        render={(sentiment) => <SentimentItem sentiment={sentiment} />}
      />
    </div>
  );
}
