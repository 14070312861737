import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

const AdminLayout = () => {
  const navigate = useNavigate();
  const { clearSession } = useContext(AuthContext);

  return (
    <div>
      <header style={headerStyle}>
        <div style={titleStyle}>Brandluminaire</div>
        <button
          style={buttonStyle}
          onClick={() => {
            clearSession();
            navigate("/login")
          }}
        >
          Logout
        </button>
      </header>
      <main style={mainStyle}>
        <Outlet />
      </main>
    </div>
  );
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  backgroundColor: "#f5f5f5",
  borderBottom: "1px solid #ddd",
};

const titleStyle = {
  fontSize: "1.5rem",
  fontWeight: "bold",
};

const buttonStyle = {
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  padding: "0.5rem 1rem",
  cursor: "pointer",
  fontSize: "1rem",
  borderRadius: "4px",
};

const mainStyle = {
  padding: "1rem 3rem",
};

export default AdminLayout;
