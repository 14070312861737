import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchGeoMarkets,
  deleteGeoMarket,
} from "../../../redux/actions/geoMarketsActions";
import { Table, Button } from "react-bootstrap";
import classes from "./Table.module.css";
import { AuthContext } from "../../../context/auth-context";

const GeoMarketsComponent = ({ handleSelectedData }) => {
  const dispatch = useDispatch();
  const geoMarkets = useSelector((state) => state.geoMarkets);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  const handledeleteGeoMarket = (geoMarketId) => {
    dispatch(deleteGeoMarket(customerId, geoMarketId)).then(() => {
      dispatch(fetchGeoMarkets(customerId));
    });
  };

  useEffect(() => {
    if (geoMarkets?.data && geoMarkets?.data.length === 0) {
      dispatch(fetchGeoMarkets(customerId));
    }
  }, []);

  if (geoMarkets.loading) {
    return <p>Loading...</p>;
  }

  if (geoMarkets.deleteLoading) {
    return <p>Geo-markets are being deleted. Please wait...</p>;
  }

  if (geoMarkets.error) {
    return <p>Error: {geoMarkets.error}</p>;
  }

  const geoMarketsData = geoMarkets.data.data;

  if (geoMarketsData && geoMarketsData.length > 0) {
    // Step 1: Create a map to group markets by geography
    const geographyMap = geoMarketsData.reduce((acc, item) => {
      const geographyName = item.geography.name;
      const marketName = item.market.name;
      const geographyId = item.geography_id;

      // If geography is not in the map, add it with an empty array for markets
      if (!acc[geographyName]) {
        acc[geographyName] = {
          geographyId, // Store the geographyId
          markets: new Set(),
        };
      }

      // Add the market to the corresponding geography's market set
      acc[geographyName].markets.add(marketName);

      return acc;
    }, {});

    // Step 2: Convert the map into an array of objects for rendering
    const tableData = Object.entries(geographyMap).map(
      ([geography, { geographyId, markets }]) => ({
        geography,
        geographyId,
        markets: Array.from(markets), // Keep markets as an array of strings
      })
    );

    return (
      <Table>
        <thead style={{ backgroundColor: "#CCCCCC" }}>
          <tr>
            <th>Geography</th>
            <th>Markets</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row.geography}</td>
                <td>
                  {row.markets.map((market, idx) => (
                    <p key={idx}>{market}</p>
                  ))}
                </td>
                <td className={classes.actions}>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ backgroundColor: "green" }}
                      variant="success"
                      // onClick={() => handleSelectedData(row)}
                    >
                      Update
                    </Button>{" "}
                    <Button
                      style={{ backgroundColor: "#d32f2f" }}
                      variant="danger"
                      onClick={() => handledeleteGeoMarket(row?.geographyId)}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return null;
};

export default GeoMarketsComponent;
