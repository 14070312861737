import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

import classes from "./SentimentBar.module.css";

export default function SentimentBar({ sentiment, confidenceScores }) {
  const { positive, negative, neutral } = confidenceScores;

  const positivePercentage = Number(positive * 100).toFixed(0);
  const neutralPercentage = Number(neutral * 100).toFixed(0);
  const negativePercentage = Number(negative * 100).toFixed(0);

  return (
    <div className={classes.container}>
      <div className={classes.heading}>{sentiment}</div>
      <div className={classes.bar}>
        <div
          className={classes.bar_chunk}
          style={{ background: "#e2474d", width: `${negativePercentage}%` }}
        ></div>

        <div
          className={classes.bar_chunk}
          style={{ background: "#fb9d1c", width: `${neutralPercentage}%` }}
        ></div>
        <div
          className={classes.bar_chunk}
          style={{ background: "#45a456", width: `${positivePercentage}%` }}
        ></div>
      </div>
      <div className={classes.sentiment_legends}>
        <div>
          <span className={classes.sentiment_label}>Negative</span>
          <div className={classes.sentiment_score}>
            <SentimentVeryDissatisfiedIcon style={{ color: "#e2474d" }} />
            <span>{negativePercentage}%</span>
          </div>
        </div>
        <div>
          <span className={classes.sentiment_label}>Neutral</span>
          <div className={classes.sentiment_score}>
            <SentimentDissatisfiedIcon style={{ color: "#fb9d1c" }} />
            <span>{neutralPercentage}%</span>
          </div>
        </div>
        <div>
          <span className={classes.sentiment_label}>Positive</span>
          <div className={classes.sentiment_score}>
            <SentimentVerySatisfiedIcon style={{ color: "#45a456" }} />
            <span>{positivePercentage}%</span>
          </div>
        </div>
      </div>
    </div>
  );
}
