import api from "../../BrandOnBoarding/api";
import {
  FETCH_PERSONAS_REQUEST,
  FETCH_PERSONAS_SUCCESS,
  FETCH_PERSONAS_FAILURE,
  CREATE_PERSONA_REQUEST,
  CREATE_PERSONA_SUCCESS,
  CREATE_PERSONA_FAILURE,
  UPDATE_PERSONA_REQUEST,
  UPDATE_PERSONA_SUCCESS,
  UPDATE_PERSONA_FAILURE,
  DELETE_PERSONA_REQUEST,
  DELETE_PERSONA_SUCCESS,
  DELETE_PERSONA_FAILURE,
} from "../constants/actionTypes";

export const fetchPersonas = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PERSONAS_REQUEST });
  try {
    const response = await api.get(`/customers/${id}/personas`);
    dispatch({ type: FETCH_PERSONAS_SUCCESS, payload: response.data });
    return response.data
  } catch (error) {
    dispatch({ type: FETCH_PERSONAS_FAILURE, error: error.message });
  }
};

export const createPersona = (id, persona) => async (dispatch) => {
  dispatch({ type: CREATE_PERSONA_REQUEST });
  try {
    const response = await api.post(
      `/customers/${id}/customer-persona`,
      persona
    );
    dispatch({ type: CREATE_PERSONA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: CREATE_PERSONA_FAILURE, error: error.message });
  }
};

export const updatePersona = (id, personaId, persona) => async (dispatch) => {
  dispatch({ type: UPDATE_PERSONA_REQUEST });
  try {
    const response = await api.put(
      `/customers/${id}/customer-persona/${personaId}`,
      persona
    );
    dispatch({ type: UPDATE_PERSONA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_PERSONA_FAILURE, error: error.message });
  }
};

export const deletePersona = (id, personaId) => async (dispatch) => {
  dispatch({ type: DELETE_PERSONA_REQUEST });
  try {
    await api.delete(`/customers/${id}/customer-persona/${personaId}`);
    dispatch({ type: DELETE_PERSONA_SUCCESS, payload: id });
  } catch (error) {
    dispatch({ type: DELETE_PERSONA_FAILURE, error: error.message });
  }
};

export const updatePersonaMonitoring = (id, personaId, persona) => async (dispatch) => {
  dispatch({ type: UPDATE_PERSONA_REQUEST });
  try {
    const response = await api.patch(
      `/customers/${id}/customer-persona/${personaId}`,
      persona
    );
    dispatch({ type: UPDATE_PERSONA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: UPDATE_PERSONA_FAILURE, error: error.message });
  }
};
