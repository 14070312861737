import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import classes from "./Spinner.module.css";

export default function Spinner({ size = 22, style, color }) {
  return (
    <Box className={classes.container} style={style}>
      <CircularProgress size={size} style={{ color: color }} />
    </Box>
  );
}
