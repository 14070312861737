import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Typography } from "@mui/material";

export default function BasicList({ title, data, render, className }) {
  return (
    <Box sx={{ width: "100%" }}>
      <List
        subheader={<Typography variant="h6">{title}</Typography>}
        dense
        sx={{ paddingBottom: 0 }}
        className={className}
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? "mt-2 list-item" : "list-item"}`}
          >
            {render(item, index)}
          </div>
        ))}
      </List>
    </Box>
  );
}
